.terms-policy-page {
  max-width: 1040px;
  margin: auto;
  padding: 40px 0;
  color: #666666;
}

.terms-policy-page h1 {
  text-align: center;
  font-size: 30px;
  margin-top: 40px;
  color: #666666;
}
.terms-policy-page .contact-wrapper{
  margin-bottom: 20px;
}
.terms-policy-page .contact-wrapper p{
  margin:0;
  color: #666666;
}
.terms-policy-page h2 {
  margin: 35px 0 20px 0;
  color: #666666;
}

.terms-policy-page h3 {
  margin: 20px 0;
  color: #666666;
}
.terms-policy-page h4 {
  margin: 30px 0 15px 0;
  color: #666666;
}

.terms-policy-page p {
  margin-top: 20px;
  line-height: 20px;
  font-size: 16px;
  color: #484848;
}
.terms-policy-page ol {
  margin: 10px 70px;
}

.terms-policy-page a {
  color: blue !important;
}

.terms-policy-page ol li {
  display: list-item;
  margin: 0;
  font-size: 16px;
  color: #484848;
}
.terms-policy-page .last-modified{
  font-style: italic;
}
.terms-policy-page .dot-list {
  list-style-type: disc;
}
.terms-policy-page .ordered-list li{
  margin: 0;
}
.terms-policy-page  table {
  border-collapse: collapse;
  width: 100%;
}

.terms-policy-page  table td, .terms-policy-page  table th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 16px;
}

.terms-policy-page  table tr:nth-child(even){
  background-color: #f2f2f2;
}

.terms-policy-page  table tr:hover {
  background-color: #ddd;
}

.terms-policy-page  table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #1388c6;
  color: white;
}
