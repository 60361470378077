.mrp-page {
  min-height: calc(100vh - 72px);
  position: relative;
}

.mrp-page .sticky-bars {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
}

.mrp-page .sticky-bars > * {
  position: relative;
}
.mrp-page .cancellation-policy{
  color: #218234 !important;
  font-weight: 700;
}
.mrp-page .no-cancellation-policy{
  color: #666666 !important;
  font-weight: 700;
}
.mrp-page .options-bar-outer {
  background-color: #f5f5f5;
  padding: 5px 0;
  cursor: pointer;
}

.mrp-page .cancellation-policy > span > span, .mrp-page .no-cancellation-policy > span > span {
  display: inline;
}

.mrp-page .options-bar-inner {
  background-color: #efefef;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px;
}

.mrp-page .options-bar-inner .guests {
  font-weight: bold;
}

.mrp-page .options-bar-inner .dates-wrapper {
  display: flex;
  align-items: center;
  color: #28b6ff;
}

.mrp-page .options-bar-inner .dates-wrapper > * {
  margin-left: 8px;
}

.mrp-page .options-bar-inner .dates-wrapper .dates {
  text-decoration: underline;
  font-weight: bold;
}

.mrp-page .options-bar-inner .dates-wrapper .vertical-separator {
  height: 20px;
  border-left: 1px solid #28b6ff;
}

.mrp-page .options-bar-inner .dates-wrapper svg {
  width: 13px;
  height: 100%;
  margin-top: -2px;
  fill: #28b6ff;
}

.mrp-page .property-main {
  background: #fff;
}

.mrp-page .about-section {
  margin: 20px;
}

.mrp-page .accessibility-section {
  margin: 20px 0;
}
.mrp-page .accessibility-box li.mrp {
  width: 25%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.mrp-page .property-main .center-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mrp-page .property-main .center-content .loading-property {
  text-align: center;
  font-size: 26px;
}

.object-page-no-results{
  background-image: url("../../images/object-page/object-page-no-results.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 25px;
  background-size: auto 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 120px 0px 30px 0px;
  align-items: center;
  margin: 10px 0px;
  border: 1px solid lightgrey;

}
.object-page-no-results p{
  font-weight: bold;
  font-size: 16px;
  color: black;
  width: 270px;
  text-align: center;
  margin-bottom: 15px;
}
.mrp-page .search-bar-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #1e1e1e;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.2);
}

.mrp-page .search-bar-wrapper .search-bar {
  display: flex;
  justify-content: space-between;
  align-content: stretch;
  max-width: 1200px;
  width: 100%;
  padding: 20px;
}

.mrp-page .search-bar-wrapper .search-bar > * {
  border-radius: 3px;
  flex: 1;
  margin-left: 5px;
  border: none;
}

.mrp-page .search-bar-wrapper .search-bar select {
  border-radius: 3px;
}

.mrp-page .search-bar-wrapper .search-bar:first-child {
  margin: 0;
}

.mrp-page .book-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: #0077cb;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  z-index: 1000;
  font-weight: bold;
}

.mrp-page .book-bar .selected {
  background: inherit;
  border: none;
}

.mrp-page .property-main.host {
  border-top: none;
}

.mrp-page .property-main.host .section-wrapper {
  justify-content: flex-end;
}

.mrp-page .property-main.host .section-wrapper.title {
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
/* .mrp-page  .description-section:before {
  content: '';
  display: block;
  padding-top: 93px;
  margin-top: -93px;
} */

.mrp-page .search-bar-wrapper button.search {
  padding: 0 15px 0 15px;
  height: 54px;
}

.mrp-page .search-bar-wrapper .search-bar .mobile-drop-down-wrapper {
  flex: 2;
  height: auto;
}
.mrp-page .search-bar-wrapper .search-bar .date-picker {
  flex: 1;
}
.mrp-page .search-bar-wrapper .search-bar .select-dropdown {
  height: auto;
}
.mrp-spacer {
  height: 87px;
  background: white;
}
.mrp-page .mobile-drop-down {
  position: absolute;
}
button[disabled] {
  background: #9d9d9d;
}
button.space-below {
  margin-bottom: 10px;
}
button.mrp-booking, button.mrp-confirm {
  height: 37px;
  padding: 5px 7px;
  font-weight: 400;
}

.mrp-page .contact-hotel {
  font-size: 20px;
  font-weight: bold;
  color: #484848;
  padding: 0 20px 0 0;
}

.object-page-title__wrapper{
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  justify-content: space-between;
}
.object-page-title__left{
  display: flex;
  flex-direction: column;
}
.object-page-title__right{
  display: flex;
  align-items: center;
}
.object-page-title__property-title-wrapper{
  display: flex;
}
.object-page-title__mobility-icon-wrapper{
  display: flex;
  align-items: center;
}
.object-page-title__mobility-icon-wrapper .mobility-icon{
  max-width: 40px;
  max-height: 40px;
}
.object-page-title__property-title{
  font-size: 40px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 0;
  margin-left: 10px;
}
.object-page-title__property-address-wrapper{
  display: flex;
  margin-top: 1rem;
  align-items: baseline;
}
.object-page-title__property-address{
  position: relative;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #666666;
  margin-left: 16px;
}
.object-page-title__property-address::before{
  background-image: url('../../images/object-page/map-pin-gray.svg');;
  content: " ";
  position: absolute;
  width: 12px;
  height: 16px;
  left: -15px;
  top: 2px;

}
.object-page-title__property-address-map-link{
  color: #0E75BA;
  font-size: 16px;
  text-transform: capitalize;
  text-decoration: underline;
  font-weight: 700;
  margin-left: 7px;
}
.object-page-title__breadcrumb-item{
  position: relative;
  margin-left: 25px;
}
.object-page-title__breadcrumb-item::after{
  content: '>';
  position: absolute;
  right: -15px;
}
.object-page-title__breadcrumb-item:last-child:after{
  display: none;
}
.object-page-title__breadcrumb-item:nth-child(1){
  margin-left: 0;
}
.object-page-title__breadcrumb-item a{
  font-weight: 500;
  font-size: 14px;
  color: #1E1E1E;
}
.object-page-title__right .validated-icon{
  max-width: 105px;
  max-height: 73px;
}
.tripadvisor-review{
  margin-left: 20px;
  background-color: #F5F5F5;
  padding: 10px 20px;
  border-radius: 6px;
}
.tripadvisor-review .required-text{
  font-size: 14px;
  color: #000000;
}
.tripadvisor-review .tripadvisor-review-quantity{
  font-size: 14px;
  color: #000000;
}
.facilities{
  margin-bottom: 0;
}
.facilities .single-property-perks{
  border-top: 1px solid #E6E6E6;
  padding-top: 20px;
}
.facilities .single-property-perks li{
  flex-basis: inherit;
}
.facilities .single-property-perks .single-property-perk{
  display: flex;
  align-items: center;
  max-height: 45px;
  margin-right: 20px;
  min-width: fit-content;
}
.facilities .single-property-perks .single-property-perk span{
  min-width: fit-content;
}
.table-header-facilities{
  margin-top: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 0 10px;
  border-bottom: 1px solid #E6E6E6;
  color: black;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.table-header-facilities ul{
  margin-top: 10px;
  margin-bottom: 0;
}
.table-header-facilities ul > li{
  margin-bottom: 5px;
}
.table-header-facilities ul > li > .single-property-perk{
  padding: 5px;
  width: max-content;
  margin: 0 5px 0 0;
}
.table-header-facilities ul > li > .single-property-perk  img{
  background-color: transparent;
  width: auto;
  height: 16px;
  margin-top :0px;
}
.table-header-facilities ul > li > .single-property-perk  span{
font-size: 14px;
}
.table-header-nrGuests{
  display:flex;
  margin:10px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid #E6E6E6;
  border-top: 1px solid #E6E6E6;
  color: black;
  font-weight: bold;

}
.facilities .outside-facilities-box .expand-button,.facilities .outside-facilities-box .collapse-button{
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 21px 15px;
  font-weight: bold;
  text-decoration: underline;
}
.put-info .expand-button,.put-info  .collapse-button{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-decoration-line: underline;
  color: #0E75BA;
  cursor: pointer;
  width:100%;
  margin-bottom: 5px;
}
.put-info .table-all-details-text{
  margin: 0 0 0 10px;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  color: #0E75BA;
  text-decoration-line: underline;
  cursor: pointer;
}
.put-info .expand-button .expand-button-arrow,.put-info .collapse-button .collapse-button-arrow{
  width: 10px;
  height: 16px;
  background: transparent;
  margin : 0 5px;
}
.put-info .collapse-button .collapse-button-arrow{
  transform: rotate(180deg) 
}
.table-header-nrGuests > div{
  flex-direction: row !important;
  text-transform: lowercase;
  color:#666666
}
.table-header-nrGuests img{
  background-color: transparent !important;
  margin: 0 5px !important;
}
.object-page-table-included-icons{
  width: auto;
  height: 16px;
  margin-right: 5px;
}
.mrp-page .description-section .description-about-property{
  border-top: 1px solid #E6E6E6;
  padding-top: 20px;
}
.mrp-page .description-section .description-about-property{
  max-height: 280px;
}
.mrp-page .description-about-property.show-all{
  max-height: none;
}
.mrp-page .description-about-property.expandable div.main::after{
  display: none;
}
.mrp-page .description-about-property button{
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  text-decoration: underline;
  border: none;
  border-radius: 0;
  padding: 0;
}
.mrp-page .description-fader{
  position: absolute;
  left: 0;
  right: 0;
  top: 110px;
  height: 120px;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #fff);
}
.mrp-page .host .object-page-title__host-wrapper{
    display: flex;
    justify-content: space-between;
    background: rgba(14, 117, 186, 0.1);
    padding: 20px;
    border: 1px solid #0E75BA;
    border-radius: 3px;
}
.mrp-page .host .object-page-title__host-wrapper .object-page-title__host-left{
  display: flex;
  flex-direction: column;
}
.mrp-page .host .object-page-title__host-wrapper .object-page-title__host-disclaimer{
  color: #0E75BA;
  font-size: 20px;
  font-weight: bold;
}
.mrp-page .host .object-page-title__host-wrapper .object-page-title__host-disclaimer-small{
  font-size: 16px;
  color: #0E75BA;
}
.mrp-page .object-page-title__host-button-wrapper .about-host-contact-button{
  position: relative;
  background: #0E75BA;
    font-size: 18px;
    padding: 16px 43px 16px 76px;
}
.mrp-page .property-main.host{
  border-bottom: none;
}
.mrp-page .object-page-title__host-button-wrapper .about-host-contact-button::before{
  content: ' ';
  position: absolute;
  width: 23px;
  height: 23px;
  background-image: url('../../images/object-page/host-dialog-icon.svg');
  left: 31px;
  top: 19px;
}
.mrp-page .object-page-title__best-price-wrapper{
    display: flex;
    padding: 20px;
    background: rgba(33, 130, 52, 0.1);
    border: 1px solid #218234;
    border-radius: 3px;
    justify-content: space-between;
}
.mrp-page .object-page-title__best-price-left img{
  max-width: 70px;
  max-height: 70px;
}
.mrp-page .object-page-title__best-price-left{
  display: flex;
  flex-direction: column;
}
.mrp-page .object-page-title__best-price-disclaimer{
  color: #218234;
  font-weight: bold;
  font-size: 20px;
}
.mrp-page .object-page-title__best-price-disclaimer-small{
  color: #218234;
  font-weight: normal;
  font-size: 16px;
}
.mrp-page .puts-section{
  margin-top: 20px;
}
.mrp-page .object-page-mobile-select-btn__validated{
  font-size: 13px;
  font-weight: bold;
  background: #F5F5F5;
  color: #1E1E1E;
  padding: 10px 15px 10px 60px;
  position: relative;
}
.mrp-page .object-page-mobile-select-btn__validated::before{
  content: '';
  position: absolute;
  background-image: url('../../images/object-page/accessibility-validated-by-handsicover.png');
  width: 41px;
  height: 28px;
  background-size: contain;
  left: 11px;
  top: 6px;
}
.mrp-page .object-page-mobile-select-btn__btn-wrapper{
  padding: 10px 15px;
  padding-top: 20px;
}
.mrp-page .object-page-mobile-select-btn__btn{
  width: 100%;
  height: 50px;
  background-color: #0E75BA;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  outline: none;
}
.mrp-page .puts-section .rooms select{
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .outside-facilities-box .single-property-perks li > div{
    margin-right:0 !important
  }
  .mrp-page .accessibility-section {
    margin: 0;
  }
  .mrp-page .accessibility-box li.mrp {
      width: 50%;
  }
  .property-main.host .section-wrapper.title {
    flex-direction: column;
    justify-content: left;
  }
  .contact-hotel {
    padding: 0 0 20px 0;
    text-align: left;
  }
  .object-page-title__breadcrumbs-wrapper{
    display: none;
  }
  .object-page-title__property-title{
    font-size: 24px;
    margin-left: 0;
    text-indent: 42px;
    line-height: 32px;
  }
  .object-page-title__wrapper{
    flex-direction: column;
    position: relative;
  }
  .object-page-title__wrapper .validated-icon{
    display: none;
  }
  .object-page-title__wrapper .tripadvisor-review{
    position: absolute;
    top: 110%;
    z-index: 1;
    left: 37%;
    background: rgba(245, 245, 245, 0.9);
    padding: 5px;
  }
  .object-page-title__property-address-map-link,
  .object-page-title__property-address{
    font-size: 14px;
    display: inline;
  }
  .object-page-title__property-address-wrapper{
    display: block;
  }
  .mrp-page .facilities .single-property-perks li{
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .mrp-page .host .object-page-title__host-wrapper{
    flex-direction: column;
  }
  .mrp-page .host .object-page-title__host-disclaimer-small{
    display: none;
  }
  .mrp-page .object-page-title__host-disclaimer{
    text-align: center;
    font-size: 18px;
  }
  .mrp-page .object-page-title__host-button-wrapper .about-host-contact-button{
    margin: auto;
    margin-top: 20px;
    padding-left: 60px;
  }
  .mrp-page .accessibility-box .icon{
    width: 35px;
    height: 35px;
  }
  .mrp-page .headers h4{
    font-size: 14px;
  }
  .mrp-page .puts-section img.best-price{
    display: none;
  }
  .object-page-title__mobility-icon-wrapper{
    width: 35px;
    height: 35px;
    position: absolute;
  }
  .object-page-title__property-title-wrapper{
    position: relative;
  }
  .object-page-title__mobility-icon-wrapper .mobility-icon{
    width: 35px;
    height: 35px;
  }
  .mrp-page .property-page .top-images > img,
  .property-page .top-images{
    /* height: 37vh; */
  }
  
  }