/* Information Table */

.information-table {
  display: flex;
  width: 100%;
  border-top: 1px solid #D4D4D4;
  border-right: 1px solid #D4D4D4;
  background-color: white;
  flex-wrap: wrap;
  border-radius: 3px;
  margin-bottom: 40px;
}
.right-side-content .information-table {
	margin: 10px 0 10px 0;
}
.information-table ul {
	flex: 1;
}

.information-table li {
  flex-basis: 50%;
  display: flex;
  font-size: 12px;
  color: #1e1e1e;
}
.information-table.one-col li {
	flex-basis: 100%;
}
.information-table li:first-child p {
	border-top-left-radius: 3px;
}
.information-table.two-cols li:nth-last-child(2):nth-child(odd) p, .information-table.two-cols li:nth-last-child(1):nth-child(odd) p {
	border-bottom-left-radius: 3px;
}
.information-table.one-col li:nth-last-child(1) {
	border-bottom-left-radius: 3px;
}
.information-table li p {
  font-size: 12px;
  color: #1e1e1e;
  font-weight: bold;
  flex:1;
}
.information-table li p, .information-table li div {
	border-bottom:1px solid #d4d4d4;
	border-left:1px solid #d4d4d4;
	padding: 10px;
	margin: 0;
}

.information-table li div {
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  flex: 1.5;

}
.information-table li div.has-label {
	padding: 2px;
}
.information-table span.label {
	margin-left: 0;
}
@media(max-width: 575px) {
	.information-table li {
		flex-basis: 100%;
	}
}
.information-table .has-tooltip > .tooltip {
  font-family: 'Quicksand', sans-serif;
  position: absolute;
  top: 25px;
  left: 120px;
  max-width: 220px;;
  background: #9d9d9d;
  color: white;
  padding: 4px 8px 4px;
  border-radius: 8px;
  border: 1px solid black;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.3);
  z-index: 999;
  text-align: center;
  display: none;
}
.information-table .has-tooltip:hover > .tooltip {
  opacity: 1;
  display: block;
}
