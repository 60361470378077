.about-host-section .single-property-about-host {
	display: flex;
}

.about-host-section .about-host-picture-container {
	margin-right: 30px;
}

.about-host-section .about-host-picture {
	width: 140px;
	height: 140px;
	background: url('../../images/profile/default-avatar.png') no-repeat;
	background-size: cover;
	background-position: center center;
	box-shadow: inset 0 0 2px rgba(0,0,0,.5);
	margin-bottom: 10px;
	border-radius: 3px;
	position: relative;
}

.about-host-section .about-host-picture::before {
	top: 6px;
	left: 6px;
	position: absolute;
}

.puts-section .about-host-contact-button,
.about-host-section .about-host-contact-button {
	background: #4fc33c;
	border-radius: 4px;
	padding: 5px 10px;
	color: #fff;
	justify-content: center;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
}

.puts-section .about-host-contact-button::before,
.about-host-section .about-host-contact-button::before {
	width: 20px;
	height: 20px;
	background-image: url('../../images/object-page/contact.png');
}

.about-host-section .about-host-buttons {
	display: flex;
	margin-bottom: 20px;
}

.about-host-section .about-host-buttons > div {
	border: 1px solid #d4d4d4;
	border-radius: 2px;
	background: white;
	padding: 8px 10px;
	margin-right: 10px;
	font-weight: 700;
}

.about-host-section .verified::before {
	width: 20px;
	height: 20px;
	background-image: url('../../images/object-page/verified-2.png');
}

.about-host-section .about-host-buttons > div.ratings-button {
  padding-left: 2px;
}

.about-host-section .ratings-button span.label {
	margin-left: 0;
}

/* About host properties */
.about-host-section .about-host-properties ul {
	display: flex;
	margin-left: -20px;
}

.about-host-section .about-host-properties li {
	width: 25%;
}

.about-host-section .about-host-properties li .inner {
	border: 1px solid #d4d4d4;
	border-radius: 4px;
	margin-left: 20px;
}

.about-host-section .about-host-properties-image {
	padding-bottom: 75%;
	background-size: cover;
}

.about-host-section .about-host-property-title {
	padding: 5px;
}

.about-host-section .about-host-property-title p.location {
	font-size: 12px;
}

.about-host-section .about-host-property-title .name {
	font-weight: 600;
}
