.slideshow-container {
  position: relative;
  width: 80%;
  height: 60vh;
  margin: 0 auto;
  margin-top: 20px !important;
}
.slide{
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.8158613787311799) 32%, rgba(0,0,0,0.6730042358740371) 73%, rgba(255,255,255,0.10717790534182425) 100%);
  width: 100%;
  height: 100%;
  position: relative;
}
.slideshow-clip {
  overflow: hidden;
  height: 100%;
}

.slide-left-arrow {
  direction: rtl;
  left: 25px;
}

.slide-right-arrow {
  direction: ltr;
  right: 25px;
}

.slide-arrow {
  position: absolute;
  height: 50px;
  width: 50px;
}

.slide-arrow:hover {
  transform: scale(1.1);
}

.learn-more {
  margin-top: 10px;
  margin-bottom: 20px;
  align-self: center;
  display: none;
}

.slide-textBlock {
  left: 12%;
  width: 75%;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 25px;
  position: absolute;
  bottom: 40px;
  font-family: inherit;
}
.slide-textBlock>.slide-title>h1 {
  color: white;
  font-size: 23px;
  margin-bottom: 0px;
}
.slide-textBlock>.slide-title {
  border: 2px solid white;
  padding: 1px;
  align-self: flex-start;
}
.slide-textBlock h2 {
  font-size: 40px;
  color: white;
  word-wrap: break-word;
  font-weight: 900 !important;
  max-width: 800px;
  font-family: inherit;
  margin-bottom:10px
}
.slide-textBlock p {
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  color: white;
  font-family: inherit;
  width: auto;
  padding: 2px;
  margin: 0px;
  margin-bottom: 10px;
  text-overflow: inherit;
  overflow: hidden;
  max-height: 50px;
  flex: none;
}

a.slide-textBlock-small::after {
  content: "|";
  margin: 0px 5px;
}

a.slide-textBlock-small:last-child::after {
  content: "";
}

.slide-textBlock-small {
  font-size: 14px !important;
  color: white;
}
.slide-textBlock-small:hover {
  color: white;
}

.learn-more > button, 
.learn-more > a.button {
  padding: 10px 30px !important;
}
.slide-tags {
  font-family: inherit;
  word-wrap: break-word;
  padding-top:2px;
  font-size: 10px;
  font-weight: 600;
  margin-left: 10px;
}

.slide-arrow {
  display: flex;
  top: 48%;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  transition: transform ease-in 0.1s;
}

.slide-tags::before {
  content:"";
  background-image: url("../images/icons/tag_icon_slider.png");
  background-size: 10px 10px;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 5px;
}
.slide-dt-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.image-link{
  position : absolute;
  height: 100%;
  width: 100%;
}
.slider-dots{
  position: absolute;
  bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
div.buttons button, a.button {
  margin-right: 0px !important;
}
@media screen and (max-width : 813px) {
  .slideshow-container {
    width: 95%;
  }
  .slide-textBlock>.slide-title>h1{
    font-size: 18px;
  }
  .slideshow-container {
    height: 50vh;
  }
  .slide-textBlock-description{
    display: none !important;
  }
  .slide-dt-wrapper {
    display: none !important;
  }
  .learn-more {
    display: block;
  }
  .slide-textBlock h2{
    font-size: 25px;
    padding-left: 10px;
  }
  .slide-textBlock h1{
    font-size: 18px;
  }
  .slide-textBlock button{
    margin-top: 40px;
  }
  .slide-arrow{
    background:transparent !important;
  }
  .slide-left-arrow {
    left: 15px;
  }
  .slide-right-arrow {
    right: 15px;
  }
  .slide-arrow {
    height: 30px;
    width: 30px;
  }
}
@media screen and (max-height : 500px) {
  .slide-textBlock-description{
    display: none !important;
  }
  .slide-dt-wrapper {
    display: none !important;
  }
  .slide-textBlock button{
    margin-top: 40px;
  }
  .slide-arrow{
    background:transparent !important;
  }
  .slide-left-arrow {
    left: 15px;
  }
  .slide-right-arrow {
    right: 15px;
  }
  .slide-arrow {
    height: 30px;
    width: 30px;
  }
}
@media screen and (max-width : 350px) {
  
  .slide-textBlock button{
    margin-top: 20px;
  }
  .slide-textBlock h2{
    font-size: 20px;
  }
  .slide-textBlock h1{
    font-size: 10px;
  }
  .slide-left-arrow {
    left: 10px;
  }
  .slide-right-arrow {
    right: 10px;
  }
}
@media (orientation: landscape)
and (max-device-width: 812px) {
  .learn-more {
    align-self: center;
    display: block;
  }
  .slide-textBlock {
    left: 12%;
  }
  .slideshow-container{
    min-height: 300px;
  }
}