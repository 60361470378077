.address-form span {
  font-weight: bold;
  color: #484848;
  margin-bottom: 6px;
}

.address-form .address-form-content {
  margin-top: 20px;
}

.address-form .google-input-field,
.address-form .input-field-wrapper {
  margin-top: 0px;
  height: 52px;
}

.address-form label {
    margin-bottom: 20px;
    width: 100%;
    display: block;
}

.address-form .country-change-disabled {
    font-size: 12px;
}