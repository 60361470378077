/*css style for the B&B landings*/

/*top section*/

.landings-page-top-section-bb-amsterdam{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Amsterdam-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-barcelona{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Barcelona-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-berlin{
    background: #b3dff9 url('../images/landings-index/Accessible-Berlin-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-blackpool{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Blackpool-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-cape-town{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Cape-Town-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-ceredigion{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Ceredigion-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-cornwall{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Cornwall-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
/*.landings-page-top-section-bb-devon{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Devon-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}*/
.landings-page-top-section-bb-dorset{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Dorset-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-dublin{
    background: #b3dff9 url('../images/landings-index/Accessible-Dublin-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}

.landings-page-top-section-bb-lisbon{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Lisbon-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-london{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-London-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-madrid{
    background: #b3dff9 url('../images/landings-index/Accessible-Madrid-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-majorca{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Majorca-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-nice{
    background: #b3dff9 url('../images/landings-index/Accessible-Nice-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-norfolk{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Norfolk-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-paris{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Paris-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-rome{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Rome-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-tenerife{
    background: #b3dff9 url('../images/landings-index/Accessible-Tenerife-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-venice{
    background: #b3dff9 url('../images/landings-index/Accessible-Venice-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-bb-yorkshire{
    background: #b3dff9 url('../images/landings-index/bb/Accessible-Yorkshire-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}

/*guide section*/

.guide-section-picture-bb-amsterdam {
    background: #b3dff9 url("../images/landings-index/bb/Amsterdam-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-barcelona {
    background: #b3dff9 url("../images/landings-index/bb/Barcelona-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-berlin {
    background: #b3dff9 url("../images/landings-index/Berlin-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-blackpool {
    background: #b3dff9 url("../images/landings-index/bb/Accessible-Blackpool-For-Disabled.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-cape-town{
    background: #b3dff9 url("../images/landings-index/bb/Cape-Town-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-ceredigion{
    background: #b3dff9 url("../images/landings-index/bb/Ceredigion-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-cornwall{
    background: #b3dff9 url("../images/landings-index/bb/Cornwall-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
/*.guide-section-picture-bb-devon{
    background: #b3dff9 url("../images/landings-index/bb/Devon-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}*/
.guide-section-picture-bb-dorset{
    background: #b3dff9 url("../images/landings-index/bb/Dorset-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-dublin{
    background: #b3dff9 url("../images/landings-index/Dublin-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-lisbon{
    background: #b3dff9 url("../images/landings-index/bb/Lisbon-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-london{
    background: #b3dff9 url("../images/landings-index/bb/London-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-madrid {
    background: #b3dff9 url("../images/landings-index/Madrid-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-majorca{
    background: #b3dff9 url("../images/landings-index/bb/Majorca-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-nice{
    background: #b3dff9 url("../images/landings-index/Nice-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-norfolk{
    background: #b3dff9 url("../images/landings-index/bb/Norfolk-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-paris{
    background: #b3dff9 url("../images/landings-index/bb/Paris-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-rome{
    background: #b3dff9 url("../images/landings-index/bb/Rome-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-tenerife{
    background: #b3dff9 url("../images/landings-index/Tenerife-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-venice{
    background: #b3dff9 url("../images/landings-index/Venice-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-bb-yorkshire{
    background: #b3dff9 url("../images/landings-index/bb/Yorkshire-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
