.profile-settings__input-label{
    font-size: 14px;
    line-height: 17px;
    color: #797676;
    font-weight: 500;
    margin: 0;
    display: initial;
}
.profile-settings__section-header{
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #161616;
    margin: 0;
}
.profile-settings__form-section{
    border-top: 1px solid #F3F3F3;
    padding-top: 21px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 41px;
}
.profile-settings__mobility-info-wrapper{
    position: relative;
}
.profile-settings__form-section:last-child{
    padding-bottom: 60px;
}
.profile-settings__input{
    height: auto;
}
.dashboard-page .input-field-wrapper.profile-settings__input input{
    height: auto;
    text-indent: initial;
    font-size: 17px;
    line-height: 21px;
    color: #161616;
    margin-top: 17px;
    border: 1px solid #E8E8E8;
    border-radius: 2px;
    padding: 12px;
}
.profile-settings__input-label .select-dropdown{
    height: initial;
    border: none;
    margin-top: 17px;
}
.profile-settings__personal-info-input-wrapper .profile-settings__input-select{
    text-indent: initial;
    font-size: 17px;
    line-height: 21px;
    color: #161616;
    padding: 12px;
    border: 1px solid #E8E8E8;
}
.profile-settings__personal-info-input-wrapper .profile-settings__input-select:disabled{
    border: none;
    color: #161616;
    background-color: #ffffff;
    padding: 13px;
    opacity: 1;
}
.dashboard-page .input-field-wrapper.profile-settings__input input:-moz-read-only { /* For Firefox */
    border: none;
    padding: 13px;
  }
  
.dashboard-page .input-field-wrapper.profile-settings__input input:read-only {
    border: none;
    padding: 13px; 
    background-color: #ffffff;
  }
 .profile-settings__input-label.edit-mode-false .select-dropdown::after{
     display: none;
 }
 .profile-settings__address-form-wrapper .address-form-content{
     margin-top: 0;
     display: flex;
     flex-wrap: wrap;
 }
 .profile-settings__address-form-wrapper .address-form label{
    margin-bottom: 0;
    width: 33%;
    margin-top: 30px;
    padding-right: 20px;
 } 
.profile-settings__address-form-wrapper .address-form label span{
    font-size: 14px;
    line-height: 17px;
    color: #797676;
    font-weight: 500;
    margin: 0;
 }
 .profile-settings__address-form-wrapper .address-form .input-field-wrapper{
     height: initial;
 } 
 .profile-settings__address-form-wrapper .address-form .input-field-wrapper input{
    height: auto;
    text-indent: initial;
    font-size: 17px;
    line-height: 21px;
    color: #161616;
    margin-top: 17px;
    border: 1px solid #E8E8E8;
    border-radius: 2px;
    padding: 12px; 
 }
.profile-settings__address-form-wrapper .address-form .select-dropdown{
    height: initial;
    border: none;
    margin-top: 17px;
 }
 .profile-settings__address-form-wrapper .address-form .select-dropdown select{
    text-indent: initial;
    font-size: 17px;
    line-height: 21px;
    color: #161616;
    padding: 12px;
    border: 1px solid #E8E8E8;
 }
 .profile-settings__address-form-wrapper .address-form .select-dropdown select:disabled{
    border: none;
    color: #161616;
    background-color: #ffffff;
    padding: 13px;
    opacity: 1;
}
.profile-settings__address-form-wrapper .address-form .input-field-wrapper input:-moz-read-only { /* For Firefox */
    border: none;
    padding: 13px;
  }
  
.profile-settings__address-form-wrapper .address-form .input-field-wrapper input:read-only {
    border: none;
    padding: 13px;
  }

.profile-settings__address-form-wrapper .address-form .select-dropdown::after{
    display: none;
}

 