.booking-srp-modal-user-address .input-field-wrapper{
    height: 47px;
    padding: 12px;
}
.booking-srp-modal-user-address .err{
    font-size: 15px;
    color: red;
}
.enquiry-modal-content .srp-booking-fields-group{
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}
.enquiry-modal-content .srp-booking-fields{
    /* width: 30%;
    margin: 0 5px; */
}
.enquiry-modal-content .address-form-content input{
    height: 40px;
}
.enquiry-modal-right-side .select-dropdown{
    height: 40px;
}
.booking-srp-modal-validation input{
    border: 1px solid red !important; 
}
.srp-modal-red-star::after{
    content: "*";
    color: red;
    position: absolute;
    display: block;
}
.address-srp-modal-input-wrapper{
    position: relative;
}
.srp-booking-fields{
    position: relative;
}
.address-srp-modal-input-wrapper::after{
    content: "*";
    color: red;
    position: absolute;
    display: block;
    display: block;
    left: 74px;
    top: -13px;
    font-size: 26px;
}
.address-srp-group-input::after{
    content: "*";
    color: red;
    position: absolute;
    display: block;
    left: 91px;
    top: -14px;
    font-size: 26px;
}
.address-srp-country-input::after{
    content: "*";
    color: red;
    position: absolute;
    display: block;
}
.address-srp-city-input::after{
    content: "*";
    color: red;
    position: absolute;
    display: block;
    left: 24px;
    top: -13px;
    font-size: 26px;
}
.address-srp-zip-input-label{
    position: relative;
}
.address-srp-zip-input-label::after{
    content: "*";
    color: red;
    position: absolute;
    display: block;
    left: 58px;
    top: -14px;
    font-size: 26px;
    font-weight: 400;
}
.srp-booking-fields-phone-wrapper{
    position: relative;
}
.srp-booking-fields-phone-wrapper input{
    height: 40px;
}
.srp-booking-modal-total-price{
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px !important;
    padding-top: 10px;
}
.srp-booking-fields-phone-wrapper span{
    font-size: 13px;
    font-weight: 700;
}
.srp-booking-fields-phone-wrapper span::after{
    content: "*";
    color: red;
    position: absolute;
    display: block;
    left: 157px;
    top: -12px;
    font-size: 26px;
    font-weight: 500;
}
.srp-booking-fields-wrapper{
    width: 32%;
}
.srp-booking-fields-phone-error input{
    border: 1px solid red;
}
.center-mobile-srp-page{
    display: flex;
    flex-direction: column;
}
.srp-booking-fields-city-zip-input{
    width: 48%;
}
.enquiry-modal-content label{
    margin-bottom: 0;
    padding: 0 0;
}
.address-srp-country-input-label{
    position: relative;
}
.address-srp-country-input-label::before{
    content: "*";
    color: red;
    position: absolute;
    display: block;
    left: 49px;
    top: -14px;
    font-size: 26px;
    font-weight: 400;
}
.srp-booking-modal-price-disclaimer{
    margin-top: 18px !important;
    font-size: 13px;
    color: #969494;
}