.profile-settings__user-avatar-modal__after-open{
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-settings__user-avatar-modal-content__after-open{
    max-width: 700px;
    width: 100%;
    background: #fff;
    position: relative;
    border: 1px solid gray;
    position: relative;
    padding-top: 34px;
    border-radius: 5px;
}
.profile-settings__user-avatar-modal-content-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile-settings__user-avatar-header{
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #050709;
    text-align: center;
    margin-bottom: 0;
}
.profile-settings__user-avatar-modal-wrapper{
    width: 115px;
    height: 115px;
    margin-top: 22px;
}
.profile-settings__user-avatar-modal-button{
    background: #007EB1;
    border: 1px solid #007EB1;
    border-radius: 5px;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    padding: 10px 14px;
    margin-top: 22px;
    color: #fff;
    cursor: pointer;
    margin-bottom: 33px;
}
.profile-settings__user-avatar-modal-input{
    opacity: 0;
   position: absolute;
   z-index: -1;
}
.profile-settings__user-avatar-modal-close-button{
    position: absolute;
    width: 31px;
    height: 31px;
    background-image: url('../../images/user-profile-page/close-cross-icon.svg');
   border: 1px solid rgba(121, 118, 118, 0.5);
   border-radius: 5px;
   padding: 0;
   background-color: #fff;
   background-repeat: no-repeat;
   background-position: center;
   right: 25px;
    top: 25px;
}
.profile-settings__user-avatar-modal-change-button{
    margin-top: 7px;
    margin-bottom: 0;
    display: block;
}
.profile-settings__user-avatar-modal-change-button span{
    background-color: transparent;
    color: #007EB1;
    text-decoration: underline;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
}
.profile-settings__user-avatar-modal-button-wrapper{
    margin-top: 27px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.profile-settings__user-avatar-modal-button-wrapper button{
    background: #007EB1;
    border-radius: 0;
    padding: 25px;
}
.profile-settings__user-avatar-modal-button-cancel{
    width: 49.5%;
    text-align: left;
}
.profile-settings__user-avatar-modal-button-save{
    width: 50%;
    text-align: right;
}