ul.counter-fields {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.counter-field {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 52px;
  min-width: 100px;
  border-radius: 3px;
  border: solid 1px #aaa;
}

.counter-field .input-field-wrapper {
  flex: 4;
  height: 100%;
}

.counter-field input[type=text] {
  border: none;
  text-indent: 0;
  text-align: center;
}

.counter-field > button {
  width: 50px;
  border: none;
  /*border-left: solid 1px #0c4059;*/
  margin-left: 1px;
  border-radius: 3px;
  background-color: #FFF;
  font-size: 30px;
  line-height: 47px;
  color: #464646;
  font-weight: bold;
  padding-bottom: 5px;
}

.counter-field > button.minus {
  color: rgba(0,0,0,0.4);
}
button.minus, button.plus {
	padding: 0;
	border-left: 1px solid #d6d6d6;
}