.payment-methods-modal-overlay {
  background-color: rgba(0,0,0,0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.payment-methods-modal-content {
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  width: 600px;
  height: 400px;
  outline: none;
  padding: 20px;
  border: 1px solid gray;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-methods-modal-content > div > div {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}

.payment-methods-modal-content > div > div > div {
  flex-grow: 2;
  margin-right: 10px;
  margin-left: 10px;
}

.payment-methods-modal-content > div {
  width: 100%;
}

.payment-methods-modal-card-expiriry-year {
  margin-top: 16px;
}

.payment-methods-modal-buttons {
  margin-right: 10px;
  justify-content: flex-end !important;
}
.payment-methods-modal-buttons button {
  margin-left: 20px;
}
