.dashboard-settings-layout {
  margin-top: 20px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 15px;
}

.dashboard-settings-layout a {
  color: #636363;
}

.dashboard-selected {
  font-weight: bold;
  color: #383838 !important;
}

.dashboard-settings-layout a:hover {
  color: #ADADAD;
}

.dashboard-settings-layout > .navigation {
  flex: 1;
  margin-right: 30px;
}

.dashboard-settings-layout > .navigation li {
  display: block;
  margin-bottom: 12px;
}

.dashboard-settings-layout > .content {
  display: flex;
  flex: 4;
  border: 1px solid #d4d4d4;
  width:100%;    
  margin-bottom: 50px;
  border-top: 6px solid #189ce2;
  background-color: white;
}
