.become-host-wrapper{
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    height: 200px;
    justify-content: space-around;
}
.become-host-select-dropdown {
    width: 100%;
}
.stripe-update-content-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 16px;
}
.stripe-update-btn{
    margin-right: 0 !important;
}
.stripe-update-close-btn{
    background-color: #ff7059;
}
.stripe-host-section{
    display: flex;
    justify-content: space-between;
}
.stripe-payments-info-wrapper{
    display: flex;
}
.stripe-payments-disallowed{
    color: red;
}
.stripe-payments-allowed{
    color: green;
}
.stripe-payments-info-charges{
    box-shadow: inset -1px 0 #9DA0AD
}
.stripe-payments-info{
    padding: 0 20px;
} 
a.stripe-payments-disallowed{
color: red;
}
a.stripe-payments-disallowed:hover{
    text-decoration: none;
    }
.payout-methods .hidden-tr{
        display: none;
}
.payouts-section .hidden-tr{
    display: none;
}
.profile-settings__host-modal__after-open {
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-settings__host-modal-content__after-open{
    background: #fff;
    position: relative;
    border: 1px solid gray;
    padding-top: 34px;
    border-radius: 5px;
    padding: 60px;
}
.profile-settings__host-modal-content-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 110px;
    font-size: 26px;
    font-weight: 500;
} 