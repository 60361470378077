.landings-page{
    position: relative;
}

.landings-page__content{
    position: relative;
}

.guide-section{
    margin-top: 30px;
}

.guide-section__wrap{
    color: #484848;
    padding-bottom: 10px;
}

.guide-section-picture{
    width: 100%;
    height: 100%;
    min-height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-weight: bold;
    text-shadow: 0 0 2px black;
}

.guide-section-picture:hover{
    color: white;
}

.guide-section__picture-text-top{
    font-size: 35.1px;
}

.guide-section__picture-text-bott{
    font-size: 20px;
}

.properties-section__title{
    margin: 0;
    color: #484848;
    line-height: 24px;
    font-size: 22px;
    font-weight: bold;
}
.london-list-properties{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
}
ul.additional-properties {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
  width: 100%;
  padding-top: 20px;
}
ul.additional-properties li {
  width: 25%;
  height: auto;
}
ul.additional-properties li img {
  max-width: 100%;
}
.london-list-properties .last-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
    margin-top: 10px;
}

.london-list-properties .view-btn {
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    font-size: 14px;
    background-color: #ff7059;
}

.london-list-properties .info h2 {
    text-overflow: ellipsis;
}

.london-single-property{
    margin-bottom: 20px;
    max-width: 320px;
    cursor: pointer;
    position: relative;
    width: 50%;
    padding: 0 10px;
    height: 312px;
}
.properties-section__link{
    display: block;
    width: 100%;
    height: 97%;
}

.properties-section-map{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.properties-section__amount{
    background-color: white;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 3.2%;
    padding-right: 3.2%;
    width: 37.1%;
    margin-top: 2%;
    margin-left: 2%;
    border: 1px solid black;
    border-radius: 6.2%;
    color: #484848;
    font-weight: bold;
}
.properties-section__show-map{
    background-color: white;
    padding-right: 2%;
    padding-top: 2.1%;
    padding-left: 2%;
    color: #2b2b96;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    border-bottom-right-radius: 6.2%;
    border-bottom-left-radius: 6.2%;
    font-weight: bold;
}
a.show-map {
  color: inherit
}
a.show-map:hover {
  text-decoration: none;
}
.landings-page-top-section{
    position: relative;
    display: table;
    width: 100%;
    height: 452px;
}
.landings-page__top-section-content {
    position: relative;
    display: table-cell;
    width: 100%;
    vertical-align: middle;
}
.landings-page__top-title {
    position: relative;
    padding-bottom: 50px;
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.landings-page__top-title div.headers {
  flex: 1;
}
.landings-page__top-title h2 {
    padding: 0;
    margin: 0;
    color: #fff;
    line-height: 50px;
    font-size: 46px;
    font-weight: bold;
}
.landings-page__top-title h4 {
    color: #fff;
    line-height: 40px;
    font-size: 36px;
    font-weight: bold;
}
.search-wrapper {
    background: #1e1e1edb;
    border-radius: 8px;
    position: relative;
    width: 80%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 15px;
}
.search-wrapper .search {
    display: flex;
    align-items: center;
    margin: 0 0 0 -3px;
}

.guide-section__text {
    overflow-y: hidden;
    max-height: 408px;
    font-size: 16px;
    column-count: 2;
}
.guide-section__text p{
font-size: 16px;
}
.guide-section__texthiden{
    column-count: 2;
}
@media (max-width: 720px) {
    .guide-section__text,
    .guide-section__texthiden {
        column-count: 1;
    }
}
.guide-section__texthiden,
.guide-section__texthiden p{
    font-size: 16px;
}

.london-see-all {
    text-align: center;
    /*margin-top: 15px;*/
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1.5em;
}
.landings-page .pre {
  padding-right: 4px;
}
.search-wrapper .search {
    margin: 0;
}

.guide-section-picture-london{
    background: #b3dff9 url("../images/landings-index/london-441853_640.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-paris{
    background: #b3dff9 url("../images/landings-index/Paris-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-barcelona{
    background: #b3dff9 url("../images/landings-index/Barcelona-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-amsterdam{
    background: #b3dff9 url("../images/landings-index/Amsterdam-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-berlin{
    background: #b3dff9 url("../images/landings-index/Berlin-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-blackpool{
    background: #b3dff9 url("../images/landings-index/hotels/Accessible_-Blackpool_For_Disabled.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-cape-town{
    background: #b3dff9 url("../images/landings-index/Cape-Town-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-ceredigion{
    background: #b3dff9 url("../images/landings-index/Caredigion-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-cornwall{
    background: #b3dff9 url("../images/landings-index/Cornwall-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-devon{
    background: #b3dff9 url("../images/landings-index/Devon-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-dorset{
    background: #b3dff9 url("../images/landings-index/Dorset-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-dublin{
    background: #b3dff9 url("../images/landings-index/Dublin-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-lisbon{
    background: #b3dff9 url("../images/landings-index/Lisbon-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-madrid{
    background: #b3dff9 url("../images/landings-index/Madrid-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-majorca{
    background: #b3dff9 url("../images/landings-index/Mallorca-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-nice{
    background: #b3dff9 url("../images/landings-index/Nice-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-norfolk{
    background: #b3dff9 url("../images/landings-index/Norfolk-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-rome{
    background: #b3dff9 url("../images/landings-index/Rome-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-tenerife{
    background: #b3dff9 url("../images/landings-index/Tenerife-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-venice{
    background: #b3dff9 url("../images/landings-index/Venice-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-yorkshire{
    background: #b3dff9 url("../images/landings-index/Yorkshire-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.properties-section-map-london{
    background: #b3dff9 url('../images/landings-index/Map-london.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-paris{
    background: #b3dff9 url('../images/landings-index/Map-paris.jpg') no-repeat center center;
    background-size: cover;
}
.properties-section-map-amsterdam{
    background: #b3dff9 url('../images/landings-index/Map-amsterdam.jpg') no-repeat center center;
    background-size: cover;
}
.properties-section-map-barcelona{
    background: #b3dff9 url('../images/landings-index/Map-barcelona.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-berlin{
    background: #b3dff9 url('../images/landings-index/Map-berlin.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-blackpool{
    background: #b3dff9 url('../images/landings-index/Map-blackpool-new.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-cape-town{
    background: #b3dff9 url('../images/landings-index/Map-cape-town.jpg') no-repeat center center;
    background-size: cover;
}
.properties-section-map-ceredigion{
    background: #b3dff9 url('../images/landings-index/Map-ceredigion.jpg') no-repeat center center;
    background-size: cover;
}
.properties-section-map-cornwall{
    background: #b3dff9 url('../images/landings-index/Map-cornwall.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-devon{
    background: #b3dff9 url('../images/landings-index/Map-devon.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-dorset{
    background: #b3dff9 url('../images/landings-index/Map-dorset.jpg') no-repeat center center;
    background-size: cover;
}
.properties-section-map-dublin{
    background: #b3dff9 url('../images/landings-index/Map-dublin.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-florence{
    background: #b3dff9 url('../images/landings-index/Map-florence.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-lisbon{
    background: #b3dff9 url('../images/landings-index/Map-lisbon.jpg') no-repeat center center;
    background-size: cover;
}
.properties-section-map-madrid{
    background: #b3dff9 url('../images/landings-index/Map-madrid.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-majorca{
    background: #b3dff9 url('../images/landings-index/Map-majorka.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-nice{
    background: #b3dff9 url('../images/landings-index/Map-nice.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-norfolk{
    background: #b3dff9 url('../images/landings-index/Map-norfolk.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-porto{
    background: #b3dff9 url('../images/landings-index/Map-porto.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-rome{
    background: #b3dff9 url('../images/landings-index/Map-rome.jpg') no-repeat center center;
    background-size: cover;
}
.properties-section-map-tenerife{
    background: #b3dff9 url('../images/landings-index/Map-tenerife.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-venice{
    background: #b3dff9 url('../images/landings-index/Map-venice.png') no-repeat center center;
    background-size: cover;
}
.properties-section-map-yorkshire{
    background: #b3dff9 url('../images/landings-index/Map-yorkshire.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-london{
    background: #b3dff9 url('../images/landings-index/Accessible-London-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-paris{
    background: #b3dff9 url('../images/landings-index/Accessible-Paris-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-amsterdam{
    background: #b3dff9 url('../images/landings-index/Accessible-Amsterdam-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-barcelona{
    background: #b3dff9 url('../images/landings-index/Accessible-Barcelona-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-berlin{
    background: #b3dff9 url('../images/landings-index/Accessible-Berlin-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-blackpool{
    background: #b3dff9 url('../images/landings-index/Accessible-Blackpool-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-cape-town{
    background: #b3dff9 url('../images/landings-index/Accessible-Cape-Town-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-ceredigion{
    background: #b3dff9 url('../images/landings-index/Accessible-Caredigion-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-cornwall{
    background: #b3dff9 url('../images/landings-index/Accessible-Cornwall-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-devon{
    background: #b3dff9 url('../images/landings-index/Accessible-Devon-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-dorset{
    background: #b3dff9 url('../images/landings-index/Accessible-Dorset-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-dublin{
    background: #b3dff9 url('../images/landings-index/Accessible-Dublin-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-lisbon{
    background: #b3dff9 url('../images/landings-index/Accessible-Lisbon-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-madrid{
    background: #b3dff9 url('../images/landings-index/Accessible-Madrid-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-majorca{
    background: #b3dff9 url('../images/landings-index/Accessible-Mallorca-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-nice{
    background: #b3dff9 url('../images/landings-index/Accessible-Nice-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-norfolk{
    background: #b3dff9 url('../images/landings-index/Accessible-Norfolk-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-rome{
    background: #b3dff9 url('../images/landings-index/Accessible-Rome-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-tenerife{
    background: #b3dff9 url('../images/landings-index/Accessible-Tenerife-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-venice{
    background: #b3dff9 url('../images/landings-index/Accessible-Venice-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-yorkshire{
    background: #b3dff9 url('../images/landings-index/Accessible-Yorkshire-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.guide-section__btn{
    margin: 50px 0;
}
.guide-section__btnstyle{
    background-color: white;
    color: #2574A9;
    border: 1px solid #2574A9;
    border-radius: 4px;
    margin: auto;
    display: block;
    text-transform: uppercase;
    font-weight: 600;
font-size: 18px;
line-height: 24px;
padding: 19px 42px;
}
/* .guide-section__btnstyle:active{
       background-color: #3ab1fe;
    border: none;
    border-radius: 0;
    box-shadow: none !important;
}
.guide-section__btnstyle:focus{
       background-color: #3ab1fe;
    border: none;
    border-radius: 0;
    box-shadow: none !important;
} */
.search-wrapper .search > button {
    padding: 0;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    height: 55px;
    background-color: #3097E3;
    width: 190px;
    font-weight: bold;
font-size: 18px;
line-height: 24px;
border-radius: 5px;
}
.guide-section__title{
    width: 50%;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
}
.landings-page .london-single-property{
    height: initial;
}
.landings-page .search-single-property a{
 display: flex;
 flex-direction: column;
height: 100%;
}
.landings-page .search-single-property .put-card-image{
    border-radius:5px 5px 0px 0px;
    width: 100%;
    height: 200px;
    background-color: gray;
    object-fit: cover;
}
.landings-page .search-single-property .search-single-property-tripadvisor-rating-wrapper{
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 28px;
    color: #00AA6C;
    background: #F2F2F2;
    border-radius: 5px;
    width: fit-content;
    padding: 0 6px 0 6px;
    margin-top: 10px;
}
.landings-page .search-single-property .search-properties-item-tripadvisor-rating-image{
    width: 87px;
    min-height: initial;
    max-height: initial;
    border-radius:0;
    background-color: transparent;
    object-fit: initial;
}
.landings-page .search-single-property .info{
    border: none;
    border-radius: 0;
    box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius:0px 0px 5px 5px;
    padding: 14px;
    align-items: initial;
    flex-grow: 1;
}
.landings-page .name-mobility-wrapper{
    display: flex;
    align-items: center;
}
.landings-page .name-mobility-wrapper .property-name{
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-transform: capitalize;
    color: #000000;
    flex: 1;
}
.landings-page .search-single-property .price{
  padding: 11px 10px 9px 10px;
  color: #ffffff;
  position: absolute;
  top: 156px;
  right: 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  border-radius: 5px 0 0 0;
  max-width: 90%;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  border: none;
}
.landings-page .price-string{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}
.landings-page .post{
    display: initial;
}
.landings-page .london-list-properties .view-btn {
    height: 50px;
    padding: 0 30px;
    background-color: #ffffff;
    border: 1px solid #2574A9;
    border-radius: 4px;
    width: 100%;
    color: #2574A9;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
}
@media only screen and (max-width: 61.938em) {
    .header .logo {
        background-size: cover;
        background-repeat: no-repeat;
    }
}
@media (min-width: 200px) and (max-width: 600px) {
    .header .logo {
        background-size: cover;
        background-repeat: no-repeat;
    }
}
@media (max-width: 1330px) {
    .landings-page__top-title,
    .search-wrapper {
        width: 94%;
    }
}
@media (max-width: 1140px) {
    .landings-page__top-title {
      flex-direction: column;
    }
    .landings-page__top-title,
    .search-wrapper {
        width: 94%;
    }
    .landings-page-top-section {
        display: block;
        height: auto;
    }
    .landings-page__top-section-content {
        display: block;
        padding: 100px 0;
    }
    .search-wrapper .date-picker .DateInput__display-text,
    .search-wrapper .search-input-field input,
    .search-wrapper,
    .search-wrapper .mobile-drop-down-wrapper .mobile-drop-down li p {
        font-size: 12px;
    }
    /*.search-wrapper .search-input-field ::-webkit-input-placeholder,*/
    /*.search-wrapper .search-input-field input::-moz-placeholder,*/
    /*.search-wrapper .search-input-field input::placeholder*/
    .search-wrapper .date-picker .DateRangePickerInput__calendar-icon {
        padding: 15px 4px 15px 0;
    }
}
@media (max-width: 970px) {
    .search-wrapper {
        width: 98%;
    }
}

@media (max-width: 900px) {
    .mail-section-content {
        padding: 30px;
    }
    .mail-section-form {
        padding: 48px 0 0;
    }
    .guide-section__title {
        margin-top: 15px;
        width: initial;
    }
}
@media (max-width: 767px) {
  .london-single-property {
    max-width: 900px !important;
    width: 100% !important;
  }
  .properties-section__link{
    display: none;
  }
}
@media (max-width: 575px) {
  .mail-section-form-input,
  .mail-section-form-button {
      width: 100%;
  }
  .search-wrapper {
    padding: 7.5px;
  }
  .mail-section-form-input {
      margin-bottom: 20px;
  }
  .landings-page__top-title h2,
  .landings-page__top-title h4 {
    line-height: 38px;
    font-size: 34px;
  }
  .landings-page .search-wrapper .search > button {
    width: auto;
    height: 52px;
  }
  .london-single-property {
    text-align: center;
  }    
}
.landings-page__content .ui-map{
    height: 100%;
}



