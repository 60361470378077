.signup-login-modal {
  padding: 16px;
  position: relative;
}
.signup-login-modal a {
  font-weight: bold;
}

.signup-login-modal > h1 {
  color: #575757;
  font-size: 16px;
  margin: 10px 0 20px;
  text-align: center;
}

.signup-login-modal .input-field-wrapper {
  height: 60px;
  margin-top: 20px;
  border-radius: 3px;
  line-height: 30px;
}

.signup-login-modal .input-wrapper{
  margin-bottom: 10px;
}

/*  För "check your email"
#signup-login-modal {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}*/

.signup-login-modal .form-wrapper .action-button {
  background-color: #00a8e6;
  border-radius: 3px;
  color: #FFF;
  font-size: 16px;
  height: 60px;
  margin-bottom: 80px;
  margin-top: 10px;
  width: 100%;
}
.signup-login-modal .form-wrapper .action-button:hover {
  background-color: #00b9fe;
}


.social-login-button {
  display: block !important;
  height: 60px;
  margin: 10px auto 0;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #252A31;
  height: 50px;
  width: 220px !important;
  padding: 0;
  padding-left: 50px;
  text-align: left;
}
.social-login-button {
  position: relative;
}
.social-login-button::before {
  display: block;
  height: 18px;
  width: 18px;
  position: absolute;
  left: 23px;
  top: 15px;
  content: ' ';
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

#fb-button::before {
  background-image: url('../images/icons/fb-icon-trans-centered.svg');
}

#google-login-button::before {
    background-image: url('../images/icons/gp-icon-trans-centered.svg');
}

/* .signup-login-modal .social-login-button > * {
  box-shadow: none;
} */

.signup-login-modal .g-signin2 .abcRioButton:hover {
  box-shadow: none;
}

.signup-login-modal .divider {
  margin: 20px 0;
}

.signup-login-modal .divider > * {
  display: inline-block;
  vertical-align: middle;
}

.signup-login-modal .divider p {
  color: #484848;
  font-size: 12px;
  padding-bottom: 2px;
  text-align: center;
}

.signup-login-modal .divider .separator {
  background-color: #d4d4d4;
  width: 50%;
}
.divider {
    display: flex;
    align-items: center;
}
.divider p.or {
    font-size: 16px;
    font-weight: 700;
    margin: 0 20px;
}



.signup-login-modal .switch-area {
  background-color: #e9e9e9;
  border-top: 1px solid #cfcfcf;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px;
  position: absolute;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #484848;
}

.signup-login-modal .signup-login.login {
  padding-bottom: 100px;
}
.signup-login-modal .signup-login.signup {
    padding-bottom: 60px;
}
.signup-login-modal .switch-area .switch-link:hover {
  text-decoration: underline;
}

.signup-login.login .action-button, .signup-login.signup .action-button {
  margin-bottom: 10px;
}

.signup-login.login > a {
  color: #08c;
  margin-top: 5px;
}

.signup-login.login > a:hover {
  text-decoration: underline;
}

.signup-login-error {
  margin-bottom: 10px;
  color: red;
  font-size: 16px;
  font-weight: 700;
}
p.tos {
  text-align: center;
}
p.tos a {
  white-space: nowrap;
}
.error-bubble{
  margin-bottom: 10px;
  color: red;
  font-size: 16px;
  font-weight: 700;
  margin-top: -15px;
}
.error-box{
  color:red;
  font-weight: bold;
}

