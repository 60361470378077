@keyframes fade {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@-moz-keyframes fade {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@-webkit-keyframes fade {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@-ms-keyframes fade {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@-o-keyframes fade {
    from { opacity: 0; }
    to   { opacity: 1; }
}
#signup-landing-page-wrapper{
    margin:0;
    padding:0;
    width: 100%;
    height: auto;
}
#signup-landing-page-wrapper #signup-landing-page-head-section{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-image: url("../images/signup-landing-page/signup-landing-page-header-background.png");
}
#signup-landing-page-wrapper #signup-landing-page-head-section h1{
    margin:0;
    padding:0;
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 48px;
    color: #FFFFFF;
    padding: 120px 0px 0px 200px;
    width: 775px;
}
#signup-landing-page-wrapper #signup-landing-page-head-section p{
    margin:0;
    padding:0;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    padding: 15px 0px 0px 200px;
    color: #FFFFFF;
    width: 620px;
}
#signup-landing-page-wrapper #signup-landing-page-head-section #form-wrapper{
    padding: 32px 0px 145px 200px;
}
#signup-landing-page-wrapper #signup-landing-page-head-section .validate input{
    width: 250px;
    border-radius: 0;
}
#signup-landing-page-wrapper #signup-landing-page-head-section .validate button{
    width: 190px;
    float:none;
    background-color: #EE4C10;
    border-radius: 0;
}
#signup-landing-page-wrapper #signup-landing-page-offer-section{
    background: linear-gradient(0deg, rgba(0, 180, 219, 0.1), rgba(0, 180, 219, 0.1));
    backdrop-filter: blur(4px);
    padding-bottom: 50px;
}
#signup-landing-page-wrapper #signup-landing-page-offer-section h2{
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    color: #1E1E1E;
    padding: 30px 0px 0px 0px;
}
#signup-landing-page-wrapper #signup-landing-page-offer-section p{
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1E1E1E;
    
}
#signup-landing-page-wrapper #offer-list-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    top: 260px;
    justify-content: center;
    margin-top: 35px;
}
#signup-landing-page-wrapper #offer-list-wrapper #travel-offer1{
    background-repeat: no-repeat;
    background-size: 80px 80px;
    background-position: top;
    background-image: url("../images/signup-landing-page/content-benefit.svg");
    padding-top: 80px;
    width: 160px;
}
#signup-landing-page-wrapper #offer-list-wrapper #travel-offer2{
    background-repeat: no-repeat;
    background-size: 80px 80px;
    background-position: top;
    background-image: url("../images/signup-landing-page/tips-benefit.png");
    padding-top: 80px;
    width: 160px;
    margin: 0px 90px;
}
#signup-landing-page-wrapper #offer-list-wrapper #travel-offer3{
    background-repeat: no-repeat;
    background-size: 80px 80px;
    background-position: top;
    background-image: url("../images/signup-landing-page/offer-benefit.svg");
    padding: 80px 27px 0px 27px;
    width: 160px;
}
#signup-landing-page-wrapper #signup-landing-page-travel-section{
    background: linear-gradient(180deg, rgba(207, 242, 255, 0) 0%, #CFF2FF 35%);
    text-align: center;
}
#signup-landing-page-wrapper #signup-landing-page-travel-section h2{
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 40.436px;
    line-height: 45px;
    text-align: center;
    color: #1E1E1E;
    padding: 70px 0px 0px 0px;
}
#signup-landing-page-wrapper #signup-landing-page-travel-section p{
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 22.4644px;
    line-height: 31px;
    text-align: center;
    color: #1E1E1E;
}
#signup-landing-page-wrapper #signup-landing-page-travel-section a{
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    background: #EE4C10;
    border: 1.1px solid #EE4C10;
    box-sizing: border-box;
    border-radius: 9px;
    color: white;
    padding: 12.5px 68px 12.5px 22.5px;
    background-repeat: no-repeat;
    background-size: 25px 16px;
    background-position: 135px 16px;
    background-image: url("../images/signup-landing-page/button-arrow.svg");
}
#signup-landing-page-wrapper #signup-landing-page-travel-section #anchor-button{
    padding: 65px 0px 85px 0px;
}
#signup-landing-page-wrapper #travel-list-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    top: 260px;
    justify-content: center;
    margin-top: 50px;
}
#signup-landing-page-wrapper #travel-list-wrapper #travel-item1{
    background-repeat: no-repeat;
    background-size: 300px 300px;
    background-position: top;
    background-image: url("../images/signup-landing-page/inspired-image1.png");
    padding-top: 310px;
    width: 300px;
}
#signup-landing-page-wrapper #travel-list-wrapper #travel-item2{
    background-repeat: no-repeat;
    background-size: 300px 300px;
    background-position: top;
    background-image: url("../images/signup-landing-page/inspired-image2.png");
    padding: 310px 60px 0px 60px;
    width: 300px;
    margin: 0px 26px;
}
#signup-landing-page-wrapper #travel-list-wrapper #travel-item3{
    background-repeat: no-repeat;
    background-size: 300px 300px;
    background-position: top;
    background-image: url("../images/signup-landing-page/inspired-image3.png");
    padding-top: 310px;
    width: 300px;
}
#signup-landing-page-wrapper #signup-landing-page-access-section{
    background: linear-gradient(180deg, #00B4DB 0%, #0E75BA 100%);
}
#signup-landing-page-wrapper #signup-landing-page-access-section h2{
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
    padding: 80px 0px 0px 0px;
}
#signup-landing-page-wrapper #signup-landing-page-access-section #form-wrapper{
    display: flex;
    justify-content: center;
    padding: 40px 0px 80px 0px;
}
#signup-landing-page-wrapper #signup-landing-page-access-section .validate input{
    width: 250px;
    border-radius: 0;
}
#signup-landing-page-wrapper #signup-landing-page-access-section .validate button{
    width: 190px;
    float:none;
    background-color: #EE4C10;
    border-radius: 0;
}
#signup-landing-page-wrapper #button-prev, #signup-landing-page-wrapper #button-next{
    display: none;
    width: 11px;
    height: 18px;
    padding: 11px 10px;
    margin: 165px 10px;
    background-color:transparent;
    outline: none;
}
#signup-landing-page-wrapper #button-prev{
    background-repeat: no-repeat;
    background-size: 11px 18px;
    background-position: center;
    background-image: url("../images/signup-landing-page/arrow-prev.svg");
}
#signup-landing-page-wrapper #button-next{
    background-repeat: no-repeat;
    background-size: 11px 18px;
    background-position: center;
    background-image: url("../images/signup-landing-page/arrow-next.svg");
}
@media only screen and (max-width:1700px){
    #signup-landing-page-wrapper #signup-landing-page-head-section{
        background-size: auto 100%;
    }
}
@media only screen and (max-width:1170px){
    #signup-landing-page-wrapper #signup-landing-page-head-section h1{
        padding: 115px 0px 0px 95px;
        width: 670px;
    }
    #signup-landing-page-wrapper #signup-landing-page-head-section p{
        padding: 15px 0px 0px 95px;
    }
    #signup-landing-page-wrapper #signup-landing-page-head-section #form-wrapper{
        padding: 30px 0px 150px 95px;
    }
}
@media only screen and (max-width:1000px){
    #signup-landing-page-wrapper #button-prev, #signup-landing-page-wrapper #button-next{
        display: block;
    }
    #signup-landing-page-wrapper #active{
        display: block;
        -webkit-animation: fade 2s;
        -moz-animation: fade 2s;
         -ms-animation: fade 2s; 
          -o-animation: fade 2s;
             animation: fade 2s;
    }
    #signup-landing-page-wrapper #hidden{
        display: none;
    }
    #signup-landing-page-wrapper #travel-list-wrapper #travel-item2{
        margin: 0px;
    }
    #signup-landing-page-wrapper #travel-list-wrapper{
        margin-top: 35px;
    }
    #signup-landing-page-wrapper #travel-list-wrapper #travel-item1,
    #signup-landing-page-wrapper #travel-list-wrapper #travel-item2,
    #signup-landing-page-wrapper #travel-list-wrapper #travel-item3{
        width: 350px;
        background-size: 350px 350px;
        padding: 365px 20px 0px 20px;
    }
    #signup-landing-page-wrapper #travel-list-wrapper #travel-item2{
        padding-right:90px;
        padding-left:90px;
    }
    #signup-landing-page-wrapper #signup-landing-page-travel-section #anchor-button{
        padding:35px 0px 50px 0px;
    }
    #signup-landing-page-wrapper #signup-landing-page-travel-section h2{
        padding: 70px 0px 0px 0px;
    }
}
@media only screen and (max-width:880px){
    #signup-landing-page-wrapper #signup-landing-page-head-section h1{
        padding: 50px 0px 0px 3%;
        width: 561px;
    }
    #signup-landing-page-wrapper #signup-landing-page-head-section p{
        padding: 15px 0px 0px 3%;
        width: 510px;
    }
    #signup-landing-page-wrapper #signup-landing-page-head-section #form-wrapper{
        padding: 30px 0px 15% 3%;
    }
}

@media only screen and (max-width:690px){
    #signup-landing-page-wrapper #travel-list-wrapper{
        margin-top: 25px;
    }
    #signup-landing-page-wrapper #offer-list-wrapper{
        flex-direction: column;
        align-items: center;
        margin: 25px 0px;
    }
    #signup-landing-page-wrapper #signup-landing-page-offer-section h2{
        font-size: 24px;
        padding: 30px 0px 0px 0px;
    }
    #signup-landing-page-wrapper #offer-list-wrapper #travel-offer1,
    #signup-landing-page-wrapper #offer-list-wrapper #travel-offer2,
    #signup-landing-page-wrapper #offer-list-wrapper #travel-offer3{
        margin: 0px 0px 15px 0px;
    }
    #signup-landing-page-wrapper #signup-landing-page-travel-section h2{
        padding:0px;
        font-size: 24px;
    }
    #signup-landing-page-wrapper #travel-list-wrapper #travel-item1,
    #signup-landing-page-wrapper #travel-list-wrapper #travel-item2,
    #signup-landing-page-wrapper #travel-list-wrapper #travel-item3{
        width: 280px;
        background-size: 280px 280px;
        padding: 290px 0px 0px 0px;
    }
    #signup-landing-page-wrapper #travel-list-wrapper #travel-item2{
        padding-right:65px;
        padding-left:65px;
    }

    #signup-landing-page-wrapper #button-prev, #signup-landing-page-wrapper #button-next{
        margin: 130px 10px;
    }
    #signup-landing-page-wrapper #signup-landing-page-travel-section p{
        font-size: 18px;
        line-height: 26px;
    }
    #signup-landing-page-wrapper #signup-landing-page-access-section #form-wrapper{
        padding: 40px 0px 55px 0px;
    }
    #signup-landing-page-wrapper #signup-landing-page-access-section .validate input{
        width: 150px;
    }
    #signup-landing-page-wrapper #signup-landing-page-access-section .validate button{
        width: 135px;
        font-size: 13px ;
        padding: 0;
    }   
    #signup-landing-page-wrapper #signup-landing-page-access-section h2{
        padding: 25px 0px 0px 0px;
        font-size: 24px;
    }   
}
@media only screen and (max-width:620px){
    #signup-landing-page-wrapper #signup-landing-page-head-section h1{
        padding: 50px 0px 0px 30px;
        width: 345px;
        font-size: 28px;
        line-height: 36px;
    }
    #signup-landing-page-wrapper #signup-landing-page-head-section p{
        font-size: 16px;
        padding: 10px 0px 0px 30px;
        width: 290px;
        line-height: 20px;
    }
    #signup-landing-page-wrapper #signup-landing-page-head-section #form-wrapper{
        padding: 10px 0px 45px 30px;
    }
    #signup-landing-page-wrapper #signup-landing-page-head-section .validate input{
        width: 150px;
    }
    #signup-landing-page-wrapper #signup-landing-page-head-section .validate button{
        width: 135px;
        font-size: 13px ;
        padding: 0;
    } 
}
@media only screen and (max-width:370px){
    #signup-landing-page-wrapper #signup-landing-page-head-section h1{
        width: 265px;
    }
    #signup-landing-page-wrapper #signup-landing-page-head-section .validate input{
        width: 130px;
    }
    #signup-landing-page-wrapper #signup-landing-page-head-section .validate button{
        width: 105px;
    }

}
@media only screen and (max-width:350px){
    #signup-landing-page-wrapper #button-prev, #signup-landing-page-wrapper #button-next{
        margin: 130px 0px;
    } 
}