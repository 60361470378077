.block {
  display: block;
}

.block ul li {
  padding: 10px;
}

.properties-image {
  background-color: rgba(0, 0, 0, 0.1);
  width: 133px;
  height: 100px;
  background-size: cover;
  margin-right: 20px;
}

.dashboard-property-list li {
  display: block;
}
.dashboard-property-list ul li {
  border-bottom: 1px solid gray;
  padding-bottom: 20px;
  height: auto;
}

.dashboard-property * {
  display: flex;
  align-items: center;
}

.list-properties-rest {
  flex: 1;
  display: block;
}

.list-properties-headers {
  width: 300px;
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: 14px;
  display: block;
}

.list-properties-headers h3 {
  font-weight: lighter;
}

.dashboard-property .blue-xs {
    width: 100%;
    display: block;
    margin-right: 0;
}

.list-properties-buttons  {
  flex-direction: row;
  flex: 2;
  margin-top: -10px;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
}
.list-properties-buttons button{
  width: 40% !important;
}

.list-properties-buttons button, .list-properties-buttons a.button {
  margin-top: 10px;
  padding:0;
  width: 40%;
}

.dashboard-custom-box {
    display:block;
    margin-bottom:0px;
}

.dashboard-custom-box .inner-content {
    margin-top: 0px;
}

.content .dashboard-property-no-listing {
  font-size: 10px;
}

.content .dashboard-property-no-listing * {
  margin-top: 30px;
}

.dashboard-properties .border div {
  display: flex;
  align-items: center;
}

.dashboard-properties .border div span {
  flex-grow: 8;
}

.dashboard-property-list {
  padding: 20px;
  border-bottom: 1px solid #d4d4d4;
}

.dashboard-properties {
  width:100%;
  border-bottom: 1px solid #d4d4d4;
}

.dashboard-properties .dashboard-property-list ul li {
  border-bottom: 1px solid #d4d4d4;
  padding: 10px;
  height: auto;
}
.dashboard-properties .properties-image {
  background-color: rgba(0, 0, 0, .1);
  width: 100%;
  height: 120px;
  background-size: cover;
  margin-right: 20px;
  flex: 2;
}

.dashboard-properties .list-properties-headers {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  align-items: flex-start;
  display: block;
}

.dashboard-properties .list-properties-headers .mobility-icon {
  min-width:30px;
  min-height:30px;
  max-width:30px;
  max-height:30px;
  margin-top: 10px;
}

.dashboard-properties .dashboard-property-list-type {
  margin-bottom: 0px;
}

.list-add-property {
  margin-left: 20px;
  width: 200px !important;
  padding: 0;
}
