@import url("./user-profile-tabs.css");
@import url("./user-profile-general-info.css");
@import url("./user-profile-form-elements.css");
@import url("./user-profile-avatar-modal.css");
@import url("./user-profile-disability.css");
@import url("./user-profile-travel-info.css");
@import url("./user-profile-mobile.css");

.profile-settings{
    border: 1px solid #F3F3F3;
    border-top: none;
    background-color: #ffffff;
}
.profile-settings__edit-button{
    background: #ffffff;
    color: #007EB1;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    border: 1px solid #007EB1;
    border-radius: 5px;
    padding: 10px 15px 10px 41px;
    outline: none !important;
    position: absolute;
    left: 90%;
    top: 132px;
}
.profile-settings__edit-button::before{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url('../../images/user-profile-page/edit-button-icon.svg');
    left: 10px;
    background-position: center;
    background-repeat: no-repeat;
}
.profile-settings__edit-button_active{
    background: #007EB1;
    color: #ffffff;
}
.profile-settings__edit-button_active::before{
    background-image: url('../../images/user-profile-page/edit-button-icon-active.svg');
}