.profile-settings__general-info-wrapper{
    position: relative;
}
.profile-settings__user-picture-wrapper{
    display: flex;
    padding: 24px 0;
    padding-left: 20px;
}
.profile-settings__user-picture-image-wrapper{
    height: 63px;
    width: 63px;
    border-radius: 50%;
    background-image: url('../../images/user-profile-page/user-icon-default.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-color: #F2F2F2;
}
.profile-settings__user-picture-image{
    height: 100%;
    border-radius: 50%;
    width: 100%;
}
.profile-settings__user-name{
    font-size: 20px;
    line-height: 25px;
    color: #000000;
}
.profile-settings__user-picture-actions-wrapper{
    margin-left: 23px;
}
.profile-settings__user-picture-actions button{
    background-color: transparent;
    color: #007EB1;
    text-decoration: underline;
    border-radius: 0;
    padding: 0;
    margin-left: 20px;
    position: relative;
}
.profile-settings__user-picture-actions button::after{
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-position: center;
    background: #007EB1;
    left: 109%;
}
.profile-settings__user-picture-actions button:last-child::after{
    display: none;
}
.profile-settings__user-picture-actions button:last-child{
    border-right: none;
}
.profile-settings__user-picture-actions button:first-child{
    margin: 0;
}
.profile-settings__personal-info-inputs{
    display: flex;
    flex-wrap: wrap;
}
.profile-settings__personal-info-input-wrapper{
    width: 33%;
    margin-top: 30px;
    padding-right: 20px;
}
.profile-settings__general-info-save-section{
    background-color: #007EB1;
    width: 100vw;
    left: 0px;
    position: fixed;
    z-index: 999;
    top: 88vh;
    padding: 20px;
    padding-left: 160px;
}
.profile-settings__general-info-save-section-sticky{
    position: absolute;
    top: 95%;
    width: 100%;
}
.profile-settings__general-info-save-section button{
    border: 1px solid #FFFFFF;
    background: transparent;
    border-radius: 5px;
}
.profile-settings__general-info-save-section-save-btn{
    padding: 15px 10px;
    padding-left: 40px;
    position: relative;
}
.profile-settings__general-info-save-section-save-btn::before{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url('../../images/user-profile-page/save-icon.svg');
    background-position: center;
    left: 12px;
    top: 17px;
}
.profile-settings__general-info-save-section-cancel-btn{
    padding: 15px 10px;
    margin-left: 15px;
}