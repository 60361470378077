.profile-settings__disability-info-who-wrapper,
.profile-settings__travel-info-destination-wrapper{
    border-top: none;
}
.profile-settings__disability-info-who-wrapper .select-dropdown{
    height: initial;
    border: none;
}
.profile-settings__disability-info-who-wrapper .select-dropdown select{
    text-indent: initial;
    font-size: 17px;
    line-height: 21px;
    color: #161616;
    padding: 12px;
    border: 1px solid #E8E8E8;
    border-radius: 2px;
}
.profile-settings__disability-info-who-wrapper .select-dropdown select:disabled{
    border: none;
    color: #161616;
    background-color: #ffffff;
    padding: 13px;
    opacity: 1;
    padding-left: 0;
}
.profile-settings__disability-info-who-wrapper .select-dropdown select:disabled::after{
    display: none;
}
.profile-settings__disability-info-who-wrapper .profile-settings__input-label{
    display: block;
    max-width: 372px;
    width: 100%;
}
button.profile-settings__edit-disability-button{
    top: 18px;
}
.profile-settings__add-disability-button{
    background: #ffffff;
    color: #007EB1;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    border: 1px solid #007EB1;
    border-radius: 5px;
    position: relative;
    padding: 10px 15px 10px 41px;
    outline: none !important;
    display: none;
}
.profile-settings__add-disability-button::before{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url('../../images/user-profile-page/plus-icon.svg');
    left: 10px;
    background-position: center;
    background-repeat: no-repeat;
}
.profile-settings__add-disability-button-active{
    display: initial;
}
.profile-settings__disabilities-cards-wrapper{
    margin-top: 17px;
    display: flex;
    flex-wrap: wrap;
}
.profile-settings__disabilities-card{
    max-width: 388px;
    width: 32%;
    border: 1px solid rgba(121, 118, 118, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 17px;
    position: relative;
    margin-top: 16px;
    margin-right: 10px;
}
.profile-settings__disability{
    border: 1px solid #007EB1;
    box-sizing: border-box;
    border-radius: 4.56px;
    margin-bottom: 0;
    position: relative;
    width: 119px;
    height: 119px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    padding: 13px;
    font-weight: 500;
    font-size: 13px;
    background-repeat: no-repeat;
    background-position-y: 14px;
    background-position-x: center;
    padding-bottom: 8px;
    color: #007EB1;
    background-color: #EDF5FF;
}
.profile-settings__disability_allergy{
    background-image: url('../../images/disability-profile/allergy-icon.svg');
}
.profile-settings__disability_intellectual_disability{
    background-image: url('../../images/disability-profile/Intellectual-icon.svg');
}
.profile-settings__disability_visual_impairment{
    background-image: url('../../images/disability-profile/visual-icon.svg');
}
.profile-settings__disability_hearing_impairment{
    background-image: url('../../images/disability-profile/hearing-icon.svg');
}
.profile-settings__disability_mobility_impairment{
    background-image: url('../../images/disability-profile/wheelchair-icon.svg');
}

.profile-settings__disability-delete-button{
    position: absolute;
    width: 31px;
    height: 31px;
    background-image: url('../../images/user-profile-page/close-cross-icon.svg');
   border: 1px solid rgba(121, 118, 118, 0.5);
   border-radius: 5px;
   padding: 0;
   background-color: #fff;
   background-repeat: no-repeat;
   background-position: center;
   right: 17px;
    top: 17px;
}
.profile-settings__user-disability-modal__after-open{
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-settings__user-disability-modal-content__after-open{
    background: #fff;
    position: relative;
    border: 1px solid gray;
    padding-top: 34px;
    border-radius: 5px; 
}
.profile-settings__user-disability-modal-content-wrapper .user-disability-modal__checkbox-label{
    width: 110px;
    height: 110px;
}
.profile-settings__user-disability-modal-content-wrapper .user-disability-modal__question_allergies .user-disability-modal__checkbox-label,
.profile-settings__user-disability-modal-content-wrapper .user-disability-modal__question_equipment .user-disability-modal__checkbox-label
{
    width: initial;
    height: 49px;
}
.profile-settings__user-disability-modal-content-wrapper .user-disability-modal__question{
    flex-direction: column;
}
.profile-settings__user-disability-modal-disabilities-button{
    background: #007EB1;
    padding: 25px;
    margin-top: 25px;
    border-radius: 0;
}
.profile-settings__user-disability-modal-disabilities-header{
    color: #050709;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
}
.profile-settings__user-disability-modal-content-wrapper .user-disability-modal__checkboxes-wrapper{
    padding: 30px;
    padding-bottom: 0;
}
.profile-settings__user-disability-modal-content-wrapper .user-disability-modal__question_allergies .user-disability-modal__checkboxes-wrapper,
.profile-settings__user-disability-modal-content-wrapper .user-disability-modal__question_equipment .user-disability-modal__checkboxes-wrapper
{
    max-width: initial;
}
.profile-settings__disabilities-card-value{
    background-color: #EDF5FF;
    border: 1px solid #007EB1;
    border-radius: 4px;
    width: 50px;
    height: 50px;
    text-indent: -9999px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 10px;
    margin-top: 15px;
}
.profile-settings__disabilities-card-extra-info{
    width: initial;
    height: initial;
    text-indent: initial;
    display: flex;
    align-items: center;
    color: #007EB1;
    font-size: 14px;
    font-weight: 600;
    padding: 0 12px;
    min-height: 50px;
}
.disabilities-card-value_food{
    background-image: url('../../images/disability-profile/food-icon.svg');
}
.disabilities-card-value_fur{
    background-image: url('../../images/disability-profile/fur-icon.svg');
}
.disabilities-card-value_dust{
    background-image: url('../../images/disability-profile/dust-icon.svg');
}
.disabilities-card-value_mold{
    background-image: url('../../images/disability-profile/mold-icon.svg');
}
.disabilities-card-value_walker{
    background-image: url('../../images/disability-profile/walker-icon.svg');
}
.disabilities-card-value_electrical_wheelchair{
    background-image: url('../../images/disability-profile/electrical-wheelchair-icon.svg');
}
.disabilities-card-value_manual_wheelchair{
    background-image: url('../../images/disability-profile/manual-wheelchair-icon.svg');
}
.disabilities-card-value_crutch{
    background-image: url('../../images/disability-profile/crutch-icon.svg');
}
.disabilities-card-value_stick{
    background-image: url('../../images/disability-profile/walking-stick-icon.svg');
}
.disabilities-card-value_leading_dog{
    background-image: url('../../images/disability-profile/assistance-dog-icon.svg');
}
.profile-settings__disabilities-card-values-button{
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../images/user-profile-page/plus-icon.svg');
    background-color: #FFFFFF;
    border: 1px solid #007EB1;
    box-sizing: border-box;
    border-radius: 4px;
    height: 50px;
    width: 50px;
    padding: 0;
    margin-left: 10px;
    margin-top: 15px;
}
.profile-settings__disabilities-card_values-wrapper{
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
}
.profile-settings__disabilities-card_values-label{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 15px 0;
    color: #797676;
}
.profile-settings__change-mobility-button{
    background: #FFFFFF;
    border: 1px solid #007EB1;
    box-sizing: border-box;
    border-radius: 5px;
    color: #007EB1;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 15px;
    display: none;
}
.profile-settings__change-mobility-button-active{
    display: initial;
}
.profile-settings__mobility-info-card-wrapper{
    padding-top: 17px;
}
.profile-settings__mobility-info-card{
    border: 1px solid #007EB1;
    border-radius: 5px;
    padding: 25px 25px 25px 68px;
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 340px;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    height: 128px;
    align-items: center;
    justify-content: center;
    margin-left: 19px;
    color: #007EB1;
    position: relative;
}
.profile-settings__mobility-info-card span{
    padding-top: 15px;
}
.profile-settings__mobility-info-card::after{
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: 100%;
    border-radius: 4px 0px 0px 4px;
    background-color: #EDF5FF;
    left: 0px;
    top: 0px;
    border-right: 1px solid #007EB1;
}
.profile-settings__mobility-info-card-1::after{
    background-image: url('../../images/disability-profile/mobility-level-1-icon.svg');
}
.profile-settings__mobility-info-card-2::after{
    background-image: url('../../images/disability-profile/mobility-level-2-icon.svg');
}
.profile-settings__mobility-info-card-3::after{
    background-image: url('../../images/disability-profile/mobility-level-3-icon.svg');
}
.profile-settings__user-disability-modal-content-wrapper .user-disability-modal__radio-wrapper{
    padding: 30px;
}
.profile-settings__delete-disability-modal .profile-settings__user-disability-modal-disabilities-header{
    padding: 40px;
}
.profile-settings__mobility-info-assistant-wrapper{
    display: flex;
}
.profile-settings__disability-info-facilities-wrapper .user-disability-modal__checkboxes-wrapper{
    display: flex;
    flex-wrap: wrap;
    padding-top: 3px;
}
.profile-settings__disability-info-facilities-wrapper .user-disability-modal__checkbox-label{
    width: 33%;
    padding-right: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-top: 15px;
    display: flex;
    cursor: pointer;
}
.profile-settings__disability-info-facilities-wrapper .user-disability-modal__checkbox-input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}
.profile-settings__disability-info-facilities-wrapper .user-disability-modal__checkbox-input:disabled + .user-disability-modal__checkbox-input-custom{
    width: 18px;
    height: 18px;
    background-color: #D5D5D5;
    border: 1px solid #D5D5D5;
    border-radius: 1px;
    margin-right: 13px;
}
.profile-settings__disability-info-facilities-wrapper .user-disability-modal__checkbox-input-custom{
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    border: 1px solid #007EB1;
    border-radius: 1px;
    margin-right: 13px;
}
.profile-settings__disability-info-facilities-wrapper .user-disability-modal__checkbox-label_checked .user-disability-modal__checkbox-input-custom{
    background-image: url('../../images/disability-profile/checked-icon-white.svg');
    background-color: #007EB1;
    background-repeat: no-repeat;
    background-position: center;
}
.profile-settings__mobility-title-wrapper,
.profile-settings__disability-info-disabilities-title-wrapper
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profile-settings__disability-info-age{
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #161616;
}
.profile-settings__disability-info-age-wrapper{
    margin-top: 17px;
}