.blog-login-form {
  top:10px;
  position:relative;   
  height: 410px;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-left: solid 10px red;
}

.blog-login-form .handiscover-img {
  display: block;
  position:absolute;
  width: 160px;
  height: auto;
  left:0;
  right:0;
  top:20px;
  margin: 0 auto;
  display:none
}
.blog-login-form .join-our-community {
  margin:0 !important;
  padding:0 !important;
  text-align: center;
  font-weight: bold !important;
  font-family: Quicksand;
  font-style:normal;
  font-size: 32px;
  line-height: 40px;
  word-wrap: break-word !important;
  color: #1E1E1E;
  position: absolute;
  top: 43px;
  right: 0;
  left: 0;
  letter-spacing: -0.02em;
}
.blog-login-form .enter-email-inputs {
  display: flex;
}

.blog-login-form .join-button {
  height: 55px;
  border-radius: 3px;
  margin: auto;
  background-color: #0E75BA;
  flex: 0 0 50%;
  position: absolute;
  left:0;
  right:0;
  top:290px;
  font-size:18px;
  font-weight: bold;
  font-style:normal;
  font-family:Quicksand;
  line-height: 22px;
  letter-spacing: 0.1em;
}
.signup-form-border-top{
  position: absolute;
  left:0;
  right:0;
  margin: auto;
  width: 581px;
  height: 1px;
  background: #E6E6E6;
  opacity: 0.7;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.05);
  top:0px;
}
.signup-form-benefits{
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  top: 260px;
justify-content: center;
}
.signup-form-benefits img{
  margin:0 !important;
  padding:0 !important;
  display: block;
  position:absolute;
  width: 168px !important;
  height: auto !important;
  min-width: auto;
}
.singup-form-benefits-list{
  position: absolute;
  left: 220px;
  top:35px;
}
.singup-form-benefits-list ul{
  list-style-type: none;
}
.singup-form-benefits-list h5{
  margin:0 !important;
  padding:0 !important;
  font-family: Quicksand;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.02em !important;
  color: #1E1E1E !important;
  margin-bottom: 5px !important;
}
.singup-form-benefits-list li{
  margin:0 !important;
  padding:0 !important;
  font-size: 0px;
  background-repeat: no-repeat;
  background-size: 17px 15px;
  background-position: left center;
  background-image: url("../../images/icons/tick_image.svg");

}
.singup-form-benefits-list p{
  margin:0 !important;
  padding:0 !important;
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 26px;
  color: #1E1E1E;
  margin-left: 23px !important;
}

.blog-login-form .already-a-member {
  text-align: center;
  font-size: 16px;
  word-wrap: break-word;
  font-weight: normal;
  font-style: normal;
  line-height: 162%;
  color: #000000;
  position: absolute;
  left: 0;
  right: 0;
  top: 205px;
}
.blog-login-form .not-already-a-member {
  text-align: center;
  font-size: 16px;
  word-wrap: break-word;
  font-weight: normal;
  font-style: normal;
  line-height: 162%;
  color: #000000;
  position: absolute;
  left: 0;
  right: 0;
  top: 180px;
}

.blog-login-form .already-a-member .login-to-read {
  text-align: center;
  font-weight: bold;
  color: #46b3f0;
  font-size: 16px;
  word-wrap: break-word;
  font-style: normal;
  line-height: 162%;
  text-decoration: underline;
}
.blog-login-form .not-already-a-member .signup-to-read {
  text-align: center;
  font-weight: bold;
  color: #46b3f0;
  font-size: 16px;
  word-wrap: break-word;
  font-style: normal;
  line-height: 162%;
  text-decoration: underline;
}

.blog-login-form .signup-login-modal {
  display: flex;
  padding:0 !important;
  margin:0 !important;
  position:absolute;
  flex-direction: row;
  top:105px;
  justify-content:space-around;
  width:100%

}

.blog-login-form .signup-login-modal .fb-login-button {
  margin:0;
  padding:0;
  display: flex;
  flex-direction: column;
  position: absolute;
  width:220px;
  height: 46px;
  left:265px;
  background-color: white !important;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-image: url("../../images/icons/signup-form-fb-new-icon.svg");
  background-position: 23px;
  border-style: solid;
  border-width: 1px;
  border-color:lightgray;
  border-radius:7px;
  padding: 12px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.1);

}
.blog-login-form .signup-login-modal .fb-login-button p{
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color:#1E1E1E;
  margin-left:45px;
}
.blog-login-form .signup-login-modal .g-signin2 {
  margin:0;
  padding:0;
  display: flex;
  flex-direction: column;
  position: absolute;
  width:220px;
  height: 46px;
  right:270px;
  background-color: white !important;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-image: url("../../images/icons/signup-form-g-new-icon.svg");
  background-position: 23px;
  border-style: solid;
  border-width: 1px;
  border-color:lightgray;
  border-radius:7px;
  padding: 12px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
}
.blog-login-form .signup-login-modal .g-signin2 p{
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color:#1E1E1E;
  margin-left:45px;
}
#benefit-1{
  width: 139px;
  height: auto;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-size: 58px 58px;
  background-position: top;
  background-image: url("../../images/signup-page/benefit-1-image.svg");
}
#benefit-2{
  width: 144px;
  height: auto;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-size: 58px 58px;
  background-position: top;
  background-image: url("../../images/signup-page/benefit-2-image.png");
}
#benefit-3{
  width: 95px;
  height: auto;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-size: 58px 58px;
  background-position: top;
  background-image: url("../../images/signup-page/benefit-3-image.svg");
  
}
#benefit-1 p, #benefit-2 p, #benefit-3 p{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #666666;
  margin-top: 65px;
}

@media only screen and (max-width: 1080px) {
  .blog-login-form .join-our-community {
    padding-left: 12.5% !important;
    padding-right:12.5% !important;
  }
  .blog-login-form .signup-login-modal .g-signin2{
    right:170px;
  }
  .blog-login-form .signup-login-modal .fb-login-button{
    left: 165px;
  }
}

@media only screen and (max-width: 820px) {
  .blog-login-form .enter-email-inputs {
    display: block;
  }
  .blog-login-form .join-our-community{
    padding-left: 24% !important;
    padding-right: 24% !important;
    font-size: 24px;
  }
  .blog-login-form .not-already-a-member{
    top: 260px;
  }
  .blog-login-form .already-a-member{
    top: 285px;
  }
  .blog-login-form .signup-login-modal{
    top:140px;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto !important;
    width:auto;
  }
  .blog-login-form .signup-login-modal .fb-login-button{
    right: 0;
    left: 0;
    margin: auto;
    top:60px
  }
  .blog-login-form .signup-login-modal .g-signin2{
    right: 0;
    left: 0;
    margin: auto;
  }
  .signup-form-benefits{
    flex-direction: column;
    top: 340px;
    align-items: center;
  }
  .blog-login-form{
    height: 500px;
  }
  #benefit-1{
    width: auto;
    margin:0px;
    background-size: 23px 23px;
    background-position: left;
    padding: 10px;
  }
  #benefit-2{
    width: auto;
    margin:0px;
    background-size: 23px 23px;
    background-position: left;
    padding: 10px;
  }
  #benefit-3{
    width: auto;
    margin:0px;
    background-size: 23px 23px;
    background-position: left;
    padding: 10px;
  }
  #benefit-1 p, #benefit-2 p, #benefit-3 p{
    margin: 0;
    text-align: left;
    margin-left: 22px;
  }
}
@media only screen and (max-width: 550px) {
  .blog-login-form .join-our-community{
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  .blog-login-form .signup-login-modal .fb-login-button p{
    font-size: 14px !important;
  }
  .blog-login-form .signup-login-modal .g-signin2 p{
    font-size: 14px !important;
  }
}
@media only screen and (max-width:415px){
  .signup-form-benefits{
    align-items: start;
    padding-left: 10%;
  }
}
@media only screen and (max-width: 405px) {
  .blog-login-form .join-our-community {
    padding-left: 5% !important;
    padding-right: 5% !important;
}
.blog-login-form {
  height: 530px;
}
}
@media only screen and (max-width: 320px) {
  .blog-login-form .join-our-community {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}