.transactions-page{
  width: 100%;
}
.transactions-page .dashboard-custom-box .inner-content{
  padding: 20px;
}
.transactions-page table{
  border-collapse: collapse;
  width: 100%;
}
.transactions-page table thead td {
  border: 1px solid rgb(150, 150, 150);
  border-bottom: none;
}
.transactions-page table td {
  padding: 10px;
}
.transactions-page table thead{
  background: rgb(150, 150, 150);
  color: #fff;
}
.transactions-page table tbody td {
  border: 1px solid rgb(200, 200, 200);
}
.transactions-page .retry-container{
  display: flex;
    flex-direction: column;
    align-items: baseline;
}
.transactions-page .retry-button {
  padding: 5px 40px;
  margin: 5px auto;
  width: 100%;
}
.transactions-page-cards{
  background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    width: 500px;
    outline: none;
    padding: 20px;
    border: 1px solid gray;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.transactions-page-cards .payment-card-information{
  display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.transactions-page-cards #stripe-card-element{
 width: 100%;
}
.transactions-page-cards .saved-card-label::before{
  left: -26%;
}
.transactions-page-cards-buttons{
  display: flex;
    flex-direction: column
}
.transactions-page-cards-buttons button{
  margin-top: 10px;
}