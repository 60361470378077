.user-disability-modal .user-disability-modal__form-header-mobile{
    width: 100%;
    background: linear-gradient(180deg, rgba(47, 128, 237, 0.75) 0%, rgba(86, 204, 242, 0.75) 100%), url('../../images/disability-profile/beach-wheelchair.jpeg'), #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    background-size: contain;
    padding-top: 36px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    overflow: auto;
    background-position: center;
}
.user-disability-modal .user-disability-modal__form-header-mobile-header{
    color: #FFFFFF;
    font-size: 24px;
}
.user-disability-modal .user-disability-modal__form-header-mobile-text{
    color: #FFFFFF;
    margin-bottom: 0;
    width: 69%;
    padding-bottom: 28px;
}
.user-disability-modal .user-disability-modal__form-header-mobile-counter{
    background: #007EB1;
    height: 56px;
    padding-left: 20px;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
}
@media only screen and (max-width: 900px) {
    .user-disability-modal{
        overflow: auto;
        min-height: 100%;
        align-items: initial;
        display: block;
    }

    .user-disability-modal__content {
      width: auto;
      height: auto;
    }
    .user-disability-modal__content{
        width: 100%;
    }
    .user-disability-modal__content-wrapper{
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
    .user-disability-modal__start-wrapper{
        width: auto;
        height: auto;
    }
    .user-disability-modal__inner-content{
        flex: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;
    }
    .user-disability-modal__questions-wrapper{
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
    }
    .user-disability-modal__button_back, .user-disability-modal__button_next, .user-disability-modal__button_skip{
        width: 49%;
    }
    .user-disability-modal__questions-title{
        padding-left: 20px;
    }
    .user-disability-modal__questions-text{
        font-weight: 500;
        font-size: 18px;
        padding-left: 10px;
        max-width: 90%;
    }
    .user-disability-modal__radio-wrapper{
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }
    .user-disability-modal__radio-label{
        margin-left: 0;
    }
    .user-disability-modal__question-who{
        justify-content: flex-start;
        align-items: baseline;
        padding-left: 84px;
    } 
    .user-disability-modal__radio-label:first-child, .user-disability-modal__radio-label{
        margin-top: 17px;
    }
    .user-disability-modal__question-age-slider{
        width: 90%;
    }
    .user-disability-modal__question_disability .user-disability-modal__checkboxes-wrapper{
        flex-wrap: wrap;
        padding: 0 5% 5%;
        justify-content: space-evenly;
    }
    .user-disability-modal__question_disability .user-disability-modal__checkbox-label{
        margin-left: 0;
        margin-top: 5%;
    }
    .user-disability-modal__question_equipment .user-disability-modal__checkboxes-wrapper{
        justify-content: space-evenly;
    }
    .user-disability-modal__question_equipment .user-disability-modal__checkbox-label{
        margin-left: 0;
    }    
    .user-disability-modal__question_disability{
        justify-content: center;
    }
    .user-disability-modal__question_mobility .user-disability-modal__radio-label,
    .user-disability-modal__question_disability .user-disability-modal__radio-label:first-child
    {
        margin: 0px;
        margin-top: 5%;
    }
    .user-disability-modal__question_mobility .user-disability-modal__radio-wrapper{
        justify-content: space-around;
    }
    .user-disability-modal__question-wrapper{
        height: auto;
    } 
    .user-disability-modal-mobile-finish{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        background: rgba(61, 158, 229, 0.36);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .user-disability-modal-mobile-finish__content{
        position: absolute;
    }
    .user-disability-modal-mobile-finish__content-wrapper{
        width: 318px;
        height: 247px;
        background: #FFFFFF;
        border-radius: 3px; 
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 40px;
        justify-content: space-between;
    }
    .user-disability-modal-mobile-finish__button{
        background: #007EB1;
        border-radius: 5px;
        padding: 18px 33px;
        text-transform: uppercase;
        color: #FFFFFF;
    }
    .user-disability-modal-mobile-finish__header{
        font-weight: 500;
        font-size: 20px;
        color: #000000;
        margin: 0;
    }
    .user-disability-modal-mobile-finish__text{
        color: #007EB1;
        font-size: 16px
    }
    .user-disability-modal__question .google-input-field{
        width: 90%;
    }
    .user-disability-modal__question .google-input-field input{
        width: 100%;
    }
    .user-disability-modal__question_destination .user-disability-modal__radio-wrapper{
        padding: 0;
    }
    .user-disability-modal__question-wrapper{
        padding-bottom: 5%;
    }
    .user-disability-modal__question_allergies .user-disability-modal__checkboxes-wrapper{
        justify-content: space-evenly;
    }
    .user-disability-modal__question_allergies .user-disability-modal__checkbox-label{
        margin-left: 0;
    }
    .user-disability-modal__question_destination .user-disability-modal__checkboxes-wrapper{
        flex-direction: column;
        align-items: baseline;
    }
    .user-disability-modal__question_destination .user-disability-modal__checkbox-label{
        margin-left: 0;
    }
  }
  