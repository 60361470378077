.accessibility-box {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -8px 0;
  flex: 1;
}

.accessibility-box li {
  margin-bottom: 20px;
  align-items: center;
  border-radius: 3px;
  flex-direction: column;
  padding: 0 8px;
}
.accessibility-box li.more {
  display: none;
  cursor: pointer;
}
.large.accessibility-box li:nth-of-type(5) ~ li {
  display: none;
}
.small.accessibility-box li:nth-of-type(7) ~ li {
  display: none;
}

.large.accessibility-box li:nth-of-type(7) ~ li.more {
  display: block;
}
.small.accessibility-box li:nth-of-type(10) ~ li.more {
  display: block;
}

.large.accessibility-box li:first-child:nth-last-child(7) ~ li:nth-of-type(6) {
  display: block;
}
/* .small.accessibility-box li:first-child:nth-last-child(10) ~ li:nth-of-type(9) {
  display: block;
} */

.large.accessibility-box.show-all li:nth-of-type(5) ~ li {
  display: block;
}
.small.accessibility-box.show-all li:nth-of-type(8) ~ li {
  display: flex;
}

.large.accessibility-box.show-all li:nth-of-type(5) ~ li.more {
  /* display: none; */
  /* border: 1px solid pink; */
}
.small.accessibility-box.show-all li:nth-of-type(8) ~ li.more {
  /* display: none; */
  /* border: 1px solid pink; */
}

/* .accessibility-box li.more .icon-wrap {
  background: #919191;
} */
.accessibility-box li.more .icon-wrap .icon {
  background-color: #666666;
}
/* .accessibility-box li.more .icon {
  background: url("../../images/object-page/accessibility/more.png") #919191
    no-repeat center center;
}
.accessibility-box.show-all li.more .icon {
  background-image: url("../../images/object-page/accessibility/less.png");
}*/

.accessibility-box .icon-wrap {
  margin-bottom: 13px;
  position: relative;
  border-radius: 3px;
  padding: 5%;
}
.accessibility-box .icon {
  background-color: white;
  width: 100%;
}
.accessibility-box .info-icon::after {
  position: absolute;
  top: -12px;
  right: -12px;
  padding-left: 0;
  padding-bottom: 0;
}
.headers h4 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0;
}
.more .headers h4{
  text-decoration: underline;
  font-weight: bold;
}

.headers p {
  font-size: 12px;
  color: #666;
  font-weight: normal;
}
.mrp-page .accessibility-box.full li.mrp{
  width: 25%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.small.accessibility-box.show-short li:nth-of-type(7) ~ li {
  display: none;
}
.mrp-page .accessibility-box.full li.more{
  display: flex !important;
}
.mrp-page .accessibility-section{
  background-color: white;
}
.mrp-page .accessibility-box .icon-wrap{
  padding: 0;
  margin-bottom: 0;
}
.mrp-page .accessibility-box .icon{
  width: 45px;
  height: 45px;
  border-radius: 3px;
  margin-right: 10px;
}
.mrp-page .accessibility-and-reviews{
  border-top: 1px solid #E6E6E6;
}
.accessibility-and-reviews ul > li .icon-wrap img{
  max-width: none;
}


  
