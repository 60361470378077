
.similar-properties-section{
    width: 100%;
    display: flex;
    margin: auto;
    flex-direction: column;
}
.similar-properties-list-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%; 
}
.similar-properties-list {
    display: flex;
    width: 85%;
    justify-content: left;
    margin-top: 1rem;
}
.similar-properties-list .search-single-property {
    width: 19%;
    padding: 0;
    margin: 0 4px;
}
.similar-properties-list .search-single-property-mobile {
    width: 100%;
    padding: 0;
    margin: 0 4px;
}
@media only screen and (max-width: 750px) {
    .similar-properties-list .search-single-property {
        width: 100%;
        padding: 0;
        margin: 0 4px;
    }
  }
.similar-properties-list .last-row{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.similar-properties-button{
    border-radius: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    background-image: url(../images/icons/similar-prop-arrow.svg);
}
.similar-properties-button:focus{
    outline: none;
}
.similar-properties-button:active{
    opacity: 0.2;
}
.similar-properties-list button{
    padding: 6px 20px;
    background-color: #ff7059;
}
.previous-button{
    background-image: url(../images/icons/similar-prop-arrow-back.svg);
}
.similar-properties-list-wrapper .button-wrapper{
    width: 80px;
}
.similar-properties-list-wrapper h2{
    font-size: 13px;
    white-space: normal !important;
}

.similar-properties-section-title{
    margin-left: 12%;
    color: #a0a0a0;
    font-size: 18px;
    font-weight: 700;
}
.similar-properties-list a{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-radius: 3px 3px 3px 3px;
    border-color: #d4d4d4;
}
.similar-properties-list .info{
    height: 100%;
    border: none;
}
.similar-properties-list .pre {
    padding-right: 10px;
}