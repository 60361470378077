.booking-widget {
  margin-top: -47px;
  background-color: white;
  border-radius: 3px;
}

.booking-widget .booking-widget-price {
  background-color: #1e1e1e;
  width: 100%;
  height: 47px;
  color: #fff;
  display: flex;
  align-items: center;
}

.booking-widget .booking-widget-price p {
  margin-left: 20px;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  margin-bottom: 0;
}

.booking-widget .book-body p {
  font-size: 16px;
  font-weight: bold;
  color: #484848;
  margin-bottom: 10px;
}

.booking-widget p.err {
  color: #ff0000;
}

.booking-widget .select-dropdown {
  margin-bottom: 20px;
}

.booking-table {
  margin-bottom: 20px;
  width: 100%;
  font-weight: bold;
}
.booking-table .hidden-tr {
  display: none;
}
.booking-widget .coupon-input-field {
  margin-bottom: 20px;
}
.booking-widget .coupon-input-field input {
  border-radius: 3px 0px 0px 3px;
  border-right: none;
}
.booking-widget .coupon-input-field button {
  max-width: 80px;
  padding: 0;
  border-radius: 0 3px 3px 0;
  text-transform: uppercase;
}

.booking-widget .book-body .booking-button {
  height: auto;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
}
.booking-widget .book-body .booking-button.active {
  border: 1px inset gray;
  filter: blur(1px);
  cursor: wait;
}
.booking-widget .book-body {
  border: 1px solid #d4d4d4;
  border-top: none;
  padding: 25px 20px;
  background-color: #fff;
  border-radius: 0 0 3px 3px;
}

.booking-widget .date-picker {
  height: 50px;
  border: 1px solid #d4d4d4;
  margin-bottom: 16px;
}

.booking-widget .book-body .counter-field {
  border: 1px solid #d4d4d4;
  margin-bottom: 20px;
  display: flex;
  height: 50px;
  min-width: 100px;
  border-radius: 3px;
}

.booking-widget .book-body .counter-field > button {
  border: none;
  border-radius: 3px;
  background-color: #fff;
  font-size: 30px;
  color: #464646;
  font-weight: 700;
  display: block;
  margin-bottom: 2px;
  padding: 0px;
}

.booking-widget .book-body .counter-field .input-field-wrapper {
  height: 100%;
  width: 100%;
}

.booking-widget .book-body .counter-field input {
  text-align: left;
  text-indent: 20px;
}

.booking-widget .book-body .small-booking-text {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
  color: #666;
}

/* Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) {
}

/* Medium devices (phones, 768px and down) */
@media (max-width: 768px) {
  .booking-widget {
    background-color: rgba(0, 0, 0, 0.9);
    /* position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto; */
    margin-top: 0;
  }

  .booking-widget .booking-widget-price {
    height: 90px;
    justify-content: space-between;
  }

  .booking-widget .booking-widget-price button {
    margin-right: 20px;
    font-size: 40px;
    opacity: 0.8;
  }

  .booking-widget .date-picker {
    height: 70px;
  }

  .booking-widget .select-dropdown {
    height: 70px;
  }
  .booking-table {
    margin-bottom: 20px;
    width: 100%;
    font-weight: bold;
    line-height: 30px;
    font-size: 16px;
  }

  .booking-widget .input-field-wrapper {
    height: 70px;
  }

  .booking-widget .book-body .booking-button {
    height: 100px;
  }
}
.booking-widget .best-price{
  max-height: 100px;
  margin: auto;
  display: block;
}

/* Large devices (tablets, 992px and down§) */
@media (max-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
}
