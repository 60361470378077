.list-your-property-page {
  margin-top: 60px;
}

.list-your-property-page-layout {
  position: relative;
}

.list-your-property-page > header {
  align-items: center;
  background-color: #FFF;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  height: 60px;
  justify-content: space-between;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.list-your-property-page > header .center-content > * {
  display: inline-block;
}

.list-your-property-page > header .logo {
  background-image: url('../../images/misc/handiscover_logo_text@2x.png');
  background-size: 100%;
  height: 36px;
  width: 170px;
  margin: 0 20px;
}

.list-your-property-page > header h1,
.list-your-property-page > header h2 {
  flex: 3;
  margin: 0 20px;
}

.list-your-property-page > header h2 {
  text-align: right;
  color: #bbb;
}

.list-your-property-page > header button {
  background-color: #FFF;
  color: blue;
  padding: 0 20px;
}

.list-your-property-page-layout .layout-content {
  align-items: stretch;
  background: linear-gradient(90deg, #FFF 66.66%, #fafafa 66.66%);
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  flex-wrap: wrap;
  max-width: 1170px;
  margin: auto;
  position: relative;
}

.list-your-property-page-layout .full-width {
  justify-content: center;
  background: none;
}

.list-your-property-page-layout .put-image-upload-box {
  margin-top: 50px;
}

.list-your-property-page-layout .left,
.list-your-property-page-layout .right {
  padding: 10px 0;
  min-height: 100%;
}
.list-your-property-page-layout .left {
  flex: 8;
}
.list-your-property-page-layout .right {
  flex: 4;
}
.list-your-property-page-layout .left:after {
  content: "";
  display: table;
  clear: both;
}

.list-your-property-page-layout .content-box {
  margin: 40px 40px 0 0;
}

.list-your-property-page-layout .right {
  border-left: 1px solid #e5e5e5;
}

.list-your-property-page-layout .info-boxes {
	margin: 60px 40px;
	position: fixed;
	left: 50%;
	top: 40px;
	width: 33.3%;
  transform: translate(195px,0);
  overflow-x: scroll;
  max-height: 100%;
}

.list-your-property-page-layout .info-boxes > * {
  background-color: #FFF;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  padding: 20px;
  width: 300px;
}

.list-your-property-page-layout nav {
  border-bottom: 1px solid #e5e5e5;
}

.list-your-property-page-layout nav ul {
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
}

.list-your-property-page-layout nav ul li {
  flex:1;
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.list-your-property-page-layout nav ul.mrp-room li.hide-mrp-room {
  display: none;
}
.list-your-property-page-layout nav ul.mrp-hotel li.hide-mrp-hotel {
  display: none;
}
.list-your-property-page-layout nav ul li a {
  background-color: #FFF;
  flex:1;
  text-align: center;
  line-height: 40px;
}

.list-your-property-page-layout nav ul li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.list-your-property-page-layout nav ul.disabled li a {
  pointer-events: none;
  color: rgba(0,0,0,0.3);
}

.list-your-property-page h2 {
  margin-top: 10px;
}

.list-your-property-page h3 {
  margin: 20px 0 5px;
}

.info-boxes div.icon {
	display: inline-block;
	background: url('../../images/list-my-space/lightbulb.png') no-repeat;
	height: 59px;
	width: 51px;
}

.property-facilities-form textarea {
	width: 100%;
	height: 30em;
	padding: 10px;
}

.property-facilities-form label.checkbox, .put-facilities-form label.checkbox{
	display: inline-block;
	width: 33.33%;
}

@media(max-width:1170px) {
	.list-your-property-page-layout .info-boxes {
		transform: translate(16.66vw,0);
	}
}
