.a11y-stepper-wrapper{
    display: flex;
}
.a11y-stepper{
    width: 30%;
}
.a11y-lys-item-tabs-wrapper{
    width: 70%;
}
.MuiBox-root{
    padding: 0;
}
.a11y-lys-text-input-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
}
.a11y-lys-text-input{
    margin-left: 15px;
}