.protect-refund__wrapper{
    display: flex;
    flex-direction: column;
    border: 1px solid #E6E6E6;
    border-radius: 3px;
    padding: 10px;
    display: none;
}
.protect-refund__wrapper_disabled{
    filter: grayscale(1);
}
.protect-refund__title-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.protect-refund__title{
    margin-bottom: 0;
    font-size: 18px;
    color: #1e1e1e;
    font-weight: bold;
}
.protect-refund__title-wrapper span{
    background-color: #218234;
    color: white;
    text-transform: uppercase;
    padding: 5px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 12px;
}
.protect-refund__checkbox-label{
    position: relative;
    padding: 10px !important;
    border: 1px solid #E6E6E6;
    border-radius: 3px;
    margin-top: 10px;
    display: flex;
    padding: 10px;
    cursor: pointer;
}
.protect-refund__checkbox-label::before{
    height: 20px;
    width: 20px;
    content: '';
    position: absolute;
    background-image: url('../images/object-page/protect-refund.svg');
    left: 38px;
}
.protect-refund__checkbox{   
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.protect-refund__checkbox-custom{
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border: 1px solid #E6E6E6;
    border-radius: 3px;
}
.protect-refund__checkbox-custom-checked{
    width: 20px;
    height: 20px;
    background-image: url('../images/disability-profile/checked-icon-white.svg');
    background-color: #007EB1;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #007EB1;
    border-radius: 1px;
}
.protect-refund__checkbox-label-left{
    font-weight: 500;
    font-size: 14px;
    color: #666666;
    margin-left: 34px;
}
.protect-refund__checkbox-label-right{
    font-size: 14px;
    color: #666666;
    font-weight: bold;
    margin-left: 6%;
}
.protect-refund__checkbox-label.true{
    border: 1px solid #0E75BA;
}
.protect-refund__checkbox-label.true .protect-refund__checkbox-label-left{
    color: #1e1e1e;
}
.protect-refund__checkbox-label.true .protect-refund__checkbox-label-right{
    color: #0E75BA;
}
.protect-refund__checkbox-label.true::before{

    background-image: url('../images/object-page/protect-refund-active.svg');
}
.protect-refund__list{
    list-style: none !important;
    padding-top: 10px;
    padding-left: 22px;
    margin-bottom: 0 !important;
}
.protect-refund__list-item{
    position: relative;
    font-weight: normal;
    font-size: 16px;
    color: #1E1E1E;
    margin-bottom: 0 !important;
}
.protect-refund__list-item::before{
    content: '';
    position: absolute;
    width: 17px;
    height: 14px;
    background-image: url('../images/object-page/protect-refund-list-icon.svg');
    left: -21px;
    top: 5px;
}
.protect-refund__many-more{
    color: #1E1E1E;
    font-size: 16px;
}
.protect-refund__disclaimer{
    position: relative;
    background: rgba(230, 230, 230, 0.5);
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px;
    padding-left: 60px;
    font-size: 13px;
    color: #1e1e1e;
    margin-top: 10px;
    margin-bottom: 0 !important;
}
.protect-refund__disclaimer::before{
    content: '';
    position: absolute;
    background-image: url('../images/object-page/protect-refund-logo.png');
    width: 40px;
    height: 30px;
    left: 11px;
    top: 34px;
    background-size: contain;
    background-repeat: no-repeat;
}
.included-in-price .protect-refund__checkbox-label-right{
    margin-left: 6px;
}
.included-in-price .protect-refund__checkbox-label::before{
    left: 4px;
    top: 14px;
}
.included-in-price  .protect-refund__checkbox-label-left{
    margin-left: 20px;
}
.included-in-price  .protect-refund__price-recommended{
    background: rgba(230, 230, 230, 0.5);
    border: 1px solid #E6E6E6;
    color: #666666;
    text-transform: uppercase;
    padding: 5px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 12px;
    margin-left: 10px;
}
.included-in-price  .protect-refund__checkbox-label{
    align-items: baseline;
    cursor: initial;
    display: none;
}
@media only screen and (max-width: 400px) {
    .protect-refund__checkbox-label{
        flex-direction: column;
    }
    .protect-refund__checkbox-label-left{
        margin-left: 60px;
    }
    .protect-refund__checkbox-label-right{
        margin-left: 60px;
    }
    .protect-refund__checkbox-custom{
        position: absolute;
        top: 20px;
        left: 11px;
    }
    .protect-refund__checkbox-label::before{
        left: 40px;
        top: 20px;
    }
}