.blog-post {
  display: flex;
  flex-direction: column;
}
.blog-post > a >h4 {
  margin-top: 3% !important;
  color: rgb(10, 160, 217);
  font-size: 23px;
  margin-bottom: 0px;
  border: 2px solid rgb(10, 160, 217);
  padding: 3px;
  align-self: flex-start;
}
.blog-post h1 {
  margin-top: 1% !important;
  color: rgb(10, 160, 217);
  margin-bottom: 0px;
}
.eBook-container{
  width:max-content;
  width: fit-content;
  border: 1px solid #E6E6E6;
  border-radius: 3px;
  position: relative;
  overflow:hidden;
}
.eBook-image{
  max-width: 100% !important;
  width:auto !important;
  height: auto !important;
  object-fit: contain !important;
  opacity: 0.3;
  border: 1px solid #E6E6E6;
  padding: 5px;
  border-radius: 3px;
  position:relative;
  -webkit-transition:all .9s ease; 
  -moz-transition:all .9s ease; 
  -o-transition:all .9s ease;
  -ms-transition:all .9s ease; 
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  margin-bottom:0px !important;
}
.eBook-container:hover  #eBook-image-id{
  opacity: 0.7;
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
  background: #fff;
  box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.50);
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.eBook-button{
  background-color: rgb(14, 117, 186);
  position: absolute;
  z-index: 1;
  left:50%;
  transform: translatex(-50%);
  width: 192px;
  top: 44%;
}
.blog-post-date {
  font-size: 16px;
  font-weight: 600;
  color: black;
  margin: 0px;
  margin-top: 0px;
  font-family: inherit;
  padding-bottom: 24px;
  flex: none;
  text-overflow: inherit;
  width: auto;
  line-height: 19px;
}
.blog-post > a{
  align-self: flex-start;
}
.blog-post > div > .blog-post-date{
  margin-top: 0px !important;
}
.blog-post-tags{
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #0C0C0C;
}
.blog-share {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
.blog-share p{
margin:0 !important;
font-family: Quicksand;
font-style: normal;
font-weight: bold;
font-size: 18px !important;
line-height: 22px;
align-items: center;
letter-spacing: 0.02em;

}
#blog-share-sub-text{
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 18px !important;
  line-height: 26px;
  color:black;
  margin-bottom: 10px !important;
}
.share-border-top{
  width: 100%;
  height: 1px;
  background: #E6E6E6;
  margin-top:30px;
}
.share-wrapper {
  display: flex;
  flex-direction: row;
  margin: 35px 0px 77px 0px;
  align-self: center;
}


.share-button{
  margin:0;
  padding:0;
  display: flex;
  width:fit-content;
  height: 50px;
  background: #FFFFFF;
  border: 0.951049px solid #EAEAEA;
  box-sizing: border-box;
  box-shadow: 0px 0.951049px 3.8042px rgba(0, 0, 0, 0.08);
  border-radius: 3.8042px;
  background-repeat: no-repeat;
  background-size: 19px 19px;
  background-position: 15px;
  margin: 0 9px 0 9px;
  padding: 0 25px;
}
.share-button p{
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 15.2168px;
  line-height: 160%;
  color:000000;
  width: max-content;
}
.share-button.facebook{
  background-image: url("../images/signup-page/facebook-small.svg");
}
.share-button.twitter{
  background-image: url("../images/signup-page/twitter-small.svg");
}
.share-button.pinterest{
  background-image: url("../images/signup-page/pinterest-small.svg");
}
.share-button.linkedin{
  background-image: url("../images/signup-page/linkedin-small.svg");
}
.meta-wrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 63px;
  margin-top: 24px;
  text-align: center;
}
.border-bottom-separator{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  width: 500px;
  align-self:center;
  margin-bottom: 50px;
}
#share-wrapper-title{
  display: none;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #0C0C0C;
}

@media screen and (max-width: 940px) {
  .share-button p{
    display: none;
  }
  .share-button{
    height: auto;
    padding: 23px 40px 23px 40px; 
    margin: 0 -1px 0 -1px;
    border-radius: 0;
    background-position: 28px;
  }
  #share-wrapper-title{
    margin-top: 30px;
    display:block;
  }
  .share-wrapper{
    margin: 5px 0px 15px 0px;
  }
}
@media screen and (max-width: 900px) {
  .meta-wrapper {
    flex-direction: column;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 550px){
  #social{
    font-size: 11px !important;
  }
}
@media only screen and (max-width: 500px){
  .border-bottom-separator{
    width: 100%;
  }

}