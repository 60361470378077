.dropdown-parent {
  background-color: black;
}


.dropdown-box {
  height: 50px;
  width: 100px;
  background-color: red;
}

.dropdown-checkbox {
  margin-top: 20px;
  height: 200px;
  width: 100px;
  background-color: green;
}
