.profile-settings__travel-info-destinations-cards{
    display: flex;
    flex-wrap: wrap;
}
.profile-settings__travel-info-destinations-card-wrapper{
    display: flex;
    align-items: center;
    border: 1px solid rgba(121, 118, 118, 0.5);
    border-radius: 2px;
    padding: 12px;
    color: #161616;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    margin-right: 10px;
    margin-top: 16px;
}
.profile-settings__travel-info-destinations-card-button{
    background-color: #FFFFFF;
    border: 1px solid rgba(121, 118, 118, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    width: 27px;
    height: 27px;
    padding: 0;
    margin-left: 10px;
    background-image: url('../../images/user-profile-page/close-cross-gray-icon.svg');
    background-position: center;
}
.profile-settings__travel-info-destinations-input-wrapper{
    padding-top: 20px;
    display: flex;
}
.profile-settings__travel-info-destinations-input-wrapper .google-input-field{
    max-width: 263px;
    width: 100%;
}
.profile-settings__travel-info-destinations-input-wrapper .g-input{
    padding: 10px 16px;
    text-indent: initial;
    border: 1px solid rgba(121, 118, 118, 0.5);
    border-right: none;
    border-radius: 5px 0 0 5px;
    max-height: 47px;
}
.profile-settings__travel-info-destinations-input-button{
    padding: 15px;
    background-color: #007EB1;
    border-radius: 0px 5px 5px 0px;
    max-height: 47px;
    display: flex;
    align-items: center;
}
.profile-settings__travel-info-destinations-input-wrapper .autocomplete-container{
    padding: 0;
    border: 1px solid rgba(121, 118, 118, 0.5);
    border-bottom: none;
    margin-top: -1px;
    border-radius: 0;
    position: absolute;
    max-width: 263px;
    width: 100%;
}
.profile-settings__travel-info-destinations-input-wrapper .google-item-list{
    min-height: 55px;
    border-bottom: 1px solid rgba(121, 118, 118, 0.5);
}
.profile-settings__travel-info-destinations-input-wrapper .properties_autocomplete{
    font-size: 18px;
    color: rgba(121, 118, 118, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    min-height: 55px;
    color: black;
}