.dashboard-content {
  margin: 50px 0;
}

.inbox-conversation {
  display: flex;
  justify-content: center;
}

.inbox-conversation-right {
  flex: 2.5
}

.chat-box {
  font-size: 16px;
  color: #484848;
  margin-bottom: 20px;
}

.conversation-my-message {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  margin-top: 30px;
}

.conversation-message-box {
  background-color: #edefed;
  border:1px solid #d4d4d4;
  min-height: 80px;
  flex: 2.5;
  border-radius: 3px;
  padding: 20px;
}
div.buttons {
  margin-top: 10px;
}
button, a.button {
  padding: 15px 40px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
  background-color:#28b6ff;
  color:white;
}
div.buttons button, a.button {
  margin-right: 10px;
}
.conversation-profile {
  width: 64px;
  height: 64px;
  background-size: 100%;
  background-color:black;
  border-radius: 3px;
}

/* Send message component */
.send-message {
  width: 100%;
  display: flex;
}

.send-message .send-message-bubble {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  flex: 2.5;
}

.send-message .send-message-bubble textarea {
  width: 100%;
  height: 80px;
  resize: vertical;
  border: none;
  margin: 20px;
  font-size: 16px;
  color: #484848;
}

.send-message-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  background-color: #edefed;
  width: 100%;
  border-top:1px solid #d4d4d4;
}

/* Reserverations Details */

.reservation-details {
  border: 1px solid #d4d4d4;
  padding: 20px 30px;
  flex: 1;
  margin-right: 30px;
}

.reservation-details table {
  width:100%;
}

.reservation-details .payment-btn {
  width: 100%;
}

.reservation-details .owner-buttons {
  display: flex;
  justify-content: space-between;
}

.reservation-details .owner-buttons > * {
  padding: 0;
  width: 49%;
}

.reservation-details .owner-buttons .deny-button, .reservation-details .cancel-button {
  background-color: red;
}

.reservation-details-checkin-out {
  display: flex;
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  justify-content: space-between;
  padding:10px 0;
  margin:10px 0;
}

.check-in {
  font-size: 14px;
}

.check-out {
  text-align: right;
}

.date {
  font-weight: bold;
}

.reservation-details-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.reservation-details-row-border-top {
  border-top: 1px solid gray;
  padding-top: 20px;
}

.about-this-guest {
  margin-top: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  padding-bottom: 20px;
}
.date-created {
  font-size: 14px;
  color: #666666;
}
@media(max-width: 575px) {
  .date-created {
    font-size: 12px;
  }
  .conversation-message-box {
    padding: 7px;
    min-height: 55px;
    margin-right: 0px;
  }
  .conversation-my-message {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .send-message .send-message-bubble textarea {
    line-height: 16px !important;
    margin: 10px;
  }
  .conversation-profile {
    width: 40px;
    height: 40px;
  }
  .chat-box {
    line-height: 19px;
    margin-bottom: 5px;
    font-size: 14px;
  }
  .inbox-conversation .send-message-bottom button {
    padding: 15px 32px;
  }
}