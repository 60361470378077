
.sign-in-wrapper{
   background-color: #fff;
   width: 100%;
   margin-bottom: 20px;
   padding: 15px;
   font-size: 14px;
}
.booking-item-container{
border-radius: 4px;
box-shadow:0px 2px 6px rgba(0, 0, 0, 0.14); 
}

.booking-page .textFieldInput .MuiOutlinedInput-input{
   /* padding: 14px 14px; */
   border: none!important;
}

.booking-page{
   background-color: #fff;
}

/* .booking-page .textFieldInput.width.MuiFormControl-fullWidth{
    width: 90%!important;
    } */
.booking-page .input-group{
   display: flex;
   justify-content: space-between;
}

.booking-page .names-form{
   width: 100%;
}

.booking-page .names-form .room-info{
   background-color:transparent;
   border-top: 1px solid #E0E0E0;
   border-radius: 0px;
}

.booking-page .room-info .textfield-group{
   display: flex;
   justify-content: space-between;

}
 
.booking-page .room-info .textfield-group .enquiry-name.required{
    width: 100%;
}

 .booking-page .input-group .MuiGrid-grid-xs-6{
   max-width: 47%;
   display: flex;
   justify-content: space-between;
 }

 .booking-page .room-info .textFieldInput.MuiFormControl-fullWidth{
    width: 97%!important;
 }

 .payment-summary .grid-item-margin{
    margin-bottom: 10px!important;
 }


.payment-summary{
    background-color: #fff;
 }

.payment-summary .property-name{
    font-size: 18px;
    font-weight: bold;
 }

.payment-summary .property-details{
    padding: 15px;
    width: 100%;
}

.payment-summary .dates-wrapper{
    display: flex;
    padding: 10px 10px;
    border: 1px solid black;
    justify-content: space-around!important;
 }

 .payment-summary .display-column{
    display: flex;
    flex-direction: column;
 }
 .payment-summary .border-bottom{
   border-bottom: 1px solid #E0E0E0!important;
 }

 .payment-summary .booking-price{
    background-color: #EAF3FE;
    padding: 15px 20px; 
 }

 .payment-summary .booking-price-pay-now{
    background-color:  #0E75BA;
    padding: 15px 20px; 
    color:#fff;
 }
 .payment-summary .booking-price.wrapper, .payment-summary .booking-price-pay-now.wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center
 }

 .payment-summary .booking-timer{
    background-color:  #fdede7;
    padding: 15px 20px; 
 }

 .payment-summary .textFieldInput .MuiOutlinedInput-input{
    /* padding: 14px 14px; */
    border: none!important;
    }
    
.payment-summary .accordion.MuiPaper-elevation1{
    box-shadow: none;
} 

.payment-summary .pay-now-price{
   color:#0E75BA;
   font-size: 14px;
   font-weight: bold;
   margin-left: 5px;
}

.payment-summary .pay-later-price{
   font-size: 14px;
   font-weight: bold;
   margin-left: 5px;
}

.payment-summary .info-pay-booking-wrapper{
   margin-top: 7px;
   margin-bottom: 15px;
}

.payment-summary .object-page-tabel-included-icons{
  margin-right: 7px;
  width: 20px;
  height: auto; 
}

.payment-summary .included-in-price ul li{
   font-size: 13px;
}
.booking-page-checkout .MuiGrid-spacing-xs-3{
    width: inherit;
}

.booking-room-details .put-info .accessibility-icon {
   width: 38px;
   height: 38px;
   background-color: rgba(0, 0, 0, 0.2);
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   border-radius: 3px;
   margin: 5px 5px 0 0;
   display: inline-block;
 }

 .booking-room-details{
    background-color: #fff;
    margin-top: 20px;
 }
 
.booking-room-details .table-header-nrGuests{
   border-bottom: none;
   border-top: none;
   padding: 0px;
   margin: 3px 0px;
 }

 .booking-room-details .put-info{
    border-bottom: 1px solid #E6E6E6;
    padding: 10px;
 }

 .booking-room-details .table-header-nrGuests.wrapper >div {
display: flex;
align-items: center;
text-transform:none!important;
 }

 .booking-room-details .guests-wrapper{
    margin-left: 5px;
 }

 .booking-room-details .accordion.MuiPaper-elevation1{
   box-shadow: none;
} 

 .booking-header-step{
    font-weight: bold;
    font-size: 18px;
 }

 .booking-room-details .room-put-name,.payment-summary .room-put-name{
    font-size: 14px;
    font-weight: bold;
 }

 .booking-room-details .guests-number,.payment-summary .guests-number{
   font-size: 13px;
}

.booking-room-details .booking-enquiry-message-body{
   width: 80%;
   min-height: 90px;
   padding: 10px;
}

.guest-form .phone-number {
   width: 100%;
}

.guest-form .phone-number .textFieldInput.MuiFormControl-fullWidth {
   width: 98.5% !important;
}

.booking-room-details .booking-enquiry-message-body.special-needs-textarea {
   width: 100%;
}

.booking-page-checkout .wrapper-columns {
   display: flex;
   justify-content: center;
}

.booking-page-checkout .buttons-wrapper-under-section {
   display: none;
   width: calc(100% - 24px);
   margin-top: 10px;
   margin-bottom: 80px;
}

.booking-page-checkout .buttons-wrapper-under-left-column .buttons-wrapper-under-left-column-inner-section {
   display: flex;
   justify-content: space-between;
   margin-top: 10px;
   margin-bottom: 10px;
}

.booking-page .textFieldInput .MuiOutlinedInput-input {
   text-indent: 0;
}

.best-price-section-wrapper-desktop {
   display: block;
}

.best-price-section-wrapper-mobile {
   display: none;
   margin: 0px 12px 15px 12px;
}

.booking-page-checkout .object-page-title__best-price-left {
   justify-content: center;
}

.booking-room-details .MuiAccordionSummary-root {
   padding: 0 !important;
}

.accordion-icon-expanded {
   transform: rotate(90deg);
}

@media only screen and (min-width: 1280px) {
   .checkout-title-wrapper {
      max-width: 67% !important;
   }
}

@media only screen and (min-width: 960px) {
   .checkout-title-wrapper {
      max-width: 75%;
   }
}


@media only screen and (max-width: 900px) {
   .booking-page-checkout .wrapper-columns .MuiGrid-grid-xs-5 {
      max-width: 60%;
      flex-basis: 60%;
   }
   .booking-page-checkout .wrapper-columns .MuiGrid-grid-xs-4 {
      max-width: 40%;
      flex-basis: 40%;
   }
   .MuiTypography-h6, .MuiTypography-h6 {
      font-size: 15px !important;
   }
   .booking-page-checkout .MuiGrid-grid-xs-9 {
      margin-right: auto;
   }
}

@media only screen and (max-width: 767px) {
   .MuiGrid-grid-xs-4 .best-price-section, 
   .best-price-section-wrapper-mobile .best-price-section {
      padding: 0;
   }
}

@media only screen and (max-width: 600px) {
   .booking-page-checkout .wrapper-columns .MuiGrid-grid-xs-5, 
   .booking-page-checkout .wrapper-columns .MuiGrid-grid-xs-4 {
      max-width: 100%;
      flex-basis: 100%;
   }
   .booking-page-checkout .buttons-wrapper-under-left-column {
      display: none;
   }
   .booking-page-checkout .buttons-wrapper-under-section {
      display: block;
   }
   .booking-page-checkout .buttons-wrapper-under-section .buttons-wrapper-under-left-column-inner-section {
      display: flex;
      justify-content: space-between;
   }
   .best-price-section-wrapper-mobile {
      display: block;
   }
   .best-price-section-wrapper-desktop {
      display: none;
   }
}