.property-page .image-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.property-page .image-grid.layout2 {
  flex-wrap: nowrap;
}
.property-page .image-grid.layout2 img {
  object-fit: cover;
  min-width: 0;
  border-radius: 3px;
  max-width: 100%;
  height: 100%;
  width:100%;
}
.property-page .image-grid.layout2 .main-image {
  flex: 0.7;
  min-width: 0;
}
.property-page .image-grid.layout2 .small-images {
  flex: 0.3;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  margin-left: 5px;
}
.property-page .image-grid.layout2 .small-images > * {
  flex: 1;
  margin: 0;
}
.property-page .image-grid.layout2 .small-images .img-wrap {
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
}
/* .property-page .image-grid .image {
  flex-basis: 49%;
  height: 240px;
  background-size: contain;
  background-position: center;
  background-color: white;
  margin-bottom: 16px;
  border-radius: 3px;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  cursor: pointer;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  position: relative;
} */
.property-page .image-grid .image-wrapper {
  width: calc(50% - 8px);
  background-color: white;
  margin-bottom: 16px;
  position: relative;
}
.property-page .image-grid .image {
  object-fit: cover;
  object-position: center;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  position: relative;
  width: 100%;
}
.property-page .image-grid .image-wrapper::after,
.property-page .image-grid.layout2 .img-wrap::after {
  content: attr(data-title);
  position: absolute;
  right: 0;
  bottom: 10px;
  background: #000;
  padding: 5px 20px;
  border-radius: 3px 0 0 3px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.property-page .image-grid .no-caption::after {
  display: none;
}
.property-page .see-all-images {
  background-color: rgba(24, 156, 226, 0.85);
  height: 100%;
  width: 100%;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.property-page .see-all-images:hover {
  background-color: rgba(24, 156, 226, 1);
}
.image-modal {
  height: 100%;
  width: 100%;
}
.image-modal .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 5);
  z-index: 100;
  border-radius: 0;
  outline: none;
  padding: 16px 21px;
}
.image-modal .slider-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.image-modal .carousel {
  display: flex;
  height: 75%;
}
.image-modal .carousel .slick-list {
  width: 100%;
}
.image-modal .image-index {
  color: #fff;
  height: 5%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
.image-modal .carousel-small {
  height: 20%;
  padding-bottom: 20px;
}
@media only screen and (max-width: 767px) {
.image-modal .carousel {
  height: 82%;
}
.image-modal .image-index {
  height: 8%;
}
.image-modal .carousel-small {
  height: 10%;
}
}
.image-modal .carousel .arrow-right {
  content: url("../../images/misc/arrow.svg");
  width: 30px;
  height: auto;
  margin: 0 32px 0 42px;
  transform: rotate(180deg);
  cursor: pointer;
}
.image-modal .carousel .arrow-left {
  content: url("../../images/misc/arrow.svg");
  width: 30px;
  height: auto;
  margin: 0 42px 0 32px;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
.image-modal .carousel .arrow-left,
.image-modal .carousel .arrow-right {
  width: 10px;
  margin: 0 10px 0 10px;
}
}

.carousel-small .slick-list {
  height: 100%;
  margin: auto;
  width: 100%;
}
.image-modal .carousel .slick-slide {
  display: flex !important;
  justify-content: center;
}
.image-modal .carousel-small .slick-slide {
  display: flex !important;
  justify-content: center;
  width: auto !important;
  padding: 0 5px;
}
.image-modal .carousel .slick-list .slick-track {
  height: 100%;
}
.image-modal .carousel-small .slick-list .slick-track {
  height: 100%;
}
.image-modal .carousel-small .slick-slide {
  opacity: 0.5;
}
.image-modal .carousel-small .slick-current {
  opacity: 1;
}

.image-modal .carousel .slick-slide > div {
  width: 100%;
  display: flex;
  align-items: center;
}

.image-modal .carousel-small .slick-slide > div {
    height: 100%;
}

.image-modal .carousel .tile {
  object-fit: contain;
  margin: 0.3% auto;
  width: auto !important;
  display: block !important;
  max-width: 100%;
  max-height: 100% !important;
  height: 100%;
}

.image-modal .carousel-small .tile {
  height: 100%;
  width: auto !important;
}

/*Low Quality Layout pictures*/

.low-quality-layout-main-image{
  flex: 0.5;
  min-width: 0;
  margin: 0 5px;
}

.low-quality-layout-image{
  object-fit: cover;
  object-position: center;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  position: relative;
  width: 100%;
  flex: 0.5;
  margin: 0 5px;
}
.carousel-small{
  display: flex;
}
.image-modal .carousel-small .arrow-left {
  content: url("../../images/misc/arrow.svg");
  width: 30px;
  height: auto;
  margin: 0 42px 0 32px;
  cursor: pointer;
}
.image-modal .carousel-small .arrow-right {
  content: url("../../images/misc/arrow.svg");
  width: 30px;
  height: auto;
  margin: 0 32px 0 42px;
  transform: rotate(180deg);
  cursor: pointer;
}

.image-wrapper{
  width: 100%;
}