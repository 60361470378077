.signup-page-body{
  min-height: 868px;
  position: relative;
}
.signup-page-left{
  width: fit-content;
  display:inline-block;
  position: relative;
}
.signup-page-logo{
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translatex(-50%);
    top: 36%;
}
.signup-benefits{
    position: absolute;
    z-index: 1;
    text-align: center;
    top: 49%;
    width: 100%;
}

.signup-page-banner{
  width: 600px;
}

.signup-benefits ul{
    list-style-type: none;
  }
.signup-benefits h2{
    color:white;
    font-family: Quicksand;
    font-weight: bold;
    font-size: 28px;
    line-height: 160%;
    font-style: normal;
    margin-bottom: 20px;
  }
  .signup-benefits li{
    background-repeat: no-repeat;
    background-size: 17px 15px;
    background-position: left center;
    background-image: url("../images/signup-page/tick.svg");
    margin-bottom:10px;
  }
  .signup-benefits-elements p{
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color:white;
    margin-left: 25px;
  }
.signup-page-right{
  width: fit-content;
  display: inline-block;
  position: absolute;
  left: 52%;
  top: 53%; 
  transform: translateY(-50%);
}
.signup-form-container{
  position: relative;
}
.signup-page-header{
  color:black;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  align-items: center;
  text-align: center;
  margin-bottom:0;
}
.signup-already-a-member{
  font-family: Quicksand;
  font-weight: normal;
  font-size: 16px;
  line-height: 162%;
  align-items: center;
  text-align: center;
  font-style: normal;
}
.signup-page-right .signup-form-container .login-to-read {
  text-align: center;
  font-weight: bold;
  color: #0E75BA;
  font-size: 16px;
  word-wrap: break-word;
  font-style: normal;
  line-height: 162%;
  text-decoration: underline;
}

.signup-page-right .signup-form-container .fb-login-button {
  margin:0;
  padding:0;
  display: flex;
  flex-direction: column;
  position: absolute;
  width:220px;
  height: 46px;
  left: 56px;
  background-color: white !important;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-image: url("../images/icons/signup-form-fb-new-icon.svg");
  background-position: 23px;
  border-style: solid;
  border-width: 1px;
  border-color:lightgray;
  border-radius:7px;
  padding: 12px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.1);

}
.signup-page-right .signup-form-container .fb-login-button p{
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color:#1E1E1E;
  margin-left:45px;
}
.signup-page-right .signup-form-container .g-signin2 {
  margin:0;
  padding:0;
  display: flex;
  flex-direction: column;
  position: absolute;
  width:220px;
  height: 46px;
  right: 55px;
  background-color: white !important;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-image: url("../images/icons/signup-form-g-new-icon.svg");
  background-position: 23px;
  border-style: solid;
  border-width: 1px;
  border-color:lightgray;
  border-radius:7px;
  padding: 12px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
}
.signup-page-right .signup-form-container .g-signin2 p{
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color:#1E1E1E;
  margin-left:45px;
}
.signup-page-login-modal{
  margin-top: 15px;
  margin-bottom: 95px;
}
.signup-form-title{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 15px;
  align-items: center;
  text-align: center;
  font-family: Quicksand;
  margin-bottom:25px;
}
.signup-page-right .signup-form-container .signup-page-input{
  width: 450px;
  height: 55px;
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  margin: 15px auto 0px auto;
  display: block;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 13px;
  padding-right: 19px;
  color: #666666;
}
.signup-page-input-container{
  position: relative;
  left: 0;
  right: 0;
  margin: 0;
  text-align: center;
}
.signup-page-form-submit{
  padding: 15px 208px;
  margin-left: 1px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
  background-color: #0E75BA;
  color: white;
}
.signup-tos{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  font-family: Quicksand;
  margin-top: 10px;
}
.error-message{
  font-weight: bold;
  width: 265px;
  text-align: left;
  margin-left: 56px;
  color: red;
}
.signup-page-right .signup-form-container  .email-used{
  text-align: center;
  font-weight: bold;
  color: #0E75BA;
  font-size: 16px;
  word-wrap: break-word;
  font-style: normal;
  line-height: 162%;
  text-decoration: underline;
}
.signup-page-privacy-and-terms{
  text-align: center;
  font-weight: bold;
  color: #0E75BA;
  font-size: 16px;
  word-wrap: break-word;
  font-style: normal;
  line-height: 162%;
  text-decoration: underline;
}
.password-view{
  position: absolute;
  top: 17px;
  right: 70px;
  font-size: 16px;
  font-family: 'Quicksand';
  color: gray !important;
}
.signup-page-submit-message{
  width: 558px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  align-items: center;
  text-align: center;
  padding-top: 60px;
}
@media only screen and (max-width: 1200px){
  .signup-page-right{
    left:45%;
  }
  .signup-page-banner {
    width: 420px;
    object-fit: cover;
    height: 808px;
    transition: 0.7s;
  }
} 
@media only screen and (max-width: 1080px){
.signup-page-right {
  left: 49%;
}
  .signup-page-login-modal {
  margin-bottom: 80px;
}
.signup-page-submit-message{
  width: 550px;
}
.signup-form-title{
  margin-bottom:20px;
}
.signup-tos{
  width: 345px;
  margin: auto;
  margin-top: 10px;
}
.error-message{
  margin-left: 0px;
}
.signup-page-right .signup-form-container .fb-login-button{
  left:0px;
}
.signup-page-right .signup-form-container .g-signin2{
  right:0px;
}
.password-view{
  right: 20px;
}
}
@media only screen and (max-width: 990px){
  .signup-page-right {
    left:49%;
    top: 50%; 
  }
.signup-page-submit-message{
  width:450px;
  }
.signup-form-inner-container {
  width: 450px;
}
.signup-page-banner {
  height: 858px;
}
}
@media only screen and (max-width: 925px){
  .signup-page-right .signup-form-container .fb-login-button{
    right: 0;
    left: 0;
    margin: auto;
  }
  .signup-page-right .signup-form-container .g-signin2 {
    right: 0;
    left: 0;
    margin: auto;
    top: 135px;
  }
  .signup-tos{
    width: 90%;
  }
.signup-page-right .signup-form-container .signup-page-input{
  width: 90%;
}
.password-view{
  right: 35px;
}
.error-message{
  margin-left: 25px;
}
.signup-page-form-submit{
  padding: 0;
  width: 90%;
  height: 55px;
}
.signup-page-login-modal {
  margin-bottom: 135px;
}
  .signup-page-left{
    width: 100%;
    display:block;
    margin: auto;
  }
  .signup-page-banner{
    width: 100%;
    height: 320px;
  }
  .signup-page-logo{
    top: 10%;
    width: 100px;
    height: auto;
  }
  .signup-benefits{
    top: 45%;
  }
  .signup-page-body{
    min-height: 1200px;
  }
  .signup-page-right {
    padding: 0;
    margin: auto;
    margin-top: 30px;
    padding-top: 20px;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    top: unset; 
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    margin-bottom: 30px;
  }
  .signup-form-title{
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    letter-spacing: 0.02em;
    font-style: normal;
  }
  .signup-form-inner-container{
    padding-top: 5%;
    padding-bottom: 3%;
    background: rgba(14, 117, 186, 0.05);
    border-radius: 0px;
  }
  .signup-page-submit-message{
    padding-bottom: 50px;
    width: 100%;
  }
}

@media only screen and (max-width: 470px){
  .signup-page-logo{
    width: 80px;
  }
  .signup-benefits h2{
    font-size: 24px;
  }
  .signup-benefits-elements p{
    font-size: 16px;
  }
  .signup-benefits{
    top:40%;
  }
  .signup-page-banner{
    height: 280px;
  }
  .signup-page-body {
    min-height: 1120px;
}
  .signup-page-right{
    width: 100%;
    margin-top: 0;
    box-shadow: none;
    margin-bottom: 0;
  }
  .signup-form-container{
    width: 100%;
  }
  .signup-form-inner-container{
    width: 100%;
  }
  .error-message{
    width: 90%;
    margin-left: 5%;
  }
  .signup-page-header{
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 10px;
    font-size: 20px;
  }
  .signup-already-a-member{
    margin-top: 10px;
  }
  .signup-page-right .signup-form-container .g-signin2{
    top: 155px;
  }
}
@media only screen and (max-width: 370px){
  .signup-benefits-elements p {
    font-size: 14px;
}
.signup-page-header{
  font-size: 20px;
}
.signup-page-banner{
  height: 300px;
}
}
@media only screen and (max-width: 325px){
  .signup-benefits ul{
    text-align: left;
    padding: 0 0 0 5px;
  }
}
@media only screen and (max-width: 300px){
  
  .signup-page-header{
    font-size: 20px;
  }
  .signup-page-banner{
    height: 320px;
  }
  .password-view{
    right: 26px;
  }
}
@media only screen and (max-width: 280px){
.signup-page-header{
  font-size: 20px;
}
}