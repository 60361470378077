.user-disability-modal__start-wrapper{
    width: 950px;
    height: 390px;
    background: linear-gradient(180deg, rgba(47, 128, 237, 0.75) 0%, rgba(86, 204, 242, 0.75) 100%), url('../../images/disability-profile/beach-wheelchair.jpeg'), #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    background-size: contain;
    padding-top: 55px;
}
.user-disability-modal__start{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.user-disability-modal__start-logo{
    max-width: 175px;
    max-height: 145px;
}
.user-disability-modal__start-header{
    font-size: 32px;
    color: white;
    margin-top: 32px;
}
.user-disability-modal__start-text{
    font-size: 18px;
    width: 398px;
    color: white;
    text-align: center;
    margin-top: 11px;
    font-weight: 500;
}
.user-disability-modal__button{
    background: #007EB1;
    border-radius: 0px;
    padding: 0;
    text-transform: uppercase;
}
.user-disability-modal__button_start{
    margin-top: 75px;
    width: 100%;
    height: 80px;
}
.user-disability-modal__tabs{
    display: flex;
}
.user-disability-modal__tab{
    background: white;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    height: 50px;
    width: 184px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #939393;
    padding-left: 45px;
    font-weight: 500;
    position: relative;
}
.user-disability-modal__tab::after{
    content: "";
    position: absolute;
    top: -1px;
    right: -26px;
    width: 50px;
    height: 50px;
    transform: scale(0.707) rotate(45deg);
    z-index: 1;
    background: #FFFFFF;
    border:1px solid #E0E0E0;
    border-bottom: none;
    border-left: none;
}
.user-disability-modal__tab_active{
    background: #007EB1;
    color: white;
    border: 1px solid #3A93D5;
}
.user-disability-modal__tab_active::after{
    border:1px solid #2574A9;
    background-color: #007EB1;
    border-bottom: none;
    border-left: none;
}
.user-disability-modal__questions-title{
    height: 76px;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-disability-modal__questions-number {
    width: 35px;
    height: 35px;
    border: 1px solid #939393;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    color: #939393;
}
.user-disability-modal__questions-text {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #050709;
    padding-left: 13px;
}
.user-disability-modal__buttons-wrapper{
    display: flex;
    justify-content: space-between;
}
.user-disability-modal__button_back, .user-disability-modal__button_skip{
    width: 473px;
    height: 65px;
    text-align: left;
    padding-left: 61px;
    position: relative;
}
.user-disability-modal__button_back::before{
    content: '';
    position: absolute;
    width: 16.5px;
    height: 14px;
    background-image: url('../../images/disability-profile/arrow.svg');
    top: 25px;
    left: 37px;
}
.user-disability-modal__button_next{
    width: 474px;
    height: 65px;
    text-align: right;
    padding-right: 61px;
    position: relative;
}
.user-disability-modal__button_next::after{
    content: '';
    position: absolute;
    width: 16.5px;
    height: 14px;
    background-image: url('../../images/disability-profile/arrow.svg');
    transform: rotate(180deg);
    top: 25px;
    right: 37px;
}
.user-disability-modal__finish-text{
    width: 496px;
}
.user-disability-modal__question-wrapper{
    min-height: 198px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-disability-modal__question{
    height: 100%;
    display: flex;
}
.user-disability-modal__radio-wrapper{
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 22px;
}
.user-disability-modal__radio-label{
    position: relative;
    margin-left: 94px;
    margin-bottom: 0;
    cursor: pointer;
}
.user-disability-modal__radio-label:first-child{
    margin: 0px;
}
.user-disability-modal__radio-input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}
.user-disability-modal__radio-input-custom{
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background: #FFFFFF;
    border: 2px solid #007EB1;
    box-sizing: border-box;
    border-radius: 50%;
    left: -31px;
    cursor: pointer;
}
.user-disability-modal__radio-input-custom::after{
    content: "";
    position: absolute;
    display: none;
    height: 14px;
    width: 14px;
    background-color: #007EB1;
    border-radius: 50%;
    left: 3px;
    top: 3px;
}
.user-disability-modal__radio-input:checked ~ .user-disability-modal__radio-input-custom::after{
    display: block;
}
.user-disability-modal__question_age{
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.user-disability-modal__question-age-value{
    width: 42px;
    height: 40px;
    background: #007EB1;
    border-radius: 16px;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}
.user-disability-modal__question-age-slider{
    margin-top: 24px;
    width: 404px;
}
.user-disability-modal__question-age-slider .rc-slider-rail{
    height: 5px;
    background: rgba(61, 158, 229, 0.5);
    border-radius: 2px;
}
.user-disability-modal__question-age-slider .rc-slider-track{
    height: 5px;
    background: #007EB1;
    border-radius: 2px;
}
.user-disability-modal__question-age-slider .rc-slider-handle{
    height: 32px;
    width: 32px;
    border: 3px solid #EDF5FF;
    background: #007EB1;
    top: -9px;
}
.user-disability-modal__question_residence{
    justify-content: center;
}
.user-disability-modal__question .google-input-field input{
    height: 55px;
    width: 338px;
    border: 1px solid #007EB1;
    box-sizing: border-box;
    border-radius: 5px;
    color: #007EB1;
    padding-left: 54px;
}
.user-disability-modal__question .google-input-field input::placeholder{
    color: #007EB1;
}
.user-disability-modal__question .google-input-field{
    position: relative;
    max-width: 338px;
    max-height: 55px;
}
.user-disability-modal__question .google-input-field::after{
    position: absolute;
    content: '';
    width: 55px;
    height: 55px;
    background: #007EB1;
    background-image: url('../../images/disability-profile/pin.svg');
    border-radius: 5px 0px 0px 5px;
    top: 0px;
    background-repeat: no-repeat;
    background-position: center;
}
.user-disability-modal__question .google-input-field .search-category-title{
    display: none;
}
.user-disability-modal__question .autocomplete-container{
    padding: 0;
    border: 1px solid #007EB1;
    border-bottom: none;
    margin-top: -3px;
    border-radius: 0;
}
.user-disability-modal__question .google-item-list{
    min-height: 55px;
    border-bottom: 1px solid #007EB1;
}
.user-disability-modal__question .properties_autocomplete{
    font-size: 18px;
    color: #007EB1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    min-height: 55px;
}
.user-disability-modal__question-who{
    justify-content: center;
    align-items: center;
}
.user-disability-modal__question_destination {
    flex-direction: column;
    align-items: center;
 }
 .user-disability-modal__question_destination  .user-disability-modal__radio-wrapper{
     padding-top: 25px;
 }
 .user-disability-modal__question_disability .user-disability-modal__checkboxes-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
 }
 .user-disability-modal__question_disability .user-disability-modal__checkbox-label{
    border: 1px solid #007EB1;
    box-sizing: border-box;
    border-radius: 4.56px;
    margin-bottom: 0;
    position: relative;
    width: 119px;
    height: 119px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    padding: 13px;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position-y: 14px;
    background-position-x: center;
    padding-bottom: 8px;
    margin-left: 22px;
 }
 .user-disability-modal__question_disability .user-disability-modal__checkbox-label:first-child{
     margin-left: 0;
 }
 .user-disability-modal__question_disability .user-disability-modal__checkbox-input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
 }
 .user-disability-modal__question_disability .user-disability-modal__checkbox-input-custom{
    position: absolute;
    height: 16px;
    width: 16px;
    background: #FFFFFF;
    border: 1px solid #007EB1;
    border-radius: 50%;
    left: 11px;
    cursor: pointer;
    top: 11px;
}
.user-disability-modal__question_disability .user-disability-modal__checkbox-input-custom::after{
    content: "";
    position: absolute;
    display: none;
    height: 16px;
    width: 16px;
    background-color: #FFFFFF;
    background-image: url('../../images/disability-profile/checked-mark.svg');
    border-radius: 50%;
    left: -1px;
    top: -1px;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #2574A9;
}
.user-disability-modal__checkbox-input:checked ~ .user-disability-modal__checkbox-input-custom::after{
    display: block;
}
.user-disability-modal__question_disability .user-disability-modal__checkbox-label_checked{
    background-color: #007EB1;
    color: #FFFFFF;
    background-repeat: no-repeat;
    background-position-y: 14px;
    background-position-x: center;
}

.user-disability-modal__checkbox-label_mobility_impairment{
    background-image: url('../../images/disability-profile/wheelchair-icon.svg');
}
.user-disability-modal__checkbox-label_mobility_impairment.user-disability-modal__checkbox-label_checked{
    background-image: url('../../images/disability-profile/wheelchair-icon-active.svg');
}

.user-disability-modal__checkbox-label_hearing_impairment{
    background-image: url('../../images/disability-profile/hearing-icon.svg');
}
.user-disability-modal__checkbox-label_hearing_impairment.user-disability-modal__checkbox-label_checked{
    background-image: url('../../images/disability-profile/hearing-icon-active.svg');
}

.user-disability-modal__checkbox-label_visual_impairment{
    background-image: url('../../images/disability-profile/visual-icon.svg');
}
.user-disability-modal__checkbox-label_visual_impairment.user-disability-modal__checkbox-label_checked{
    background-image: url('../../images/disability-profile/visual-icon-active.svg');
}

.user-disability-modal__checkbox-label_intellectual_disability{
    background-image: url('../../images/disability-profile/Intellectual-icon.svg');
}
.user-disability-modal__checkbox-label_intellectual_disability.user-disability-modal__checkbox-label_checked{
    background-image: url('../../images/disability-profile/Intellectual-icon-active.svg');
}

.user-disability-modal__checkbox-label_allergy{
    background-image: url('../../images/disability-profile/allergy-icon.svg');
}
.user-disability-modal__checkbox-label_allergy.user-disability-modal__checkbox-label_checked{
    background-image: url('../../images/disability-profile/allergy-icon-active.svg');
}
.user-disability-modal__question_equipment{
    flex-direction: column;
}
.user-disability-modal__question_equipment .user-disability-modal__checkbox-label{
    border: 1px solid #007EB1;
    border-radius: 4px;
    height: 49px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding:16px 10px 16px 61px;
    font-size: 13px;
    color: #050709;
    cursor: pointer;
    position: relative;
    margin-left: 21px;
    margin-top: 15px;
}
.user-disability-modal__question_equipment .user-disability-modal__checkbox-label:first-child{
    margin-left: 0;
}
.user-disability-modal__question_equipment .user-disability-modal__checkbox-label::after{
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    width: 49px;
    height: 47px;
    border-radius: 4px 0px 0px 4px;
    background-color: #EDF5FF;
    left: 0px;
    top: 0px;
    border-right: 1px solid #007EB1;
}
.user-disability-modal__question_equipment .user-disability-modal__checkbox-label_manual_wheelchair::after{
    background-image: url('../../images/disability-profile/manual-wheelchair-icon.svg');
}

.user-disability-modal__question_equipment .user-disability-modal__checkbox-label_electrical_wheelchair::after{
    background-image: url('../../images/disability-profile/electrical-wheelchair-icon.svg');
}

.user-disability-modal__question_equipment .user-disability-modal__checkbox-label_crutch::after{
    background-image: url('../../images/disability-profile/crutch-icon.svg');
}

.user-disability-modal__question_equipment .user-disability-modal__checkbox-label_walker::after{
    background-image: url('../../images/disability-profile/walker-icon.svg');
}

.user-disability-modal__question_equipment .user-disability-modal__checkbox-label_stick::after{
    background-image: url('../../images/disability-profile/walking-stick-icon.svg');
}

.user-disability-modal__question_equipment .user-disability-modal__checkbox-label_leading_dog::after{
    background-image: url('../../images/disability-profile/assistance-dog-icon.svg');
}

.user-disability-modal__question_equipment .user-disability-modal__checkboxes-wrapper{
    display: flex;
    flex-wrap: wrap;
    max-width: 540px;
    justify-content: center;
    padding-top: 10px;
    margin: 0 auto;
    align-content: flex-start;
}
.user-disability-modal__question_equipment .user-disability-modal__checkbox-input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
 }
 .user-disability-modal__question_equipment .user-disability-modal__checkbox-label_checked{
    background-color: #007EB1;
    color: #FFFFFF;
 }
 .user-disability-modal__question_mobility .user-disability-modal__radio-wrapper{
    display: flex;
    margin: 0 auto;
}
 .user-disability-modal__question_mobility .user-disability-modal__radio-label{
    border: 1px solid #007EB1;
    border-radius: 5px;
    padding: 15px 23px 15px 68px;
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 290px;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    height: 128px;
    align-items: center;
    justify-content: center;
    margin-left: 19px;
    color: #007EB1;
 }
 .user-disability-modal__question_mobility .user-disability-modal__radio-label:first-child{
     margin-left: 0;
 }
 .user-disability-modal__question_mobility .user-disability-modal__radio-input-custom{
    display: none;
 }
 .user-disability-modal__question_mobility .user-disability-modal__radio-label::after{
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: 126px;
    border-radius: 4px 0px 0px 4px;
    background-color: #EDF5FF;
    left: 0px;
    top: 0px;
    border-right: 1px solid #007EB1;
}
.user-disability-modal__question_mobility .user-disability-modal__radio-label_checked{
    background-color: #007EB1;
    color: #FFFFFF;
}
.user-disability-modal__question_mobility .user-disability-modal__radio-label_1::after{
    background-image: url('../../images/disability-profile/mobility-level-1-icon.svg');
}
.user-disability-modal__question_mobility .user-disability-modal__radio-label_2::after{
    background-image: url('../../images/disability-profile/mobility-level-2-icon.svg');
}
.user-disability-modal__question_mobility .user-disability-modal__radio-label_3::after{
    background-image: url('../../images/disability-profile/mobility-level-3-icon.svg');
}
.user-disability-modal__question_allergies{
    flex-direction: column;
    width: 100%;
}
.user-disability-modal__question_allergies .user-disability-modal__checkbox-label{
    border: 1px solid #007EB1;
    border-radius: 4px;
    height: 49px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding:16px 10px 16px 61px;
    font-size: 13px;
    color: #050709;
    cursor: pointer;
    position: relative;
    margin-left: 21px;
    margin-top: 15px;
}
.user-disability-modal__question_allergies .user-disability-modal__checkboxes-wrapper{
    display: flex;
    flex-wrap: wrap;
    max-width: 540px;
    justify-content: center;
    padding-top: 10px;
    margin: 0 auto;
    align-content: flex-start;
}
.user-disability-modal__question_allergies .user-disability-modal__checkbox-input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
 }
 .user-disability-modal__question_allergies .user-disability-modal__checkbox-label_checked{
    background-color: #007EB1;
    color: #FFFFFF;
 }


 .user-disability-modal__question_allergies .user-disability-modal__checkbox-label::after{
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    width: 49px;
    height: 47px;
    border-radius: 4px 0px 0px 4px;
    background-color: #EDF5FF;
    left: 0px;
    top: 0px;
    border-right: 1px solid #007EB1;
}
.user-disability-modal__question_allergies .user-disability-modal__checkbox-label_mold::after{
    background-image: url('../../images/disability-profile/mold-icon.svg');
}

.user-disability-modal__question_allergies .user-disability-modal__checkbox-label_dust::after{
    background-image: url('../../images/disability-profile/dust-icon.svg');
}

.user-disability-modal__question_allergies .user-disability-modal__checkbox-label_food::after{
    background-image: url('../../images/disability-profile/food-icon.svg');
}

.user-disability-modal__question_allergies .user-disability-modal__checkbox-label_fur::after{
    background-image: url('../../images/disability-profile/fur-icon.svg');
}
.user-disability-modal__question_destination .user-disability-modal__checkboxes-wrapper{
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    flex-wrap: wrap
}
.user-disability-modal__question_destination .user-disability-modal__checkbox-label{
    position: relative;
    margin-left: 94px;
    margin-bottom: 0;
    cursor: pointer;
    margin-top: 25px;
}
.user-disability-modal__question_destination .user-disability-modal__checkbox-label:first-child{
    margin-left: 0;
}
.user-disability-modal__question_destination .user-disability-modal__checkbox-label .user-disability-modal__checkbox-input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.user-disability-modal__question_destination .user-disability-modal__checkbox-label .user-disability-modal__checkbox-input-custom{
    position: absolute;
    height: 24px;
    width: 24px;
    background: #FFFFFF;
    border: 2px solid #007EB1;
    border-radius: 2px;
    left: -40px;
    cursor: pointer;
}
.user-disability-modal__question_destination .user-disability-modal__checkbox-label .user-disability-modal__checkbox-input-custom::before{
    content: "";
    position: absolute;
    display: none;
    height: 24px;
    width: 24px;
    background-color: #007EB1;
    background-image: url('../../images/disability-profile/checked-icon-white.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 2px;
    top: -2px;
    left: -2px;
}
.user-disability-modal__checkbox-input:checked ~ .user-disability-modal__checkbox-input-custom::before{
    display: block;
}
.user-disability-modal__question_allergies-custom-allergy .input-field-wrapper{
    max-width: 263px;
    height: initial;
    max-height: 50px;
    width: 100%;
}
.user-disability-modal__question_allergies-custom-allergy{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0;
}
.user-disability-modal__question_allergies-custom-allergy .input-field-wrapper input{
    border: 1px solid #007EB1;
    border-radius: 5px 0 0 5px;
    padding: 12px 16px;
    height: initial;
    color: #007EB1;
    text-indent: initial;
}
.user-disability-modal__question_allergies-custom-allergy .input-field-wrapper input::placeholder{
    color: #007EB1;
}
.user-disability-modal__question_allergies-input-wrapper{
    display: flex;
    max-width: 340px;
    width: 100%;
}
.user-disability-modal__question_allergies-custom-allergy-cards{
    display: flex;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.user-disability-modal__question_allergies-button{
    text-transform: uppercase;
    padding: 11px 15px 11px 20px;
    border-radius: 0 5px 5px 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    background: #007EB1;
}
.user-disability-modal__question_allergies-custom-allergy-wrapper{
    margin-top: 25px;
    border: 1px solid #007EB1;
    border-radius: 5px;
    padding: 12px 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    max-width: 90%;
    overflow: hidden;
}
.user-disability-modal__question_allergies-custom-allergy-button{
    padding: 0;
    height: 25px;
    width: 25px;
    background-image: url('../../images/disability-profile/close-icon.svg');
    background-color: #ffffff;
    background-position: center;
}
.user-disability-modal__question_allergies-custom-allergy-title{
    padding-right: 10px;
}

 