.puts-section {
  width: 100%;
}
.puts-section .fixed-css {
  /* background: #000; */
  position: fixed;
  top: 90px;
  z-index: 9;
  display: table;
}

.puts-section .hidden-tr {
  display: none;
}

.puts-section .put-info .title {
  display: flex;
  align-items: center;
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #E6E6E6;
}

.puts-section .put-info .title .mobility-icon {
  background-color: gray;
  min-width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.puts-section .put-info .title span {
  font-size: 16px;
  font-weight: bold;
}

.puts-section .put-info img {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: 5px;
  border-radius: 3px;
}
.puts-section .put-info .table-header-accessibility{
  padding: 0 10px
}

.puts-section .put-info .accessibility {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
}
.puts-section .put-info .accessibility >p{
  width:100%;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin:0;
}
.puts-section .put-info .accessibility-icon {
  width: 38px;
  height: 38px;
  background-color: rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 3px;
  margin: 5px 5px 0 0;
  display: inline-block;
}

.puts-section .put-info button {
  margin-top: 5px;
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  width: 100%;
  font-size: 12px;
}

.puts-section .nr-guests {
  text-align: center;
}

.puts-section table {
  border: 1px solid #d4d4d4;
  border-bottom: none;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.puts-section td {
  padding: 15px;
}

.puts-section thead {
  color: #fff;
  font-weight: bold;
  /* background-color: #000; */
  /* border: 1px solid #000; */
}

.puts-section tr {
  vertical-align: top;
  position: relative;
}

.puts-section tbody td {
  border: 1px solid #d4d4d4;
}

.puts-section tbody tr:first-child td {
  border-top: none;
}

.puts-section thead.fixed-css tr {
  width: 100%;
  display: table;
}

.puts-section thead.fixed-css td:nth-child(1) {
  width: 30%;
  display: table-cell;
}

.puts-section thead.fixed-css td:nth-child(2) {
  width: 18%;
}

.puts-section thead.fixed-css td:nth-child(3) {
  width: 11%;
}

.puts-section thead.fixed-css td:nth-child(4) {
  width: 14%;
}

.puts-section thead.fixed-css td:nth-child(5) {
  width: 12%;
}
.puts-section tr td:nth-child(1){
  width: 16%;
  padding:15px 0;
  background-color: #F5F5F5;
}
.puts-section tr:nth-child(1) td:nth-child(1){
  padding: 0;
}
.puts-section thead tr:nth-child(2) td{
  padding:15px;
}
.puts-section thead{
  border: none;
}
.puts-section thead tr td{
  padding: 10px !important;
  background-color: black;
}
.puts-section thead tr td:nth-child(1){
  background-color: #F5F5F5;
  padding: 0px;
}
.puts-section .fixed-css thead tr td:nth-child(1)
{
  background-color: transparent;
}
.puts-section .fixed-css thead tr:nth-child(1) td{
  padding:15px;
}
.puts-section tr td:nth-child(2) {
  width: 18%;
}

.puts-section tr td:nth-child(3) {
  width: 8%;
}

.puts-section tr td:nth-child(4) {
  width: 10%;
}

.puts-section tr td:nth-child(5) {
  width: 7%;
}
.puts-section tr td:nth-child(6) {
  width: 9%;
  padding: 15px 15px 0 10px;
}

.puts-section .price-wrapper {
  text-align: right;
}

.puts-section .nr-guests {
  margin-top: 27px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}

.puts-section .price-wrapper .price {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: flex-end;
}

.puts-section .price-wrapper .price > * {
  line-height: 1;
  font-weight: bold;
}

.puts-section .price-wrapper .price .number {
  color: #EE4C10;
  font-size: 22px;
  line-height: 27px;
}

.puts-section .price-wrapper .price .currency {
  font-size: 16px;
  margin-left: 5px;
  color: #EE4C10;
  padding-bottom: 3px;
  font-weight: normal;
}

.puts-section .price-wrapper mark {
  background-color: transparent;
  color: black;
  border-radius: 3px;
  width: 100%;
  padding: 0;
  text-align: right;
  margin-top: 5px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
.puts-section .total-col {
  position: sticky;
  top: 205px;
}
.puts-section .total-col .total-rooms {
  font-size: 16px;
  font-weight: bold;
}

.puts-section .total-col .price {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.puts-section .total-col .price > * {
  line-height: 1;
  font-weight: bold;
  margin-top: 5px;
}

.puts-section .total-col .price .number {
  font-size: 20px;
}

.puts-section .total-col .price .currency,
.puts-section .total-col .price .fees {
  font-size: 14px;
  margin-left: 2px;
  padding-bottom: 1px;
}

.puts-section .total-col .price .fees {
  margin-left: 3px;
}

.puts-section .total-col mark {
  background-color: #000;
  color: #fff;
  border-radius: 3px;
  width: 100%;
  padding: 3px 10px;
  margin-bottom: 5px;
}

.puts-section .total-col .summary {
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0;
}

.puts-section .total-col .err {
  color: red;
  font-size: 1em;
  background-color: transparent;
  padding: 0;
}

.puts-section .total-col ul.err-list {
  list-style: disc;
  list-style-position: outside;
  margin-left: 15px;
  color: red;
}

.puts-section .total-col ul.err-list li {
  display: list-item;
  margin-bottom: 10px;
}

.puts-section .total-col button.book {
  width: 100%;
  margin: 10px 0;
}
.puts-section .total-col button.book.book-now {
  background-color: #EE4C10;
  
}
.puts-section .total-col .buttons{
  padding:0;
}
.puts-section .total-col button {
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  font-size: 14px;
}

.property-page.put-modal .property-main {
  padding: 20px;
}

.puts-section.cards {
  margin-top: 40px;
  width: 100%;
  padding: 20px;
  background: #fafafa;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.puts-section .put-card {
  background: #fff;
  margin-bottom: 20px;
}

.puts-section .put-card .cover {
  width: 100%;
}

.puts-section .put-card .card-body {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  padding: 10px 10px;
}
.puts-section .card-body .accessibility-wrapper p{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
  margin:0 0 5px 0;
  width: 100%;
}
.puts-section .card-body .accessibility-wrapper div{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.puts-section .card-body .accessibility-wrapper div > span{
  width: 100%;
  cursor: pointer;
}
.puts-section .card-body .accessibility-wrapper div > span > img{
  margin-left:5px
}
.puts-section .card-body .accessibility-wrapper div > span > .collapse-button-arrow{
  transform: rotate(180deg);
}
.puts-section .put-card .details-wrapper p{
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
}
.puts-section .put-card .details-wrapper .details-content-wrapper{
  display: flex;
  justify-content: space-around;
}
.puts-section .put-card .details-wrapper .details-content-wrapper div img{
  width: 24px;
  height: 17px;
}
.puts-section .put-card .details-wrapper .details-content-wrapper div{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
}
.puts-section .put-card .title {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.puts-section .put-card .title .mobility-icon {
  background-color: gray;
  min-width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-right: 10px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.puts-section .put-card .title span {
  font-size: 16px;
  font-weight: bold;
}

.puts-section .put-card .accessibility {
  display: flex;
  flex-wrap: wrap;
}
.puts-section .put-card .accessibility-icon {
  width: 35px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 5px 5px 0;
  border-radius: 6px;
}
.puts-section .put-card .accessibility-icon:nth-child(4n + 1) {
  /* margin-left: 0; */
}
.put-card .slick-slider,
.put-info .slick-slider {
  display: flex;
  padding: 10px;
}
.put-card .slick-slider .slick-list,
.put-info .slick-slider .slick-list {
  width: 100%;
}

.put-card .slick-slider .slick-list,
.put-info .slick-slider .slick-list img {
  max-height: 210px;
  width: initial !important;
  display: block !important;
  margin: auto;
  max-width: 100%;
  background: transparent;
  /* padding: 10px; */
}
.put-card .slick-slider .slick-list img {
  max-height: 210px;
  width: initial !important;
  display: block !important;
  margin: auto;
  max-width: 100%;
}

.puts-section .slick-track {
  display: flex;
  align-items: center;
}

.puts-section .accessibility-carousel .accessibility-arrow-left {
  content: url("../../images/misc/table-image-arrow.svg");
  width: 30px;
  height: 30px;
  margin: 0;
  z-index: 1;
  cursor: pointer;
  position: absolute;
  left: 9px;
  top: 45%;
  transform: rotate(-180deg);
  /* transform: translate(-50% 0); */
}
.puts-section .accessibility-carousel .accessibility-arrow-right {
  content: url("../../images/misc/table-image-arrow.svg");
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  right: 9px;
  top: 45%;
  /* transform: translate(-50% 0); */
}
@media only screen and (max-width: 767px) {
.puts-section .accessibility-carousel .accessibility-arrow-right {
  right: 0;
}
.puts-section .accessibility-carousel .accessibility-arrow-left{
  left:0;
}
}
.has-tooltip {
  position: relative;
}
.has-tooltip.help {
  cursor: help;
  text-decoration: underline dotted;
}
.has-tooltip > .tooltip {
  font-family: 'Quicksand', sans-serif;
  position: absolute;
  top: -10px;
  left: -120px;
  /* opacity: 0; */
  background: #adadad;
  color: white;
  padding: 4px 8px 4px;
  border-radius: 8px;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.3);
  z-index: 999;
  text-align: center;
  display: none;
}
.has-tooltip > .accessibility-tooltip {
  font-family: 'Quicksand', sans-serif;
  position: absolute;
  bottom: 30px;
  left: 10px;
  min-width: 150px;
  background: #9d9d9d;
  color: white;
  padding: 4px 8px 4px;
  border-radius: 8px;
  border: solid 1px black;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.3);
  text-align: center;
  z-index: 999;
  display: none;
}
.has-tooltip:hover > .accessibility-tooltip {
  opacity: 1;
  display: block;
}
.has-tooltip:hover > .tooltip {
  opacity: 1;
  display: block;
}
.puts-section .put-card .separator {
  margin: 10px 0;
  background: rgba(0, 0, 0, 0.2);
}

.puts-section .put-card .guests-wrapper,
.puts-section .guests-wrapper,
.puts-section .put-card .beds-wrapper {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.puts-section td .guests-wrapper {
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.puts-section .put-card .guests-wrapper img,
.puts-section .guests-wrapper img,
.puts-section .put-card .beds-wrapper img {
  width: auto;
  margin-right: 5px;
  height:16px;
}

.puts-section .put-card .beds-wrapper {
  margin-top: 10px;
}

.puts-section .put-card .info-section {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
}

.puts-section .put-card .info-section .booking-info {
  font-weight: bold;
}

.puts-section .put-card .info-section .price-wrapper {
  margin: 0;
  max-width: 50%;
}

.puts-section .put-card .info-section .price-wrapper mark {
  padding: 0
}

.puts-section .put-card .info-section .price-wrapper .price {
  justify-content: flex-end;
}
.puts-section .object-page-tabel-included-icons{
  margin-right: 7px;
  width: 20px;
  height:auto;
}
.puts-section .put-card .err {
  color: red;
}

.puts-section .put-card .cards-rooms {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.puts-section .put-card .cards-rooms p{
  font-weight: 500;
  font-size: 16px;  
  display: flex;
  align-items: center;
  color: #666666;
  width: 160px;
}
.puts-section .put-card .cards-rooms > * {
  max-width: 50%;
  width: 100%;
  height: 52px;
}

.puts-section .put-card .cards-rooms button {
  margin-left: 10px;
  padding: inherit;
}
.puts-section .put-card .cards-rooms .book-now {
  background-color: #ff7059;
}
.puts-section .put-card .cards-rooms .book-now[disabled]{
  background: #9d9d9d;
}
.puts-section .charge-info-text{
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: #218234;
}
.puts-section .enquiry-info-text{
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  color: #666666;
  margin-bottom: 0;
}
.puts-section .enquiry{
  background-color:#0E75BA
}
.included-in-price ul li {
  display: block;
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 0;
}
.included-in-price ul li.info {
  color: #0E75BA;
  font-size: 12px;
}
.puts-section p.err {
  color: red;
  font-size: 1.5em;
  font-weight: bold;
}
.no-dates.price {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}
.puts-section .booking-button {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  font-size: 13px;
}
.break-words {
  word-wrap: break-word;
}
.color-grey {
  color: #666666;
}
.included-in-price {
  color: #7fc13f;
}
.contact-no-rooms {
  color: red;
  font-weight: bold;
}
.rooms .select-dropdown{
  position: static;
}
.rooms .select-dropdown::after{
  display: none
}

.rooms .select-dropdown select{
  background-image: url("../../images/misc/room-table-dropdown-arrow.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 90%;
}

.puts-section .filter-section {
  display: flex;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
  padding-bottom: 10px;
  border-bottom: 1px solid #E6E6E6;
}
.puts-section .filter-section p{
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: #1E1E1E;
  width: 100%;
  padding-bottom: 10px;
    border-bottom: 1px solid #E6E6E6;

}
.puts-section .filter-section .white {
  background-color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #1E1E1E;
  background-repeat: no-repeat;
  background-image:url('../../images/misc/room-filter-icon.svg');
  background-size: 23px 21px;
  padding-left: 25px;
  border: none;
  margin-right: 20px;
}

.puts-section .filter-section > div:first-child {
    padding: 10px 0 10px 20px;
}
.puts-section .filter-section > div {
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 10px;
}
.puts-section .filter-section > div .checkbox{
  padding: 0 10px;
}
.puts-section .filter-section > div .checkbox-label{
  font-weight: normal;
  font-size: 16px;
  line-height: 162%;
  color: #1E1E1E;
  padding: 0;
  border: none;
  display: inline;
}
.puts-section .filter-section .filter-button button {
  padding: 0;
  width: unset;
  outline: none;
  background-color: transparent;
  color: #0E75BA;
  text-decoration: underline;
}

.puts-section .filter-section .filter-button {
    max-width: 125px;
    padding-left: 0;
    border: none;
    margin-left: auto;
    margin-right: 0;
}
.secondary-room-wrapper{
  padding: 5%;
  margin-top: 1%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: 1s;
  background-color: white;
}
.secondary-rooms-toggle-btn{
  position: relative;
  background-color: #28b6ff;
  border-radius: 3px;
  height: 52px;
  width: 100%;
  font-size: 15px;
  color: #FFF;
}
.hide-btn{
  background-color: gray;
}
.show-btn::after{
	content: " ";
  background: url('../../images/icons/down-arrow-white.png');
  position: absolute;
  height: 20px;
  width: 20px;
  top: 64%;
  left: 48%;
  background-repeat: no-repeat;
  background-size: contain;
}
.hide-btn::after{
	content: " ";
  background: url('../../images/icons/down-arrow-white.png');
  position: absolute;
  height: 20px;
  width: 20px;
  top: 1%;
  left: 48%;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(180deg);
}
.reserved-room-wrapper{
  text-align: right;
  margin-top: 15px;
}
.reserved-room-price-wrapper{
  display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 1%;
    font-weight: 600;
}
.checkout-area{
  position: fixed;
  top: 6%;
  z-index: 9;
  background: #F5F5F5;
  width: 100%;
  left: 0%;
  padding: 20px;
  padding-top: 5px;
  padding-bottom: 5%;
  color: black;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.07);
  border-radius: 0px 0px 6px 6px;
}
.checkout-area button{
  padding: 15px 23px;
  border-radius: 5px;
}
.reserved-room-buttons{
  display: flex;
    justify-content: space-between;
    margin-top: 2%;
}
.checkout-area .cancel-btn{
  background: url('../../images/misc/cancel-button.svg');
  padding: 0;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 10px 0 0 0;
}
.checkout-area .price-wrapper{
  margin: 0;
}
.checkout-area .book-now{
  background-color: #ff7059;
  border: 1px solid white;
}
.checkout-area .enquiry{
  background-color: #0E75BA;
  border: 1px solid white;
}
.checkout-area-total-price{
  display: flex;
    justify-content: space-between;
    padding-top: 1%;
    font-weight: 600;
}
.checkout-area .number, .checkout-area .currency{
  color: white;
}
.reserved-room-title{
  width: 60%;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
}
.checkout-area-total-price span{
  font-size: 16px;
}
.checkout-area-padding-top{
  top: 2%;
  padding-top: 14%;
  transition: 0.2s;
  position: absolute;;
}
.puts-section p.error.err{
  cursor: pointer;
}
@media only screen and (max-width:767px){
  .checkout-area .number, .checkout-area .currency{
    color: black !important;
  }
  .put-card .slick-slider,
.put-info .slick-slider {
  display: block;
  padding:0;
}
}
@media only screen and (max-width:510px){
 .put-card .slick-slider .slick-list img{
  width: 100% !important;
}
}
