.basic-property-info-form > button {
  color: #FFF;
  float: right;
  height: 50px;
  margin-top: 20px;
  width: 170px;
  text-align: center;
  border-bottom: 2px solid #1e82b4;
}

.basic-property-info-form #ui-map {
  display: block;
  height: 200px;
  margin-top: 20px;
  width: 400px;
}

.list-your-property-page .info-boxes p {
	margin-bottom: 30px;
}
.basic-property-info-form label {
	font-size: 18px;
	font-weight: 400;
	margin: 20px 0 5px;
	display: block;
}
.list-your-property-page label span {
	font-weight: 400;
}
.form-field-status {
	display: inline-block;
	background: url('../images/list-my-space/invalid.png') no-repeat;
	background-size: contain;
	width: 40px;
	height: 40px;
	margin-left: 10px;
	margin-top: 7px;
}
.fieldset-container {
	display: flex;
	justify-content: space-between;
}
.fieldset-container fieldset {
	flex: 1;
}
label.checkbox {
	display: block;
	margin: 10px 0 10px 0;
}
label.checkbox input {
	margin-right: 5px;
}
.content-box .footer {
	background: transparent;
	color: inherit;
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
	margin-bottom: 50px;
}
.content-box .footer .separator {
	display: none;
}
button.back-btn {
	background: none;
	border: none;
	color: #6d6d6d;
}
button.back-btn::before {
	content: '⃪';
	position: relative;
	left: -20px;
}