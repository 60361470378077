.suggestions-list {
  display: flex;
  flex-direction: column;
  height: auto;
  border-width: 0px 1px 1px 1px;
  position: absolute;
  font-weight: bold;
  width: 100%;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.suggestion-active {
  font-size: 13px;
  display: block;
  transition: 0.5s;
  padding-left: 10px;
  background-color: rgb(230, 230, 230);
}

.suggestion a {
  cursor: pointer;
  background-color: white;
  color: #666;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: 500;
  display: block;
}

.suggestion a:hover {
  font-size: 13px;
  transition: 0.5s;
  background-color: rgb(230, 230, 230);
}

.articles-modif::after{
  background: url('../../images/icons/articles.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
}

.type-modif::after{
  background: url('../../images/icons/type.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
}

.list-style{
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
}
