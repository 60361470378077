.blog-post-widget-card {
  transition: .3s;
  border-radius: 3px 3px 3px 3px;
  margin: 5px;
  box-shadow: 0px 4px 4px rgba(51,51,51,0.1)
}

.blog-post-widget-card:hover a {
  text-decoration: none;
}

.blog-post-widget-card img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.blog-post-widget-card-info {
  text-decoration: none;
  text-align: center;
  z-index: 2;
}

.blog-post-widget-card-info-wrapper {
  background-color: #fff;
  height: 125px;
  border-radius: 0px 0px 3px 3px;
  padding-top: 20px;
}

.blog-post-widget-card-title {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.2px;
  color: #484848;
  padding-left: 41.5px;
  padding-right: 41.5px;
  margin-bottom: 0.5rem !important;
}

.blog-post-widget-card-category {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px !important;
  letter-spacing: 0.36px;
  color: #484848;
  margin-bottom: 0rem !important;
}

.blog-post-widget-card .img-wrapper {
  width: 100%;
  height: 135px;
  overflow: hidden;
  margin: 0px;
  border-radius: 3px 3px 0px 0px;
  box-shadow: none !important;
}

.slider-wrapper {
  width: 90%;
  height: initial;
  margin: 0 auto;
}

.slider-wrapper .spinner-overlay {
  position: relative !important;
}

.blog-post-widget-card-more {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-decoration-line: underline !important;
  text-align: center !important;
  color: #3097E3 !important;
  margin-top: 10px !important;
}

/*BIG SCREENS*/
@media only screen and (min-width: 600px) {

  .blog-post-widget-card:hover {
    box-shadow: 0 12px 22px 0 rgba(82, 87, 104, 0.1);
    transform: translateY(-4px);
  }

  .blog-post-widget-card-spinner {
    height: 260px !important;
    width: calc(50% - 10px);
    float: left;
  }

  .blog-post-widget-card-wrapper {
    display: inline-block;
    padding: 4%;
    margin: 0;
  }

  .blog-post-widget-card {
    width: calc(50% - 10px);
    overflow: hidden;
    float: left;
  }
  
  .blog-post-widget-card-expand {
    display: none;
  }

  .blog-post-widget-slider-button-next{
    z-index: 10;
    float: right;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    padding: 20px;
    right: -35px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../images/icons/similar-prop-arrow.svg);
}

  .blog-post-widget-slider-button-previous{
    z-index: 10;
    float: left;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 20px;
    left: -35px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../images/icons/similar-prop-arrow-back.svg);
  }
}

/*SMALL SCREENS*/
@media only screen and (max-width: 600px) {
  .blog-post-widget-card-expand {
    display: none;
  }
  
  .blog-post-widget-card-wrapper {
    width: 90%;
    margin: auto;
    padding: 4%;
    width: 100%;
    padding-bottom: 22% !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
    background-color: #f4f4f4;
  }

  .blog-post-widget-card .img-wrapper {
    height: 230px;
  }

  .blog-post-widget-card-info-wrapper {
    margin-top: 0px !important;
  }

  .blog-post-widget-card-spinner {
    height: 260px !important;
    height: 100%;
  }
}
