.book-login-form {
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    box-shadow: 0px 3px 20px rgba(0,0,0,0.1);
    border-radius: 6px;
    position:relative;
    height: auto;
    padding-bottom:10px;
  }
  #book-login-form-signedUp{
    height: 295px !important;
  }
  #book-form-container-id{
    height:227px !important;
  }
  .book-login-form .join-our-community-book-singedUp{
    margin:0 !important;
    padding:0 !important;
    text-align: center;
    font-weight: bold !important;
    font-family: Quicksand;
    font-style:normal;
    font-size: 22px !important;
    line-height: 27px;
    word-wrap: break-word !important;
    color: #1E1E1E;
    position: absolute;
    top: 20px;
    right: 0;
    left: 0;
  }
  .book-login-form .join-our-community-book {
    margin:0 !important;
    padding:0 !important;
    text-align: center;
    font-weight: bold !important;
    font-family: Quicksand;
    font-style:normal;
    font-size: 22px !important;
    line-height: 27px;
    word-wrap: break-word !important;
    color: #1E1E1E;
    position: absolute;
    top: 20px;
    right: 0;
    left: 0;
  }
  .book-border-top-signedUp{
    height:1px;
    width: auto;
    background: #E6E6E6;
    margin-top: 65px;
  }
  .book-border-top{
    height:1px;
    width: auto;
    background: #E6E6E6;
    margin-top: 65px;
  }
  .book-login-form #password-error-message{
    right:213px;
  }

  .book-login-form .already-a-member {
    text-align: center;
    font-size: 16px;
    word-wrap: break-word;
    font-weight: normal;
    font-style: normal;
    line-height: 162%;
    color: #000000;
    left: 0;
    right: 0;
    margin-top:5px;
  }

  
  .book-login-form .already-a-member .login-to-read {
    text-align: center;
    font-weight: bold;
    color: #46b3f0;
    font-size: 16px;
    word-wrap: break-word;
    font-style: normal;
    line-height: 162%;
    text-decoration: underline;
  }
  
  .book-login-form .signup-login-modal {
    display: flex;
    padding:0 !important;
    margin:0 !important;
    position:absolute;
    flex-direction: row;
    top:90px;
    justify-content:space-around;
    width:100%
  }
  .book-login-form .signup-login-modal .fb-login-button {
    margin:0;
    padding:0;
    display: flex;
    flex-direction: column;
    position: absolute;
    width:220px;
    height: 46px;
    left:260px;
    background-color: white !important;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-image: url("../../images/icons/signup-form-fb-new-icon.svg");
    background-position: 23px;
    border-style: solid;
    border-width: 1px;
    border-color:lightgray;
    border-radius:7px;
    padding: 12px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
  
  }
  .book-login-form .signup-login-modal .fb-login-button p{
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color:#1E1E1E;
    margin-left:45px;
  }
  .book-login-form .inbox-image{
    position: absolute;
    padding: 0;
    width: 55px;
    height: 55px !important;
    top: 116px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .book-login-form .signup-login-modal .g-signin2 {
    margin:0;
    padding:0;
    display: flex;
    flex-direction: column;
    position: absolute;
    width:220px;
    height: 46px;
    right:287px;
    background-color: white !important;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-image: url("../../images/icons/signup-form-g-new-icon.svg");
    background-position: 23px;
    border-style: solid;
    border-width: 1px;
    border-color:lightgray;
    border-radius:7px;
    padding: 12px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
  }
  .book-login-form .signup-login-modal .g-signin2 p{
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color:#1E1E1E;
    margin-left:45px;
  }
  .book-login-form .book-form-container-signedUp{
    width:auto;
    height: 320px;
    background: rgba(14,117,186,0.05);
    margin-top: 95px;
  }
  .book-login-form .book-form-container{
    width:auto;
    height: auto;
    background: rgba(14,117,186,0.05);
    margin-top: 95px;
    padding-bottom:10px;
}
.signup-message{
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  font-weight: bold !important;
  font-family: Quicksand;
  font-style: normal;
  font-size: 22px !important;
  line-height: 27px !important;
  word-wrap: break-word !important;
  color: #0E75BA !important;
  padding-top:130px !important;
}
.book-login-form .singup-message-signedUp{
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  font-weight: bold !important;
  font-family: Quicksand;
  font-style: normal;
  font-size: 22px !important;
  line-height: 27px !important;
  word-wrap: break-word !important;
  color: #0E75BA !important;
  padding-top:130px !important;
  padding-left: 180px !important;
  padding-right: 180px !important;
}
.book-login-form .error-message .email-used{
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color:#007bff;
  text-decoration: underline;
}
.book-login-form .error-message{
  color: red;
  margin-left: 7px;
  font-size: 13px !important;
  line-height: 18px;
  position: absolute;
  width: 293px;
}
.book-login-form  .book-form-title{
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 22px;
    letter-spacing: 0.02em;
    color:black;
    position: relative !important;
    text-align: center;
    top: 10px;
    right: 0;
    left: 0;
}
.book-login-form #book-tos-with-margin{
  margin-top:25px;
}
.book-login-form .book-tos{
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 15px;
    margin-left: 25px;
    margin-top: 5px;
    position: relative !important;
    color:black
}
.book-login-form .book-input::placeholder{
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color:#666666
}
.book-login-form .book-input{
    width:297px;
    height: 50px;
    border:1px solid #E6E6E6;
    box-sizing:border-box;
    border-radius:5px;
    position: relative;
    display: inline-flex;
    margin: 5px;
    font-family:Quicksand;
    font-style:normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 13px;
    padding-right: 19px;
    color:#666666
}
.book-login-form .book-input:focus{
    border-color: #46b3f0;
    border-width: 2px;
}
.book-login-form .book-input-container{
    margin-left: 179px;
    margin-bottom: 7px;
}
.book-login-form .book-form-submit{
    padding: 0;
    width:120px;
    height:55px;
    border-radius: 3px;
    background:#0E75BA;
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 22px;
    margin-top: 15px ;
    margin-left: 245px;   
    transition: 1s; 
}


  @media only screen and (max-width: 1080px) {
    .book-login-form .signup-login-modal{
      top: 80px;
    }
    .book-login-form .book-form-container{
      margin-top: 85px;
    }
    .book-login-form .book-input-container{
      margin-left: 93px;
    }
    .book-login-form .book-form-submit{
      margin-left: 245px;
    }
    .book-login-form .already-a-member{
      top: 482px;
    }
    .book-login-form .signup-login-modal .g-signin2{
      right:172px;
    }
    .book-login-form .signup-login-modal .fb-login-button{
      left: 175px;
    }
    .signup-message{
      padding-left: 10% !important;
      padding-right: 10% !important;
    }
    .book-login-form #password-error-message{
      right: 100px;
    }
    .book-login-form .singup-message-signedUp{
      padding-left: 80px !important;
      padding-right: 80px !important;
    }
  }
  

  @media only screen and (max-width: 820px) {
    .book-login-form .signup-login-modal .fb-login-button{
      left:44px;
    }
    .book-login-form .signup-login-modal .g-signin2{
      right:41px;
    }
    .signup-message{
      padding-top:120px;
    }
    .book-login-form .singup-message-signedUp{
      padding-left: 0 !important;
      padding-right: 0 !important;
      font-size: 22px !important;
    }    
    .book-login-form .book-input-container{
      margin-left: 0px;
    }
    .book-login-form .error-message{
      margin-left: 7px;
    }
    .book-login-form .book-tos{
      margin-left: 25px;
    }
    .book-login-form .book-form-container{
      padding-bottom: 75px;
    }
    .book-login-form .error-message{
      width:250px;
    }
    .book-login-form #password-error-message{
      right:13px;
      padding-right: 50px;
    }
    .book-login-form .book-form-submit{
      position: absolute;
      left: 0px;
      right: 0px;
      margin:auto;
      margin-top: 15px;
    }
    .book-login-form .book-input{
      width:259px;
    }
    .book-login-form .already-a-member{
      top:480px;
    }
  }
  
  @media only screen and (max-width: 550px) {
    /* For mobile phones: */
    .book-login-form .singup-message-signedUp{
      padding-left: 5% !important;
      padding-right: 5% !important;
      padding-top:120px;
    }
    .book-login-form .signup-login-modal p{
      font-size: 14px !important;
    }
    .book-login-form .signup-login-modal{
      top: 15px;
      position: relative;
    }
    .book-login-form .join-our-community-book-singedUp{
      padding-left: 17% !important;
      padding-right: 17% !important;
    }
    .book-login-form .join-our-community-book{
      padding-left: 18% !important;
      padding-right: 18% !important;
    }
    .book-border-top{
      margin-top:85px
    }
    .book-border-top-signedUp{
      margin-top: 65px;
    }
    .book-login-form .signup-login-modal .fb-login-button{
      left: 0;
      right: 0;
      margin: auto;
    }
    .book-login-form .signup-login-modal .g-signin2{
      left: 0;
      right: 0;
      margin: auto;
      top: 60px;
    }
    .book-login-form .book-form-container{
      margin-top: 140px;
    }
    .book-login-form .book-input-container{
      left: 0;
      right: 0;
      margin: 0;
      text-align: center;
    }
    .book-login-form .book-input{
      width: 97%;
      margin: 5px !important;
    }
    .book-login-form .book-tos{
      margin-left: 0px;
      padding: 0px 17% 0 17% !important;
    }
    .book-login-form .already-a-member{
      top: 667px;
    }
    .signup-message{
      padding-top: 195px !important;
    }
    .book-login-form .error-message{
      margin:0 !important;
      width:100%;
      position:relative;
      text-align: start;
      padding-left: 7px;
    }
    .book-login-form #password-error-message{
      right:0;
      padding-right: 0px;
    }
    .book-login-form #book-tos-with-margin{
      margin-top:15px;
    }
    .book-login-form .book-form-container{
      padding-bottom:85px;
    }
  }
  @media only screen and (max-width: 500px){
  .book-login-form #error-message-email{
    padding-right:42.4%;
    
  }
  .book-login-form .book-tos{
    padding: 0px 14% 0 14% !important;
  }
  .book-login-form .book-input{
    width: 90%;
  }
  .book-login-form .error-message{
    margin-left: 17px !important;
  }
  }
  @media only screen and (max-width: 460px){
    .book-login-form .join-our-community-book-singedUp{
      padding-left: 10% !important;
      padding-right: 10% !important;
    }
    .book-login-form .join-our-community-book{
      padding-left: 10% !important;
      padding-right: 10% !important;
    }
    .book-login-form .book-tos{
      padding: 0px 10% 0 10% !important;
    }
  .book-login-form .already-a-member {
      top: 680px;
  }
  .book-login-form .book-form-submit{
      margin-top:15px;
  }
  .book-login-form #error-message-email{
    padding-right:38%;
  }
  .book-login-form .book-form-submit{
    width:90%;
  }
  .book-login-form .error-message{
    margin-left: 15px !important;
  }
  .book-login-form .signup-login-modal .g-signin2{
    top:65px;
  }
  .book-login-form .book-form-container{
    margin-top: 150px;
  }
  }
  @media only screen and (max-width: 430px){
    .book-login-form #error-message-email{
      padding-right: 33%;
    }
    .book-login-form .singup-message-signedUp{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  @media only screen and (max-width: 411px){
    .book-login-form .book-input{
      width: 90%;
    }
    .book-login-form .error-message{
      margin-left: 13px !important;
    }
    .book-login-form .book-tos{
      padding: 0px 5% 0 5% !important;
    }
    .book-login-form #error-message-email{
      padding-right: 30%;
    }
  }
  @media only screen and (max-width: 388px){
    .book-login-form .join-our-community-book{
      padding-left: 5% !important;
      padding-right: 5% !important;
    }
    .book-login-form .inbox-image{
      top:100px;
    }
    .book-login-form .singup-message-signedUp{
      padding-top: 105px !important;
    }
    .book-login-form .join-our-community-book-singedUp{
      padding-left: 5% !important;
      padding-right: 5% !important;
    }
    .book-login-form #error-message-email{
      padding-right: 26%;
    }
    .book-login-form .book-tos{
      padding: 0px 4% 0 4% !important;
    }
  }
  @media only screen and (max-width: 360px){
    .book-login-form .join-our-community-book-singedUp{
      padding-left: 1% !important;
      padding-right: 1% !important;
    }
    .book-login-form .join-our-community-book{
      padding-left: 1% !important;
      padding-right: 1% !important;
    }
    .book-login-form .book-input-container{
      padding-right: 0;
      padding-left: 0;
    }
    .book-login-form #error-message-email{
      padding-right: 19.5%;
    }
    .book-login-form .book-tos{
      padding: 0% !important;
    }
    .book-login-form #password-error-message{
      padding-right: 25%;
    }
  }
  @media only screen and (max-width: 335px){ 
    .book-login-form .already-a-member{
      top: 682px;
    }
    .book-login-form #password-error-message{
      padding-right: 20%;
    }
    .book-login-form #error-message-email{
      padding-right: 14.5%;
    }
  }
  @media only screen and (max-width: 310px){
    .book-login-form .join-our-community-book-singedUp{
      padding-left: 10% !important;
      padding-right: 10% !important;
      top: 15px;
    }
    #book-login-form-signedUp{
      height: 315px !important;
    }
    .book-border-top-signedUp {
      margin-top: 85px;
  }
    .book-login-form .singup-message-signedUp{
      padding-left: 0% !important;
      padding-right: 0% !important;
      padding-top: 80px !important;
    }
  .book-login-form .already-a-member {
    top: 694px;
}
.book-login-form #error-message-email{
  padding-right: 7.5%;
}
.book-login-form .book-tos{
  padding-left: 8% !important;
  padding-right: 8% !important;
}
}
   @media only screen and (max-width: 294px){
    .book-login-form .already-a-member{
      top: 715px;
    }
    .book-border-top{
      margin-top: 105px;
    }
   }
   @media only screen and (max-width: 270px){
    #book-login-form-signedUp{
      height: 335px !important;
    }
    #book-form-container-id{
      height:247px !important;
    }
  }