.payout-preferences-page {
  width: 100%;
}

.payout-preferences-page .dashboard-custom-box .inner-content {
  padding: 20px;
}

.payout-preferences-page .accounts-section > *,
.payout-preferences-page .payouts-section > * {
  margin: 0 0 10px 0;
}

.payout-preferences-page .payouts-section {
  margin-top: 20px;
}

.payout-preferences-page table {
  border-collapse: collapse;
  width: 100%;
}

.payout-preferences-page table td {
  padding: 10px;
}

.payout-preferences-page table thead {
  background: rgb(150, 150, 150);
  color: #fff;
}

.payout-preferences-page table thead td {
  border: 1px solid rgb(150, 150, 150);
  border-bottom: none;
}

.payout-preferences-page table tbody td {
  border: 1px solid rgb(200, 200, 200);
}

.payout-preferences-page table tbody tr:first-child td {
  border-top: none;
}

.payout-preferences-page .add-wrapper {
  width: 100%;
  text-align: right;
}
