.dashboard-page{
  background-color: #F5FCFF;
  min-height: 95vh;
}
.dashboard-page .dashboard-header {
  background-color: #373A47;
  height: 44px;
}
.dashboard-page h1 {
  margin-bottom: 0;
}

.dashboard-page .dashboard-header ul {
  height: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 40px;
}

.dashboard-page .dashboard-header ul li {
  height: 100%;
}

.dashboard-page .dashboard-header ul li:hover {
  background-color: rgba(255,255,255,0.2);
}

.dashboard-page .dashboard-header li a {
  color: #bbb;
  line-height: 44px;
  padding: 15px 20px;
}

.dashboard-page .dashboard-content-wrapper {
  background-color: #f5fcff;
  max-width: 1200px;
  min-height: 80%;
  margin: 0 auto;
}

.dashboard-page .dashboard-content {
  width: 100%;
  padding: 0 40px;
}

.dashboard-page .input-field-wrapper input {
  height: 52px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  width: 100%;
}

@media(max-width: 575px) {
  .dashboard-page .dashboard-header ul {
    padding: 0 0;
    text-align: center;
  }
  .dashboard-page .dashboard-header li a{
    padding: 15px 4px;
    font-size: 12px;
  }
  .dashboard-page .dashboard-content {
    padding: 0 10px;
  }
}