.wpcf7 .screen-reader-response {
	position: absolute;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	width: 1px;
	margin: 0;
	padding: 0;
	border: 0;
}

.wpcf7 form .wpcf7-response-output {
	margin: 2em 0.5em 1em;
	padding: 0.2em 1em;
	border: 2px solid #00a0d2; /* Blue */
}

.wpcf7 form.init .wpcf7-response-output {
	display: none;
}

.wpcf7 form.sent .wpcf7-response-output {
	border-color: #46b450; /* Green */
}

.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output {
	border-color: #dc3232; /* Red */
}

.wpcf7 form.spam .wpcf7-response-output {
	border-color: #f56e28; /* Orange */
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
	border-color: #ffb900; /* Yellow */
}

.wpcf7-form-control-wrap {
	position: relative;
}

.wpcf7-not-valid-tip {
	color: #f00;
	font-size: 1em;
	font-weight: normal;
	display: block;
}

.use-floating-validation-tip .wpcf7-not-valid-tip {
	position: absolute;
	top: 20%;
	left: 20%;
	z-index: 100;
	border: 1px solid #ff0000;
	background: #fff;
	padding: .2em .8em;
}

span.wpcf7-list-item {
	display: inline-block;
	margin: 0 0 0 1em;
}

span.wpcf7-list-item-label::before,
span.wpcf7-list-item-label::after {
	content: " ";
}

div.wpcf7 .ajax-loader {
	visibility: hidden;
	display: inline-block;
	/* background-image: url('../../images/ajax-loader.gif'); */
	width: 16px;
	height: 16px;
	border: none;
	padding: 0;
	margin: 0 0 0 4px;
	vertical-align: middle;
}

div.wpcf7 .ajax-loader.is-active {
	visibility: visible;
}

div.wpcf7 div.ajax-error {
	display: none;
}

div.wpcf7 .placeheld {
	color: #888;
}

div.wpcf7 input[type="file"] {
	cursor: pointer;
}

div.wpcf7 input[type="file"]:disabled {
	cursor: default;
}

div.wpcf7 .wpcf7-submit:disabled {
	cursor: not-allowed;
}

.wpcf7 input[type="url"],
.wpcf7 input[type="email"],
.wpcf7 input[type="tel"] {
	direction: ltr;
}

.wpcf7-submit,
input[type="submit"] {
  display: inline-block;
  font-size: 1.4rem;
  text-transform: uppercase;
  line-height: 2.8rem;
  min-width: 40px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 2px;
  max-width: 200px;
  border: none;
  text-align: center;
  color: #fff;
}
.entry-content .s2_form_widget input[type="submit"] {
  min-width: 120px;
}
div.wpcf7-response-output {
  margin: 0;
  padding: 10px 20px;
  border: none;
  border-radius: 2px;
}
.entry-content .error,
div.wpcf7-validation-errors {
  color: #fff;
  background: #e74c3c;
}
.entry-content .success,
div.wpcf7-mail-sent-ok {
  color: #fff;
  background: #27ae60;
}
.entry-content .error,
.entry-content .success {
  display: block;
  float: left;
  margin: 0;
  padding: 10px 20px;
  border-radius: 2px;
}
.entry-content span.wpcf7-not-valid-tip {
  display: none;
  position: absolute;
  top: -8px;
  right: 3px;
  left: auto;
  padding: 6px 10px;
  border: none;
  color: #fff;
  background: #f1c40f;
}
.entry-content .invalid span.wpcf7-not-valid-tip {
  display: block;
}
.entry-content input[type="number"].wpcf7-not-valid,
.entry-content input[type="text"].wpcf7-not-valid,
.entry-content input[type="email"].wpcf7-not-valid,
.entry-content input[type="url"].wpcf7-not-valid,
.entry-content input[type="tel"].wpcf7-not-valid,
.entry-content input[type="password"].wpcf7-not-valid,
.entry-content textarea.wpcf7-not-valid {
  border-color: #f1c40f;
}

.entry-content .wpcf7-submit,
  .entry-content input[type="submit"] {
    max-width: 100%;
    width: 100%;
  }

.entry-content input[type="number"],
.entry-content input[type="text"],
.entry-content input[type="email"],
.entry-content input[type="url"],
.entry-content input[type="tel"],
.entry-content input[type="date"],
.entry-content input[type="password"],
.entry-content select,
.entry-content textarea {
  border: 1px solid;
  padding: 10px;
  outline: 0;
  line-height: normal;
  width: 100%;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  border-radius: 2px;
}
.entry-content button {
  outline: 0;
}
.entry-content .submit {
  width: 31.5%;
  height: 50px;
  text-align: center;
  border: none;
  color: #fff;
  text-transform: uppercase;
}