.gdpr-page-container{
    width: 100%;
}
.gdpr-banner{
    padding:43px 0px 33px 0px;
    background: #FAFAFA;
    width: 100%;
    height: auto;
    text-align: center;
    border-bottom: 1px solid #E6E6E6;
}
.gpdr-form-container p{
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #1E1E1E;
}
.gdpr-banner-image{
    display: inline-block;
    width: 120px;
    height: 120px;
    vertical-align: top;
}
.gdpr-banner-text{
    display: inline-block;
    width: 844px;
    margin-left: 49px;
    text-align: start;
    transform: translateY(20%);
}
.gdpr-banner .gdpr-banner-text h3{
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: #1E1E1E;
}
.gdpr-banner .gdpr-banner-text p{
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1E1E1E;
}
.gpdr-form-container{
    margin: 30px 0px 64px 0px;
    width: 100%;
}
.gpdr-form-container{
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #1E1E1E;
    padding: 0px 24% 0px 24%;
}
.gdpr-page-form-label{
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1E1E1E;
}
.gpdr-form-container input[type="checkbox"]{
    border: 1px solid #0E75BA;
    box-sizing: border-box;
    border-radius: 1.15581px;
}
.gdpr-page-input-container{
    padding: 5px 5px 5px 0px;
    width: fit-content
}
.gdpr-page-form-label{
    margin-left: 10px;
}
.gdpr-page-form-column{
    width: 250px;
    display: inline-block;
    vertical-align: top;
}
.gdpr-form{
    margin-top: 21px;
}
.gdpr-page-submit-button{
    display: block;
    font-family: Quicksand;
    font-style: normal;
    line-height: 22px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    background: #0E75BA;
    padding: 0;
    width: 155px;
    height: 55px;
    margin-top: 15px;
}
#gdpr-request-sent{
    color: #1E1E1E;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    font-family: Quicksand;
    text-align: center;
    padding: 8.5vw 0px 8.5vw 0px;
}
@media only screen and (max-width: 1050px){
    .gdpr-banner-text{
        width: 90%;
        text-align: center;
        margin-left: 0px;
    }
    .gpdr-form-container{
        padding: 0px 22% 0px 22%;
    }
}
@media only screen and (max-width: 910px){
    .gpdr-form-container{
        padding: 0px 18% 0px 23%;
    }
}
@media only screen and (max-width: 410px){
    .gdpr-banner{
        padding: 43px 0px 50px 0px;
    }
}
@media only screen and (max-width: 345px){
    .gdpr-page-form-column{
        width: 190px;
    }
}