@media only screen and (max-width: 900px) {
    .profile-settings__tabs{
        overflow: scroll;
        border-bottom: none;
    }
    .profile-settings__tabs::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    .profile-settings__tab-wrapper{
        display: block;
    }
    .profile-settings__tab-wrapper button{
        width: 100%;
        border-bottom: 1px solid #F3F3F3;
    }
    .profile-settings__tab_active{
        border-top: 1px solid #F3F3F3;
        border-bottom: 3px solid #007EB1 !important;
    }
    .profile-settings__personal-info-inputs{
        flex-direction: column;
    }
    .profile-settings__personal-info-input-wrapper,
    .profile-settings__address-form-wrapper .address-form label{
        width: initial;
    }
    .profile-settings__address-form-wrapper .address-form-content{
        flex-direction: column;
    }
    .profile-settings__edit-button{
        left: 70% !important;
    }
    .profile-settings__section-header{
        max-width: 75%;
    }
    .profile-settings__general-info-save-section{
        top: 87%;
        display: flex;
        justify-content: center;
        padding: 15px;
        z-index: 3;
    }
    .profile-settings__disability-info-facilities-wrapper .user-disability-modal__checkboxes-wrapper{
        flex-direction: column;
    }
    .profile-settings__disability-info-facilities-wrapper .user-disability-modal__checkbox-label{
        width: initial;
    }
    .profile-settings__mobility-info-assistant-wrapper {
        flex-direction: column;
    }
    .profile-settings__disabilities-card{
        width: 100%;
    }
    .profile-settings__mobility-info-card{
        margin-left: 0;
    }
    .profile-settings__form-section{
        padding-right: 20px;
        padding-left: 20px;
    }
    .profile-settings__form-section:last-child{
        padding-bottom: 0;
    }
    .profile-settings__personal-info-input-wrapper{
        padding-right: 0;
    }
    .profile-settings__address-form-wrapper .address-form label {
        padding-right: 0;
    } 
}