.date-picker {
  background-color: #FFF;
  color: #c2c6cc;
  /* height: 100%; */
  text-align: left;
  min-width: 260px;
  border: solid 1px #aaa;
  border-radius: 3px;
  z-index: 100;
}

.DateInput{
  color: #fff;
  background-color: white;
}

.DateInput_input{
  padding: 0;
}

.date-picker .CalendarDay__highlighted_calendar {
  background: #FFF;
  color: rgb(202, 204, 205);
}

.CalendarDay__selected_span,
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #3E7AC0;
  border: 1px solid #3E7AC0;
  color: #fff;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #6e93bed0;
  border: 1px solid #6e93bed0;
  color: #fff
}

.date-picker .DateRangePicker {
  height: 100%;
  width: 100%;
  padding: 0 5px;
}

.DateRangePicker > div {
  height: 100%;
}

.DateRangePickerInput_calendarIcon {
  margin: 0;
  padding: 0 5px 0 5px;
  height: 100%;
  line-height: 10px;
}
.date-picker .DateInput_input{
  font-size: 16px;
}

.date-picker input#startDateId,
.date-picker input#endDateId {
  text-align: center;
  text-indent: 0px;
  border:none;
}

.date-picker .DateInput {
  height: 100%;
  background-color: transparent;
  flex: 1;
  padding: 8px 0 8px 8px;
  text-align: center;
  width: auto;
}

.date-picker .DateRangePickerInput {
  border: none;
  height: 100%;
  border-radius: 3px;
  text-align: left;
  width: 100%;
  justify-content: space-between;
  display: flex ;
  align-items: center;
  padding: 0;
}

.date-picker .DateInput__display-text {
  height: 100%;
  font-size: 15px;
  font-weight: 700;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #ff7059;
	color: #FFF;
	border-radius: 3px;
}


.date-picker .DateRangePickerInput__arrow svg {
  height: 18px;
  width: 18px;
  margin: 0;
}

.date-picker .DateInput__display-text.DateInput__display-text--focused {
  background-color: #ff7059;
  color: #FFF;
}

.date-picker .CalendarDay__button {
  width: 100%;
  height: 100%;
}

.date-picker mark.available {
	border: 1px solid #e4e7e7;
	background: white;
  color: #565a5c;
}
mark.unavailable {
	background-color: #ffe8bc;
	color: black;
}
.DateRangePicker_picker__fullScreenPortal {
  z-index: 999999;
  top: 0 !important;
}
.contact-host-modal-left-side .date-picker ul li {
  list-style-type: none;
  display: inline-block;
}
.calendar-info-wrapper{
  border-left: solid 2px #28b6ff;
  border-bottom: solid 2px #28b6ff;
  border-right: solid 2px #28b6ff;
  padding: 10px 20px;
}
.calendar-info{
  font-size: inherit;
    font-weight: 600;
  text-align: center;
    color: gray;
    border-top: 2px solid gray;
}
.DayPicker_transitionContainer{
      border-left: solid 2px #28b6ff;
      border-top: solid 2px #28b6ff;
      border-right: solid 2px #28b6ff;
}
@media only screen and (max-width:1585px){
  .date-picker .DateInput_input{
    padding: 0px;
    text-indent:0px;
  }
  .search-wrapper .date-picker .DateRangePickerInput{
    padding: 0px
  }
}
@media only screen and (max-width:1140px){
  .search-wrapper .date-picker .DateRangePickerInput{
    flex-direction: column;
  }
  .date-picker .DateRangePickerInput_calendarIcon {
    position: absolute;
    left:0;
    padding: 10%;
  }
  .date-picker .DateInput_input{
    font-size: 14px;
    text-indent:20%;
    max-width: 95px;
    margin-left: 15px;
  }
  .date-picker .DateInput{
    padding: 0;
  }
}
@media only screen and (max-width:767px){
  .date-picker .DateRangePickerInput_calendarIcon {
    position: unset;
    padding: 10px;
  }
  .date-picker .DateInput_input{
    font-size: 16px;
    text-indent: 20%;
    max-width: unset;
    margin-left: unset;
  }
}