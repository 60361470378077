.mrp-dashboard-hotel-card-main{
    width: 100%;
}
.mrp-dashboard-hotel-card-wrapper{
    display: flex;
    padding: 5px;
}
.mrp-dashboard-hotel-card-pictures-wrapper{
    width: 40%;
    padding: 5px;
}
.mrp-dashboard-hotel-card-description-wrapper{
    width: 30%;
    padding: 5px;
}
.mrp-dashboard-hotel-card-status-buttons-wrapper{
    width: 30%;
}
.mrp-dashboard-hotel-card-image{
    width: 100%;
    height: 100%;
    border-radius: 3px;
}
.mrp-dashboard-hotel-card-description{
    max-height: 300px;
    overflow: scroll;
}
.mrp-dashboard-hotel-card-status-wrapper{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
}
.mrp-dashboard-hotel-card-status-active{
    background-color: green;
    color: white;
    padding: 5px;
    border-radius: 3px;
    width: 145px;
}
.mrp-dashboard-hotel-card-status-inactive{
    background-color: #ff7059;
    color: white;
    padding: 5px;
    border-radius: 3px;
    width: 145px;
}
.mrp-dashboard-hotel-card-buttons-wrapper{
    display: flex;
    flex-direction: column;
    height: 280px;
    justify-content: space-around;
    padding: 10px;
}
.mrp-dashboard-hotel-card-image-wrapper{
    text-align: center;
    height: 262px;
}
.mrp-dashboard-hotel-card-name{
    font-weight: 700;
}
.mrp-dashboard-hotel-card-button-red{
    background-color: #ff7059;
}
.mrp-dashboard-hotel-card-wrapper .accessibility-box{
    margin: 0;
}
.mrp-dashboard-hotel-card-mobility{
    margin-top: 6px;
}
.mrp-dashboard-hotel-card-mobility .icon{
    width: 40px;
    height: 40px;
}
.mrp-dashboard-hotel-card-accessibilities-wrapper{
    display: flex;
    padding-top: 13px;
}


.mrp-dashboard-hotel-rooms-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.mrp-dashboard-room-card-image{
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../images/mrp/mrp-default.png');
    display: flex;
    flex-direction: column-reverse;
}
.mrp-dashboard-room-card-wrapper{
    width: 30%;
    margin-bottom: 30px;
    height: 250px;
    position: relative;
    transition: 0.5s;
}
.mrp-dashboard-room-card-wrapper:hover{
    box-shadow: 0px 0px 20px 5px #888888;
    cursor: pointer;
    width: 33%;
    height: 270px;
    transition: 0.5s;
}
.mrp-dashboard-hotel-rooms-header{
    width: 100%;
    text-align: center;
}
.mrp-dashboard-room-card{
    height: 100%;
    background-image: url('../images/mrp/unpublished.png');
}
.mrp-dashboard-room-card-unpublished{
    height: 100%;
    background-image: url('../images/mrp/unpublished.png');
}
.mrp-dashboard-room-card-image-wrapper{
    height: 80%;
}
.mrp-dashboard-room-card-name{
    font-weight: 700;
    padding: 10px;
    color: white;
    font-size: 16px;
    background-color: #0000006b;
}
.mrp-dashboard-room-card-accessibilities-wrapper{
    height: 20%;
    display: flex;
    padding: 5px;
}
.mrp-dashboard-room-card-mobility{
    height: 100%;
}
.mrp-dashboard-room-card-mobility .icon{
    width: 34px;
    height: 34px;
}
.mrp-dashboard-room-card-wrapper .accessibility-box{
    margin: 0;
}
.mrp-dashboard-hotel-new-room-button{
    width: 30%;
    margin-bottom: 30px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}
.mrp-dashboard-hotel-new-room-button:hover .mrp-dashboard-hotel-new-room-button-image{
    cursor: pointer;
    width: 70%;
    height: 70%;
    transition: 0.5s;
}
.mrp-dashboard-hotel-new-room-button:hover{
    box-shadow: 0px 0px 20px 3px #888888;
    width: 24%;
    height: 260px;
    border-radius: 50%;
    transition: 0.5s;
}
.mrp-dashboard-hotel-new-room-button-image{
    width: 80%;
    height: 80%;
    background-image: url('../images/mrp/new-room-button.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.5s;
}
.mrp-dashboard-hotel-modal-form{
    display: flex;
    flex-direction: column;
}
.mrp-dashboard-room-card-wrapper .spinner-overlay{
    position: absolute;
    z-index: auto;
    border-radius: 0;
}
.mrp-dashboard-hotel-modal-content-wrapper{
    width: 80%;
}
.mrp-dashboard-hotel-modal_content{
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    width: 500px;
    outline: none;
    padding: 20px;
    border: 1px solid gray;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 500px;
    overflow: scroll;
}
.new-mrp-room-form-buttons-wrapper{
    display: flex;
    justify-content: space-between;
}
.mrp-dashboard-room-card-unp-img{
    position: absolute;
    background-color: #8080808a;
    width: 100%;
    height: 100%;
}
.mrp-dashboard-hotel-tip-modal_content{
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    width: 500px;
    height: 420px;
    outline: none;
    padding: 20px;
    border: 1px solid gray;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
}