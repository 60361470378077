.mobile-drop-down-wrapper {
  background-color: #FFF;
  border-radius: 3px;
  cursor: pointer;
  height: 52px;
  position: relative;
  width: 300px;
  border: solid 1px #aaa;
  z-index: 201;
}

.mobile-drop-down-wrapper .icon {
  min-width: 40px;
  height: 40px;
  background-size: 100%;
  background-repeat: no-repeat;
  pointer-events: none;
  margin: 0 15px;
}

.mobile-drop-down-wrapper .icon.mobile-icon1 {
  background-image: url('../images/misc/mobility_c1.png');
}

.mobile-drop-down-wrapper .icon.mobile-icon2 {
  background-image: url('../images/misc/mobility_c2.png');
}

.mobile-drop-down-wrapper .icon.mobile-icon3 {
  background-image: url('../images/misc/mobility_c3.png');
}

.mobile-drop-down-wrapper .current-option {
  height: 100%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /*padding-top: 2%;*/
}
@media (max-width: 760px) {
  .mobile-drop-down-wrapper .current-option {
    height: auto;
    min-height: 70px;
    padding-top: 5%;
    padding-bottom: 4.3%;
    position: relative;
  }
  .mobile-drop-down{
    min-width: unset;
  }
}

.mobile-drop-down-wrapper .current-option > * {
  display: inline-block;
  pointer-events: none;
}

.mobile-drop-down-wrapper .current-option.non-default .icon {
  min-width: 35px;
  height: 35px;
  margin-left: 20px;
}

.mobile-drop-down-wrapper .current-option span {
  font-size: 15px;
  font-weight: 700;
  color: #666;
  margin: 0 0 0 20px;
}

.mobile-drop-down-wrapper .current-option.non-default span {
  margin: 0 10px 0 10px;
}

.mobile-drop-down-wrapper .current-option::after {
  /* content: url('../images/misc/arrow.svg'); */
  position: absolute;
  pointer-events: none;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  bottom: 0;
  right: 10px;
  width: 2em;
  text-align: center;
  z-index:200;
}

.mobile-drop-down {
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 12px 100px 0 rgba(31,31,60,.17),0 12px 40px 0 rgba(13,13,26,.08);
  display: none;
  pointer-events: none;
  text-align: left;
  transform: translateZ(0);
  transition: opacity 0.1s;
  width: 100%;
  min-width: 385px;
}

.mobile-drop-down.active {
  display: block;
  pointer-events: auto;
}

.mobile-drop-down li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 10px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.mobile-drop-down li:hover {
  background-color: rgba(250, 250, 252, 0.6);
}
.mobile-dropdown-text{
  padding-left: 10px;
}
.mobile-drop-down li p {
  pointer-events: none;
}
.mobile-dropdown-text h4,.mobile-dropdown-text p{
  margin: 0;
} 
.mobile-dropdown-text h4{
  font-weight: bold;
  font-size: 16px;
}
.mobile-dropdown-text p{
  font-weight: 400;
  font-size: 16px !important
}
@media only screen and (max-width:410px){
  .mobile-drop-down{
    min-width: unset;
  }
}