.property-log-modal__after-open{
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.property-log-modal__content__after-open{
    background: #fff;
    position: relative;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 50px;
}
.property-log-post_wrapper{
    display: flex;
    flex-direction: column;
}
.property-log-post_inputs-wrapper{
    display: flex;
}
.property-log-post_wrapper-right{
    margin-left: 50px;
}
.property-log-post_input-label{
    display: flex;
    flex-direction: column;
}
.property-log-post_input-label textarea{
    height: 116px;
}
.property-log-get_logs-wrapper,
.property-log-get_logs{
    display: flex;
    flex-direction: column;
}
.property-log-get_logs-wrapper{
    max-height: 500px;
    overflow: scroll;
}
.property-log-get_logs{
    margin-top: 50px;
    background: #f4f4f4;
    padding: 10px;
}