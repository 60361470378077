.put-seasons-page {
	padding-left: 10px;
}

.put-seasons-page .date-picker {
  height: 65px;
}
table.seasons {
	width: 100%;
	border-top: 1px solid #d6d6d6;
	border-right: 1px solid #d6d6d6;
	margin-top: 10px;
	border-spacing: 0;
}
table.seasons tr.hidden-tr {
  display: none;
}
table.seasons tr.old {
  opacity: 0.6;
  filter: grayscale(0.5);
}
table.seasons.advanced {
	width: 50%;
}
table.seasons td {
	border-bottom: 1px solid #d6d6d6;
	border-left: 1px solid #d6d6d6;
	padding: 2px 4px;
}
table.editable tbody td:last-child {
	padding: 0;
}
table.seasons td div {
	display: flex;
}
table.seasons td div a {
	display: inline-block;
	flex: 1;
	padding: 2px 4px;
	color: white;
}
table.seasons td div a.edit {
	background: #15d069;
}
table.seasons td div a.delete {
	background: #808080;

}

.buttons {
	padding: 20px 5px 5px 5px;
}

.buttons-save {
	padding-top: 30px;
}

.put-seasons-error {
	font-size: 16px;
	font-weight: bold;
	color: #FF3434;
}
.disable-form-elements {
  position: relative;
  filter: grayscale(100%) blur(2px);
  -webkit-filter: grayscale(100%) blur(1px);
}
.disable-form-elements::after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: ' ';
  color: white;
  opacity: 0.2;
}
.disable-form-elements .disable-form-elements::after {
  opacity: 0;
}