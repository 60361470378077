.user-disability-modal{
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-disability-modal__content{
    background: #fff;
    position: relative;
    width: 950px;
    min-height: 390px;
    border: 1px solid gray;
}
.user-disability-modal__content ul{
    margin: 0px;
}