.blog-loader {
    margin:0 auto;
    width:70px;
    text-align:center;
    padding: 50px 0;
  }
  
  .text-left, caption, td, th {
    text-align:left
  }
  
  .blog-loader > div {
    background-color: #0288d1;
    width:18px;
    height:18px;
    border-radius:100%;
    display:inline-block;
    animation:sk-bouncedelay 1.4s infinite ease-in-out both
  }
  
  .blog-loader .bounce1 {
    animation-delay:-.32s
  }
  .blog-loader .bounce2 {
    animation-delay:-.16s
  }
  
  @keyframes sk-bouncedelay {
    0%, 100%, 80% {
      -webkit-transform:scale(0);
      transform:scale(0)
    }
    40% {
      -webkit-transform:scale(1);
      transform:scale(1)
    }
  }
  