.host-profile-page {
  width: 100%;
}

.host-profile-page .dashboard-custom-box .inner-content {
  padding: 20px;
}

.host-profile-page .company-fields > * {
  display: block;
}

.host-profile-page textarea {
  display: block;
  width: 100%;
  padding: 5px 20px;
}

.host-profile-page .err {
  color: red;
}

.host-profile-page button {
  line-height: 1;
  display: block;
}

.host-right-column {
  text-align: center;
}

.host-right-column img {
  width: 250px;
  margin-top: 30px;
}
