.banner-wrapper{
    display: block;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #bfdcf3;
    font-weight: 600;
    color: blue !important;
}
.banner-wrapper:hover{
text-decoration: none !important;
}
.banner-content{
    flex-grow: 1;
    text-align: right;
    display: flex;
    flex-direction: column;
    color: blue !important;
}
.banner-wrapper .banner-button-wrapper button{
  padding: 5px 40px;
  outline: none;
}
.banner-content div{
  text-align: center;
}
.banner-button-wrapper{
    text-align: right;
}
.banner-button{
    background: transparent !important;
    color: blue !important;
}

@media only screen and (max-width: 770px) {
    .visible-banner + .main-layout-content > *{
      /* padding-top:180px; */
    }
    .banner-open + .main-layout-content > * {
      padding-top: 120px;
    }
    .policy-not-accepted.visible-banner + .main-layout-content > *p
    {
      /* padding-top: 180px !important */
    }
    .banner-content {
      text-align: center;
    }
    .banner-button-wrapper{
      width: auto;
    }
    .banner-wrapper{
      justify-content: flex-end;
    }
    .banner-button{
      padding: 15px 35px;
    }
  }
  @media only screen and (max-width: 320px){
    .banner-wrapper{
      height: auto;
    }
    .banner-wrapper .banner-button-wrapper button{
      padding: 5px 25px
    }
    .header .inner{
      height: unset;
    }
  }