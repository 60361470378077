.enquiry-modal-content .close-btn {
    position: absolute;
    right: 2px;
    top: 2px;
    width: 50px;
    height: 50px;
    padding: 0;
}

.enquiry-modal-content .dates {
    margin-bottom: 10px;
}

.enquiry-modal-content .date-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 -5px;
}

.enquiry-modal-content .date {
    flex: 1;
    text-align: center;
    margin: 0 5px;
    font-weight: bold;
}

.enquiry-modal-content .date-string {
    background: #666;
    border-radius: 3px;
    padding: 6px;
    color: white;
}

.enquiry-modal-content .enquiry-modal-guest {
    flex: 1;
    text-align: center;
    background: #666;
    padding: 6px;
    border-radius: 3px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 10px;
}

.enquiry-modal-content {
    display: flex;
    align-items: stretch;
    justify-content: center;
    color: #4d4d4d;
}

.enquiry-modal-content p {
    margin-bottom: 0.5rem;
}

.enquiry-modal-content .title {
    margin-top: 0px;
}

.enquiry-modal-content .title h1,
.enquiry-modal-content .modal-column-heading {
    margin-bottom: 5px;
    font-size: 21px;
    font-weight: normal;
    color: black;
}

.enquiry-modal-content .title p {
    font-size: 14px;
    color: inherit;
}

.enquiry-modal-content .title h2.property-title {
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: normal;
}

.room-info {
    background: #f2f2f2;
    padding: 6px;
    border-radius: 4px;
    margin-bottom: 14px;
}

.room-info legend,
fieldset.box legend {
    font-size: 1em;
    font-weight: bold;
}

.room-info legend {
    margin-bottom: 0.6rem;
}

span.price span.currency {
    margin-left: 3px;
}

.room-info .input-field-wrapper {
    height: 28px;
}

.room-info .coupon-input-field.added .input-field-wrapper {
    width: 100%;
}

.room-info input[type="text"] {
    text-indent: 10px;
    font-weight: 300;
    color: black;
}

fieldset.payment-card-information {
    padding-top: 14px;
}

.room-info #stripe-card-element {
    margin: 0 6px 40px 6px;
    background: white;
}

.enquiry-modal-content .room-info label {
    width: 50%;
    margin-bottom: 0.8rem;
}

.enquiry-modal-content .room-info label.phone-number {
    width: 100%;
}

.enquiry-modal-content>div {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.enquiry-modal-content>div.hide {
    display: none;
}

.enquiry-modal-content div.spacer {
    flex: 1;
}

.enquiry-modal-content.sm {
    flex-wrap: wrap;
}

.enquiry-modal-content.sm>div {
    width: 100%;
    border: none;
}

.enquiry-modal-left-side {
    height: 100%;
    padding: 20px;
}

.enquiry-modal-content ul {
    list-style-type: disc;
    list-style-position: inside;
    font-size: 16px;
    font-weight: normal;
}

.enquiry-modal-content ul li {
    display: list-item;
    margin-bottom: 20px;
    font-size: 16px;
}

.enquiry-modal .enquiry-confirmed {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    align-content: center;
    height: 100%;
    padding: 15px;
}

.enquiry-modal .enquiry-confirmed h1 {
    width: 100%;
}

.enquiry-modal .enquiry-confirmed p {
    margin-bottom: 30px;
    width: 100%;
}

.enquiry-modal-right-side {
    border-left: 1px solid #d4d4d4;
    padding: 10px;
    height: auto;
}

.enquiry-modal-right-side h2 {
    font-size: 16px;
    font-weight: bold;
    color: #484848;
    margin-bottom: 10px;
}

.enquiry-modal-right-side textarea {
    resize: none;
    width: 100%;
    height: 100px;
    margin-bottom: 5px;
    padding: 6px;
    font-size: 14px;
}

.enquiry-modal-right-side>p {
    font-size: 12px;
    color: #666;
    margin-bottom: 20px;
}

p.error {
    color: #e85746;
    text-align: center;
    font-weight: bold;
}

.specify-checkin-check-out {
    display: flex;
    align-items: center;
    height: 70px;
    margin-bottom: 30px;
}

.warning-icon {
    width: 70px;
    height: 100%;
    background: #CA0F0C;
    border-radius: 3px 0 0 3px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../images/object-page/warning.png');
}

.warning-text {
    padding: 20px;
    height: 100%;
    width: 3px;
    border-radius: 0 3px 3px 0;
    flex-grow: 1;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 12px;
    border: 1px solid #d4d4d4;
    border-left: none;
}

.enquiry-modal-content .booking-button {
    height: 52px;
    width: 100%;
    margin-bottom: 5px;
    font-weight: normal;
    padding: 15px 40px;
    border-radius: 3px;
    font-size: 20px;
}

button.loading {
    background: #9d9d9d;
    position: relative;
}

button.loading::after {
    background: url('../../images/misc/ajax-loader.gif') no-repeat center center;
    width: 16px;
    height: 16px;
    content: ' ';
    display: inline-block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.enquiry-modal-content button {
    width: 100%;
    /* margin-bottom: 10px; */
}

.enquiry-modal-content h2 {
    color: #575757;
    font-size: 16px;
    margin: 10px 0 20px;
    font-weight: normal;
}

.enquiry-modal-content h2.booking-enquiry-message-header {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 15px;
    color: black;
}

.enquiry-modal-content h2.unregistered-header {
    margin-bottom: 5px;
    font-size: 20px;
}

.enquiry-modal-content fieldset.enquiry-form {
    flex-direction: column;
    margin-left: -5px;
    margin-right: -5px;
}

.enquiry-form .input-field-wrapper {
    height: 33px;
    margin-bottom: 16px;
}

.enquiry-form input[type="text"],
.enquiry-modal-right-side textarea {
    font-weight: normal;
    border: 2px solid #565656;
    border-radius: 4px;
    color: inherit;
}

.enquiry-form input[type="text"] {
    text-indent: 6px;
}

.enquiry-modal-content label.required {
    position: relative;
}

.enquiry-modal-content label.required::after {
    position: absolute;
    right: 2px;
    top: -16px;
    display: block;
    content: '\2731';
    color: red;
    font-size: 13px;
}

.enquiry-modal-content label.enquiry-name {
    flex: 1;
    max-width: 50%;
    padding: 0 5px;
}

.enquiry-modal-content .guest-form {
    padding: 0 0 5px 0;
}

.enquiry-modal-content label.enquiry-email {
    flex: 2;
    min-width: 100%;
    padding: 0 5px;
}

.enquiry-modal-content label {
    flex: 1;
    width: 100%;
    padding: 0 5px;
}

.modal-container .main-image {
    width: 100%;
    height: auto;
    margin-bottom: 5px;
}

.enquiry-info-section p {
    margin: 0;
}

fieldset.booking-enquiry-message,
fieldset.box {
    background: #f2f2f2;
}

.logged-in fieldset.booking-enquiry-message {}

.logged-in fieldset.booking-enquiry-message textarea {
    height: 260px;
}

.logged-in fieldset.booking-enquiry-message textarea.mrp-booking {
    height: 85px;
    border: none;
}

.logged-in fieldset.booking-enquiry-message textarea.mrp-booking:disabled {
    background: rgb(218, 218, 218);
    color: black;
}

a.login {
    color: #41b5fb !important;
}

span.asterisk {
    height: 15px;
    color: red;
    display: inline-block;
    font-size: 30px;
    position: relative;
    top: 13px;
    line-height: 15px;
}

p.note {
    text-align: center;
    color: red;
}

p.note.positive {
    color: inherit;
}

p.note.positive.hotel-checkin {
    color: #6fa825;
    font-weight: 600;
}

div.payment-cards {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
}

svg.payment-card {
    margin: 2.5px;
}

img.payment-card {
    height: 35px;
    width: auto;
    margin-left: 3px;
}

img.payment-ok {
    margin: auto;
    display: block;
}

h2.payment-ok {
    text-align: center;
    color: #6fa825;
    font-weight: bold;
    font-size: 1.5em;
}

div.modal-loading-caption {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1050;
}

div.modal-loading-caption div.inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 2.5em;
    text-align: center;
    color: black;
    text-shadow: white 1px 0 0;
}

@media (max-width: 767px) {
    .enquiry-modal-left-side {
        padding: 60px 40px 5px;
    }
    .enquiry-modal-right-side {
        padding: 5px 40px 40px 40px;
    }
    .info-section {
        padding: 0px;
    }
}

.saved-card-wrapper_mrp-wallet {
    display: flex;
    align-items: baseline;
}

.saved-card-label_mrp-wallet {
    width: 80%!important;
}

.disabled-card-edit-field {
    pointer-events: none;
}