/*css style for the apartments landings*/

/*top section*/

.landings-page-top-section-apartments-amsterdam{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Amsterdam-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-barcelona{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Barcelona-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-berlin {
    background: #b3dff9 url('../images/landings-index/Accessible-Berlin-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-blackpool {
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Blackpool-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-cape-town{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Cape-Town-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-ceredigion{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Ceredigion-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-cornwall{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Cornwall-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-devon{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Devon-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-dorset{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Dorset-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-dublin{
    background: #b3dff9 url('../images/landings-index/Accessible-Dublin-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-lisbon{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Lisbon-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-london{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-London-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-madrid{
    background: #b3dff9 url('../images/landings-index/Accessible-Madrid-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-majorca{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Majorca-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-nice{
    background: #b3dff9 url('../images/landings-index/Accessible-Nice-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-norfolk{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Norfolk-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-paris{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Paris-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-rome{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Rome-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-tenerife{
    background: #b3dff9 url('../images/landings-index/Accessible-Tenerife-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-venice{
    background: #b3dff9 url('../images/landings-index/Accessible-Venice-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-apartments-yorkshire{
    background: #b3dff9 url('../images/landings-index/apt/Accessible-Yorkshire-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}

/*guide section*/

.guide-section-picture-apartments-amsterdam{
    background: #b3dff9 url("../images/landings-index/apt/Amsterdam-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-barcelona{
    background: #b3dff9 url("../images/landings-index/apt/Barcelona-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-berlin{
    background: #b3dff9 url("../images/landings-index/Berlin-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-blackpool {
    background: #b3dff9 url("../images/landings-index/apt/Accessible-Blackpool-For-Disabled.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-cape-town{
    background: #b3dff9 url("../images/landings-index/apt/Cape-Town-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-ceredigion{
    background: #b3dff9 url("../images/landings-index/apt/Ceredigion-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-cornwall{
    background: #b3dff9 url("../images/landings-index/apt/Cornwall-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-devon{
    background: #b3dff9 url("../images/landings-index/apt/Devon-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-dorset{
    background: #b3dff9 url("../images/landings-index/apt/Dorset-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-dublin{
    background: #b3dff9 url("../images/landings-index/Dublin-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-lisbon{
    background: #b3dff9 url("../images/landings-index/apt/Lisbon-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-london{
    background: #b3dff9 url("../images/landings-index/apt/London-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-madrid {
    background: #b3dff9 url("../images/landings-index/Madrid-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-majorca{
    background: #b3dff9 url("../images/landings-index/apt/Majorca-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-norfolk{
    background: #b3dff9 url("../images/landings-index/apt/Norfolk-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-paris{
    background: #b3dff9 url("../images/landings-index/apt/Paris-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-rome{
    background: #b3dff9 url("../images/landings-index/apt/Rome-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-tenerife{
    background: #b3dff9 url("../images/landings-index/Tenerife-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-venice{
    background: #b3dff9 url("../images/landings-index/Venice-picture-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-apartments-yorkshire{
    background: #b3dff9 url("../images/landings-index/apt/Yorkshire-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
