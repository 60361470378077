a.button:not([href]):not([tabindex]){
    color:white;
  }
.web .menu-bar{
    width: 100%;
    background-color: #2a88ce;
    position: fixed;
    z-index: 9;
    /*margin-top: 71px;*/
    height: 50px ;
}
.menu-wrapper {
    width:100%;
}

.web.menu-wrapper {
    height: 60px;
    margin-bottom: 50px;
}

.mobile.menu-wrapper {
    height: 20px;
    margin-bottom: 50px;
}

.web .button-bar{
    float:right;
    width:80vw;
    padding:0;
    margin:0;
    height: 100%;
}
.web .search-box{
    width:20%;
    height: 60px;
    float:right;
    margin-right: 10px;
    padding-top:10px;
    padding-bottom: 10px;
    position: absolute;
    top: 50px;
    right: 0px;
}
.web .button{
    outline:none;
    padding:0;
    margin:0;
    float:right;
    width: 121px;
    height: 100%;
    border-radius: 0;
    font-size: 13.5px;
    background-color: #2a88ce;
    position:relative;
    text-align:center;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-wrap: break-word;
}
.button{
    text-transform:capitalize;
}
.web #menuTravelGuides{
    padding-left: 17px !important; 
    padding-right: 12.5px !important;
}
.web #menuMoreInfo{
    padding-left: 10.5px !important; 
    padding-right: 10.5px !important;
}
.web .button:hover{
    color:black;
    background-color: white;
}
.web .menu-image{
    margin:0;
    padding:0;
    width:200px;
    height: auto;
    padding-top:2px;
    margin-left: 20px;
}
.web .menu-image-search{
    float:right;
    margin:0;
    padding:0;
    margin-left: 10px;
    margin-right: 30px;
    margin-top: 13px;
    width: 25px;
    height: auto;
}
.web .drop-down-arrow{
    width: 10%;
    position: absolute;
    top:26%;
    right:2px
}
.mobile .drop-down-arrow.menu-show {

    transform: rotate(-90deg);
    transition: transform 0.2s;
}

.mobile .drop-down-arrow{
    display:inline-block;
    margin-right: 30px;
}
.web .menu-image-search-active{
    float:right;
    margin:0;
    padding:0;
    margin-left: 10px;
    margin-right: 30px;
    margin-top: 13px;
    width: 25px;
    height: auto;
}
.web .menu-bar-dropdown {
    margin:0;
    padding:0;
    display:flex;
    flex-direction:column;
    height: auto;
    margin-top:50px;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    border-color: #dbdbdb;
    position: absolute;
    display: flex;
    font-weight: bold;
    
}

.web .menu-bar-dropdown.full-width{
    display:block;
    width: 100%;
    background-color: white;
    position: fixed;
    left:0;
}
.menu-bar-dropdown-button {
    color: black;
    display: block;
    text-align:left;
    text-transform:capitalize;
}
web .menu-bar-dropdown-button.short{
    margin: 0;
    padding: 0;
    height: auto;
    z-index: 9;
    padding-left: 20px;
    font-size: 20px;
    padding-top: 15px;    
    padding-right: 15px;    
    font-weight: 500;

}
.web .menu-bar-dropdown-button.long{
    margin: 0;
    padding: 0;
    height: auto;
    z-index: 9;
    width:120px;
    display:inline-block;
    font-size: 14px;
    margin:15px;
    padding-left:10px;
    padding-right: 10px;
    text-align: center;
    font-family: quicksand;

}

.menu-bar-dropdown-button:hover {
    background-color: #dbdbdb;
}

.menu-bar-dropdown-button {
    background: white;
    min-width: 117px;
    padding:12px 15px 12px 25px;
    text-align:start;
    font-size:14px;
    font-weight: bold;
    font-family: quicksand;
}

.web .menu-checkbox-wrapper{
    float:right;
    padding:0;
    margin:0;
    width: 118px;
    height: 100%;
    background-color: #2a88ce;
}

.web .id-menuTravelInfo {
    width: auto;
}
.web #menuTravelInfo{
    width: auto;
    min-width: 121px;
    white-space: nowrap;
    padding-right: 8px;

}

.web .menu-checkbox-wrapper .menu-bar-dropdown-container {
    display:none;
}
.web .menu-checkbox-wrapper:hover .menu-bar-dropdown-container {
    display:block;
}

.web .menu-checkbox-wrapper:hover .button{
    color:black;
    background-color: white;
}

.mobile .menu-image-drawer{
    padding:0;
    margin:0;
    width:30px;
    height: auto;
    margin-top: 11px;
    margin-left: 10px;
    float:left;
}
.mobile .menu-image{
    padding:0;
    margin:0;
    width: 200px;
    height: auto;
    margin-top: 4px;
    

}
.mobile .menu-image-search{
    padding:0;
    margin:0;
    float:right;
    width: 30px;
    height: auto;
    margin-top: 10px;
    margin-right: 15px;
}
.mobile .button{
    background-color:  #2a88ce;
    padding:0;
    padding-left: 20px;
    margin:0;
    width:250px;
    height:50px;
    border-radius: 0;
    text-align: start;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-wrap: break-word;
}
.mobile .button-inline{
    background-color: white;
    border-radius: 0;
    padding:0;
    margin:0;
    width:100%;
    text-align: start;
    cursor: pointer;
    color: #484848 !important;
    border-bottom: 1px solid #afafaf;
    padding-top: 10px;
    padding-bottom: 5px;
    height: 44px;
    font-size: 13px;   
    transition: 0.7s;
    padding-left: 1.4%;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.mobile .button-inline:hover{
    background-color: #e9e8e8;
    padding-left: 2.4%;
    box-shadow: -2px 3px 10px 0px rgba(0, 0, 0, 0.22);
    position:relative;
}
.mobile .menu-bar-dropdown-inline{
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
}
.mobile .menu-bar-dropdown-button-inline{
    background-color: white;
    border-radius: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: start;
    cursor: pointer;
    color: #484848 !important;
    border-bottom: 1px solid #afafaf;
    padding-top: 10px;
    padding-bottom: 5px;
    height: auto;
    min-height: 44px;
    font-size: 14px;
    transition: 0.7s;
    padding-left: 6.4%;
    display: block;
    font-weight: bold;
    line-height: 2;
}
.mobile .menu-bar-dropdown-button-inline:hover{
    background-color: #e9e8e8;
    padding-left: 7.4%;
    box-shadow: -2px 3px 10px 0px rgba(0, 0, 0, 0.22);
    position:relative;
}
 .button-container{
    width:93%;
    display: inline-block;
    font-family: quicksand;
    font-size: 14px;
}
.mobile .menu-bar-dropdown-button{
    outline: none;
    background-color: white;
    color: black;
    padding: 0;
    padding-left: 30px;
    margin: 0;
    width: 250px;
    height: 65px;
    border-radius: 0;
    display: flex;
    align-items: center;
    text-align: start;
    font-weight: bold;
    transition: 0.7s;
    line-height: 1;
}
.mobile .menu-bar-dropdown-button:hover{
    padding-left: 8%;
    background-color: #afafaf;
}
.mobile .button-name{
    width:250px;
    height:50px;
    margin:0;
    padding:0;
}
.mobile .menu-bar-dropdown {
    margin:0;
    padding:0;
    width: 250px;
    height: auto;
}

.mobile .menu-bar-dropdown-container.menu-show {
    display: block;
}
.mobile .menu-bar-dropdown-container.menu-hide {
    display: none;
}

.mobile .menu-bar{
    width: 100%;
    background-color: #2a88ce;
    position:fixed;
    z-index: 9;
    height: 50px;
    text-align: center;
}
.mobile .menu-bar .search-mobile{
    text-align: left;
}
.mobile .search-box{
    padding:0;
    margin:0;
    position: fixed;
    width: 40%;
    height: 50px;
    margin-top: 15px;
    margin-left: 30%;
}
.menu-drawer{
    margin:0;
    padding:0;
    background-color: #2a88ce;
    position:fixed;
    width:251px;
    z-index: 9;
    overflow-y: auto;
    overflow-x: hidden;
}