.required-text {
  font-weight: bold;
}
.accessibility-section .required-text {
  font-size: 1.2em;
}

.tripadvisor-review p {
  margin-bottom: 0;
}
.rating-container {
  clear: both;
}
.property-titles-wrapper .tripadvisor-review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 20px;
}
ul.subratings {
  margin-bottom: 0;
}
ul.subratings li {
  display: block;
}
ul.subratings li img {
  margin-right: 15px;
  width: 51px;
  height: 11px;
}
img.review-image {
  margin-right: 4px;
  width: 119px;
  height: 20px;
}
.tripadvisor-logo {
  max-width: 64%;
  height: auto;
}
.rating-numeric {
  font-size: 45px;
  font-weight: bold;
  line-height: 1em;
  color: #118874;
  margin-right: 5px;
}
p.rating-text {
  color: #118874;
  font-weight: bold;
}
p.ranking-string {
  margin: 0.5em 0 0.5em 0;
  color: #6e6e6e;
}
a.web-url {
  color: inherit;
  vertical-align: middle;
}
a.web-url.read-more {
  font-size: 1em;
  color: #6e6e6e;
}
div.accessibility-and-reviews {
  flex-wrap: wrap;
}
div.accessibility-and-reviews ul.full {
  width: 100%;
  flex: auto;
}
@media only screen and (min-width: 768px) {
  .accessibility-section .tripadvisor-review {
    width: 30%;
  }
}
@media only screen and (max-width: 767px) {
  div.accessibility-and-reviews {
    flex-wrap: wrap;
    padding: 20px;
  } 
  div.accessibility-and-reviews > ul {
    width: 100%;
    flex: auto;
  }
  .mrp-page div.accessibility-and-reviews .accessibility-box li.mrp {
    width: 50%;
  }
  .property-titles-wrapper .tripadvisor-review {
    align-items: center;
  }
  .mrp-page .property-main .center-content{
    margin: 0;
    padding: 0;
    border:none
  }
  .accessibility-section-box{
    border-top: 1px solid #E6E6E6;
    padding: 20px;
    background: #F5F5F5;
  }
  .mrp-page .accessibility-section .accessibility-section-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #F5F5F5;
    padding: 20px;
  }
  .property-page .property-main h3{
    margin-bottom: 0;
    font-size: 18px;
  }
  .single-property-perks{
    padding: 20px;
    margin: 0;
  }
  .mrp-page .description-section .description-about-property{
    padding: 20px;
  }
  .mrp-page .property-main.host{
    padding: 20px;
  }
  .mrp-page .best-price-section{
    padding: 20px;
  }
}
