.payment-methods-page {
    width: 100%;
    border-bottom: 1px solid #d4d4d4;
}
.dashboard-settings-layout .dashboard-custom-box .inner-content .big-add-btn {
    background-color: #fff;
    display: block;
    font-size: 16px;
    height: 127px;
    padding: 10px;
    text-align: center;
    width: 148px;
}
