.main-layout-content > .search-properties-page {
  padding: 0;
}
.search-properties-page .small-page,
.search-properties-page .large-page {
  max-height: 100vh;
  min-height: 100vh;
}

.search-properties-page .header {
  position: relative;
}
.search-properties-page .date-picker .DateInput{
  padding: 4px 0;
}

.search-properties-page .search-bar-wrapper {
  width: 100%;
  background-color: #1e1e1e;
  padding-left: 10px;
  padding-right: 10px;
}
.mobile-drop-down-wrapper .current-option.non-default span {
  margin: 0 10px 0 10px;
  line-height: 18px;
  max-height: 95%;
  overflow: hidden;
}

.search-properties-page .search-bar {
  padding: 10px 0px;
  height: auto;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
}

.search-properties-page .search-bar > * {
  height: 52px;
  margin: 0 2.5px;
  border: none !important;
  flex: 2;
}
.search-properties-page .small-page a{
  flex: none;
}
.search-properties-page .small-page .handiscover-logo-icon{
  margin: 0;
  width: 52px;
  height: 100%;
  margin-right: 10px;
  background-image: url('../images/misc/handiscover_icon_logo.png');
  background-size: auto 100%;
  background-repeat: no-repeat ;
  background-position: left;
}

.search-properties-page .search-bar .blue-large {
  text-transform: uppercase;
  flex: 1;
}

.search-properties-page .search-bar input {
  border: none;
}

.search-properties-page .search-bar .mobile-drop-down-wrapper {
  border: solid 1px #aaa;
}

.search-properties-page .search-bar .date-picker .DateInput__display-text {
  line-height: 28px;
}

.search-properties-page .search-bar .search-properties-price-range {
  width: 100%;
  margin-left: 7px;
}

.rc-slider.search-properties-price-range {
  flex: 1;
  width: 100%;
  align-items: center;
  margin-top:12px;
}

.search-properties-page .map-properties {
  margin: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  height: calc(100vh - 142px);
}
.search-properties-page .small-page .map-properties {
  height: calc(100vh - 72px);
}

.search-properties-page .map-properties .spinner-overlay {
  position: absolute;
}

.search-properties-page .map-properties .display-count {
  text-align: right;
  margin: 10px;
  flex: 0.6;
  font-weight: bold;
  color: #666;
}

.search-properties-page .ui-map {
  background-color: #FFF;
  z-index: 0;
  flex:1;
}

.search-properties-page .found-properties {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  background-color:#FFF;
  flex: 1;
  max-width: 600px;
  width: auto;
  min-width: 320px;
}

.search-properties-page .small-page .map-properties > * {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
.small-page .found-properties.in-front {
  min-width: 100%;
}

.search-properties-page .small-page .map-properties > .in-front {
  z-index:10;
}

.search-properties-page .small-page .map-properties > .in-back {
  z-index:0;
}

.search-properties-page .small-page .switch {
  align-items: center;
  background-color: #FFF;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 30px;
  display: flex;
  height: 50px;
  justify-content: center;
  left: 50%;
  position: fixed;
  top: calc(100% - 70px);
  transform: translateX(-50%);
  width: 300px;
  z-index: 20;
}

.search-properties-page .small-page .switch button {
  background-color: rgba(255, 255, 255, 0);
  color: #000;
  line-height: initial;
  outline: none;
  padding: 0 30px;
  text-align: center;
  height:100%;
  flex:1;
}

.search-properties-page .small-page .switch .vertical-separator {
  height: 40%;
}

.search-properties-page .small-page .switch .view-button {
  text-align: center;
}

.search-properties-page .found-properties .sort-by {
  padding: 15px 0px;
  display: flex;
  align-items: center;
}

.search-properties-page .found-properties .sort-by .select-dropdown > select {
  height: 100%;
  border: none;
  font-size: 14px;
  cursor: pointer;
}
.search-properties-page .select-dropdown::after {
  /* content: url('../images/misc/arrow.svg'); */
  position: absolute;
  pointer-events: none;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  bottom: 0;
  right: 10px;
  width: 2em;
  text-align: center;
  z-index: 200;
}

.sort-by-dropdown {
  flex: 1;
  margin: 0px 15px;
}
.search-contact-us {
  background: #46b414;
  color: white;
  margin: 0 15px;
  padding: 5px 5px;
}
.search-contact-us a {
  color: white;
  text-decoration: underline;
}
.filter-bar .property-type {
  flex: 0.6;
  padding: 10px 15px;
  max-height: 100%;
}
.filter-bar .select-dropdown::after{
  right: -6px;
}
.sort-by .property-type {
  flex: 1;
}
.search-properties-page .found-properties .no-results {
  font-size: 20px;
  margin: 20px;
}

.search-properties-page .filter-bar {
  width: 98%;
  margin: 0 auto;
  height: 70px;
  border-bottom: solid 1px #d4d4d4;
  display: flex;
  align-items: center;
}

.search-properties-page .filter-bar .vertical-separator {
  max-width: 1px;
  background-color: #000;
  height: 30px;
  border-left: solid 1px #d4d4d4;
}

.search-properties-page .filter-bar .label {
  color: #666;
  font-size: 10px;
  font-weight: bold;
  overflow: hidden;
}

.search-properties-page .filter-bar select {
  font-size: 14px;
  cursor: pointer;
}

.search-properties-page .filter-bar .price-range-wrapper {
  padding: 10px 15px;
  height: 100%;
  flex:1;
}

.search-properties-page .filter-bar .price-range-wrapper .rc-slider {
  height: 22px;
  flex: 1;
  width: 100%;
  align-items: center;
}

.search-properties-page .filter-bar .price-range-wrapper .rc-slider-rail {
  top: 50%;
  transform: translateY(-50%);
}

.search-properties-page .filter-bar .price-range-wrapper .rc-slider-track {
  background-color: #000;
  top: 50%;
  transform: translateY(-50%);
}

.search-properties-page .filter-bar .price-range-wrapper .rc-slider-handle {
  height: 22px;
  width: 22px;
  background-color: #28b6ff;
  border: none;
  top: 0px;
}

.search-properties-page .filter-bar .price-range-wrapper .range-header {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.search-properties-page .filter-bar .price-range-wrapper .range-header .range {
  color: #999;
}

.search-properties-page .filter-bar .select-dropdown {
  border: none;
  height: auto;
}

.search-list-properties {
  margin-top: 20px;
  padding: 0 5px;
}

.search-properties-page .small-page .search-list-properties {
  display: flex;
  flex-direction: column;
}

.search-single-property {
  margin-bottom: 20px;
  max-width: 320px;
  cursor: pointer;
  position: relative;
  width: 50%;
  padding: 0 10px;
}

.search-properties-page .small-page .search-single-property {
  max-width: none;
  width: auto;
}

.search-single-property img, div.missing {
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  border-radius: 3px 3px 0 0;
  background-color: gray;
  object-fit: cover;
}
.search-single-property.override a {
  display: block;
  padding: 5%;
}
.search-single-property.override img {
  max-height: 100%;
  width: 90%;
  max-width: 100%;
}
@media only screen and (max-width: 900px) {
  .search-single-property.override img{
    width: 100%;
    object-fit: contain;
    background: white;
  }
}
@media only screen and (max-width: 900px) {
  .search-single-property.override img{
    width: 90%;
  }
}
.search-properties-page .small-page .search-single-property img {
  max-height: 320px;
  object-fit: cover;
}

.search-single-property h2 {
  font-size: 18px;
  font-weight: 400;
}

.search-single-property h3 {
  font-size: 14px;
  font-weight: 700;
}

.search-single-property h2,
.search-single-property h3 {
  margin: 0;
}

.search-single-property .info {
  display: flex;
  background-color: #FFF;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-end;
  flex-direction: column;
  padding: 10px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 3px 3px;
  border-color: #d4d4d4;
}

.search-single-property .info .info-icon {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.search-single-property .info .info-icon::after {
  display: none
}
.search-single-property .info > h2 {
  color: #666;
  font-weight: bold;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 4px;
}

.search-single-property .info h3 {
  color: #a0a0a0;
  overflow: hidden;
}

.search-single-property .info .info-titles {
  flex:3;
}

.search-single-property .icon {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  background-color: #FFF;
  background-size: 100%;
  background-repeat: no-repeat;
}

.search-single-property .price,
.search-properties-page .leaflet-popup-content .price {
  padding: 2px 7px;
  color: #686868;
  position: absolute;
  top: 140px;
  right: 10px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 3px 0 0 3px;
  max-width: 90%;
  white-space: nowrap;
}

.search-single-property .price {
  background-color: rgba(0, 0, 0, 0.81);
  border: 1px solid #000000;
  color: #FFF;
}

.search-properties-page .priceflex {
  line-height: 14px;
  text-align: right;
}
.search-properties-page .pre {
  text-align: right;
  font-size: 12px;
  padding-right: 10px;
}
.search-properties-page .post {
  text-align: right;
  width: 100%;
  font-size: 12px;
  line-height: 12px;
}
.search-properties-page .last-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  margin-top:10px;
}
.search-properties-page .view-btn {
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  font-size: 14px;
  background-color: #ff7059;
}
.search-properties-page .view-btn:hover {
  background-color: #ff8470;
}

.search-properties-page .leaflet-popup-content .price {
  bottom: 2.9px;
  right: 7.2px;
  top: auto;
}

.search-single-property .price .instant-book {
  background-image: url(../images/form-elements/book-now.svg);
  background-repeat: no-repeat;
  width: 20px;
}

.search-properties-page .leaflet-popup-content-wrapper {
  width: 500px;
  /*padding: 15px;*/
  padding: 0px;
}

.search-properties-page .leaflet-popup-content {
  width: 490px !important;
  margin:0px;
  display: flex;
}

.search-properties-page .leaflet-popup-content .marker-popup {
  width:100%;
  display: flex;
}

.search-properties-page .leaflet-popup-content img {
  /*     width: 250px; */
  height: 125px;
  background-size: cover;
  /*     margin-bottom: 5px; */
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.marker-popup .guests-info {
  width: 100%;
  font-size: 14px;
  color: #a0a0a0;
  overflow: hidden;
}

.search-properties-page .map-cluster-count,
.landings-page .map-cluster-count{
  padding-top: 3px;
  text-align: center;
  width: 100%;
}

.search-page-guest-picker-wrapper {
  height: 50px;
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575px) {
  .search-properties-page .search-bar {
    padding: 10px 0px;
  }
  .search-contact-us {
    font-size: 11px;
  }
}
/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767px) {
}
/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991px) {
  .found-properties .search-single-property {
    max-width: 480px;
    width: 100%;
  }
}
/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199px) {
}
/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}
.descrip4 {
  color: black;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.leftblockwrap2{
  margin-left: 2.4%;
  padding-top: 2.7%;
  padding-bottom: 2.7%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  justify-content: space-between;
}
.imagehome{
  height: 40px;
  width: 40px;
  background-color: black;
}

.marker-popup .icon {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  background-color: #FFF;
  background-size: 100%;
  background-repeat: no-repeat;
}
