.search-filter-modal {
  width: 100vw;
  overflow: scroll;
}

.search-filter-modal > * {
  width: auto;
}

.search-filter-modal .title-bar {
  background-color: #FFF;
  color: #000;
  font-size: 20px;
  height: 70px;
  display:flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100vw;
  border-bottom: 1px solid #c5c5c5;
  font-weight: 700;
}

.search-filter-modal .title-bar > * {
  width: auto;
}

.search-filter-modal .title-bar .title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex:3;
}

.search-filter-modal .title-bar button {
  background-color: #FFF;
  color: #000;
  min-width: 100px;
  max-width: 100px;
}

.search-filter-modal .content.d-flex.flex-column.justify-content-start.align-items-stretch{
  height: 100%;
  padding: 40px 20px;
  background-color: rgba(246, 246, 246, 1);
}

.search-filter-modal .select-dropdown{
  height: 70px;
  border: none;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #d4d4d4;
}
.search-filter-modal .select-dropdown::after {
    content: url('../images/misc/arrow.svg');
    position: absolute;
    pointer-events: none;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    height: 100%;
    bottom: 0;
    right: 10px;
    width: 2em;
    text-align: center;
    z-index: 200;
}
.search-filter-modal .select-dropdown > select {
    font-size: 18px;
    color:#484848;
}

.search-filter-modal .range-header{
  display: flex;
  justify-content: space-between;
  color: #464646;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
}

.search-filter-modal .price-range-wrapper{
  height: 70px;
  margin-bottom: 40px;
}

.search-filter-modal .sort-by-dropdown{
  flex:1;
  margin:0;
}

.search-filter-modal button.blue-large {
  width: 100%;
  height: 100px;
  padding:0;
  margin-bottom: 20px;
  font-size: 20px;
}

.search-filter-modal .rc-slider-handle.rc-slider-handle-1{
  margin-left: 0px;
  background-color: #28b6ff;
  border:none;
  box-shadow: none;
}

.search-filter-modal .rc-slider-handle.rc-slider-handle-2{
  /* margin-left: -32px; */
  background-color: #28b6ff;
  border:none;
  box-shadow: none;
}

.search-filter-modal .rc-slider-rail{
  background-color: #c8c8c8;
}

.search-filter-modal .rc-slider-handle{
  width: 32px;
  height: 32px;
  margin-top: -14px;
}

.search-filter-modal .rc-slider-track{
  background-color: #464646;
}

.search-filter-modal button.close{
  padding: 15px 20px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  opacity: 1;
  cursor: pointer;
  text-align: right;
}
.search-filter-modal button.close:hover{
  opacity: 0.5;
}
.search-filter-modal button.clear{
  padding: 15px 20px;
  text-align: left;
  color:rgb(200, 0, 0);
}
.search-filter-modal button.clear:hover{
  opacity: 0.5;
}
.search-filter-wrapper{
  display: flex;
    flex-direction: column;
    padding: 7%;
    height: 100%;
    justify-content: space-between;
}
