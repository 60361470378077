.pagination {
  margin-top: 20px;
  padding: 20px 20px 20px 0;
}

.pagination ul {
  display: flex;
  justify-content: flex-start;
  padding-left: 25px;
}
.pagination ul li {
  border: 1px solid #3097e382;
    border-radius: 3px;
    /*width: 30px;
    height: 32px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    color: #fc5d62;
	cursor: pointer;
  background-color: #ffffff;
  width: 30px;
  height: 30px;
}

.pagination ul li a {
    text-align: center;
    color: #3097e382;
}
.pagination ul li:hover a{
  color: white;
}

.pagination ul li.active {
	background: #edefec;
	color: #484848;
}
.pagination ul li.dots {
	border: none;
	background: transparent;
}
.pagination ul li:hover {
  background-color: #28b6ff;
  border: 1px solid #28b6ff;
  color: white;
}

.pagination-arrow {
  background-repeat: no-repeat;
  background-image: url('../images/icons/pagination-arrow-icon.svg');
  background-position: center;
}

.pagination-left-arrow {
  transform: rotate(180deg);
}


.pagination .dotdotdot  {
  border: none;
  background-color: rgba(0,0,0,0) !important;
  color: #3097e382 !important;
  font-weight: 500;
  font-size: 14px;
  width: 10px
}


.pagination .page-selected {
  background-color: #28b6ff;
  border: none;
  width: 30px;
  height: 30px;
}
.pagination .page-selected a{
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
}
