.mrp-form .room-titles > * {
  display: inline-block;
}

.mrp-form .room-titles .h31 {
  width: 60%;
}

.mrp-form .room-titles .h32 {
  width: 40%;
}

.mrp-form .room-type-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mrp-form .room-type-row > * {
  display: inline-block;
  vertical-align: top;
}

.mrp-form .room-type-row.mrp .number-units-wrapper {
  width: 40%;
}

.mrp-form .room-type-row.mrp .dummy-whitespace {
  width: 5%;
}

.mrp-form .room-type-row > .input-field-wrapper {
  /*width: 55%;*/
  flex: 2;
  /*margin-right: 10px;*/
}
