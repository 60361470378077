.search-properties-item-horizontal-wrapper{
    padding: 10px 0px;
    border-top: 1px solid #bfbfbf;
}
.search-properties-item-horizontal{
    display: flex;
    min-height: 150px;
}
.search-properties-item-horizontal-right{
    width: 40%;
}
.search-properties-item-horizontal-left{
    background-color: white;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: inherit;
}
.horizontal-card-property-link{
    text-decoration: none;
    color: inherit;
}
.horizontal-card-property-link:hover{
    text-decoration: none;
    color: inherit;
}
 .accessibility-carousel .accessibility-arrow-left {
    content: url(http://localhost:5000/images/ad9c6c.svg);
    width: 12px;
    height: auto;
    margin: 0 -16px 0 4px;
    z-index: 1;
    cursor: pointer;
}
.search-properties-item-horizontal-wrapper .accessibility-carousel .accessibility-arrow-right {
    content: url(http://localhost:5000/images/ad9c6c.svg);
    width: 12px;
    height: auto;
    margin: 0 4px 0 -16px;
    transform: rotate(180deg);
    cursor: pointer;
}
.search-properties-item-horizontal-wrapper .accessibility-carousel{
    display: flex;
}
.search-properties-item-horizontal-wrapper .slick-slider .slick-list img {
    max-height: 210px;
    width: initial !important;
    display: block !important;
    margin: auto;
    max-width: 100%;
}
.search-properties-item-horizontal-test-img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 3px;
}
.search-properties-item-horizontal-right-slider{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.search-properties-item-horizontal-mobility-wrapper .icon{
    width: 45px;
    height: 45px;
}
.search-properties-item-horizontal-top-info{
    display: flex;
    align-items: center;
}
.search-properties-item-horizontal-mobility-wrapper{
    width: 20%;
    padding: 1%;
    justify-content: center;
}
.search-properties-item-horizontal-tripadvisor-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
}
.search-properties-item-horizontal-name{
    font-weight: 700;
    font-size: 14px;
}
.search-properties-item-horizontal-name-address-wrapper{
    flex: 1;
}
.search-properties-item-horizontal-bottom-left{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.search-properties-item-horizontal-bottom-left-rooms-info{
    display: flex;
    justify-content: center;
}
.search-properties-item-horizontal-bottom-left-rooms-info-cell{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    padding: 0px 20px;
}
.search-properties-item-horizontal-bottom-left-rooms-accessibilities{
    width: 95%;
    margin-top: 6%;
}
.search-properties-item-horizontal-bottom-left-rooms-accessibilities .accessibility-box{
    display: flex;
    flex-wrap: wrap;
}
.search-properties-item-horizontal-bottom-left-rooms-accessibilities .row{
    margin: 0;
    background-color: #c5c5c5;
    border-radius: 3px;
}
.search-properties-item-horizontal-bottom-left-rooms-accessibilities .accessibility-box li{
    padding: 8px;
}
.search-properties-item-horizontal-bottom-left-rooms-accessibilities .accessibility-box .icon-wrap{
 background: transparent;
 padding: 0;
}
.search-properties-item-horizontal-bottom-left-rooms-accessibilities .accessibility-box .icon-wrap{
    margin-bottom: 0;
}
.search-properties-item-horizontal-bottom-info{
    display: flex;
    margin-top: 4%;
    align-items: flex-end;
}
.search-properties-item-horizontal-tripadvisor-rating{
    width: 80%;
}
.search-properties-item-horizontal-tripadvisor-reviews-rating{
    color: #f4af3d;
    margin-right: 7px;
    font-weight: 700;
}
.search-properties-item-horizontal-address{
    color: gray;
    font-size: 12px;
    font-weight: 700;
}
.horizontal-bedrooms-info{
    border-left: 1px solid gray;
    border-right: 1px solid gray;
}
.search-properties-item-horizontal-bottom-left-rooms-accessibilities .accessibility-box li {
    margin-bottom: 0;
}
.search-properties-item-horizontal-bottom-right{
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.search-properties-item-horizontal-bottom-right-price-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
}
.search-properties-item-horizontal-bottom-right-button-wrapper .search-properties-item-horizontal-bottom-right-button{
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    background-color: #28b6ff;
    color: white;
    display: block;
    text-align: center;
    padding: 3px;
}
.horizontal-navigation-switcher-on{
    background-image: url('../images/misc/list-nav.png');
    padding: 16px;
    background-size: cover;
    background-color: transparent;
}
.horizontal-navigation-switcher-off{
    background-image: url('../images/misc/hor-nav.png');
    padding: 16px;
    background-size: cover;
    background-color: transparent;
}
.horizontal-navigation-switcher{
    margin-top: 24px;
    margin-left: 12px;
}
.search-properties-item-horizontal-wrapper .accessibility-arrow-left {
    content: url("../images/misc/arrow-accessibilities.svg");
    width: 12px;
    height: auto;
    margin: 0 -16px 0 4px;
    z-index: 1;
    cursor: pointer;
  }
.search-properties-item-horizontal-wrapper .accessibility-arrow-right {
    content: url("../images/misc/arrow-accessibilities.svg");
    width: 12px;
    height: auto;
    margin: 0 4px 0 -16px;
    transform: rotate(180deg);
    cursor: pointer;
  }
  .search-properties-item-horizontal-wrapper .slick-track{
    display: flex;
    align-items: center;
  }
  .search-properties-item-horizontal-wrapper .slick-slider{
      display: flex;
  }
  .search-properties-item-horizontal-wrapper .slick-slider img{
    border-radius: 3px;
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
}
.horizontal-navigation-slider .carousel .control-next.control-arrow:before {
    border: none !important;
    width: 25px;
    height: 25px;
    background-image: url(../images/misc/slider-arrow.png);
    transform: rotate(90deg);
}
.horizontal-navigation-slider .carousel .control-prev.control-arrow:before {
    border: none !important;
    width: 25px;
    height: 25px;
    background-image: url(../images/misc/slider-arrow.png);
    transform: rotate(-90deg);
}
.accessibility-filters{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.accessibility-filters .checkbox{
    margin-left: 15px;
}
.found-properties-horizontal{
    max-width: 700px !important;
}
.horizontal-navigation-slider .carousel.carousel-slider{
max-height: 185px;
border-radius: 3px;
}
.horizontal-navigation-slider .carousel .control-next.control-arrow {
    right: -10px;
}
.carousel.carousel-slider .control-arrow:hover {
    background: transparent;
}
.horizontal-navigation-slider .carousel .control-prev.control-arrow {
    left: -10px;
}
.accessibility-filters-options{
   flex: 1;
}
.accessibility-filters-wrapper{
    display: flex;
    flex-direction: column;
}
.accessibility-filters-title-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 1%;
    font-size: initial;
    font-weight: 600;
    align-items: center;
}
.accessibility-filters-button{
    padding: 0;
    border-radius: 0px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-image: url(../images/icons/down-arrow.png);
    width: 15px;
    height: 15px;
    background-size: contain;
    background-color: transparent;
    transform: rotate(180deg);
    transition: 0.5s;
}
.accessibility-filters-button-rotate{
    padding: 0;
    border-radius: 0px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-image: url(../images/icons/down-arrow.png);
    width: 15px;
    height: 15px;
    background-size: contain;
    background-color: transparent;
    transition: 0.5s;
}
.-rotate:focus, .accessibility-filters-button:focus{
outline: none;
}
@media only screen and (max-width: 768px) {
    .search-properties-item-horizontal {
      flex-direction: column;
    }
    .search-properties-item-horizontal-right, .search-properties-item-horizontal-left{
        width: 100%;
    }
    .search-properties-item-horizontal-bottom-left-rooms-accessibilities .accessibility-box .icon{
        width: 40px;
        height: 40px;
    }
    .search-properties-item-horizontal-tripadvisor-reviews{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .search-properties-item-horizontal-tripadvisor-wrapper{
        padding: 1%;
    }
    .search-properties-item-horizontal-bottom-right-button-wrapper .search-properties-item-horizontal-bottom-right-button{
        padding: 7px;
    }
  }
  .horizontal-navigation-switcher-mobile{
      text-align: center;
  }
  .price-string{
      /* color:green */
  }
  .search-properties-item-horizontal-bottom-right-button-nobtn{
    color: red;
    text-align: center;
  }
  .search-list-properties-horizontal .slider-wrapper{
margin-top: 10px;
min-height: 275px;
  }