.information-icons {
  display: flex;
  margin: 0 -8px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.information-icons .item-outer {
  min-width: 130px;
  margin-bottom: 15px;
  flex: 1;
  padding: 0 8px;
}

.information-icons .item {
  border: 1px solid #d4d4d4;
  height: 90px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  font-weight: bold;
  color: #484848;
  border-radius: 3px;
  text-align: center;
}

.information-icons .item img {
  width: 30px;
  object-fit: contain;
}

@media (max-width: 1200px) {
  .information-icons {
    margin: 0 -8px;
  }
}
@media (max-width: 575px) {
  .information-icons {
    margin-left: -15px;
  }
  .information-icons .item-outer {
    padding-left: 15px;
  }
}
