.inbox-page {
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: #d4d4d4;
  margin-top: 50px;
  margin-bottom: 50px;
  border-top: 6px solid #189ce2;
}
.dashboard-inbox-top {
  padding: 10px 20px;
  border-bottom: 1px solid #d4d4d4;
}
.dashboard-page .inbox-page .select-dropdown {
  width: 260px;
  border-color: #d4d4d4;
}

.dashboard-page .inbox-page .dashboard-inbox-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-page .inbox-page .inbox-page-message * {
  font-size: 14px;
  font-weight: lighter;
  justify-content: center;
  display: block;
  margin-bottom: 0;
}
.dashboard-page .inbox-page .inbox-page-message :hover {
  background-color: lightblue;
}

.dashboard-page .inbox-page .inbox-page-message h3 {
  margin-bottom: 0;
}
.dashboard-page .inbox-page .inbox-page-message h2 {
  font-size: 16px;
  font-weight: bold;
}

.dashboard-page .inbox-page .inbox-massages-parent li {
  width: 100%;
  background-color: white;
}
.dashboard-page .inbox-page li:hover {
  background-color: #aee6ff;
}

.dashboard-page .inbox-page .inbox-page-message {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100px;
  border-bottom: 1px solid #d4d4d4;
  padding: 30px;
  align-items: center;
}
.dashboard-page .inbox-page .inbox-page-message-block {
  width: 30%;
}
.dashboard-page .inbox-page .inbox-page-message-block-message {
  width: 45%;
  overflow: hidden;
  max-height: 72px;
}

.dashboard-page .inbox-page .inbox-massages-parent ul {
  width: 100%;
  border-radius: 3px;
}

.dashboard-page .inbox-page .inbox-page-message .img-circle {
  width: 64px;
  height: 64px;
  align-items: center;
  background-color: black;
  background-size: 100%;
  border-radius: 3px;
  margin-right: 30px;
  background-position: center;
  background-repeat: no-repeat;
}
.inbox-page-message-block-message-status {
  flex: 1;
}
.message-status {
  background-color: rgb(255, 198, 62);
  padding: 5px 20px;
  color: white;
  border-radius: 3px;
}
.dashboard-page
  .inbox-page
  .inbox-page-message-block-message-status
  p.message-status {
  font-weight: bold;
  text-align: center;
}

@media (max-width: 476px) {
  .dashboard-page .inbox-page .inbox-page-message {
    padding: 10px;
  }
  .dashboard-page .inbox-page .select-dropdown {
    width: 172px;
  }
  .dashboard-page .inbox-page .dashboard-inbox-top h1 {
    font-size: 14px;
  }
  .dashboard-page .inbox-page .inbox-page-message .img-circle {
    margin-right: 8px;
  }
  .dashboard-page .inbox-page .inbox-page-message * {
    line-height: 1.4;
    padding-left: 2px;
  }
  .dashboard-page .inbox-page .inbox-page-message h3,
  .dashboard-page .inbox-page .inbox-page-message h2 {
    font-size: 12px;
  }
  .dashboard-inbox-top .inbox-page-dropdown {
    padding: 8px 0em 8px 10px;
  }
}
