
.blog-post-widget-card.object-page {
  width: calc((100% - 120px) / 3);
  margin: 20px;
}

.object-page.slider-wrapper {
  width: 100%;
  background-color: #f4f4f4;
  padding-left:10.7%;
  padding-right: 10.7%;
}

.blog-post-widget-card.object-page .img-wrapper {
  height: 200px;
}

.object-page .blog-post-widget-card-info-wrapper {
  height: auto;
  min-height: 220px;
  padding-top: 20px;
}

.object-page .blog-post-widget-card-title {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.26px;
  font-weight: 600;
  color: black;

}
.object-page .blog-post-widget-card-category {
  font-size: 12px;
  margin-bottom: 0.2rem;
}

.blog-post-widget-card-details-wrapper hr {
  width: 10%;
  height: 0px !important;
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: #adadad
}

.blog-post-widget-card-details-wrapper .blog-post-widget-card-details {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  font-weight: normal;
  color: black;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.object-page .blog-post-widget-card-spinner {
  width: 33%;
}

.object-page .blog-post-widget-slider-button-next{
  padding:15px;
  right: -50px;
}

.object-page .blog-post-widget-slider-button-previous{
  padding:15px;
  left: -50px;
}

.blog-post-widget-card-wrapper {
  padding: 0%;
}

/* MEDIUM SCREENS */
@media only screen and (max-width: 1399px) {

  .blog-post-widget-card.object-page {
    /* 120px refers to 6*20px margins */
    width: calc((100% - 120px) / 3); 
    margin: 20px;
  }

  .blog-post-widget-card-wrapper {
    padding-bottom: 30px;
  }

  .slider-wrapper {
    padding-bottom: 30px;
  }

  .object-page .blog-post-widget-card-spinner {
    /* 120px refers to 6*20px margins */
    width: calc((100% - 120px) / 3);
  }
  
  .object-page.slider-wrapper {
    width: 100%;
  }
}

/* SMALL SCREENS */
@media only screen and (max-width: 999px) {

  .blog-post-widget-card.object-page {
    /* 120px refers to 4*20px margins */
    width: calc((100% - 80px) / 2);
  }

  .blog-post-widget-card-wrapper {
    padding-bottom: 0px;
  }

  .slider-wrapper {
    padding-bottom: 30px;
  }

  .object-page .blog-post-widget-card-spinner {
    /* 120px refers to 4*20px margins */
    width: calc((100% - 80px) / 2);
  }

  .object-page.slider-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 599px) {

  .blog-post-widget-card.object-page {
    padding-top: 10px;
    width: 100%;
    margin: 0px;
  }

  .object-page .blog-post-widget-card-spinner {
    width: 100%;
  }
}

/* FONT BREAKPOINTS */
@media only screen and (max-width: 1350px) {

  .object-page .blog-post-widget-card-title {
    font-size: 18px;
  }

  .object-page .blog-post-widget-card-category {
    font-size: 12px;
  }

  .object-page .blog-post-widget-card-details {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1100px) {

  .object-page .blog-post-widget-card-title {
    font-size: 18px;
  }

  .object-page .blog-post-widget-card-category {
    font-size: 12px;
  }

  .object-page .blog-post-widget-card-details {
    font-size: 18px;
  }
}

@media only screen and (max-width: 999px) {

  .object-page .blog-post-widget-card-title {
    font-size: 18px;
  }

  .object-page .blog-post-widget-card-category {
    font-size: 12px;
  }

  .object-page .blog-post-widget-card-details {
    font-size: 16px;
  }
}

@media only screen and (max-width: 679px) {

  .object-page .blog-post-widget-card-title {
    font-size: 18px;
  }

  .object-page .blog-post-widget-card-category {
    font-size: 12px;
  }

  .object-page .blog-post-widget-card-details {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {

  .object-page .blog-post-widget-card-title {
    font-size: 18px;
  }

  .object-page .blog-post-widget-card-category {
    font-size: 12px;
  }

  .object-page .blog-post-widget-card-details {
    font-size: 16px;
  }
}
