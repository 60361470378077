.community-story {
  transition: .3s;
  border-radius: 3px 3px 3px 3px;
  position: relative;
  margin: 5px;
  overflow: hidden;
}

.community-story img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 3px 3px 3px 3px;
}

.community-story-info {
  color:white;
  /* font-size: 1rem !important; */
  font-weight: 600;
  text-decoration: none;
  padding: 7px;
  text-align: center;
  background-color: #1e1e1ea3;
  position: absolute;
  z-index: 2;
  border-radius: 3px 3px 3px 3px;
}

.community-story-title { 
  margin: 0;
  bottom: 0px;
  width: 100%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px 0px 3px 3px;
}

.community-story-category {
  text-transform: uppercase;
  top: 0px;
  left: 0px;
  font-weight: 1000 !important;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 3px 0px 3px 0px;
}

.slider-wrapper {
  width: 100%;
  height: 100%;
}

.slider-wrapper .spinner-overlay {
  position: absolute;
}

/*BIG SCREENS*/
@media only screen and (min-width: 600px) {

  .community-stories-wrapper {
    height: 351px;
  }

  .community-story {
    height: calc(50% - 5px);
    width: calc(50% - 10px);
  }

  .big {
    height: 100%;
    float: left;
  }

  .small {
    float: right;
  }

  .big .community-story-expand {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .big .community-story-title {
    font-size: 1.2rem !important;
  }

  .big:hover .community-story-expand-title {
    font-size: 1.2rem !important;
  }
  
  .small .community-story-expand .community-story-short-text {
    display: none;
  }

  .community-story a:hover {
    text-decoration: none;
  }
  
  .community-story:hover {
    box-shadow: 0 40px 50px rgba(0,0,0,0.05), 0 30px 24px rgba(0,0,0,0.03);
    transform: scale(1.01);
  }
  
  .community-story-expand {
    display: none;
  }
  
  .community-story:hover .community-story-expand {
    background-color: rgba(0,0,0,0.8);
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    border-radius: 3px 3px 3px 3px;
    text-align: center;
  }
  
  .community-story:hover .community-story-expand p {
    color: white;
    font-weight: 600;
    text-align: center;
  }
  
  .community-story:hover .community-story-title {
    display: none;
  }
  
  .community-story:hover .community-story-category {
    display: none;
  }
  
  .community-story:hover .community-story-expand-category {
    font-weight: 1000 !important;
    padding-left: 20px;
    padding-right: 20px;
    margin: 10px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    background-color:  rgba(0,0,0,0.8);
  }
  
  .community-story:hover .community-story-expand-title {
    font-weight: 1000 !important;
    font-size: 1rem;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 10px;
    text-align: center;
    color: white;
  }
  
  .community-story:hover .community-story-short-text {
    margin: 10px;
    font-size: 1rem !important;
    padding-left: 30px;
    padding-right: 30px;
  }

  .community-story:hover .community-story-more {
    font-weight: 1000 !important;
    font-size:  1rem !important;
    text-align: center;
    color: #28b6ff !important;
  }

  .community-slider-button-next{
    display: inline;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    padding: 40px;
    right: -110px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../images/icons/similar-prop-arrow.svg);
}

  .community-slider-button-previous{
    display: inline;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 40px;
    left: -110px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../images/icons/similar-prop-arrow-back.svg);
  }
}

/*SMALL SCREENS*/
@media only screen and (max-width: 600px) {
  .community-story-expand {
    display: none;
  }
  
  .community-stories-wrapper {
    width: 90%;
    margin: auto;
  }

  .community-story {
    height: 351px;
  }
}
