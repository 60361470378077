.webinar-modal__after-open{
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.webinar-modal__content{
    background: #fff;
    position: relative;
    width: 739px;
    height: 469px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
}
.webinar-modal__content-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: center;
}
.webinar-modal__title{
    font-weight: bold;
    font-size: 28px;
    color: #1e1e1e;
    margin-top: 20px;
}
.webinar-modal__text{
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #1e1e1e;
    width: 62%;
    text-align: center;
}
.webinar-modal__sign-up{
    height: 55px;
    width: 150px;
    background: #0E75BA;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.webinar-modal__sign-up:hover{
    color: white;
}
.webinar-modal__skip{
    padding: 0;
    background: transparent;
    color: #666666;
    text-decoration: underline;
    margin-top: 20px;
}
.webinar-modal__close-btn{
    position: absolute;
    background-image: url('../images/user-profile-page/close-cross-icon.svg');
    padding: 0;
    width: 25px;
    height: 25px;
    background-color: white;
    background-size: cover;
    top: 3px;
    right: 4px;
}
.webinar-modal__image{
    max-width: 100%;
}
/* mobile */
@media only screen and (max-width: 900px) {
    .webinar-modal__content{
        width: 90%;
    }
    .webinar-modal__title{
        font-size: 20px;
        text-align: center;
        width: 90%;
    }
    .webinar-modal__text{
        font-size: 16px;
        width: 90%;
    }
    .webinar-modal__content{
        height: 400px;
    }
}