.booking-timer-wrapper{
    display: flex;
    justify-content: center;
    font-size: 26px;
    background-color: #d7efd7;
    border-radius: 5px;
    color: #656363;
    width: 87%;
}
@media only screen and (max-width: 500px) {
  .booking-timer-wrapper {
    width: 100%;
  }
}
.booking-timer-seconds{
    padding-left: 8px;
}
.booking-timer-note{
    color: red;
}
/*Modal */
.booking_modal-content-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color:gray;
  }
  .booking_modal-content-time{
    text-align: center;
    font-size: 26px;
    font-weight: 700;
  }
  .booking_modal-content-note{
    width: 75%;
    font-size: 15px;
    text-align: center;
  }
  .payment .card-details .booking-modal-error{
    background-color: white;
    border: 1px solid red;
  }
  /* .booking-timer-central-section{
    margin-top: 12%;
  }
  .booking-timer-payment-section{
    margin-top: 13%;
  } */