.channel-managers-page {
    border-bottom: 1px solid #d4d4d4;
}
.channel-managers-page .channel-managers-table td {
    border: 1px solid #d4d4d4;
    padding: 10px 20px;
}
.channel-managers-page .channel-managers-table {
    width: 100%;
}

.dashboard-settings-layout .dashboard-custom-box .inner-content .channel-managers-page > .big-add-btn.add-cm-button {
    background-color: #28b6ff;
    display: block;
    font-size: 16px;
    height: auto;
    padding: 20px 40px;
    text-align: center;
    width: auto;
    border:none;
    color:white;
}

.dashboard-settings-layout .dashboard-custom-box .inner-content .channel-managers-page > .big-add-btn.add-cm-button:hover{
    background-color: #3bc4ff;
}
