/* EDIT PROFILE AND COMPANY PAGE STYLING */
.edit-profile-page {
    width:100%;
    border-bottom: 1px solid #d4d4d4;
}
.edit-profile-page .inner-content {
    font-size: 16px;
    padding: 30px;
    margin-top: 0;
}
.edit-profile-page .date {
  display: flex;
  align-items: flex-end;
  margin: 0 -10px;
}

.edit-profile-page .date label {
  padding: 0 10px;
  flex:1;

}
.edit-profile-page .date label select {
  width: 100%;
}

.edit-profile-page .date label span {
  width: 30px;
}

.edit-profile-page .date label:first-child span {
  width: 150px;
}

.edit-profile-page .content {
  margin-left: 100px;
  display: flex;
  flex-direction: column;
}

.edit-profile-page label {
    margin-bottom: 20px;
    width: 100%;
    display: block;
}

.edit-profile-page label span {
  font-weight: bold;
  color: #484848;
  margin-bottom: 6px;
}

.edit-profile-page label textarea {
  resize: vertical;
  height: 200px;
  flex: 1;
  font-size: 1.2em;
  padding: 20px;
  border: 1px solid #d4d4d4;
  width: 100%;
  margin-top:0px;
  border-radius: 3px;
}

.edit-profile-page button {
  align-self: flex-end;
}

@keyframes notifyopacity{0%{opacity:0}10%{opacity:.1}20%{opacity:.2}30%{opacity:.3}40%{opacity:.4}50%{opacity:.5}60%{opacity:.6}70%{opacity:.7}80%{opacity:.8}90%{opacity:.9}100%{opacity:1}}
@-webkit-keyframes notifyopacity{0%{opacity:0}10%{opacity:.1}20%{opacity:.2}30%{opacity:.3}40%{opacity:.4}50%{opacity:.5}60%{opacity:.6}70%{opacity:.7}80%{opacity:.8}90%{opacity:.9}100%{opacity:1}}

.notify {
    text-align: center;
    /* border: 1px #28fdf2 solid; */
    border-radius: 5px;
    max-width: 50%;
    margin: 0px auto;
    margin-bottom: 15px;
    padding: 3px;
    color: #FFF;
    background-color: #189de2;
    font-weight: bold;
}

.notify-animated-hidden {
    visibility: hidden;
}

.notify-animated {
    visibility: visible;
    animation-duration: 500ms;
    -webkit-animation-duration: 500ms;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    animation-name: notifyopacity;
    -webkit-animation-name: notifyopacity;
}
