.dashboard-custom-box {
  display: block;
  margin-bottom: 40px;
  width:100%;
}

.dashboard-properties .border div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-properties .input-field-wrapper {
  max-width: 250px;
}

.dashboard-custom-box .border {
  background-color: white;
  border-bottom: 1px solid #d4d4d4;
  color: #484848;
  font-size: 15px;
  padding: 12px 20px;
}

.dashboard-custom-box .inner-content {
  font-size: 15px;
  padding: 0px;
  margin-top: 20px;
}

.dashboard-custom-box .inner-content .big-add-btn {
  background-color: #FFF;
  border: 1px solid #dce0e0;
  color: #000;
  display: block;
  font-size: 15px;
  height: 127px;
  padding: 10px;
  text-align: center;
  width: 148px;
}

.dashboard-custom-box input[type="password"], input[type="text"], input[type="number"]{
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    font-weight: 700;
    height: 100%;
    max-height: 100px;
    text-indent: 20px;
    -webkit-appearance: none;
    width: 100%;
    color: #666;
    display: flex;
}

.dashboard-custom-box .headline {
    margin-bottom: 0;
    font-size: 24px;
    color: #484848;
}
.dashboard-custom-box-title-wrapper{
  display: flex;
  flex-direction: column;
}
.headline-wrapper{
  display: flex;
    width: 100%;
}
.logs-wrapper{
  display: flex;
  flex-direction: column;
}
.hotel-log-item{
   display: flex !important;
   flex-direction: column !important;
   align-items: baseline !important;
   padding: 5px;
}
.hotel-logs-wrapper{
  display: flex !important;
  flex-direction: column;
  font-size: 14px;
  align-items: baseline !important;
  max-height: 300px;
  overflow: scroll;
  font-size: 13px;
  background-color: #606060;
  color: white;
  border-radius: 3px;
  width: 100%;
}
.property-unit-wrapper{
  display: flex !important;
  flex-direction: column !important;
  align-items: normal !important;
}
.property-logs-wrapper{
  display: flex !important;
  flex-direction: column !important;
  align-items: baseline !important;
  max-height: 150px;
  overflow: scroll;
  font-size: 13px;
  background-color: #606060;
  color: white;
  padding: 5px;
  margin-top: 8px;
  border-radius: 3px;
}
.logs-button{
  background-color: #606060 !important;
}