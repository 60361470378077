
.google-input-field {
  z-index: 999;
}

.google-input-field .g-input.err {
  border: 1px solid rgb(255,0,0);
}

.google-input-field .autocomplete-container {
  position: relative;
  z-index: 10000;
  text-align: left;
  background-color: white;
  padding: 0 2px 0 2px;
  border-radius: 3px;
  border: 1px solid gray;
}
.properties_autocomplete{
  font-size: 13px;
  font-weight: 500;
  color: #666;
  display: block;
  transition: 0.5s;
  padding-left: 10px;
}
.properties_autocomplete:hover{
  cursor: pointer;
  color: #666;
  background-color: rgb(230, 230, 230);
  text-decoration: none;
}
.hide{
  display: none;
}
.search-category-title{
  position: relative;
  padding-left: 30px;
  padding-top: 10px;
  font-weight: 900;
  color: gray;
  font-size: 15px;
}
.search-category-title::after{
  background: url('../images/icons/Properties.png');
  content: " ";
  position: absolute;
  display: block;
  height: 14px;
    width: 14px;
    top: 13px;
    left: 11px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.city-modif::after{
  background: url('../images/icons/place-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
}
#hotelsListWrapper{
  border-bottom: 1px solid gray;
}
/* .google-items-list-wrapper{
  border-bottom: 1px solid gray;
} */
.properties-list-tooltip{
  font-size: 11px;
}