.usp-page{
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 50px;
}
.usp-page_title-section{
    font-size: 28px;
    line-height: 160%;
    text-align: center;
    margin-bottom: 0;
    color: #1E1E1E;
    padding-bottom: 60px;
    position: relative;
}
.usp-page_title-section::after{
    position: absolute;
    content: '';
    width: 159px;
    height: 4px;
    background: #FF8C00;
    left: 43%;
    bottom: 15%;
}
.usp-page_header-text{
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin: auto;
    max-width: 60%;
    color: #1E1E1E;
    margin-top: 10px;
}
.usp-page_main-info-section{
    padding-top: 30px;
    padding-left: 15%;
    position: relative;
}
.usp-page_main-info-section::before{
    width: 120px;
    height: 120px;
    content: '';
    position: absolute;
    left: 0;
}
.usp-page_main-info-section__accessibility-data::before{
    background-image: url('../images/usp-pages/notebook-icon.svg');
}
.usp-page_main-info-section__accessible-properties::before{
    background-image: url('../images/usp-pages/globe-icon.svg');
}
.usp-page_main-info-section__experts::before{
    background-image: url('../images/usp-pages/dialog-icon.svg');
}
.usp-page_main-info-section__great-price::before{
    background-image: url('../images/usp-pages/great-price-icon.svg');
}
.usp-page_main-info-section__best-price::before{
    background-image: url('../images/usp-pages/best-price-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
}
.usp-page_main-info-section__protect-refund::before{
    background-image: url('../images/usp-pages/RP_Main_Logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.usp-page_main-info-section__great-price{
    padding-bottom: 50px;
    border-bottom: 1px solid #E6E6E6;
}
.usp-page_main-info-section__best-price{
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #E6E6E6;
}
.usp-page_main-info-title{
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: #1E1E1E;
}
.usp-page_main-info-text{
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #1E1E1E;
    margin-top: 15px;
}
.usp-page_footer-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #C5E5FB;
    padding: 25px 0;
}
.usp-page_footer-section-contacts-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}
.usp-page_footer-section-title{
    font-weight: bold;
    font-size: 28px;
    color: #1E1E1E;
}
.usp-page_footer-section-text{
    font-weight: normal;
    font-size: 16px;
    color: #000000;
    margin-top: 10px;
    text-align: center;
}
.usp-page_footer-section-images-wrapper{
    margin-top: 10px;
}
.usp-page_footer-section-images-wrapper img{
    margin: 5px;
}
.usp-page_footer-section-contacts-link{
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #0E75BA;
}
.usp-page_inspired-section{
    text-align: center;
    width: 70%;
    margin: auto;
    padding: 50px 0;
    border-top: 1px solid #E6E6E6;
}
.usp-page_inspired-section .usp-page_main-info-text{
    max-width: 80%;
    margin: auto;
    margin-top: 20px;
}
.usp-page_home-btn{
    width: 166px;
    height: 55px;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    background: #0E75BA;
    border-radius: 3px;
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 20px;
    cursor: pointer;
}
.usp-page_home-btn:hover{
    color: white;
}
.usp-page_notify{
    font-weight: bold;
}
.usp-page_underline{
    text-decoration: underline;
}
.usp-page_refund-protect-list{
    display: flex;
    flex-direction: column;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #1E1E1E;
}
@media (max-width: 767px) {
    .usp-page{
    padding-left: 5%;
    padding-right: 5%;
    }
    .usp-page_header{
        font-size: 24px;
    }
    .usp-page_header-text{
        max-width: initial;
    }
    .usp-page_main-info-section{
        padding-left: 0;
        padding-top: 175px;
        padding-bottom: 10%
    }
    .usp-page_main-info-section::before{
        left: 33%;
        top: 0;
    }
    .usp-page_title-section::after{
        left: 26%;
    }
    .usp-page_inspired-section{
        width: 100%;
    }
    .usp-page_main-info-section__no-padding{
        padding-top: initial;
    }
}