.topic{
    display: flex;
}
/* Next rule is to cancel inline-block for spans from notification-page.css */
.blog-post div span{
    display: unset;
  }
.result  .article-container{
    margin : 0px;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    box-shadow: 0px 3.51266px 3.51266px rgba(51, 51, 51, 0.12), 0px 3.51266px 14.0506px rgba(51, 51, 51, 0.12);
    border-radius: 5px;
}
.topic  .article-container{
    box-shadow: 0px 3.51266px 3.51266px rgba(51, 51, 51, 0.12), 0px 3.51266px 14.0506px rgba(51, 51, 51, 0.12);
    border-radius: 5px;
    max-width: 350px;
    margin-bottom: 30px;
    margin-right : 15px;
    margin-left : 15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
}
.result  .article-text-container{
    flex-grow : 2;
    flex-direction: column;
    padding: 20px;
    padding-top: 40px;
}
.topic  .article-text-container{
    flex-grow : 1;
    flex-direction: column;
    padding: 0px;
    padding-left : 10px;
    padding-right : 10px;
    margin-bottom: 20px;
}
.result  .article-image-container{
    align-content: center;
    margin-right: 30px;
    width: 350px;
    height: 250px;
}
.result  .article-image {
    width: 350px;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10% 10%;
    border-radius: 5px 0px 0px 5px;
  }
.topic  .article-image-container{
    align-content: center;
    margin-bottom: 16px;
    width: 350px;
    height: 200px;
}
.topic  .article-image{
    width: 350px;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10% 10%;
    border-radius: 5px 5px 0px 0px;
  }
  .article-title{
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 0px;
    line-height: 30px;
    word-wrap: break-word;
    color: #1a1a1a;
    font-family: inherit;
}
.article-title:hover{
    color:rgb(22, 102, 146);
}
.result  .article-tags{
    margin-top: 5px;
    line-height: 17px;
    word-wrap: break-word;
    padding: 2px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 650;
}
.topic  .article-tags{
    display: none;
}
.tag-link{
    color : black;
}
.tag-link:hover{
    color:rgb(22, 102, 146);
    text-decoration: underline rgb(22, 102, 146) solid;
}
a.tag-link {
    text-decoration: underline;
    display: inline-block;
}
a.tag-link::after {
    content: "|";
    margin: 0px 5px;
}
a.tag-link:hover::after {
    text-decoration-style: none;
}
  
a.tag-link:last-child::after {
    content: "";
}

.article-date{
    font-size: 14px;
    font-weight: 500;
    color:black;
    margin: 0px;
    margin-top: 10px;
    padding: 0px;
    font-family: inherit;
    padding: 2px;
}
.article-text-props{
    font-family: inherit;
    padding-right: 10px;
}
.list-wrapper{
    width: auto;
}
.result  .article-tags::before{
    content:"";
    background-image: url('../images/icons/tag_icon.png');
    background-size: 10px 10px;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-right: 5px;
}
.article-description{
    margin-bottom: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color:black !important;
    text-overflow: inherit;
    overflow: hidden;
    white-space: break-spaces;
    max-height: 52px; 
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

}
.article-description> p{
    color:black !important;
}
 @media screen and (max-width: 1100px)
{
    .result  .article-image{
        border-radius: 5px 5px 0px 0px;
    }
    .result  .article-container{
        flex-direction: column;
        max-width: 350px;
        align-content: center;
    }
    .list-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .result  .article-text-container{
        padding: 20px;
    }
    .result  .article-tags{
        margin-top: 0px;
    }
    .article-date{
        margin-top: 0px;
    }
    .result .article-description{
        margin-bottom: 2px;
        text-overflow: inherit;
        overflow: hidden;
        white-space: break-spaces;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

    }
    .topic  .article-container{
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 25px;
    }
}
a:hover {
    text-decoration: none;
  }
.article-wrapper{
    margin: auto;
    margin-top: 120px;
    width: 100%; 
    display: flex;
    flex-direction : column;
    margin-left: 5px;
    margin-right: 5px;
    align-self: center;
}
