
.about-us-image {
  width: 270px;
  height: 280px;
  background-size: cover;
  border-radius:3px;
  background-color: #d4d4d4;
}
.about-the-board .about-us-image, .about-the-investors .about-us-image {
  width: 270px;
  height: 280px;
  background-size: cover;
  border-radius:3px;
  background-color: #d4d4d4;
}
.about-the-board li, .about-the-investors li{
  padding: 10px;
  width: 50%;
  margin-bottom: 20px;
}


.about-the-board .about-person, .abount-the-investors .about-person{
  margin: 0 20px 20px 20px;
}
.about-the-board, .about-the-investors {
  display: flex;
  flex-wrap: wrap;
  /* margin:0 -20px; */
  justify-content: space-between;
}

.about-the-friends li {
  padding: 10px;
  width: 50%;
  margin-bottom: 20px;
}

.about-the-friends {
  display: flex;
  flex-wrap: wrap;
  /* margin:0 -20px; */
  justify-content: space-between;
}

.main-layout-content .about-page {
    margin-bottom: 100px;
}
.about-sebastien {
  background-image: url('../images/about-page/Sebastien.jpg');
}
.about-sanna-susiluoto{
  background-image: url('../images/about-page/sanna_susiluoto.jpg');
}

.about-alex { background-image: url('../images/about-page/Alex_B.jpg'); }
.about-nikolay { background-image: url('../images/about-page/nikolay-nesterchuk.jpg'); }
.about-alex-p { background-image: url('../images/about-page/alex-p.jpg');}
.about-anna-p { background-image: url('../images/about-page/anna-p.jpg');}
.about-bjorn { background-image: url('../images/about-page/bjorn.jpg'); }
.about-bo { background-image: url('../images/about-page/bo-eklund.jpg'); }
.about-camilla-n { background-image: url('../images/about-page/camilla-n.jpg');}
.about-carl-johansson { background-image: url('../images/about-page/carl-johansson.jpg'); }
.about-eva { background-image: url('../images/about-page/eva.jpg');}
.about-jenny { background-image: url('../images/about-page/jenny.jpg'); }
.about-yesol-k { background-image: url('../images/about-page/yesol-k.jpg'); }
.about-jakob { background-image: url('../images/about-page/jacob.jpg'); }
.about-jeff { background-image: url('../images/about-page/jeff.jpg'); }
.about-jens { background-image: url('../images/about-page/jens.jpg'); }
.about-lukas { background-image: url('../images/about-page/Lukas.jpg'); }
.about-marko { background-image: url('../images/about-page/Marko_G.jpg');}
.about-mihai-d { background-image: url('../images/about-page/mihai-d.jpg');}
.about-mathieu-leclere { background-image: url('../images/about-page/Mathieu-Leclere.jpg'); }
.about-alex-mcnab { background-image: url('../images/about-page/Alex-McNab.jpeg'); }
.consuelo-romano { background-image: url('../images/about-page/consuelo-romano.png'); }
.about-pelle { background-image: url('../images/about-page/per.jpg'); }
.about-sebastian-mutsson { background-image: url('../images/about-page/Sebastian-Mutsson.jpg'); }
.about-sally { background-image: url('../images/about-page/sally.jpg'); }
.about-ulf-annvik { background-image: url('../images/about-page/ulf-annvik.jpg');}
.titian-dragomir { background-image: url('../images/about-page/titian-dragomir.png'); }
.cory-lee { background-image: url('../images/about-page/cory-lee.png'); }
.magnus-berglund { background-image: url('../images/about-page/magnus-berglund.jpg'); }
.viviana-cea { background-image: url('../images/about-page/viviana-cea.png'); }
.helene-lloyd { background-image: url('../images/about-page/helene-lloyd.png'); }
.erik-soderberg { background-image: url('../images/about-page/erik-soderberg.png'); }
.fatmir-s { background-image: url('../images/about-page/Fatmir_S_and_Gizmo.png'); }
.fabien-c { background-image: url('../images/about-page/fabien.jpg'); }
.about-invest { background-image: url('../images/about-page/impact-invest.png'); }
.aleksey-nesterchuck { background-image: url('../images/about-page/aleksey-nesterchuck.png'); }

.daniel-galan { background-image: url('../images/about-page/daniel-galan.jpg'); }
.evgeniy-smirnov { background-image: url('../images/about-page/evgeniy-smirnov.jpg'); }
.dario-cianciarulo { background-image: url('../images/about-page/dario-cianciarulo.jpg'); }
.harry-hansson { background-image: url('../images/about-page/harry-hansson.jpg'); }
.andre-costa { background-image: url('../images/about-page/andre-costa.png'); }

.filip-m { background-image: url('../images/about-page/Filip-M.jpg'); }
.michael-c { background-image: url('../images/about-page/Michael_c.jpg'); }
.signe { background-image: url('../images/about-page/Signe.jpg'); }

.about-almi {
  background-image: url('../images/about-page/almi.png');
}
.about-entreprenor-invest {
  background-image: url('../images/about-page/entreprenor-invest.png');
}
.about-howzat {
  background-image: url('../images/about-page/howzat-partners.png');
}
.about-tranquility-capital {
  background-image: url('../images/about-page/tranquility-capital.png');
}

.about-crowd {
  background-image: url('../images/about-page/crowdcube.png');
}

.about-the-team {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about-the-team li {
  width: 270px;
  margin-right: 20px;
}

.about-page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
}
.about-page h1 {
  margin-top:50px;
}
/* About Person */
.about-page h2 {
  margin:50px 0 30px 0;
  color: 484848;
}

h3.about-name {
  font-weight: bold;
  margin-bottom: 2px;
  margin-top:10px;
  color:#484848;
  font-size: 18px;
}
.about-person h4 {
  font-size: 14px;
  color: #666;
}

.about-person ul {
  display: flex;
  margin-bottom: 20px;
}
.about-person a > div {
  height: 32px;
  width: 32px;
  background-size: 100%;
  opacity: 0.8;
  background-color: gray;
}
.about-the-team .about-description {
    display:none;
}

.about-description {
  height: auto;
  font-size: 17px;
}

/* .linkend-in-button {

}

.email-button {
  
} */

.button-list li {
  width: 32px;
  margin-right: 10px;
}

.about-video-section {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.about-video-section > iframe {
  height: 550px;
  width: 100%;
  border-radius: 3px;
  border:none;
}

.about-video-section > div {
  width: 300px;
}

.about-video-section > div > h2 {
  margin-bottom: 2px;
}

@media screen and (max-width: 636px) {
  .about-the-board li, .about-the-investors li, .about-the-friends li {
    width: 100%;
    padding: 0;
  }
  .about-video-section > iframe {
    height: 290px;
  }
}