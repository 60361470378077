.profile-photos-page {}

.profile-photos-page .hidden {
  visibility: hidden;
}

.profile-photos-page .avatar {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 150px;
  height: 150px;
  border: 1px solid rgba(0,0,0,0.05);
  cursor: pointer;
}

.profile-photos-page .avatar .overlay {
  pointer-events: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.1);
  padding: 0;
}

.profile-photos-page .avatar:hover .overlay {
  opacity: 1;

}

.profile-photos-page .add-photo-button {
  color: #000;
}
