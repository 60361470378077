.contact-host-modal-content {
  display: flex;
  align-items: top;
  justify-content: center;
  flex-wrap: wrap;
  font-family: Myriad Pro, sans-serif;
  color: #4d4d4d;
}

.contact-host-modal-content h2 {
  color: #575757;
  font-size: 16px;
  margin: 10px 0 20px;
}

.contact-host-modal-content .guest-section button {
  width: 100%;
  margin-bottom: 10px;
}

.contact-host-modal-content label.contact-name {
  max-width: 50%;
  padding: 0 5px;
}

.contact-host-modal-content .contact-form {
  margin-left: -5px;
  margin-right: -5px;
}

.contact-host-modal-content .contact-email {
  padding: 0 5px;
  width: 100%;
}

.contact-host-modal-content.sm {
  flex-wrap: wrap;
}

.contact-host-modal-content.xs > div {
  width: 100%;
  border: none;
}

.contact-host-modal .dates {
    margin-bottom: 10px;
}

.contact-host-modal .date-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 -5px;
}

.contact-host-modal .date {
    flex: 1;
    text-align: center;
    background: #666;
    padding: 6px;
    margin: 0 5px;
    border-radius: 3px;
    color: white;
    font-weight: bold;
}
.contact-host-modal-content .title h1, .contact-host-modal-content .modal-column-heading {
  margin-bottom: 5px;
  font-size: 21px;
  font-weight: normal;
  color: black;
}
.contact-host-modal .host-modal-guest {
    flex: 1;
    text-align: center;
    background: #666;
    padding: 6px;
    border-radius: 3px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 10px;
}
.contact-host-modal-content > div {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.contact-host-modal-content div.spacer {
  flex: 1;
}

.contact-host-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-host-modal-left-side .title {
  margin-bottom: 50px;
  margin-top: 0px
}

.contact-host-modal-left-side .title .caption {
  font-size: 18px;
}

.contact-host-modal > div {
  height: 100%;
  width: 50%;
}

.contact-host-modal-left-side {
  height: 100%;
  padding: 20px;
  width: 50%;
}

.contact-host-modal-left-side ul {
  list-style-type: disc;
  list-style-position: inside;
  font-size: 16px;
  font-weight: normal;
}

.contact-host-modal-left-side ul li {
  display:list-item;
  margin-bottom: 20px;
  font-size: 16px;
}

.contact-host-modal .confirmed-view {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  align-content: center;
  height: 100%;
}

.contact-host-modal .confirmed-view h1 {
  width:100%;
}

.contact-host-modal .confirmed-view p {
  margin-bottom: 30px;
  width:100%;
}

.contact-host-modal-content .close-btn {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  padding: 0;
}

.contact-host-modal-right-side {
  border-left: 1px solid #d4d4d4;
  padding: 10px;
  width: 50%;
}

.modal-container .optional {
    background-color: rgb(62, 177, 102);
    color: white;
    font-weight: bold;
    padding: 5px 7px;
    border-radius: 3px;
    display: inline-block;
    width: auto;
    margin-bottom: 5px;
}
.contact-host-modal-left-side .date-picker {
  bottom: 0;
  height: auto;
}
.contact-host-modal-left-side .DateRangePicker_picker {
  bottom: 40px;
  top: auto!important;
  border: 1px solid #cacccd;
  margin-left: -1px;
}
.contact-host-modal-right-side .date-picker {
    background-color: #fff;
    color: #c2c6cc;
    height: 52px;
    text-align: left;
    min-width: 250px;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    z-index: 100;
    margin-bottom: 10px;
}

.contact-host-modal-right-side p {
    margin-bottom: 0.5em;
}

.contact-host-modal-right-side .message-wrapper {
  width: 100%;
}

.contact-host-modal-right-side textarea {
  resize: none;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 3px;

}
.contac-host-modal-right-side > p {
  font-size: 12px;
  color: #666;
  margin-bottom: 20px;
}

.specify-checkin-check-out {
  display: flex;
  align-items: center;
  height: 70px;
  margin-bottom: 30px;

}
.contact-host-modal-content h2.message-header {
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 15px;
  color: black;
}
.warning-icon {
  width: 70px;
  height: 100%;
  background: #CA0F0C;
  border-radius: 3px 0 0 3px;
  background-repeat: no-repeat;

  background-position: center;
  background-image: url('../../images/object-page/warning.png');
}

.warning-text {
  padding: 20px;
  height: 100%;
  width: 3px;
  border-radius: 0 3px 3px 0;
  flex-grow: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 12px;
  border: 1px solid #d4d4d4;
  border-left: none;
}

 .contact-host-modal-content .booking-button {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}
.contact-host-modal-content label.enquiry-name {
    flex: 1;
    max-width: 50%;
    padding: 0 5px;
}

.xs .contact-host-modal-right-side,
.xs .contact-host-modal-left-side {
  width: 100%;
  border: none;
  text-align: center;
}

.xs .contact-host-modal-left-side ul {
  text-align: left;
}

.xs .contact-host-modal-right-side label {
  width: 100%;
}

.contact-host-modal-content label {
    flex: 1;
    width: 100%;
    padding: 0 5px;
}