.booking-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  padding: 20px;
  height: 100vh;
  pointer-events: none;
}

.booking-modal > * {
  pointer-events: auto;
}

.booking-modal .info-section {
  background: #fff;
  border-radius: 3px;
}

.booking-modal .info-section .total,
.booking-modal .info-section .summary-section {
  padding: 20px;
}

.booking-modal .info-section .summary-section .title {
  font-size: 20px;
  font-weight: bold;
}

.booking-modal .info-section .summary-section .items {
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.booking-modal .info-section .summary-section .items mark {
  border-radius: 3px;
  margin-top: 5px;
}

.booking-modal .info-section .summary-section .items mark:first-child {
  margin-top: 0;
}

.booking-modal button {
  margin-top: 10px;
}

.booking-modal .info-section .total {
  display: flex;
  justify-content: space-between;
}

.booking-modal .price-wrapper .price-info {
  font-weight: bold;
}

.booking-modal .price-wrapper .price {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: flex-end;
  margin-top: 2px;
}

.booking-modal .price-wrapper .price > * {
  line-height: 1;
  font-weight: bold;
}

.booking-modal .price-wrapper .price .number {
  color: green;
  font-size: 20px;
}

.booking-modal .price-wrapper .price .currency {
  font-size: 14px;
  margin-left: 2px;
  padding-bottom: 1px;
}

.booking-modal .price-wrapper mark {
  background-color: #000;
  color: #fff;
  border-radius: 3px;
  width: 100%;
  padding: 5px;
  text-align: center;
  margin-top: 5px;
}
.booking-modal .main-image {
  width: 100%;
  height: auto;
}

.add-coupon-success{
  color:#218234;
  font-size: 15px;
  font-weight: bold;
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.add-coupon-success .success{
  margin-right: 8px;
}