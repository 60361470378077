@media (min-width: 200px) and (max-width: 770px) {
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.9)!important;
  }

  .search-properties-page .search-bar input{
    font-size: 18px;
  }

  .search-modal {
    height: 100%;
    width: 100%;
  }

  .search-modal > .content {
    padding: 20px;
  }

  .search-modal > * {
    width: auto;
  }

  .search-modal input {
    border: 0px solid!important;
  }

  .search-modal .title-bar {
    background-color: #FFF;
    color: #000;
    font-size: 20px;
    position: relative;
    height: 70px;
    font-weight: 700;
  }

  .search-modal .title-bar > * {
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .search-modal .title-bar .title {
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-modal .title-bar button {
    background-color: #FFF;
    color: #000;
    width: 100px;
    padding: 15px 20px;
    text-align: right;
  }

  .search-modal .content > * {
    width: auto;
  }
  .search-modal .date-picker {
    margin-bottom: 20px;
    border: 0px solid;
  }

  .search-modal .mobile-drop-down p {
    margin-bottom: 0px;
  }
  .search-modal .mobile-drop-down li {
    justify-content: flex-start;
  }

  .search-modal .DateRangePickerInput {
      height: 100px;
      width: 100%;
  }
  .search-modal .date-picker .DateInput__display-text{
    font-size: 18px;
  }

  .search-modal .DateRangePicker__picker {
    z-index: 1;
    background-color: #fff;
    position: absolute;
    top: 102px;
  }
  .search-modal .DateRangePicker__picker.DateRangePicker__picker--direction-left.DateRangePicker__picker--horizontal{
    max-width: 400px;
    width: 100%;
    border-radius: 3px;
    top: 102px;
  }
  .search-modal .DateInput{
    color: #484848;
  }
  .search-modal .DateInput--with-caret::before {
    top: 90px;
  }
  .search-modal .DateInput--with-caret::after {
    top: 92px;
  }

  .search-modal .counter-field {
    margin-bottom: 20px;
    border: 0px solid;
  }

  .search-modal .google-input-field {
    margin-bottom: 20px;
    border: none;
    height: 70px;
    color: #484848;
  }

  .search-modal .counter-field {
    min-height: 70px;
  }

  .search-modal .mobile-drop-down-wrapper {
      border: none;
      margin-bottom: 20px;
      height: 70px;
  }
  @media (max-width: 760px) {
    .search-modal .mobile-drop-down-wrapper {
      /* height: 100%; */
     /* min-height: 70px;*/
    }
    .search-modal .mobile-drop-down-wrapper ul {
      margin-bottom: 0;
    }
  }
  .search-modal .mobile-drop-down-wrapper .current-option span{
      font-size: 16px;
      color: #484848;
      max-height: 46px;
      overflow: hidden;
      line-height: 22px;
  }
  .search-modal button.blue-large {
      min-height: 100px;
      font-size: 20px;
  }
  .search-modal button.minus{
    order: 1;
    margin-left: 0;
    margin-right: 0px;
    border-left: none;
    border-radius: 3px 0 0 3px;
  }
  .counter-field .input-field-wrapper {
    order: 2;
    border-radius: 0px;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    background-color: white;
  }
  .search-modal .counter-field input[type=text]{
    font-size: 18px;
    color: #484848;
  }

  .search-modal button.plus{
    order: 3;
    border-left: none;
    margin-left: 0px;
    border-radius: 0px 3px 3px 0px;
    color: #484848;
  }
  .counter-field > button{
    width: 70px;
    min-height: 70px;
  }
  .search-bar > *{
    height: 70px!important;
    margin: 0;
  }

}
