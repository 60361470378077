.mobile-redirect-banner-root {
    z-index: 999999;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    padding: 10px 5px !important;
    font-family: 'Quicksand';
}

.mobile-redirect-banner-root .banner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: auto;
    box-shadow: 0 0 6px 1px #00000052;
    background-color: white;
}

.mobile-redirect-banner-root .banner-wrapper .close-button-wrapper {
    margin-top: 4px;
    line-height: 10px;
    text-align: end;
    width: 100%;
}

.mobile-redirect-banner-root .banner-wrapper button, .mobile-redirect-banner-root .banner-wrapper button:focus {
    padding: 0;
    margin: 0;
    margin: 5px 10px 0 0;
    background-color: transparent;
    color: #6666;
    outline: none;
    font-size: 13px;
    font-family: inherit;
}

.mobile-redirect-banner-root .banner-wrapper .message-rating-wrapper {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: inherit;
    padding-bottom: 5px;
}

.mobile-redirect-banner-root .banner-wrapper .message-rating-wrapper>div {
    margin-right: 5vw;
    padding-bottom: 10px;
}

.mobile-redirect-banner-root .banner-wrapper .message-rating-wrapper div>div {
    display: flex;
}

.mobile-redirect-banner-root .banner-wrapper .message-rating-wrapper>img {
    border-radius: 10px;
    margin-right: 2vw;
    padding: 5px;
    border: 1px solid lightgrey;
    width: 45px;
    height: 45px;
}

.mobile-redirect-banner-root .banner-wrapper .message-rating-wrapper div>img {
    margin: 0;
    padding: 0;
    width: 60px;
    height: auto;
    border: none;
}

.mobile-redirect-banner-root .banner-wrapper .message-rating-wrapper p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'quicksand';
    color: black;
    font-weight: 500;
}

.mobile-redirect-banner-root .banner-wrapper .message-rating-wrapper p~p, .mobile-redirect-banner-root .banner-wrapper .message-rating-wrapper div>span {
    color: #666666bf;
    font-size: 12px;
}

.mobile-redirect-banner-root .banner-wrapper .message-rating-wrapper div>span {
    line-height: 11px;
    margin-left: 5px;
}

.mobile-redirect-banner-root .banner-wrapper a, .mobile-redirect-banner-root .banner-wrapper a:hover {
    padding: 5px 10px;
    border-radius: 30px;
    width: fit-content;
    height: fit-content;
    background-color: #2f80ed;
    color: white
}