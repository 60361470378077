@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,600,700,800,900');

* {
    box-sizing: border-box;
    margin: 0;
    outline: none;
    padding: 0;
    text-rendering: geometricPrecision;
    font-family: inherit;
}
span {
  font-family: Quicksand !important;
}
html {
  min-height:100%;
}

body {
  font-family: 'Quicksand', sans-serif ;
  font-size: 13px ;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
}

li {
  display: inline-block;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.input-field-wrapper {
  height: 45px;
}

input[type=text], input[type=password] ,input[type=number]{
  border: solid 1px #d4d4d4;
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
  height: 100%;
  max-height: 100px;
  text-indent: 20px;
  -webkit-appearance: none;
  width: 100%;
  color: #666;
  line-height: 20px;
}

textarea {
  border: 1px solid #d4d4d4;
}

textarea.err, input[type=text].err, input[type=password].err {
  border: solid 1px red;
}

input[type=text]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder {
  font-weight: bold;
  font-size: 16px;
  color: #666;
}

input[type=text]:focus,
input[type=password]:focus {
  border: solid 2px #28b6ff;
}

input[type=text]:disabled {
  background-color: rgba(0,0,0,0.2);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white\0 !important;
  background-color: #FFF;
  border: none;
  color: #666;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 2em 8px 10px;
  text-indent: 10px;
  width: 100%;
}

h1 {
  font-size: 30px;
  font-weight: bold;
  color: #484848;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  font-weight: bold;
  color: #666;
  margin-bottom: 30px;
}

 h3 {
  font-size: 18px;
  font-weight: normal ;
  color: #666;
  margin-bottom: 20px;
}

h4 {
  font-size: 16px;
  font-weight: bold;
  color: #484848;
  margin-bottom: 18px;
}

p {
  font-size: 16px;
  font-weight: normal;
  color: #666;
}

mark {
  font-size: 12px;
  font-weight: bold;
  color: #FFF;
  padding: 5px 15px;
  background: #1487c6;
  display: inline-block;
}

mark.black {
  background-color: #484848;
}

mark.blue {
  background-color: #1388c6;
}

button {
  border: none;
  cursor: pointer;
}
.with-icon {
	display: flex;
	align-items: center;
}
.with-icon::before {
	display: inline-block;
	background-repeat: no-repeat;
	background-size: contain;
	margin-right: 5px;
	content: ' ';
}

button.blue-xs, a.button.blue-xs {
  background-color: #28b6ff;
  height: 36px;
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
}
button.blue-xs {
  width: 112px;
}
a.button.blue-xs {
  display: flex;
  justify-content: center;
  text-align: center;
}
a.button.blue-xs:hover {
  color: white;
}
button.blue-small {
  background-color: #28b6ff;
  height: 48px;
  width: 112px;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
}

button.green-small {
  background-color: #14d068;
  height: 48px;
  width: 112px;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
}

button.blue-medium {
  background-color: #189de2;
  height: 48px;
  width: 170px;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
}
button.long-text {
  padding: 2px;
}
button.green-medium {
  background-color: #14d068;
  height: 48px;
  width: 170px;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
}

button.blue-large {
  background-color: #28b6ff;
  border-radius: 3px;
  height: 52px;
  width: 226px;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
}

button.red-large {
  background-color: #28b6ff;
  border-radius: 3px;
  height: 52px;
  width: 226px;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
}

.separator {
  background-color: #000;
  display: block;
  height: 1px;
  width: 100%;
}

.vertical-separator {
  display: inline-block;
  border-left: 1px dotted #e5e5e5;
  height: 100%;
  width: 1px;
  color: #000;
}

.mobility-icon0 {
  background-image: url('../images/misc/mobility_c0.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.mobility-icon1 {
  background-image: url('../images/misc/mobility_c1.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.mobility-icon2 {
  background-image: url('../images/misc/mobility_c2.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.mobility-icon3 {
  background-image: url('../images/misc/mobility_c3.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

div.grid {
	display: flex;
}

div.six.units {
	flex: 6;
}
.separate {
	display: flex;
	justify-content: space-between;
}
.full-width {
	width: 100%;
}
.flex {
	display: flex;
}
.wrap {
  flex-wrap: wrap;
}
.between {
	justify-content: space-between;
}
hr {
	height: 1px;
	border: none;
	background: #d4d4d4;
	margin: 25px 0;
}
/* .info-icon::after {
	content: " ";
	background: url('../images/misc/information.png') center center no-repeat #1e1e1e;
	color: #fff;
	display: inline-block;
	border-radius: 3px;
	padding: 5px;
	width: 15px;
	text-align: center;
	margin-left: 10px;
	height: 15px;
	line-height: 15px;
} */
.hidden {
	display: none;
}

.add-dropdown-icon::after {
  content: url('../images/misc/arrow.svg');
  position: absolute;
  pointer-events: none;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  bottom: 0;
  right: 10px;
  width: 2em;
  text-align: center;
  z-index:200;
}

.avatar {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 3px;
  background-color: #FFF;
  background-size: cover;
  background-image: url('../images/profile/default-avatar.png');
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 575px) {
   .hidden-xs {
      display: none !important;
    }
	.col-xs-4 {
		flex: 0 0 33.3%;
		max-width: 33.3%;
	}
	.col-xs-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
  .center-h-xs {
    display: flex;
  }
  .center-h-xs > :last-child {
    margin-right: 0;
  }
  .no-padding-xs {
    padding: 0;
  }
}
@media only screen and (min-width: 576px) {
	.hidden-sm-up {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
  .hidden-sm {
    display: none;
  }
    .title-wrapper{
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
    .title-breadcrumb-wrapper{
        flex-direction: column;
    }
}
@media only screen and (min-width: 768px) {
	.hidden-md-up {
		display: none;
	}
}

.title-breadcrumb-wrapper{
    display: flex;
    align-items: center;
}
.title-breadcrumb{
    margin-bottom: 0;
}

/*Search modul update*/
.guests-picker-wrapper{
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    background-color: white;
    vertical-align: top;
    /* width: calc(25% - 3px); */
    width: 100%;
    margin-left: 3px;
    height: 100%;
    display: inline-block;
    position: relative;
}
.guests-picker{
    position: relative;
    cursor: pointer;
    font-weight: 700;
    height: 100%;
    max-height: 100px;
    text-indent: 20px;
    -webkit-appearance: none;
    width: 100%;
    color: #666;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 4px 33px;
}
.guests-picker.guests-picker-mobile {
  flex-direction: row;
  height: 60px;
}
.guests-picker-wrapper.guests-picker-wrapper-mobile {
  margin-top: 10px;
  border: 1px solid #C4C4C4;
  margin-left: 0px;
}
.guests-picker-wrapper.guests-picker-wrapper-mobile > div{
  text-align: center;
}
.guests-picker > div {
  width: 100%;
}
.guests-picker-dropdown-block.guests-picker-dropdown-block-mobile {
  right: 0px;
  top: 65px;
  width: 100%;
}
.guests-picker-dropdown-block{
  background-color: white;
  border-radius: 3px;
  position: absolute;
  width: 320px;
  z-index: 999;
  right: 4px;
  top: 67px;
  border: solid 2px #28b6ff;
  font-size: inherit;
  font-weight: 600;
}
.guests-button{
  background-color: white;
  padding: 2px 10px;
  border: solid 2px #28b6ff;
  border-radius: 1px;
  color: #28b6ff;
}
.guests-picker-counter-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
}
.guests-picker-button-wrapper{
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drpdwn-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 92%;
  margin: auto;
}
.drpdwn-wrapper > .select-dropdown{
  margin: 10px 1.2%;
}
.child-age-drpdwn{
  padding: 0;
  width: 117px;
}
.drpdwn-wrapper .select-dropdown::after {
  content: '';
  background-image: url("../images/icons/guests-down-arrow.png");
  position: absolute;
  height: 11px;
  width: 13px;
  top: 17px;
  left: 90%;
  background-size: contain;
  background-repeat: no-repeat;
}
.drpdwn-wrapper .select-dropdown::before {
  content: '';
  background-image: url("../images/icons/guests-up-arrow.png");
  position: absolute;
  height: 11px;
  width: 13px;
  top: 7px;
  left: 90%;
  background-repeat: no-repeat;
  background-size: contain;
}
@media only screen and (max-width: 1000px) {
  .drpdwn-wrapper .select-dropdown::after, .drpdwn-wrapper .select-dropdown::before {
    display: none;
  }
}
.hide{
  display: none;
}
.visuallyhidden { 
  position: absolute; 
  overflow: hidden; 
  clip: rect(0 0 0 0); 
  height: 1px; width: 1px; 
  margin: -1px; padding: 0; border: 0; 
}
.guests-picker::before{
  content:"";
  position: absolute;
  height: 24px;
  width: 24px;
  opacity: 0.5;
  background-image: url("../images/icons/guest-picker-icon.svg");
  left: 7px;
}
.DateInput .DateInput_input{
  border: none;
}
.DateRangePickerInput_arrow{
  display: none
}
.g-input{
  padding-left: 15px;
}

.search .google-input-field::before{
  content:"";
  position: absolute;
  height: 24px;
  width: 24px;
  opacity: 0.5;
  background-image: url("../images/icons/place-icon.svg");
  left: 25px;
  top: 31px;
}

.focus-class{
  border: solid 2px #28b6ff !important;
}
.drpdwn-wrapper .select-dropdown{
  border: solid 2px #28b6ff;
  border-radius: 1px;
  width: 43%;
  height: 40px;
}
.drpdwn-wrapper .select-dropdown > select{
  width: 100%;
  font-size: 12px;
}
.guests-summarize {
  display: block;
  text-align: center;
  border-top: 2px solid gray;
  margin: 10px 20px;
  color: gray;
}

@media (max-width: 970px) {
  .input-field-wrapper {
    width: 100%;
  }
}
.feefo-logo-block{
  background-image: url(../images/icons/feefo-logo.png);
  width: 193px;
  height: 46px;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 1140px) {
  .home-page__top-title{
    flex-direction: column;
  }
}
.home-page__top-section-1{
  background: #b3dff9 url(../images/home-page/bg-1.jpg) no-repeat
  center center;
}
.home-page__top-section-2{
  background: #b3dff9 url(../images/home-page/bg-2.jpeg) no-repeat
  center center;
}
.home-page__top-section-3{
  background: #b3dff9 url(../images/home-page/bg-3.jpg) no-repeat
  center center;
}
.home-page__top-section-4{
  background: #b3dff9 url(../images/home-page/bg-4.jpg) no-repeat
  center center;
}
.home-page__top-section-5{
  background: #b3dff9 url(../images/home-page/bg-5.jpg) no-repeat
  center center;
}
.home-page__top-section-6{
  background: #b3dff9 url(../images/home-page/bg-6.jpg) no-repeat
  center center;
}
.home-page__top-section-7{
  background: #b3dff9 url(../images/home-page/bg-7.jpg) no-repeat
  center center;
}
button.red-xs, a.button.red-xs {
  background-color: #ff7059;
  height: 36px;
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
}
.search-single-property .spinner-overlay{
  position: absolute;
}
@media screen and (min-width: 550px) {
  .header-contact strong{
    display: inline;
  }
}
