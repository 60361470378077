@import url("./information-icons.css");
@import url("./accessibility-box.css");
@import url("./image-grid.css");
@import url("./booking-widget.css");
@import url("./property-enquiry-modal.css");
@import url("./information-table.css");
@import url("./single-property-perks.css");
@import url("./puts-section.css");
@import url("./contact-host-modal.css");
@import url("./title-section.css");
@import url("./about-host-section.css");

.main-layout-content > .property-page {
  padding: 0;
}

.property-page .with-icon > * {
  margin: 0 6px 15px 0;
}

.property-page .with-icon img {
  width: 16px;
}

.property-page {
  width: 100%;
  padding-top: 0;
}

.property-page > .header {
  position: relative;
}

.property-page .internal-link-menu-wrapper {
  border-bottom: 1px solid #d4d4d4;
  background-color: #fafafa;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 10;
}

.property-page span.label {
  background: url("../../images/object-page/yellow-badge.png") #ffa915 repeat-x;
  display: inline-block;
  border-radius: 4px;
  color: #fff;
  padding: 5px 12px;
  font-size: 16px;
  font-weight: 700;
  margin: 0 10px;
}

.property-page span.label.label-info {
  background: url("../../images/object-page/blue-badge.png") #3caeea repeat-x;
}

.property-page span.label.label-success {
  background: #4fc33c;
}

.property-page div.rating a {
  color: #ffa200;
  font-size: 10px;
}

.property-page .favorite a.button {
  display: flex;
  align-items: center;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 5px 12px;
  text-align: center;
  background: white;
  color: #484848;
  margin-right: 0;
}

.property-page .favorite a.button::before {
  display: inline-block;
  width: 28px;
  height: 24px;
  background: url("../../images/object-page/heart.png") no-repeat;
  content: " ";
  margin-right: 5px;
}

.property-page .internal-link-menu-wrapper {
  border-bottom: 1px solid #d4d4d4;
  background-color: #fafafa;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 10;
}

.property-page .internal-link-menu-wrapper .menu {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.property-page .internal-link-menu {
  font-size: 12px;
  border-left: 1px solid #d4d4d4;
  display: flex;
  position: relative;
  margin-left: 20px;
}

.property-page .internal-link-menu a {
  padding: 16px 25px;
  border-right: 1px solid #d4d4d4;
  font-weight: 600;
  color: #189ce2;
  line-height: 14px;
}

.property-page .internal-link-menu a:hover {
  background-color: white;
}

.property-page .social-links {
  display: flex;
}
.property-page .social-links a {
  width: 31px;
  height: 31px;
  display: block;
  position: relative;
  overflow: hidden;
}
.property-page .social-links a.facebook {
  background-image: url("../../images/object-page/social/facebook.png");
}
.property-page .social-links a.twitter {
  background-image: url("../../images/object-page/social/twitter.png");
}
.property-page .social-links a.mail {
  background-image: url("../../images/object-page/social/mail.png");
}
.property-page .social-links a.others {
  background-image: url("../../images/object-page/social/others.png");
}
.property-page .social-links a span {
  position: absolute;
  top: -100px;
  left: -100px;
}

.property-page .top-images {
  display: flex;
  justify-content: center;
  height: 270px;
  width: 100%;
  align-content: stretch;
  background-color: white;
  overflow: hidden;
  position: relative;
}

/* .property-page .top-images > * {
  background-size: cover;
  background-position: center;
  height: 270px;
  flex: 1;
  background-color: gray;
} */

.property-page .top-images > img {
  object-fit: cover;
  object-position: center;
  height: 270px;
  flex: 1;
  background-color: gray;
}

.property-page .top-images > button {
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 150px;
  height: 45px;
  background-color: #28b6ff;
  padding: 0;
}

.property-page .top-images > *:nth-child(2) {
  width: 150px;
  background-color: #ff7059;
  padding: 0;
  margin: 0 10px;
  min-width: 480px;
}

.property-page .property-main {
  width: 100%;
  padding-top: 20px;
  background-color: #fafafa;
}
.property-page .property-main.primary {
  padding-top: 0;
}
.property-page .property-main.reviews {
  background: white;
  border-top: 1px solid #d4d4d4;
}
.property-page .property-main.host {
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 20px;
}

.property-page .property-main .center {
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  padding: 0 20px;
}

.property-page .property-main h2 {
  color: #484848;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 22px;
}

.property-page .property-main .center div.rating {
  flex: 1;
}

.property-page .property-main h3 {
  font-size: 22px;
  color: #666;
  margin-bottom: 20px;
  font-weight: 700;
}

.property-page .image-grid-section {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.property-page h3#accessibility {
  color: #1e1e1e;
}
.property-page .property-main .center .right-side {
  margin-left: 20px;
  flex: 1;
}

.property-page .right-side-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 12;
  margin-bottom: 37px;
}

.property-page .property-main .center .left-side {
  flex: 2.2;
  margin-top: 44px;
}
.property-page .left-side .about-section-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 767px) {
.property-page .left-side .about-section-details {
  flex-direction: column;
}
}

.property-page .left-side .about-section-header {
  padding: 0 50px 15px 0;
}

.property-page .single-property-page-title {
  font-size: 30px;
  color: #484848;
  font-weight: bold;
}

.property-page .position-fixed-class {
  top: 0;
  position: fixed;
  z-index: 10;
}

.property-page .accessibility-section {
  margin-top: 0;
  padding-top: 0;
}

/* Contact Host widget */
.property-page .single-property-contact-host {
  height: 114px;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  margin-top: 10px;
  padding: 20px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.single-property-contact-host .contact-host-image {
  height: 70px;
  min-width: 70px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  margin-right: 10px;
  background: url("../../images/profile/default-avatar.png") no-repeat;
  background-size: cover;
  background-position: center;
}

.single-property-contact-host .contact-host-button {
  height: 70px;
  width: 100%;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  background-color: #484848;
  color: #fff;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.single-property-contact-host .contact-host-button::before {
  display: inline-block;
  height: 28px;
  width: 28px;
  content: " ";
  margin-right: 10px;
  background: url("../../images/object-page/contact-host.png") no-repeat;
}

.property-page .selected {
  background-color: white;
  border-right: 1px solid #d4d4d4;
  border-left: 1px solid #d4d4d4;
}

.property-page .description-about-property {
  max-height: 190px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}
.property-page .description-about-property.show-all {
  max-height: none;
}
.property-page .description-about-property div.action {
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: white;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  margin-bottom: 20px;
}
.property-page .description-about-property div.main {
  flex: 1;
  overflow: hidden;
  position: relative;
  padding-bottom: 0;
}
.property-page .description-about-property.expandable div.main::after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fafafa+0,fafafa+100&0+0,1+100 */
  background: -moz-linear-gradient(
    top,
    rgba(250, 250, 250, 0) 0%,
    rgba(250, 250, 250, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(250, 250, 250, 0) 0%,
    rgba(250, 250, 250, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(250, 250, 250, 0) 0%,
    rgba(250, 250, 250, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fafafa', endColorstr='#fafafa',GradientType=0 ); /* IE6-9 */
  content: " ";
}
.property-page .description-about-property.expandable.show-all div.main::after {
  display: none;
}
.property-page .description-about-property p {
  font-size: 16px;
  color: #484848;
  font-weight: normal;
  margin-bottom: 20px;
  word-break: break-word;
}
.property-page .description-about-property button {
  padding: 5px 10px;
  background-color: white;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  color: #484848;
  align-self: center;
  margin-bottom: 20px;
  min-height: 34px;
  z-index: 1;
}
.description-about-property.show-all .expand {
  outline: none;
}
.property-page #ui-map {
  width: 100%;
  height: 300px;
  display: block;
  z-index: 5;
}

/* .property-page .anchor::before {
  display: block;
  content: " ";
  margin-top: -70px;
  height: 70px;
  visibility: hidden;
  z-index: -1;
} */

/* Reviews */
/* .property-page ul.reviews-list li {
	display: flex;
	position: relative;
	margin-bottom: 20px;
}
.property-page ul.reviews-list li::after {
	position: absolute;
	top: 100%;
	right: 0;
	left: 260px;
	content: ' ';
	display: block;
	height: 1px;
	background: #d4d4d4;
}
.property-page ul.reviews-list div.user {
	text-align: center;
	margin-left: 40px;
	margin-right: 80px;
	width: 80px;
}
.property-page ul.reviews-list li div.avatar-wrap {
	position: relative;
}

.property-page ul.reviews-list li img.avatar {
	width: 80px;
	height: auto;
	border-radius: 3px;
}
.property-page ul.reviews-list li div.avatar-wrap::after {
	position: absolute;
	top:0;
	right: 0;
	bottom: 0;
	left: 0;
	content: ' ';
	box-shadow: inset 0 0 2px rgba(0,0,0,.5);
	border-radius: 3px;
}
.property-page button.helpful {
	border: 1px solid #d4d4d4;
	background: #fff;
	color: #333;
	padding: 5px 10px;
	font-size: 12px;
	display: flex;
	align-items: center;
	margin-right: 20px;
	margin-bottom: 20px;
}
.property-page button.helpful::before {
	display: inline-block;
	width: 13px;
	height: 12px;
	background: url('../../images/object-page/thumbs-up.png') no-repeat;
	margin-right: 5px;
	content: ' ';
}
.property-page ul.reviews-list p.review-text {
	font-size: 12px;
	margin-bottom: 2em;
} */

/* Related */
/* .property-main.related .search-list-properties {
	padding: 0;
	margin-left: -20px;
	justify-content: flex-start;
}
.property-main.related .search-list-properties li {
	width: 25%;
}
.property-main.related .search-single-property {
	padding: 0 0px 0 20px;
	width: auto;
}
.property-main.related .search-single-property .info {
	align-items: flex-start;
}
.property-main.related .search-single-property h4 {
	flex: 1;
} */
/* Booking button mobile */
.property-page .booking-toggle {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  padding: 10px;
  text-align: center;
  z-index: 10;
}

.property-page .booking-toggle p {
  margin: 0.5em 0 0 0;
}

.property-page .booking-toggle button {
  width: 60%;
  padding: 15px;
}

.property-page .mrp-main > section > * {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.property-page .booking-toggle p {
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px;
  line-height: 18px;
}
@media (max-width: 767px) {
  .property-page.show-booking {
    overflow: hidden;
  }
  .property-page.show-booking .right-side {
    display: block;
  }

  .internal-link-menu {
    width: 100%;
    margin-left: 0px;
  }
  .internal-link-menu a {
    width: 25%;
    text-align: center;
    padding: 20px 12px;
  }
  /* Good night left side */
  .show-booking .left-side,
  .show-booking #reviews,
  .show-booking #host,
  .show-booking #map,
  .show-booking section.related,
  .show-booking .booking-toggle {
    display: none;
  }
  .property-page .right-side-sticky {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 1010;
  }
  .property-page .top-images > *:nth-child(2){
    min-width: unset;
  }
}

@media (max-width: 575px) {
  .single-property-about-host {
    flex-wrap: wrap;
  }
  .about-host-picture-container {
    margin-bottom: 10px;
  }
  .property-page .internal-link-menu a {
    padding: 20px 12px;
  }
  .property-page .internal-link-menu {
    margin-left: 0px;
  }
  /* .property-page .top-images > * {
		display: none
	}
	.property-page .top-images > *:nth-child(2) {
	  margin: 0;
	  width: 100vw;
	  display: block;
	} */
}

.total-col .best-price {
  height: 100px;
  margin: auto;
  display: block;
  margin-top: 20px;
}
.cards .best-price{
  height: 100px;
  margin: auto;
  display: block;
}
