.personal-profile-page {
  display: flex;
  margin: 0 auto;
  width: 80%;
  min-height: 80%;
}

.personal-profile-picture {
  width: 225px;
  height: 225px;
  /*background-image: url('../images/vincentPic.jpg');*/
  background-image: url('../images/profile/default-avatar.png');
  background-size: 100%;
  margin-bottom: 20px;
}

.profile {
  width: 100%;
}

.personal-profile-dashboard {
  width: 225px;
  margin-right: 20px;
}

.personal-profile-dashboard .dashboard-custom-box li {
  display: block;
}

.personal-profile-page .dashboard-custom-box {
  margin-bottom: 20px;
}


.personal-profile-property {
  display: block;
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
  padding: 10px;
}

.personal-profile-property div {
  display: flex;
  flex-direction: row;
}
.personal-profile-property div img {
  width: 100px;
}

.personal-profile-property-headers {
  display: block !important;
  margin-left: 20px;
  font-size: 14px;

}

.review {
  display: flex;
  font-size: 12px;
}

.review div {
  margin-right: 20px;
}

.review-text * {
  margin-bottom: 10px;
}

.review-text span * {
  display: inline;
}

.review-li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid grey;
}



.personal-profile-page h1,
.personal-profile-page h2,
.personal-profile-page h3 {
  font-weight: lighter;
}
