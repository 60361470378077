@import url("./about-page.css");
@import url("./defaults.css");
@import url("./select-dropdown.css");
@import url("./mobile-dropdown.css");
@import url("./date-picker.css");
@import url("./modal.css");
@import url("./select-dropdown-checkbox.css");
@import url("./signup-login-modal.css");
@import url("./header.css");
@import url("./footer.css");
@import url("./counter-field.css");
@import url("./mrp-form.css");
@import url("./basic-property-info-form.css");
@import url("./put-seasons-form.css");
@import url("./address-form.css");
@import url("./booking-table.css");
@import url("./search-modal.css");
@import url("./search-filter-modal.css");
@import url("./coupon-input-field.css");
@import url("./google-input-field.css");
@import url("./community-stories/community-stories.css");
@import url("./article.css");
@import url("./topic.css");
@import url("./slider.css");
@import url("./blog-post.css");
@import url("https://blog.handiscover.com/content/wp-content/plugins/contact-form-7/includes/css/styles.css");
/* @Import url("https://blog.handiscover.com/content/wp-content/themes/herald/assets/css/min.css"); */
/* @import url("./wordpress-css-duplicated/min.css"); */
@import url("./wordpress-css-duplicated/styles.css");

@import url("./landing-page.css");
@import url("./home-page.css");
@import url("./landings-index.css");
@import url("./landings-apartments-index.css");
@import url("./landings-bb-index.css");
@import url("./landings-hotels-index.css");
@import url("./dashboard-page.css");
@import url("./dashboard-custom-box.css");
@import url("./dashboard-settings-layout.css");
@import url("./host-profile-page.css");
@import url("./confirmations-page.css");
@import url("./image-upload-box.css");
@import url("./list-your-property/list-your-property-page.css");
@import url("./list-your-property/put-seasons-page.css");
@import url("./list-your-property/put-accessibility-page.css");
@import url("./list-your-property/put-availability-calendar-page.css");
@import url("./list-your-property/overview-srp-page.css");
@import url("./terms-page.css");
@import url("./payment-page.css");
@import url("./single-property-page/contact-host-modal.css");
@import url("./dashboard-payout-methods/payout-preferences-page.css");
@import url("./dashboard-payout-methods/payout-method-modal.css");

/* Dashboard page */
@import url("./edit-profile-page.css");
@import url("./personal-profile.css");
@import url("./dashboard-properties-page.css");
@import url("./booking-page.css");
@import url("./notifications-page.css");
@import url("./inbox-page.css");
@import url("./inbox-conversation.css");
@import url("./requirements-page.css");
@import url("./dashboard-channel-manager.css");
@import url("./dashboard-payments-page.css");
@import url("./settings-page.css");
@import url("./security-page.css");
@import url("./payment-methods-page.css");
@import url("./transactions-page.css");
@import url("./id-verification-page.css");
@import url("./profile-photos-page.css");
@import url("./handle-channel-managers-modal.css");
@import url("./handle-channel-managers-page.css");

/* Blog page */
@import url("./blog/single-blog-page.css");
@import url("./blog/blog-login-form.css");
@import url("./blog/blog-loader.css");

/* Page not found */
@import url("./page-not-found/page-not-found.css");

@import url("./pagination.css");
@import url("./search-properties.css");
@import url("./blog-post-widget-group/blog-post-widget-group.css");
@import url("./blog-post-widget-group/object-page-widget.css");
@import url("./blog-post-widget-group/blog-page-widget.css");
@import url("./rc-slider.css");

@import url("./single-property-page/single-property-page.css");
@import url("./single-property-page/mrp-page.css");
@import url("./single-property-page/tripadvisor.css");
@import url("./single-property-page/booking-modal.css");
@import url("./single-property-page/live-price-booking-modal.css");

@import url("./similar-properies-block.css");
@import url("./tooltip.css");
@import url("./booking-timer.css");
@import url("./how-it-works.css");
@import url("./search-properties-item-horizontal.css");
@import url("./become-host.css");
@import url("./booking-modal-srp.css");
@import url("./booking-checkout-page.css");
@import url("./banner.css");
@import url("./mrp-hotel-dasboard.css");
@import url("./user-disability-data/user-disability-data.css");
@import url("./user-profile-page/user-profile-page.css");

@import url('./menu-bar/menu-bar.css');
@import url('./menu-bar/auto-complete.css');

@import url('./mobile-search-box/mobile-search-box.css');
@import url('./blog/book-login-form.css');

@import url('./usp-pages.css');
@import url('./property-log.css');
@import url('./webinar-pop-up.css');
@import url("./signup-page.css");
@import url("./gdpr-page.css");
@import url('./protect-refund.css');
@import url('./lys-new-section.css');
@import url('./signup-landing-page.css');

@import url('./mobile-redirection-banner.css');
client-src/css/mrp-hotel-dasboard.css


.main {
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 160px;
}

.main-content {
  margin: 0 auto;
  max-width: 600px;
}

.main-layout-content > * {
  padding-top: 60px;
}
.visible-banner + .main-layout-content > * {
  /* padding-top: 100px; */
}
.policy-not-accepted + .main-layout-content > * {
  padding-top: 115px;
}
.visible-banner.policy-not-accepted + .main-layout-content > *{
  /* padding-top:160px; */
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay,
.ReactModal__ {
  z-index: 999998;
  display: flex;
  justify-content: center !important;
  align-items: center;
}

.ReactModal__Content {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100vw;
  max-height: 100vh;
}

/*zendesk widget*/
#launcher {
  right: 30px !important;
  bottom: 45px !important;
}

.DateRangePicker_picker__fullScreenPortal {
  z-index: 999999;
}

.no-overflow {
  overflow: hidden;
}

@media (max-width: 767px) {
  .ReactModal__Content {
    border-radius: 0 !important;
  }
}

.error-text {
  color: #ff2222;
  font-size: 16px;
  font-weight: bold;
}
