.header {
  background-color: #FFF;
  box-shadow: 0 0 1px #000;
  left: 0;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
}
.header-with-banner{
  background-color: #FFF;
  box-shadow: 0 0 1px #000;
  left: 0;
  position: fixed;
  top: 20px;
  width: 100%;
  z-index: 1000;
}
.header .inner {
  display: flex;
  justify-content: space-between;
  background: white;
  height: 60px;
  position: relative;
}
.header .vertical-separator {
	height: auto;
}
.vertical-separator {
  display: inline-block;
  border-left: 1px solid #afafaf;
  height: 100%;
  width: 1px;
  color: #484848;
}
.header .logo-link {
	display: flex;
	padding-left: 20px;
	padding-right: 20px;
	align-items: center;
  width: 100%;
  background-color: white;
  z-index: 1000;
}
.header .logo {
  background-image: url('../images/misc/handiscover_logo_text@2x.png');
  background-size: 100%;
  display: inline-block;
  height: 36px;
  width: 170px;
}

.header .right {
  display: flex;
  background-color: white;
  z-index: 1000;
}

.header .right > * {
  vertical-align: middle;
}

.header .right a {
  color: #484848;
  font-size: 16px;
  font-weight: 600;
  height: 60px;
  padding: 0 20px;
  text-align: center;
  display:inline-flex;
  align-items:center;
  white-space: nowrap;
}
.header .header-join-us{
  color: #2574A9 !important;
}

.header .right a:hover {
  color: rgba(89, 89, 89, 0.75);
}

.header .right .avatar {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #FFF;
  background-size: cover;
  background-image: url('../images/profile/default-avatar.png');
  background-repeat: no-repeat;
  background-position: center;
  margin:0;
}
.cookie-bar {
  border-bottom: 1px solid #afafaf;
  background: #F3F3F3;
  font-size: 18px;
}
.cookie-bar p {
  font-size: 1em;
  margin: 0;
}
.cookie-bar a.link {
  color: #007bff;
}
.cookie-bar a.link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.cookie-bar button {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  color: inherit;
}
.cookie-bar .inner {
  max-width: 1040px;
  margin: 0 auto;
  background: #F3F3F3;
}
@media(max-width: 1040px) {
  .cookie-bar p {
    margin: 0 10px;
  }
}
@media(max-width: 690px) {
  .cookie-bar {
    font-size: 1.1em;
  }   
}
@media(max-width: 575px) {
	.header .logo {
		width: 28px;
  } 
  .cookie-bar {
    font-size: 1em;
  }  
}


@media only screen and (max-width: 61.938em) {
  .header .logo {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media (min-width: 200px) and (max-width: 600px) {
  .header .logo {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media (min-width: 200px) and (max-width: 600px) {
  .header .logo {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

/**************************************MENU*************************************/

.drop_menu{
  display: flex;
  position: relative;
  color: #484848;
  font-size: 13px;
  font-weight: 700;
  height: 60px;
  align-items: center;
  width: 100px;
  padding-left: 11%;
}
.drop-down-close {
  width: 100%;
  position: absolute;
  top: -1300%;
  transition: 0.7s;
  background: white;
}
.drop-down_list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow:auto;
  max-height: 55vh;
}
.drop-down-open {
  top: 100%;
  padding-left: 2%;
  padding-right: 2%;

}
.drop-down__item{
  margin-bottom:0px;
  display: list-item;
  border-bottom: 1px solid #afafaf;
  padding-top: 10px;
  padding-bottom: 5px;
  height: 44px;
  transition: 0.7s;
  padding-left: 1.4%;
}
.drop-down__item-link{
  display: block;
  cursor: pointer;
  color: #484848 !important;
  font-size: 13px;
  font-weight: 700;
  min-height: 44px;
}
.drop-down__item:hover{
  background-color: #e9e8e8;
  padding-left: 2.4%;
  box-shadow: -2px 3px 10px 0px rgba(0, 0, 0, 0.22);
}
@media(max-width: 1024px) {
  .display-none-modif{
    display: none !important;
  }
  .header .inner p{
    font-size : 12px;
  }
}

@media(min-width: 1025px) {
  .drop_menu{
    display: none;
  }
}

.drop_menu:after{
  content: "";
  position: absolute;
  background-image: url(../images/icons/down-arrow.png);
  top: 42.1%;
  left: 70.7%;
  width: 16px;
  height: 16px;
  transition: 0.5s;
}
.drop_menu_back:after{
  transform: rotate(180deg);
}
