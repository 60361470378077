.landing-page {
  background-color: #FFF;
}

.landing-page section {
  padding: 1px 0 80px;
  position: relative;
  text-align: center;
}

.landing-page section div > h2 {
  color: #202020;
  font-size: 32px;
  margin-top: 79px;
}

.landing-page section div > h3 {
  color: #b2b2b2;
  font-size: 18px;
  margin: 6px 0 46px;
}

.landing-page section:nth-child(2n+1) {
  background-color: #4bb6f1;
}

.landing-page section:nth-child(2n+1) div > h2,
.landing-page section:nth-child(2n+1) div > h3 {
  color: #FFF;
}

.landing-page section .info-cards {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
}
[class^="box-"] {
  height: 100%;
  background: red;
}
.landing-page section.mobility-section div .info-card-box .info-card {
  flex-grow: 1;
  background-color: #FFF;
  border-radius: 3px;
  padding: 30px 30px 20px;
  margin-top: 10px;
  text-align: left;
  max-height: 350px;
  height: 100%;
}
.landing-page .mobile-drop-down-wrapper .current-option span {
  max-height: 80%;
  overflow: hidden;
}

.landing-page section  div .info-card-box .info-card .image {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 64px;
  margin: 0 auto;
  width: 64px;
}


.landing-page section div .info-card-box .info-card h3 {
  margin: 20px 0 10px;
  color: #666;
}

.landing-page section div .info-card-box .info-card h4 {
  font-size: 14px;
  line-height: 22px;
  margin-top: 44px;
}

.landing-page section  div  .info-card-box .info-card p {
  margin-top: 9px;
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.landing-page .carousel {
  height: 155px;
  width: 100%;
}

.landing-page .carousel .dot button {
  background-color: #b3d4fc;
  border-radius: 7.5px;
  height: 15px;
  margin: 30px 3px;
  opacity: 0.4;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 15px;
  padding:0;
}
.landing-page .carousel .slick-list {
  height: 100%;
}

.landing-page .carousel  .slick-list .slick-track {
  height: 100%;
}
.slick-list .slick-track{
  transition: -webkit-transform 500ms cubic-bezier(0.21, 0.01, 0.76, 0.99) 0s !important;
}
.landing-page .carousel .dot .slick-active button {
  opacity: 1;
}

.landing-page .tile > a {
  /*For sliders*/
  border-radius: 3px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  background-color: #FFF;
  display: block;
  height: 100%;
  margin: 0 auto;
  width: 100%;
}

.tile .the-guardian {
  background-image: url('../images/landing-page/featured-in/theguardian.png');
}

.tile .motherboard {
  background-image: url('../images/landing-page/featured-in/motherboard.png');
}

.tile .svd-naringsliv {
  background-image: url('../images/landing-page/featured-in/svd-naringsliv.png');
}

.tile .tech-crunch {
  background-image: url('../images/landing-page/featured-in/techcrunch.png');
}

.tile .di-digital {
  background-image: url('../images/landing-page/featured-in/di-digital.png');
}

.tile .vagabond {
  background-image: url('../images/landing-page/featured-in/vagabond.png');
}

.tile .breakit {
  background-image: url('../images/landing-page/featured-in/breakit.png');
}

.tile .mittfunktionshinder {
  background-image: url('../images/landing-page/featured-in/mittfunktionshinder.png');
}

.tile .news55 {
  background-image: url('../images/landing-page/featured-in/news55.png');
}

.tile .driva-eget {
  background-image: url('../images/landing-page/featured-in/driva-eget.png');
}

.tile .neuro-forbundet {
  background-image: url('../images/landing-page/featured-in/neuro-forbundet.png');
}

.tile .sprf {
  background-image: url('../images/landing-page/featured-in/sprf.png');
}

.tile .funktionshinderpolitik {
  background-image: url('../images/landing-page/featured-in/funktionshinderpolitik.png');
}

.tile .lokaltidningen-bastad {
  background-image: url('../images/landing-page/featured-in/lokaltidningen-bastad-laholm.png');
}

.tile .blodcancerforbundet {
  background-image: url('../images/landing-page/featured-in/blodcancerforbundet.png');
}

.tile .hd {
  background-image: url('../images/landing-page/featured-in/helsingborg-dagblad.png');
}

.tile .va {
  background-image: url('../images/landing-page/featured-in/veckans-affarer.png');
}

.tile .dhr {
  background-image: url('../images/landing-page/featured-in/dhr.png');
}

.tile .enat {
  background-image: url('../images/landing-page/partners/enat.png');
}

.tile .impacthub {
  background-image: url('../images/landing-page/partners/impacthub.png');
}

.tile .mapillary {
  background-image: url('../images/landing-page/partners/mapillary.png');
}

.tile .stripe {
  background-image: url('../images/landing-page/partners/stripe.png');
}

.tile .wheelmap {
  background-image: url('../images/landing-page/partners/wheelmap.png');
}

.tile .the-service-corporation {
  background-image: url('../images/landing-page/partners/the-service-corporation.png');
}

.tile .impact-invest {
  background-image: url('../images/landing-page/partners/impact-invest.png');
}

.tile .unborn-creations {
  background-image: url('../images/landing-page/partners/unborncreations.png');
}

.tile .pantou {
  background-image: url('../images/landing-page/partners/pantou.png');
}

/*.landing-page .cookie-notification {
  background-color: rgba(0,0,0,0.7);
  bottom: 0;
  color: #FFF;
  height: 50px;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 10;
}*/

/*.landing-page .cookie-notification > * {
  display: inline-block;
}

.landing-page .cookie-notification p {
  font-weight: 600;
  line-height: 50px;
}

.landing-page .cookie-notification a {
  color: #49B6F4;
}

.landing-page .cookie-notification a:hover {
  opacity: 0.8;
}

.landing-page .cookie-notification a.close {
  color: #FFF;
  font-weight: 600;
  margin-left: 5px;
  padding: 10px;
}*/

.landing-page .need-help {
  position: absolute;
  right: 10px;
  text-align: right;
  top: 10px;
}

.landing-page .need-help .need {
  color: #FFF;
  font-size: 17px;
  font-weight: 600;
}

.landing-page .need-help .call,
.landing-page .need-help a {
  color: #FFF;
  font-size: 17px;
}

.landing-page .need-help .call {
  font-weight: 500;
}

.landing-page .need-help a {
  font-weight: 600;
}

.landing-page .need-help a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.landing-page .top-section {
  background-image: url('../images/landing-page/paradise-island-and-beach-accessible.jpg');
  background-size: cover;
  background-position: center;
  padding-top: 1px;
  padding-bottom: 140px;
  z-index: 1;
}

.landings-page-top-section .header-contact {
  position: absolute;
  top: 10%;
  right: 10%;
  z-index: 20;
  padding: 10px;
  color: #fff;
  font-size: 1.3em;
  text-align: right;
  font-family: Helvetica, Arial, sans-serif;
  background: rgba(30, 30, 30, 0.5);
  border-radius: 5px;
}
.landings-page-top-section .header-contact a {
  color: #fff;
}
.landings-page-top-section .header-contact .phone {
  color: white;
}

.landing-page .top-section .titles {
  margin: 139px auto 0 auto;
  max-width: 1200px;
  text-align: left;
  padding: 0 30px;
}

.landing-page .top-section .titles span {
  border-radius: 6px;
  color: #FFF;
  text-shadow: 1px 0px 17px rgba(0,0,0,0.5);
}

.landing-page .top-section .titles h2 {
  letter-spacing: 1px;
  line-height: 65px;
  font-size: 46px;
  font-weight: bold;
  margin-bottom: 0;
  max-width: 90%;
  padding-bottom: 10px;

}

.landing-page .top-section .titles h4 {
  height: 30px;
  letter-spacing: 1px;
  line-height: 25px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
  max-width: 90%;
}

.landing-page .top-section .titles .property-types {
  font-size: 16px;
  height: 52px;
  line-height: 52px;
  margin: 5px auto 0 auto;
  width: 390px;
}

.landing-page .top-section .titles a {
  background-color: rgba(70,185,255,0.94);
  border-radius: 6px;
  color: #FFF;
  display: block;
  font-size: 14px;
  font-weight: 600;
  height: 60px;
  line-height: 22px;
  margin: 50px auto 0;
  width: 190px;
  padding: 20px 40px;
}

.landing-page .top-section .titles a:hover {
  background-color: rgba(179, 212, 252, 0.94);
}

.landing-page .top-section .search-wrapper {
  margin-top: 60px;
  padding: 20px 0;
  text-align: center;
}

.landing-page .top-section .search {
  background-color: #1e1e1e;
  box-shadow: 0 3px 30px rgba(0,0,0,0.2);
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  align-content: stretch;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
}

.landing-page .top-section .search > * {
  border-radius: 3px;
  border: none;
  display: inline-block;
  height: 66px;
  margin-left: 4px;
  min-width: 15%;
  vertical-align: middle;
  flex: 2;
}

.landing-page .top-section .search input[type=text] {
  border:none;
}

.landing-page .top-section .search .date-picker {
  flex: 2;
}

.landing-page .top-section .search > button {
  background-color: #ff7059;
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
  flex: 1;
}

.landing-page .top-section .search > button:hover {
  background-color: #ff8470;
}

.landing-page section h2 {
  font-size: 32px;
  margin-bottom: 0;
}

.landing-page .cities-section .cities {
  margin: 30px auto 0;
  max-width: 1200px;
}

.landing-page .cities-section .cities .city-image > * {
  display: flex;
  justify-content: center;
}
.landing-page .cities-section .cities-header {
  margin-top: 80px;
}
.landing-page .cities-section .cities .city-image {
  padding: 5px;
}

.landing-page .cities-section .cities .city-image a {
  background-position: center center;
  border-radius: 3px;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.2);
  color: #FFF;
  font-size: 24px;
  font-weight: 600;
  height: 20vw;
  line-height: 20vw;
  text-transform: uppercase;
}

.landing-page .cities-section .cities .city-image a:hover {
  background-blend-mode: hard-light;
}

.landing-page .cities-section .cities .city-image .paris {
  background-image: url('../images/landing-page/places/paris.jpg');
}

.landing-page .cities-section .cities .city-image .normandie {
  background-image: url('../images/landing-page/places/normandie.jpg');
}

.landing-page .cities-section .cities .city-image .mallorca {
  background-image: url('../images/landing-page/places/mallorca.jpg');
}

.landing-page .cities-section .cities .city-image .croatia {
  background-image: url('../images/landing-page/places/croatia.jpg');
}

.landing-page .cities-section .cities .city-image .barcelona {
  background-image: url('../images/landing-page/places/barcelona.jpg');
}

.landing-page .cities-section .cities .city-image .london {
  background-image: url('../images/landing-page/places/london.jpg');
}

.landing-page .mobility-section .image.c1 {
  background-image: url('../images/misc/mobility_c1.png');
}

.landing-page .mobility-section .image.c2 {
  background-image: url('../images/misc/mobility_c2.png');
}

.landing-page .mobility-section .image.c3 {
  background-image: url('../images/misc/mobility_c3.png');
}

.landing-page .testimonials-section .image {
  border-radius: 50px;
  margin-bottom: 20px;
}

.landing-page .testimonials-section .image.victoria {
  background-image: url('../images/landing-page/testimonials/victoria-testimonial.jpg');
}

.landing-page .testimonials-section .image.ingmari {
  background-image: url('../images/landing-page/testimonials/ingmari-testimonial.jpg');
}

.landing-page .testimonials-section .image.fabien {
  background-image: url('../images/landing-page/testimonials/fabien-testimonial.jpg');
}

.landing-page .testimonials-section .info-card p {
  font-style: italic;
  line-height: 20px;
  margin-top: 20px;
}

.landing-page .testimonials-section .info-card-box .info-card {
  position: relative;
  padding-bottom: 30px;
  text-align: center;
}

.landing-page .testimonials-section .titles h4 {
  font-size: 16px;
  margin-top: 0;
}

.landing-page .featured-section {
  padding-bottom: 120px;
}

.landing-page .slick-slider {
  max-width: 1200px;
  margin: 0 auto;
}
.landing-page .slick-slide {
  padding: 10px;
}

.landing-page .why-section .info-card-box .info-card > .image {
  width: 160px;
  height: 160px;
}

.landing-page .why-section .image.location {
  background-image: url('../images/landing-page/why/search.png');
}

.landing-page .why-section .image.accessibility {
  background-image: url('../images/landing-page/why/we-check-everything.png');
}

.landing-page .why-section .image.secure {
  background-image: url('../images/landing-page/why/secure-1.png');
}

.landing-page .why-section .image.service {
  background-image: url('../images/landing-page/why/phone-service-1.png');
}

.landing-page .partners-section {
  padding-bottom: 120px;
}
.landing-page .how-section .info-card-box .info-card {
  max-height: 100%
}
.landing-page .how-section .info-card-box .info-card .image {
  width: 100%;
  max-width: 340px;
  height: 240px;
}

.landing-page .how-section .image.surfer {
  background-image: url('../images/landing-page/how/surfer-2.png');
}

.landing-page .how-section .image.booking {
  background-image: url('../images/landing-page/how/booking-2.png');
}

.landing-page .how-section .image.vacation {
  background-image: url('../images/landing-page/how/vacation-2.png');
}

@media (min-width: 200px) and (max-width: 600px) {
  .header .logo {
    background-size: cover;
    background-repeat: no-repeat;
  }
  .landing-page .top-section .search > * {
    border-radius: 3px;
    border: none;
    display: inline-block;
    height: 66px;
    margin-left: 4px;
    min-width: 15%;
    vertical-align: middle;
  }

  .landing-page .top-section {
    background-size: cover;
    background-position: 50%;
    padding-top: 1px;
    padding-bottom: 100px;
    z-index: 1;
  }
  .landing-page .top-section .search-wrapper {
    margin-top:60px;
    padding:20px;
    text-align:center;
  }
  .landing-page .top-section .titles {
    margin: 109px auto 0;
    max-width: 1200px;
    text-align: left;
    padding: 0px 20px;
  }
  .landing-page .top-section .titles h2 {
    line-height: 26px;
    font-size: 30px;
    letter-spacing: 0;
  }
  .landing-page .top-section .titles h4 {
    line-height: 18px;
    font-size: 18px;
    letter-spacing: 0;
  }
  .landing-page .top-section .search > button {
    background-color: #ff7059;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    flex: 1;
    padding: 0 10px;
  }
  .landing-page .mobility-section .info-card-box {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 61.938em) {
  .header .logo {
    background-size: cover;
    background-repeat: no-repeat;
  }
  .landing-page .top-section .search > * {
    border-radius: 3px;
    border: none;
    display: inline-block;
    height: 66px;
    margin-left: 4px;
    min-width: 15%;
    vertical-align: middle;
  }

  .landing-page .top-section {
    background-size: cover;
    background-position: 50%;
    padding-top: 1px;
    padding-bottom: 100px;
    z-index: 1;
  }
  .landing-page .top-section .search-wrapper {
    margin-top:60px;
    padding:20px;
    text-align:center;
  }
  .landing-page .top-section .titles {
    margin: 109px auto 0;
    max-width: 1200px;
    text-align: left;
    padding: 0px 20px;
  }
  .landing-page .top-section .titles h2 {
    line-height: 26px;
    font-size: 30px;
    letter-spacing: 0;
  }
  .landing-page .top-section .titles h4 {
    line-height: 21px;
    font-size: 20px;
    letter-spacing: 0;
  }
  .landing-page .top-section .search > button {
    background-color: #ff7059;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    flex: 1;
    padding: 0 10px;
  }

  .landing-page section.mobility-section div .info-card-box .info-card {
    text-align: center;
    max-height: 500px;
  }
  .landing-page .date-picker .DateRangePickerInput__calendar-icon {
    display: none;
  }
  .landing-page .date-picker .DateRangePickerInput{
    padding: 0 5px;
  }
  .landing-page .top-section .search-wrapper{
    padding: 0;
  }
  .landing-page .top-section .search{
    border-radius: 0;
    padding: 20px;
  }
}
@media only screen and (max-width: 47.938em) {

  .landing-page .cities-section .cities {
    margin-left: 10px;
    margin-right: 10px;
  }
  .landing-page .cities-section .cities a {
    font-size: 16px;
  }
  .landing-page .cities-section .cities .city-image a {
    padding-bottom: calc(50% - 5px);
    line-height: 40vw;
  }
  .landing-page .cities-section .cities .city-image.col-xs-6  a {
    padding-bottom: 100%;
  }
  .landing-page section.mobility-section div .info-card-box .info-card {
    text-align: center;
    max-height: 350px;
  }
}
.landings-page .carousel-slider{
  height: 249px;
}
.landings-page .carousel .control-dots{
 margin: 10px 0 20px 0;
}
.landings-page .carousel-slider .info-section-column-content{
  height: 200px;
  background: #F5F7F9;
  border: 1px solid rgba(0, 0, 0, 0.12);  
  border-radius: 5px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  width: 80%;
  margin: auto;
}
.landings-page .carousel-slider .control-arrow.control-next::before{
  content: '';
    position: absolute;
    height: 36px;
    width: 30px;
    background-image: url('../images/icons/slider-arrow-icon.svg');
    top: 34%;
    right: -1px;
    background-position: center;
}
.landings-page .carousel-slider .control-arrow.control-prev::before {
  content: '';
  position: absolute;
  height: 36px;
  width: 30px;
  background-image: url('../images/icons/slider-arrow-icon.svg');
  left: -4%;
  top: 34%;
  transform: rotate(180deg);
  background-position: center;
}
.landings-page .carousel-slider  .dot{
  background: rgba(196, 196, 196, 0.5);
    box-shadow: none;
    width: 10px;
    height: 10px;
}
.landings-page .carousel-slider  .dot.selected{
  background-color: #2574A9;
}
.landings-page .carousel-slider .slide{
  background-color: white;
}
.landings-page .leaflet-popup-content a{
  width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.landings-page .price{
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.landings-page .leaflet-top.leaflet-left{
  z-index: 999;
}
.landings-page .descrip4{
  white-space: normal;
}
.landings-page .leaflet-popup-content{
  width: 200px;
}
.landings-page .leaflet-popup-content img{
  max-width: 200px;
}
.landings-page__content .map-wrapper{
  height: 860px;
}
@media only screen and (max-width: 700px) {
  .landings-page__content .map-wrapper {
    height: 400px;
    margin-bottom: 20px;
  }
} 
