ul.booking-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.bookings-row  {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.booking-list-li {
  font-size: 10px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.booking-list-li h2 {
  font-size: 24px;
}

.booking-list-li div.inner {
  border: 1px solid #dee0df;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.booking-list-li div.column {
  padding: 0 20px;
}

.booking-list-li img.cover {
  width: 100%;
}
ul.adminInfo {
  margin-bottom: 10px;
}
ul.adminInfo li {
  display: block;
  font-size: 12px;
}
.booking-list-li a.button, .booking-list-li button {
  display: block;
  background: white;
  border: 1px solid #b9b9b9;
  margin: 10px 0 10px 0;
  color: #2e2e2e;
  padding: 5px;
  width: 100%;
  text-align: center;
}
.booking-list-guest {
  height: 50px;
  display: flex !important;
  flex-direction: row;
}
.booking-status {
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-weight: bold;
  background: pink;
}
.payment_required, .owner_approval {
  background: #f3ab3c;
}
.rejected, .cancelled, .expired {
  background: #c14e2e;
}
.finished, .required_second_part_of_payment {
  background: #589e33;
}
.host-avatar {
  width: 64px;
  height: 64px;
  background-size: cover;
  background-position: center center;
  border-radius: 100px;
  margin: auto;
  border: 2px solid white;
  margin-top: -48px;
  position: relative;
  display: block;
  background-image: url('../images/profile/default-avatar.png');
}
.guests-str {
  white-space: nowrap;
}
