.accessibility-icon{
    display: block;
    max-height: 92px;
}
.how-it-works,
.best-price-guarantee{
    padding-top: 10% !important;
    padding-bottom: 40px !important;
}
.how-it-works section{
    padding-left: 15%;
    padding-right: 15%;
}
.how-it-works h1,
.best-price-guarantee h1{
    font-weight: bold;
font-size: 40px;
line-height: 50px;
letter-spacing: -0.02em;
color: #1E1E1E;
}
.how-it-works p,
.best-price-guarantee p,
.best-price-guarantee ul{
    font-size: 16px;
    line-height: 20px;
color: #1E1E1E;
}
.best-price-guarantee p,
.best-price-guarantee ul{
    margin-top: 20px;
}
.best-price-guarantee ul{
    margin-top: 0;
}
.how-it-works .guarantee-title-text{
    margin-top: 20px;
}
.how-it-works h2{
    font-weight: bold;
font-size: 28px;
line-height: 35px;
text-align: center;
letter-spacing: -0.02em;
color: #1E1E1E;
}
.finding-booking-accessible-accommodation{
    padding-bottom: 40px;
}
.accessibility-section{
    padding-top: 50px;
    background-color: #e6e6e663;
    margin-top: 40px;
    padding-bottom: 50px;
}
.validated-section{
    padding-top: 70px;
}
.icons-description{
    padding-top: 5%;
}
.icons-description-text-block{
    padding-top: 5%
}
.icons-description-text-block-central{
    position: relative;
}
/* .icons-description-text-block-central::after{
    content: '';
    position: absolute;
    width: 2px;
    height: 70%;
    background-color: gray;
    top: 43px;
    right: -9px;
    border-radius: 5px;
} */
/* .icons-description-text-block-central::before{
    content: '';
    position: absolute;
    width: 2px;
    height: 70%;
    background-color: gray;
    top: 43px;
    left: -18px;
    border-radius: 5px;
} */
.accessibility-section .icon-description{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.accessibility-section .icon-description:nth-child(2){
    margin: 0 15px;
}
.classification-section h3{
    font-size: 16px;
    line-height: 20px;
    color: #1E1E1E;
    text-align: center;
}
.accessibility-section h2{
    text-align: center;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    color: #1E1E1E
}


  .classification-section{
      padding-top: 5%;
  }

  .icon-accessibility-image{
      max-height: 96px;
  }
.icon-accessibility-image{
    max-width: 50px;
}
.classification-section-accessibility-title{
    font-size: 16px;
    font-weight: 600;
    font-weight: bold;
font-size: 22px;
line-height: 27px;
text-align: center;
margin-top: 10px;
}
.classification-section-accessibility-title-wrapper{
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.accessible-img-wrapper{
    margin-top: 30px;
    display: flex;
}
.accessible-img{
    max-width: 100%;
    display: block;
    height: 190px;
    object-fit: cover;
    width: 240px
}
.accessible-img-icon-2{
    height: 250px;
    width: 350px;
    object-position: bottom;
}
.accessible-img-title{
    margin-top: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1E1E1E;
}
.accessible-contact-support{
    text-align: center;
    margin-top: 5%;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: #1E1E1E;
}
.accessible-contact-support a{
    font-weight: bold;
    text-decoration: underline;
    color: black;
}
.accessibility-icons-section{
    padding-top: 10%;
}
.accessibility-icons-img{
    max-height: 500px;
    display: block;
    margin: auto;
    max-width: 100%;
}
.accessibility-data-section{
    padding-top: 20px;
    border: 1px solid #E6E6E6;
box-sizing: border-box;
border-radius: 6px;
margin-top: 30px;
}
.accessibility-data-section h3,
.validated-section-content h3{
    font-weight: bold;
font-size: 22px;
line-height: 27px;
color: #1E1E1E;
text-align: center;
}
.validated-section-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    border-bottom: 1px solid #E6E6E6;
}
.validated-section-content img{
    max-width: 255px;
    margin-top: 20px;
}
.accessibility-data-section-text{
 display: flex;
 flex-direction: column;
 justify-content: center;
}
.accessibility-data-section-text h3{
    text-align: left;
}
/* .accessibility-data-section img{
    max-height: 200px;
    display: block;
    margin: auto;
} */
.accessibility-icon-description{
    display: block;
    text-align: center;
    margin-top: 30px;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #1E1E1E;
}

.accessibility-section a{
    transition: 0.5s;
}
.accessibility-section a:hover{
    text-decoration: none;
    background-color: #f7f7f7;
}
.finding-booking-accessible-accommodation-list li{
    display: block;
}
.finding-booking-accessible-accommodation-image{
    max-height: 300px;
    max-width: 100%;
}
.finding-booking-accessible-accommodation .guide-section{
 margin-top: 5%;
 font-size: 16px;
}
.finding-booking-accessible-accommodation{
    padding-top: 10% !important;
}
@media only screen and (max-width: 790px) {
    .how-it-works h1{
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #1E1E1E;
    }
    .container-fluid.how-it-works{
        padding-left: 0;
        padding-right: 0;
    }
    .how-it-works{
        padding-top: 80px !important;
    }
    .policy-not-accepted + .main-layout-content > .how-it-works {
        padding-top: 140px
    }
    .how-it-works section{
        padding-left: 5%;
        padding-right: 5%;
    }
    .accessibility-section .icon-description:nth-child(2){
        margin-left: 0;
        margin-right: 0;
        margin-top: 10px;
    }
    .accessibility-section h2{
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        letter-spacing: -0.02em;
    }
    .accessibility-section .icon-description{
        margin-top: 10px;
    }
    .validated-section-content p{
        text-align: center;
    }
    .accessible-img {
        width: 100%;
    }
  }