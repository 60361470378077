/* Property Perks */

.facilities {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.single-property-perks {
  display: flex;
  flex-wrap: wrap;
}

.single-property-perks li {
  display: flex;
  margin-bottom: 20px;
  flex-basis: 33%;
  align-items: center;
  font-size: 16px;
  color: #000000;
  font-weight: 400;
}

.single-property-perks li > img {
  margin-right: 13px;
  width: 24px;
}

.single-property-perks li > .image {
  margin-right: 13px;
  width: 25px;
  height: 25px;
  background-size: 100%;
  background-repeat: no-repeat;
}
.single-property-perks li > .image.empty {
  background: transparent;
}
.single-property-perk{
  padding: 12.5px 10px;
  border: 1px solid #E6E6E6;
  border-radius: 6px;
}
.single-property-perk.perk-green {
  border: 1px solid #659b10;
  color: #659b10;
}
.single-property-perks img{
  margin-right: 6px;
}
@media (max-width: 575px) {
  .single-property-perks li {
    flex-basis: 50%;
  }
}
