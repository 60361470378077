.payment-page {
  max-width: 1200px;
  margin: 0 auto;
}

.payment-page .payment-content {
  width: 100%;
  display: flex;
  padding: 20px;
}

.payment-page .payment-content > * {
  flex-direction: column;
}
.payment-page .payment-info {
  flex:1;
  margin-right: 20px;
}
.payment-page .payment {
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  padding: 15px 15px 50px 15px;
  border-top: 6px solid #55afe8;
  flex: 1.7;
  height: 100%;
}

.payment-page .payment-content img {
  width: 100%;
  border-radius: 3px 3px 0 0;
  max-height: 200px;
  object-fit: cover;
  background-repeat: no-repeat;
}

.payment-page .card-details > button {
  margin-top: 20px;
  width: 100%;
  height: 70px;
  font-size: 20px;
}

.payment-page .header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid #d4d4d4;
  border-top: none;
}

.payment-page .mobility-icon {
  background-color: gray;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin-right: 0px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.payment-page .payment-succeeded {
  max-width: 400px;
  text-align: center;
  margin: 60px auto;
}

.payment-page .booking-table {
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  margin-bottom: 0;
  font-size: 14px;
  color: #484848;
}
.payment-page .dates-wrapper {
  padding: 15px 0px;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  justify-content: space-between;
  display: flex;
  font-size: 16px;
  align-items: center;
}
.payment-page .date {
  color: white;
  text-align: center;
  flex: 1;
  background-color: #a6a6a6;
  padding: 10px;
  border-radius: 3px;
  margin: 0 15px;
}
.payment-page td {
  padding: 5px 15px;
}
.payment-page h1 {
  font-size: 30px;
  font-weight: bold;
  color: #484848;
  margin-bottom: 20px;
  border-bottom: 1px solid#d4d4d4;
  padding-bottom: 15px;
  margin-right: -15px;
  margin-left: -15px;
  padding-left: 15px;
}
.payment-page #stripe-card-element {
  padding: 16px 15px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  margin-top: 20px;
}
.payment-page .coupon-input-field {
  display: flex;
  width: 100%;
  padding: 15px;
  border: 1px solid #d4d4d4;
}
.payment-page .alert-wrap {
  padding: 15px;
  border: 1px solid #d4d4d4;
  border-top: none;
}
.coupon-input-field input {
  border-radius: 3px 0 0 3px;
  border-right: none;
}
.coupon-input-field > * {
    flex: 2;
}
.payment-page .coupon-input-field button {
  padding: 0;
  flex:1;
  border-radius: 0 3px 3px 0;
  max-width: 100%;
  background-color: #484848;
}
.payment-page .total-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  border: 1px solid #d4d4d4;
  border-top: none;
  border-radius: 0 0 3px 3px;
  font-size: 20px;
  font-weight: bold;
}
@media(max-width: 767px) {
  .payment-page .payment-content {
    flex-direction: column;
    padding: 0;
  }

  .payment-page .payment-content > * {
    margin: 0;
  }
  
}

div.retry-container {
  width: 100%;
  text-align: center;
}

.retry-button {
  padding: 5px 40px;
  margin: 0px auto;
}
.saved-cards-wrapper{
  display: flex;
    flex-direction: column;
    padding: 5%;
}
.saved-card-wrapper{
  font-size: 18px;
}
.saved-card-label{
  margin-left: 60px;
  position: relative;
}
.saved-card-label::before{
  content: " ";
  background: url('../images/icons/credit-card.svg');
  position: absolute;
  width: 31px;
  height: 33px;
  background-repeat: no-repeat;
  background-size: contain;
  left: -12%;
}
.payment-page .best-price{
  margin-top: 2%;
}
.payment-page .best-price-wrapper{
  max-height: 100px;
  max-width: 100px;
  display: block;
  margin: auto;
}
.paypal-container{
  margin-top: 15px;
}