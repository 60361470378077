.id-verification-page {
  width: 100%;
}

.id-verification-content input {
  visibility: hidden;
}

.id-verification-content .status span {
  font-weight: bold;
}

.id-verification-content button {
  display: block;
}
