/*css style for the Hotels landings*/

/*top section*/

.landings-page-top-section-hotels-amsterdam{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Amsterdam-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-barcelona{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Barcelona-For-Disabled-2.jpeg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-berlin {
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Berlin-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-blackpool{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Blackpool-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-cape-town{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Cape-Town-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-ceredigion{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Ceredigion-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-cornwall{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Cornwall-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-devon{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Devon-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-dorset{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Dorset-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-dublin{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Dublin-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-lisbon{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Lisbon-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-london{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-London-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-madrid{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Madrid-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-majorca{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Majorca-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-nice{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Nice-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-norfolk{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Norfolk-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-paris{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Paris-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-rome{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Rome-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-tenerife{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Tenerife-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-venice{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Venice-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}
.landings-page-top-section-hotels-yorkshire{
    background: #b3dff9 url('../images/landings-index/hotels/Accessible-Yorkshire-For-Disabled.jpg') no-repeat center center;
    background-size: cover;
}

/*guide section*/

.guide-section-picture-hotels-amsterdam{
    background: #b3dff9 url("../images/landings-index/hotels/Amsterdam-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-barcelona{
    background: #b3dff9 url("../images/landings-index/hotels/Barcelona-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-berlin{
    background: #b3dff9 url("../images/landings-index/hotels/Berlin-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-blackpool{
    background: #b3dff9 url("../images/landings-index/hotels/Accessible_-Blackpool_For_Disabled.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-cape-town{
    background: #b3dff9 url("../images/landings-index/hotels/Cape-Town-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-ceredigion{
    background: #b3dff9 url("../images/landings-index/hotels/Ceredigion-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-cornwall{
    background: #b3dff9 url("../images/landings-index/hotels/Cornwall-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-devon{
    background: #b3dff9 url("../images/landings-index/hotels/Devon-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-dorset{
    background: #b3dff9 url("../images/landings-index/hotels/Dorset-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-dublin{
    background: #b3dff9 url("../images/landings-index/hotels/Dublin-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-lisbon{
    background: #b3dff9 url("../images/landings-index/hotels/Lisbon-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-london{
    background: #b3dff9 url("../images/landings-index/hotels/London-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-madrid{
    background: #b3dff9 url("../images/landings-index/hotels/Madrid-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-majorca{
    background: #b3dff9 url("../images/landings-index/hotels/Majorca-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-nice{
    background: #b3dff9 url("../images/landings-index/hotels/Nice-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-norfolk{
    background: #b3dff9 url("../images/landings-index/hotels/Norfolk-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-paris{
    background: #b3dff9 url("../images/landings-index/hotels/Paris-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-rome{
    background: #b3dff9 url("../images/landings-index/hotels/Rome-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-tenerife{
    background: #b3dff9 url("../images/landings-index/hotels/Tenerife-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-venice{
    background: #b3dff9 url("../images/landings-index/hotels/Venice-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
.guide-section-picture-hotels-yorkshire{
    background: #b3dff9 url("../images/landings-index/hotels/Yorkshire-for-accessible-guide.jpg") no-repeat center center;
    background-size: cover;
}
