.overview-srp-page {
  padding-top: 50px;
}

.overview-srp-page .progress-links {
  max-width: 500px;
  margin-bottom: 30px;
}

.overview-srp-page .published-info {
  margin-top: 30px;
}

.overview-srp-page .published-info button {
  margin: 15px 10px 0 0;
}

.overview-srp-page li {
  background-color: #b2b2b2;
  border-radius: 4px;
  height: 20px;
  margin: 0 5px 5px 0;
}

.overview-srp-page li.ok {
  background-color: #13c639;
}

.overview-srp-page li a {
  color: #FFF;
  line-height: 20px;
  padding: 0 20px;
}

.overview-srp-page .mobility-info {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.delete-btn-cover{
  position: absolute;
  top: -33px;
  left: 267px;
}
.input-name-wrapper-lyp{
  display: flex;
    flex-direction: column;
    align-items: center;
}
.input-name-button{
  width: 38%;
  margin-top: 3%;
}
/* Modal */
.delete_prop-content{
  background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    width: 500px;
    height: 270px;
    outline: none;
    padding: 20px;
    border: 1px solid gray;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.delete_prop-content-button-wrapper{
  display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.delete_prop-content-delete-button{
  background-color: #ff7059 !important;
}
.delete_prop-content-title{
  font-size: 18px;
    position: relative;
    font-weight: 600;
}
.delete_prop-content-wrapper{
  margin-top: 153px;
}
.delete_prop-content-title::after{
  background: url('../../images/misc/danger-triangle.svg');
  content: " ";
  position: absolute;
  display: block;
  height: 110px;
  width: 119px;
  top: -146px;
  left: 35%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.overview-mrp-page-no-put{
    color: red;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 600;
}
/* EndModal */
