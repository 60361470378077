.topic-header{
 border-bottom: 1px solid #E0E0E0;
 padding-bottom: 10px;
 max-width: 1140px;
}

.topic-parent{
    display: flex;
    flex-direction : column;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    flex-grow: 1;
    max-width: 1140px;
}

.topic-holder{
    display: flex;
    flex-direction: row;
    max-width: 1140px;
    padding-top: 30px;
}
.topic-name{
    margin: 0px;
    float: left;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    color: black;
    font-family: inherit;
    padding: 4px;
}
.topic-button{
    border: 1px solid #4587C9;
    border-radius: 5%;
    color:#4587C9;
    float: right;
    padding: 10px;
    background-color:white;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
}

.topic-wrapper{
    margin-top: 90px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}
@media screen and (max-width : 1400px){
    .topic-wrapper{
        width: 90%;
    }
}
@media screen and (max-width : 1100px){
    .topic-holder{
        flex-direction: column;
        max-width: 350px;
    }
    .topic-name{
        font-size: 22px;
    }
    .topic-wrapper{
        margin-top: 10px !important;
    }
}

.topic-loader{
    height : 300px;
    width : 100%;
    display : flex;
    justify-content: center;
    align-items: center;
}