.image-upload-box {
  margin-top: 60px;
  /*min-height: 100%;*/
  /*width: 800px;*/
  max-width: 900px;
}
.put-image-upload-box + .footer {
  max-width: 900px;
  margin: auto;
}
.image-upload-box .top {
	display: flex;
	justify-content: space-between;
}
.image-upload-box .top > * {
  display: inline-block;
  vertical-align: top;
}

.image-upload-box .top .file-drop-area {
  border: 2px dashed #484848;
  position: relative;
  width: 600px;
  background: url('../images/list-my-space/image-upload-background.png') repeat white;
  background-clip: content-box;
	background-origin: content-box;
	padding: 20px;
}

.image-upload-box .top .file-drop-area .draggable-image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.image-upload-box .top .file-drop-area .upload-button-wrapper {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.upload-button-wrapper button {
	display: flex;
}
.upload-button-wrapper button::before {
	display: inline-block;
	background: url('../images/list-my-space/upload-white.png') no-repeat;
	background-size: contain;
	content: ' ';
	width: 20px;
	height: 20px;
	margin-right: 5px;
}
.image-upload-box .top .file-drop-area input[type=file] {
  visibility: hidden;
}

.image-upload-box .top .mandatory-box {
  border: 1px solid #dadada;
  width: 200px;
  background: #fafafa;
  border-radius: 4px;
  padding: 10px;
}

.image-upload-box .top .mandatory-box ul {
  /*list-style: georgian;*/
}

.image-upload-box .top .mandatory-box li {
  display: flex;
  margin-bottom: 10px;
}

.image-upload-box .top .mandatory-box li .indicator {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  background: url('../images/list-my-space/invalid.png') no-repeat;
  background-size: contain;
}

.image-upload-box .top .mandatory-box li .indicator.ok {
  background-image: url('../images/list-my-space/ok.png')
}

.image-upload-box .draggable-image {
  background-color: #cbcbcb;
  height: 150px;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.image-upload-box .draggable-image img {
	height: auto;
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translate(0,-50%);
}

.image-upload-box .draggable-image.hover img {
  visibility: hidden;
}
section.images {
	display: flex;
	flex-wrap: wrap;
	margin-left: -20px;
}
section.images div.padder {
	width: 33.33%;
	padding-left: 20px;
}
.image-upload-box .images > * {
  display: inline-block;
  margin-top: 10px;
  vertical-align: top;
  width: 300px;
}
.image-upload-box .images .add-more {
	border: 1px dashed black;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 212px;
	flex-direction: column;
}
.image-upload-box .images .add-more::before {
	display: inline-block;
	content: ' ';
	background: url('../images/list-my-space/upload-blue.png') no-repeat;
	background-size: contain;
	width: 30px;
	height: 30px;
}
.layout-content div.footer {
	background: transparent;
	color: inherit;
	width: 100%;
}