@import url('./materialize-preloader.css');

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 1000;
  border-radius: 8px;
}

.spinner-overlay.is-inline {
  height: 300px;
  position: relative;
  bottom: 0;
}

.spinner-overlay .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.spinner-overlay .spinner-layer {
  border-color: #DADAEB;
}
