.icon-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  text-align: center;
}

.icon-modal .icon {
  height: 150px;
  width: 150px;
}
