.dashboard-settings-layout .security-page {
    width: 100%;
    border-bottom: 1px solid #d4d4d4;
}
.security-page .inner-content {
    font-size:16px;
    padding:30px;
    margin-top:0;
}

.security-page .inner-content .input-wrapper {
    display: flex;
    margin: 0 -10px;
    flex-flow: row wrap;
}
.security-page .inner-content .input-field-wrapper {
    min-width: 50%;
    padding: 0 10px;
    margin-bottom: 20px;
}
