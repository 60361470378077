.live-price-booking-modal {
  padding: 20px;
}

.live-price-booking-modal .close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
}

.live-price-booking-modal > h3 {
  font-size: 28px;
}

.live-price-booking-modal .nr-rooms-form ul li {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  width: 100%;
}

.live-price-booking-modal .nr-rooms-form ul li .select-dropdown {
  width: 30%;
}

.live-price-booking-modal .nr-rooms-form button {
  width: 230px;
  height: 50px;
  float: right;
  margin-bottom: 20px;
}

.live-price-booking-modal .fetching-price-info {
  min-height: 100px;
  position: relative;
  margin-top: 40px;
}

.live-price-booking-modal .fetching-price-info .live-price-spinner {
  height: 100px;
  width: 100%;
  position: relative;
}

.live-price-booking-modal .fetching-price-info .live-price-spinner > * {
  position: absolute;
}

.live-price-booking-modal .goto-booking {
  margin-top: 30px;
}

.live-price-booking-modal .goto-booking .room-infos .room-title {
  font-size: 20px;
}

.live-price-booking-modal .goto-booking .room-infos .info-wrapper {
  display: flex;
}

.live-price-booking-modal .goto-booking .room-infos .room-price {
  min-width: 120px;
}

.live-price-booking-modal .price {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: flex-end;
}

.live-price-booking-modal .price > * {
  line-height: 1;
  font-weight: bold;
}

.live-price-booking-modal .price .number {
  color: green;
  font-size: 20px;
}

.live-price-booking-modal .price .currency {
  font-size: 14px;
  margin-left: 2px;
  padding-bottom: 1px;
}

.live-price-booking-modal .price-wrapper mark {
  background-color: #000;
  color: #fff;
  border-radius: 3px;
  width: 100%;
  padding: 5px;
  text-align: center;
  margin-top: 5px;
}

.live-price-booking-modal .total-price-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.live-price-booking-modal .total-price-wrapper .price {
  max-width: 100px;
}

.live-price-booking-modal .goto-booking .booking-button {
  float: right;
  margin: 20px;
  margin-right: 0;
}