.home-page {
  position: relative;
}
.home-page__content {
  position: relative;
}
.home-page__top-section {
  position: relative;
  display: table;
  width: 100%;
  background-size: cover;
  height: 452px;
}
.home-page .home-page__top-section .header-contact {
  position: absolute;
  top: 10%;
  right: 10%;
  z-index: 20;
  padding: 10px;
  color: #fff;
  font-size: 1.3em;
  text-align: right;
  background: rgba(30, 30, 30, 0.5);
  border-radius: 5px;
}
.home-page .home-page__top-section .header-contact a {
  color: #fff;
}
.home-page__top-section-content {
  position: relative;
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}
.home-page__top-title {
  position: relative;
  padding-bottom: 50px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-page__top-title h2 {
  padding: 0;
  margin: 0;
  color: #fff;
  line-height: 50px;
  font-size: 46px;
  font-weight: bold;
}
.home-page__top-title h4 {
  color: #fff;
  line-height: 40px;
  font-size: 36px;
  font-weight: bold;
}
.search-wrapper {
  background: #1e1e1edb;
  border-radius: 8px;
  position: relative;
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 15px;
}
.search-wrapper .search {
  margin: 0 0 0 -3px;
}
.search-wrapper .google-input-field,
.search-wrapper .mobile-drop-down-wrapper,
.search-wrapper .date-picker {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  margin-left: 10px;
  height: 55px;
  border: 1px solid rgba(37, 116, 169, 0.38);
  border-radius: 5px;
}
.search-wrapper .google-input-field{
  margin-left: 0;
}
.search-wrapper .search .guests-picker-wrapper{
  width: 190px;
  height: 55px;
  margin-left: 10px;
  border: 1px solid rgba(37, 116, 169, 0.38);
  border-radius: 5px;
}
.search-wrapper .date-picker {
  min-width: 0;
}
.info-section {
  position: relative;
  padding: 45px 0;
}
.info-section-icon {
  display: block;
  margin: 0 auto;
  font-size: 0;
  line-height: 0;
  width: 34px;
  height: 34px;
  background: url(../images/home-page/info-section-icon.svg) no-repeat center
    center;
  background-size: 100% 100%;
}
.info-section-title,
.cities-section-title {
  padding: 20px 0 15px 0;
  margin: 0;
  text-align: center;
  color: black;
  line-height: 24px;
  font-size: 22px;
  font-weight: bold;
}
.info-section-title.m-inner {
  padding: 0 0 4px;
  min-height: 48px;
  line-height: 18px;
  font-size: 16px;
}
.info-section-text {
  color: black;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin: 0 14%;
}
.info-section-text.m-fluid {
  margin: 0;
}
.info-section-columns {
  padding-top: 30px;
  padding-bottom: 24px;
}
.info-section-column {
  text-align: center;
}
.info-section-column a{
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  cursor: pointer;
}
.info-section-column a:hover{
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  color: black;
}
.info-section-column img {
  height: 60px;
  margin-bottom: 40px;
}
.info-section-column-content {
  height: 100%;
  padding: 20px;
  width: 100%;
  color: black;
  background: #F5F7F9;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: block;
}
.info-section-column-text {
  line-height: 20px;
}
.info-section-column-text p{
  color: black
}
.cities-section {
  position: relative;
  background: #b3dff9;
  padding: 45px 0;
}
.cities-section-sub-title {
  text-align: center;
  color: #686868;
  font-size: 14px;
  line-height: 22px;
  padding: 15px 0;
  margin: 0 14%;
}
.cities {
  padding: 30px 0 0;
  font-size: 0;
  line-height: 0;
  margin: -5px 0 0 -5px;
}
.city-item {
  display: inline-block;
  vertical-align: top;
  height: 200px;
  position: relative;
  overflow: hidden;
  margin: 5px 0 0 5px;
  width: calc(33.33333333% - 5px);
  background-size: cover !important;
}
.city-item:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.city-item:hover:before {
  opacity: 1;
}
.city-item.paris {
  background: url(../images/home-page/bg-paris.jpg) no-repeat center center;
}
.city-item.normandy {
  background: url(../images/home-page/bg-normandy.jpg) no-repeat center center;
}
.city-item.mallorca {
  background: url(../images/home-page/bg-mallorca.jpg) no-repeat center center;
}
.city-item.croatia {
  background: url(../images/home-page/bg-croatia.jpg) no-repeat center center;
}
.city-item.barcelona {
  background: url(../images/home-page/bg-barcelona.jpg) no-repeat center center;
}
.city-item.london {
  background: url(../images/home-page/bg-london.jpg) no-repeat center center;
}
.city-item-content {
  position: absolute;
  left: 14px;
  right: 14px;
  top: auto;
  bottom: 14px;
}
.city-item-content a {
  text-decoration: none;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
}
.city-item-content a:hover {
  text-decoration: underline;
}
.mail-section {
  position: relative;
  padding: 64px 0;
  background: url(../images/home-page/bg-mail-section.jpg) no-repeat center
    center;
  background-size: cover;
}
.mail-section-content {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  padding: 40px 120px;
  position: relative;
  text-align: center;
}
.mail-section-title {
  padding: 0 0 20px;
  margin: 0;
  color: #1e1e1e;
  line-height: 32px;
  font-size: 28px;
  font-weight: bold;
}
.mail-section-sub-title {
  padding: 0;
  margin: 0;
  color: #1e1e1e;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
}
.mail-section-form {
  display: block;
  position: relative;
  padding: 48px 8% 0;
}
.mail-section-form-title {
  padding: 0;
  margin: 0;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
}
.mail-section-form-controls {
  padding: 18px 0;
}
.mail-section-form-input {
  background: #fff;
  border-radius: 4px;
  height: 44px;
  line-height: 44px;
  display: block;
  float: left;
  padding: 0 12px;
  font-size: 14px;
  border: none;
  width: 72.4%;
}
.mail-section-form-button {
  display: block;
  float: right;
  width: 27%;
  height: 44px;
  line-height: 16px;
  color: #fff;
  background: #ff9000;
  padding: 0 20px;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.mail-section-form-button:hover {
  background: #ffaa00;
}
.mail-section-form-button:focus,
.mail-section-form-button:hover:active {
  background: #e67e00;
  outline: none;
}
.mail-section-form-text {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
}
.stories-section {
  background-color: #4bb6f1;
  padding: 45px 0;
}
.stories-section-title {
  padding: 12px 0;
  margin: 0;
  text-align: center;
  color: #fff;
  line-height: 24px;
  font-size: 22px;
  font-weight: bold;
}
.stories-section-text {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  padding: 15px 0;
}
.stories-section-icon {
  display: block;
  margin: 0 auto;
  font-size: 0;
  line-height: 0;
  width: 34px;
  height: 34px;
  background: url(../images/home-page/stories-section-icon.svg) no-repeat center
    center;
  background-size: 100% 100%;
}
.stories-section-content-wrapper {
  position: relative;
  padding-top: 25px;
  padding-bottom: 8px;
  margin: -6px 0 0 -6px;
}
.stories-item {
  width: calc(50% - 6px);
  float: left;
  margin: 6px 0 0 6px;
}
.stories-item,
.stories-slider-item {
  position: relative;
  overflow: hidden;
  height: 200px;
  display: block;
  background: #ccc;
}
.stories-item.item-big {
  height: 406px;
}
.stories-item img.bg,
.stories-slider-item img.bg {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.stories-item-image {
  width: 1000px;
  height: 1000px;
  line-height: 1000px;
  font-size: 0;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -500px 0 0 -500px;
}
.stories-item-image img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  border: none;
}
.stories-item-content {
  position: absolute;
  bottom: 14px;
  left: 12px;
  right: 12px;
  top: auto;
  font-size: 0;
  line-height: 0;
}
.avatar-and-link-holder {
  float: left;
  max-width: calc(100% - 135px);
}
.avatar-and-link-holder.without-btn {
  max-width: 100%;
}
.avatar-and-link-holder .stories-item-content-avatar {
  display: block;
  float: left;
}
.avatar-and-link-holder .stories-item-content-link {
  display: block;
  float: right;
  max-width: calc(100% - 56px);
}
.stories-item-content-avatar {
  display: inline-block;
  vertical-align: top;
  width: 44px;
  height: 44px;
  position: relative;
  overflow: hidden;
  background: #eee;
}
.stories-item.item-big .stories-item-content-avatar {
  display: block;
  margin: 0 auto;
}
.stories-item-content-avatar > div {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -50px 0 0 -50px;
}
.stories-item-content-avatar > div > img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  border: none;
}
.stories-item-content-author {
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  padding: 14px 0;
  font-weight: bold;
}
.stories-item-content-text {
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  padding: 20px 50px 0;
}
.stories-item-content-link {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 1px;
  margin: 0 0 0 12px;
}
.stories-item.item-big .stories-item-content-link {
  font-size: 18px;
  /*line-height: 28px;*/
  float: left;
  display: block;
  /*text-align: center;*/
  position: relative;
  /*padding-bottom: 10px;*/
}
.stories-item.item-big .stories-item-content-divider {
  height: 1px;
  width: 100px;
  margin: 0 auto;
  background: #fff;
}
.stories-item-by {
  font-size: 20px;
  line-height: 28px;
  display: block;
  text-align: center;
  color: #fff;
  padding-top: 10px;
}
.stories-item-content-btn {
  display: block;
  float: right;
  background: #28b6ff;
  color: #fff;
  font-size: 12px;
  height: 34px;
  text-align: center;
  padding: 0 30px;
  margin: 10px 0 0;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.stories-item-content-btn:hover {
  background: #2cdcff;
}
.stories-item-content-btn:focus,
.stories-item-content-btn:active {
  outline: none;
  background: #259cde;
}
.ignite-section {
  position: relative;
  height: 440px;
  background: #86d1f5 url(../images/home-page/bg-ignite-section.png) no-repeat
    right center;
}
.ignite-section-content-wrapper {
  display: table;
  height: 440px;
  width: 100%;
}
.ignite-section-content-cell {
  display: table-cell;
  vertical-align: middle;
}
.ignite-section-content {
  position: relative;
  width: 33%;
  color: #fff;
}
.ignite-section-title {
  font-size: 30px;
  line-height: 34px;
  padding: 0 0 14px;
  margin: 0;
  color: #fff;
}
.ignite-section-text {
  line-height: 24px;
  font-size: 20px;
  padding-bottom: 44px;
  color: #fff;
}
.ignite-section-button {
  display: block;
  background: #ff9000;
  color: #fff;
  height: 44px;
  border-radius: 3px;
  font-size: 12px;
}
.destination-slider-wrapper {
  position: relative;
  overflow: hidden;
  left: 0;
  right: 0;
  width: auto;
  height: auto;
  padding: 25px 0 0;
}
.destination-item {
  display: block;
  height: 200px;
  margin: 0 3px;
  position: relative;
  overflow: hidden;
}
.destination-item:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: 0.4s;
}
.destination-item:hover:before {
  opacity: 1;
}
.destination-item img.bg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.destination-item.stockholm {
  background: url(../images/home-page/stockholm.jpg) no-repeat center center;
}
.destination-item-image {
  position: absolute;
  width: 1000px;
  height: 1000px;
  line-height: 1000px;
  font-size: 0;
  text-align: center;
  left: 50%;
  top: 50%;
  margin: -500px 0 0 -500px;
}
.destination-item-image img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  border: none;
}
.destination-item-title {
  display: block;
  position: absolute;
  top: 8px;
  left: 14px;
  max-width: 90%;
  max-height: 88px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  text-decoration: none;
  letter-spacing: 1px;
}
/*.destination-item-link:hover {*/
/*text-decoration: underline;*/
/*color: #fff;*/
/*}*/
.destination-slider-dots,
.stories-slider-dots {
  margin: 0;
  padding: 18px 0 0;
  line-height: 0;
  font-size: 0;
  text-align: center;
}
.destination-slider-dots > li,
.stories-slider-dots > li {
  display: inline-block;
  vertical-align: top;
  width: auto;
  height: auto;
}
.destination-slider-dots > li + li,
.stories-slider-dots > li + li {
  margin-left: 4px;
}
.destination-slider-dots > li > button,
.stories-slider-dots > li > button {
  display: block;
  padding: 0;
  font-size: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: none;
  background: #b7b7b7;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.destination-slider-dots > li > button:focus,
.destination-slider-dots > li > button:active,
.stories-slider-dots > li > button:focus,
.stories-slider-dots > li > button:active {
  outline: none;
}
.destination-slider-dots > li > button:hover,
.stories-slider-dots > li > button:hover {
  background: #959595;
}
.destination-slider-dots > li.slick-active > button,
.stories-slider-dots > li.slick-active > button {
  background: #484848;
}
.accessible-travel-section {
  padding: 45px 0;
}
.accessible-travel-title {
  padding: 12px 0;
  margin: 0;
  text-align: center;
  color: #484848;
  line-height: 24px;
  font-size: 22px;
  font-weight: bold;
}
.accessible-travel-text {
  color: #686868;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  padding: 15px 0;
}
.accessible-travel-section .row [class*="col-"] {
  padding: 0 5px;
  margin-bottom: 10px;
}
.accessible-travel-section .row {
  margin: 0 -5px;
}
.accessible-travel-section .accessible-travel-item {
  display: block;
  position: relative;
  background: rgba(0, 0, 255, 0.1);
  min-height: 300px;
  height: 100%;
  border-radius: 3px;
  color: #fff;
}
.accessible-travel-section .accessible-travel-item:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: 0.4s;
}
.accessible-travel-section .accessible-travel-item:hover:before {
  opacity: 1;
}
.accessible-travel-section .accessible-travel-item .info-holder {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  width: 100%;
}
.accessible-travel-section .accessible-travel-item img.bg {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.accessible-travel-section .accessible-travel-item.big .info-holder {
  padding: 50px 25px;
  text-align: center;
}
.accessible-travel-section .accessible-travel-item .info-holder .title {
  line-height: 22px;
  font-size: 20px;
  font-weight: bold;
}
.accessible-travel-section .accessible-travel-item.big .info-holder .sub-title {
  font-weight: bold;
}
.accessible-travel-section .accessible-travel-item.big .info-holder .title {
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
  color: #fff;
  padding: 10px 0 20px;
}
.accessible-travel-section .accessible-travel-item.big .info-holder .divider {
  height: 1px;
  background: #fff;
  max-width: 150px;
  margin: 0 auto 20px;
}
.accessible-travel-section .accessible-travel-item .info-holder .text {
  font-size: 14px;
  font-weight: 600;
}
.accessible-travel-section .accessible-travel-item.big .info-holder .text {
  font-size: 15px;
  font-weight: 600;
}
.accessible-travel-section .accessible-travel-item.paris {
  background: url(../images/home-page/bg-paris.jpg) no-repeat center center;
  background-size: cover;
}
.accessible-travel-section .row .see-more-link-holder {
  text-align: center;
  padding-top: 30px;
}
.accessible-travel-section .see-more-link {
  color: #686868;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  padding: 5px 20px;
  border: 1px solid #686868;
  border-radius: 3px;
  transition: 0.4s;
}
.accessible-travel-section .see-more-link:hover {
  text-decoration: none;
  color: rgba(89, 89, 89, 0.75);
  border: 1px solid rgba(89, 89, 89, 0.75);
}
/*How mobile are you section*/
section.mobility-section {
  padding: 50px 0;
}
.mobility-section-title h2 {
  padding: 12px 0;
  margin: 0;
  text-align: center;
  color: #484848;
  line-height: 24px;
  font-size: 22px;
  font-weight: bold;
}
.mobility-section-text h3 {
  color: #686868;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  padding: 15px 0;
}
section.mobility-section div .info-card-box .info-card {
  flex-grow: 1;
  border: 1px solid #bebebe;
  border-radius: 3px;
  padding: 30px 30px 20px;
  text-align: left;
  height: 100%;
}

section div .info-card-box .info-card .image {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 64px;
  margin: 0 auto;
  width: 64px;
}
section div .info-card-box .info-card h3 {
  margin: 20px 0 10px;
  color: #666;
}
section div .info-card-box .info-card h4 {
  font-size: 14px;
  line-height: 22px;
  margin-top: 44px;
}
section div .info-card-box .info-card p {
  margin-top: 9px;
  font-size: 14px;
  font-weight: 400;
  color: #666;
}
.mobility-section .image.c1 {
  background-image: url("../images/misc/mobility_c1.png");
}
.mobility-section .image.c2 {
  background-image: url("../images/misc/mobility_c2.png");
}
.mobility-section .image.c3 {
  background-image: url("../images/misc/mobility_c3.png");
}
/*Featured section*/
.featured-section {
  background-color: #4bb6f1;
  padding: 50px 0;
}
.accessibility-banner-section .container #accessibility-banner-title{
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  padding: 40px 0;
  color: black;
  max-width: 651px;
  margin: 0 auto;
}
.accessibility-banner-section .container .accessibility-banner-topics-wrapper{
  display: flex;
  flex-flow: row;
}
.accessibility-banner-section .container .accessibility-banner-topics-wrapper .accessibility-banner-topics{
  position: relative;
  width: 100%;
}
.accessibility-banner-section .container .accessibility-banner-topics-wrapper .accessibility-banner-topics .topic-icon{
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  max-width: 335px;
  margin: 20px 0;
}
.accessibility-banner-section .container .accessibility-banner-topics-wrapper .accessibility-banner-topics h4{
  font-size: 24px;
  text-align: center;
}
.accessibility-banner-section .container .accessibility-banner-topics-wrapper .accessibility-banner-topics p{
  text-align: center;
  width: 100%;
  padding: 0px 50px 10px 50px;
  font-size: 18px;
  color: black;
}
.accessibility-banner-section .container .accessibility-banner-topics-wrapper .accessibility-banner-topics .learn-more-wrapper{
  width: 100%;
  position: relative;
}
.accessibility-banner-section .container .accessibility-banner-topics-wrapper .accessibility-banner-topics .learn-more-wrapper .learn-more-arrow{
  margin-left: 5px;
}
.accessibility-banner-section .container .accessibility-banner-topics-wrapper .accessibility-banner-topics a{
  display: inline-block;
  font-size: 16px;
  width: auto;
  color:#0e75ba;
  font-weight: bold;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
}
.accessibility-banner-section .container #accessibility-banner-footer-title{
  position: relative;
  text-align: center;
  padding: 50px 0 0 0;
  color: black;
  font-size: 22px;
}
.accessibility-banner-section .container #accessibility-banner-button-container{
  width: 100%;
  text-align: center;
}
.accessibility-banner-section .container #accessibility-banner-button{
  background: orangered;
  font-size: 18px;
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 5px;
  color: white;
}
.accessibility-banner-section{
  background-color: #fbfbfb;
  padding-bottom: 50px;
}
.featured-section-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 34px;
  margin: 0;
  color: #fff;
}
.featured-section-text {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 30px;
}
.featured-section .row {
  margin: 0 -10px;
}
.featured-section .row [class*="col"] {
  padding: 0 10px;
}
.featured-item {
  display: block;
  min-height: 100px;
  margin-bottom: 20px;
  background: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90% auto;
}
.featured-item.guardian {
  background-image: url(../images/home-page/guardian.png);
}
.featured-item.today {
  background-image: url(../images/home-page/today.png);
  background-size: auto 90%;
}
.featured-item.beacon {
  background-image: url(../images/home-page/thebeacon.jpg);
}
.featured-item.motherboard {
  background-image: url(../images/home-page/motherboard.png);
}
.featured-item.huffington {
  background-image: url(../images/home-page/huffington.png);
}
.featured-item.nytimes {
  background-image: url(../images/home-page/nytimes.png);
  background-size: auto 90%;
}
.featured-item.yoocan {
  background-image: url(../images/home-page/yooocan.jpeg);
}
.featured-item.pantou {
  background-image: url(../images/home-page/pantou.jpg);
  background-size: auto 90%;
}
.featured-item.mduk {
  background-image: url(../images/home-page/mduk.jpg);
  background-size: auto 90%;
}
.featured-item.enat {
  background-image: url(../images/home-page/enat.png);
  background-size: auto 90%;
}
.featured-item.aws {
  background-image: url(../images/home-page/aws.png);
  background-color: #f7f7f7;
}
.featured-item.amadeus {
  background-image: url(../images/home-page/amadeus.png);
  background-color: #f7f7f7;
}
.featured-item-eic{
  display: block;
  height: 100px;
}
.featured-item-eic img{
 display: block;
 height: 100%;
 margin: auto;
 max-width: 100%;
}
.featured-item.veckans {
  background-image: url(../images/home-page/veckans-affarer.png);
  background-size: auto 90%;
}
.home-page__top-title span{
  background-color: #1e1e1ea3;
  font-size: 31px;
  margin-top: 5px;
  padding: 3px;
}
.header-contact strong {
  padding: 4px;
  display: block;
}
.home-page__content .carousel-slider{
  height: 249px;
}
.home-page__content .carousel .control-dots{
 margin: 10px 0 20px 0;
}
.home-page__content .carousel-slider .info-section-column-content{
  height: 200px;
  background: #F5F7F9;
  border: 1px solid rgba(0, 0, 0, 0.12);  
  border-radius: 5px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  width: 80%;
  margin: auto;
}
.home-page__content .carousel-slider .control-arrow.control-next::before{
  content: '';
    position: absolute;
    height: 36px;
    width: 30px;
    background-image: url('../images/icons/slider-arrow-icon.svg');
    top: 34%;
    right: -1px;
    background-position: center;
}
.home-page__content .carousel-slider .control-arrow.control-prev::before {
  content: '';
  position: absolute;
  height: 36px;
  width: 30px;
  background-image: url('../images/icons/slider-arrow-icon.svg');
  left: -4%;
  top: 34%;
  transform: rotate(180deg);
  background-position: center;
}
.home-page__content .carousel-slider  .dot{
  background: rgba(196, 196, 196, 0.5);
    box-shadow: none;
    width: 10px;
    height: 10px;
}
.home-page__content .carousel-slider  .dot.selected{
  background-color: #2574A9;
}
.home-page__content .carousel-slider .slide{
  background-color: white;
}
@media (max-width: 1330px) {
  .home-page__top-title,
  .search-wrapper {
    width: 94%;
  }
  .home-page .home-page__top-section .header-contact{
    right:3%;
  }
}
@media (max-width: 1140px) {
  .home-page__top-title,
  .search-wrapper {
    width: 94%;
  }
  .home-page__top-section {
    display: block;
    height: auto;
  }
  .home-page__top-section-content {
    display: block;
    padding: 100px 0;
    padding-top: 25%;
  }
  .search-wrapper .date-picker .DateInput__display-text,
  .search-wrapper .search-input-field input,
  .search-wrapper,
  .search-wrapper .mobile-drop-down-wrapper .mobile-drop-down li p{
    font-size: 12px;
  }
  /*.search-wrapper .search-input-field ::-webkit-input-placeholder,*/
  /*.search-wrapper .search-input-field input::-moz-placeholder,*/
  /*.search-wrapper .search-input-field input::placeholder*/
  .search-wrapper .date-picker .DateRangePickerInput__calendar-icon {
    padding: 15px 4px 15px 0;
  }
}
@media (max-width: 991px) {
  .home-page .container {
    max-width: 100% !important;
  }
}
@media (max-width:980px){
  .accessibility-banner-section .container .accessibility-banner-topics-wrapper .accessibility-banner-topics .topic-icon{
    width: 260px;
  }
  .accessibility-banner-section .container .accessibility-banner-topics-wrapper .accessibility-banner-topics p{
    padding: 0px 15px 10px 15px;
  }
}
@media (max-width: 970px) {
  .search-wrapper {
    width: 98%;
  }
  .info-section-text {
    margin: 0;
  }
}
@media (max-width: 900px) {
  /*.search-wrapper {*/
  /*padding: 0;*/
  /*background: transparent;*/
  /*}*/
  .home-page .home-page__top-section .header-contact{
    top: 12%;
  }
  .mail-section-content {
    padding: 30px;
  }
  .mail-section-form {
    padding: 48px 0 0;
  }
  .home-page__top-title span{
    background-color: #1e1e1ea3;
    font-size: 22px;
    margin-top: 5px;
    padding: 3px;
  }
  /* .header-contact strong {
    background-color: #1e1e1ea3;
    padding: 4px;
    display: block;
  } */
}
@media (max-width: 767px) {
  .info-section-column-text {
    margin: 0;
  }
  .info-section-column {
    margin-bottom: 30px;
  }
  .search-wrapper{
    padding:7.5px !important;
  }
  .search-wrapper .search {
    margin: 0;
  }
  .search-wrapper .search-input-field {
    display: block;
    width: 100%;
    margin: 0;
  }
}
@media (max-width: 690px){
  .accessibility-banner-section .container .accessibility-banner-topics-wrapper{
    flex-flow: column;
  }
  /* .accessibility-banner-section .container .accessibility-banner-topics-wrapper .accessibility-banner-topics p{
    padding: 0 20% 5px 20%;
  } */
  .accessibility-banner-section .container .accessibility-banner-topics-wrapper .accessibility-banner-topics .learn-more-wrapper{
    margin-bottom: 10px;
  }
}
@media (min-width: 576px) {
  .row.small-items [class*="col-"]:nth-child(n + 3) {
    margin-bottom: 0;
  }
  .search-wrapper {
    padding: 7.5px;
  }
}
@media (max-width: 575px) {
  .home-page__top-title h2,
  .home-page__top-title h4 {
    line-height: 38px;
    font-size: 34px;
  }
  .info-section-column {
    margin-bottom: 20px;
  }
  .ignite-section-content,
  .mail-section-form-input,
  .mail-section-form-button {
    width: 100%;
  }
  .mail-section-form-input {
    margin-bottom: 20px;
  }
  .info-card-box {
    margin-bottom: 10px;
  }
  .stories-slider-item {
    height: 300px;
  }
  .avatar-and-link-holder .stories-item-content-avatar,
  .avatar-and-link-holder .stories-item-content-link,
  .stories-item-content-btn {
    float: none;
    margin: 0 auto 10px;
    text-align: center;
  }
  .avatar-and-link-holder {
    float: none;
    max-width: 100%;
  }
}
@media (max-width:400px){
  .home-page__content .carousel-slider .info-section-column-content{
    height:auto
  }
  .home-page__content .carousel-slider{
    height: auto;
  }
  .carousel .slider-wrapper.axis-horizontal .slider{
    margin-bottom: 20px;
  }
  .carousel .control-next.control-arrow:before{
    border-left:none;
  }
}
@media (max-width: 360px) {
.accessibility-banner-section .container .accessibility-banner-topics-wrapper .accessibility-banner-topics p{
    padding: 0;
  }
}