.mobile-search-box{
    
}
.mobile-search-box__top-section{
    height: 210px;
    background-color: blue;
    background-image: url('../../images/home-page/bg-3.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    color: white;
}
.mobile-search-box__title{
    position: relative;
    flex: 1;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    padding: 20px;
}
.mobile-search-box__title::after{
    content: '';
    position: absolute;
    width: 65px;
    height: 65px;
    background-image: url('../../images/icons/feefo-icon-mobile.svg');
    right: 9%;
    top: 40%;
}
.mobile-search-box__call-us{
    background: rgba(30, 30, 30, 0.5);
    padding: 10px 30px 10px 50px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: white;
    position: relative;
}
.mobile-search-box__call-us-small{
    font-size: 12px;
}
.mobile-search-box__call-us::before{
    content: '';
    position: absolute;
    background-image: url('../../images/icons/phone-icon.svg');
    width: 15px;
    height: 16px;
    top: 12px;
    left: 30px;
}
.mobile-search-box__form-section{
    padding: 20px 15px;
    background-color: #E5E5E5;
}
.mobile-search-box__form-section .google-input-field{
    height: 60px;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    position: relative;
}
.mobile-search-box__form-section .google-input-field input{
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-indent: initial;
    padding-left: 40px;
    border: none;
}
.mobile-search-box__form-section .google-input-field::before{
    content: '';
    position: absolute;
    height: 33px;
    width: 33px;
    background-image: url('../../images/icons/map-pin-icon.svg');
    top: 19%;
    left: 5px;
}
.mobile-search-box__form-section .autocomplete-container{
    border: none;
}
.mobile-search-box__form-section .mobile-drop-down-wrapper{
    width: initial;
    height: 60px;
    margin-top: 10px;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
}
.mobile-search-box__form-section .mobile-drop-down-wrapper .current-option{
    padding: 0;
    height: 100%;
    min-height: initial;
    font-weight: bold;
    font-size: 16px;
    line-height: 13px;
    color: #666666;
}
.mobile-search-box__form-section .date-picker{
    height: 60px;
    margin-top: 10px;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
}
.mobile-search-box__form-section .counter-field{
    height: 60px;
    margin-top: 10px;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    display: flex;
    
}
.mobile-search-box__form-section .input-field-wrapper{
    border-left: 1px solid #C4C4C4;
    border-right: 1px solid #C4C4C4;
}
.mobile-search-box__form-section .counter-field .minus{
    order: 1;
    min-height: initial;
    width: 60px;
    border-radius: 4px 0 0 4px;
    margin: 0;
    border: none;
}
.mobile-search-box__form-section .counter-field .plus{
    order: 3;
    min-height: initial;
    width: 60px;
    border-radius: 0 4px 4px 0;
    margin: 0;
    border: none;
}
.mobile-search-box__form-section .blue-large{
    color: white;
    background-color: #2574A9;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    border-radius: 4px;
    width: 100%;
    margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: initial;
}
@media only screen and (max-width: 374px){
    .mobile-search-box__title::after{
        top:20%;
    }
}
@media only screen and (max-width:300px)
{
    .mobile-search-box__title{
        padding:30px 20px 20px 20px;
    }
}