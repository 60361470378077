.footer {
  position: relative;
  z-index: 2;
}
.footer-dropdowns {
    border-radius: 3px;
    border: none;
}
.footer .footer-first-part {
  position: relative;
  background-image: url("../images/footer_background.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  z-index: 2;
  padding: 50px 15px 80px;
}
.footer .footer-first-part:before {
  content: "";
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer > .footer-second-part > .footer-bottom {
   background: rgba(0, 0, 0, 0); 
   padding: 0px; 
   float: none; 
}
.footer .footer-second-part {
  background: #16171b;
  padding: 30px 15px 50px;
}
.footer-sub-title-holder {
  margin-bottom: 50px;
}
.footer .footer-dropdowns select {
  border:none;
}
.footer .footer-dropdowns .select-dropdown{
  border: none;
  border-radius: 3px;
}
.footer .footer-dropdowns li:first-child {
  margin-bottom: 10px;
}
.footer .footer-dropdowns select {
  border: none;
}
.footer .columns {
  display: flex;
}
.footer .columns .column {
  /*padding-right: 40px;*/
  margin-top: 20px;
  /*max-width: 20%;*/
}
.footer h3 {
  color: #d4d4d4;
  margin-bottom: 5px;
  font-weight: bold;
}
.footer a {
  color: #d4d4d4;
  font-weight: 500;
}
.footer a:hover {
  color: rgba(255,255,255,0.4);
}
.footer .columns.first-row h3,
.footer .columns.first-row a {
  color: #000;
}
.footer .separator {
  background-color: #4c4c4c;
  margin-top: 27px;
  margin-bottom: 20px;
}
.footer .columns ul li {
  display: block;
  margin: 0;
}
.footer .footer-bottom {
  margin-top: 10px;
  color: #d4d4d4;
  font-weight: 500;
  text-align: center;
}
.footer .footer-bottom p {
  color: #d4d4d4;
  font-weight: 500;
}
.footer .footer-bottom ul li {
  margin-left: 10px;
}
.without-heading {
    margin-top: 26px;
}
.footer-social-links {
  margin-bottom: 10px;
}
.social-link {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin: 0 3px;
  border-radius: 7px;
}
.social-link.fb {
  background: url("../images/icons/fb-icon-new.png");
}
.social-link.li {
  background: url("../images/icons/li-icon-new.png");
}
.social-link.gp {
  background: url("../images/icons/gp-icon-new.png");
}
.social-link.in {
  background: url("../images/icons/inst-icon2.png");
  background-size: 50px 50px;
}
.social-link.tw {
  background: url("../images/icons/tw-icon-new.png");
}
.social-link.pin {
    background: url("../images/icons/pin-icon-new.png");
}
.social-link.vk {
    background: url("../images/icons/vk-icon-new.png");
}
.reciteme {
  cursor: pointer;
  padding: 4px;
}
.reciteme:hover {
  text-decoration: underline;
}
.footer-dropdowns .select-dropdown label,.select-dropdown label{
  color:white;
}
.currency-dropdown-wrapper::after,.language-dropdown-wrapper::after{
  margin: 30px 0px 0px 0px;
}
.language-dropdown-wrapper{
  margin:35px 0px;
}
.footer .footer-second-part #become-host-anchors{
  color:#d4d4d4; 
}

.footer .footer-second-part #become-host-anchors:hover{
  color:rgba(255,255,255,0.4); 
}
@media (min-width: 768px)  {
    .footer-without-heading {
        padding-top: 26px;
    }
}
.footer-title-holder a{
  font-weight: bold;
  display: block;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  margin: auto;
  color: #3097E3;
  text-shadow: 0px 0.445px 0.741667px rgba(0, 0, 0, 0.1);
}
.footer-title-holder a:hover{
  color: #3097E3;
}
.footer-sub-title-holder h2{
  font-weight: 500;
font-size: 18px;
line-height: 24px;
text-align: center;
color: rgba(0, 0, 0, 0.87);
text-shadow: 0px 0.445px 0.741667px rgba(0, 0, 0, 0.1);
}
.footer-links{
  padding: 0 10%;
}
.footer-links ul{
  display: flex;
  flex-direction: column;
    max-height: 560px;
    flex-wrap: wrap;
}
.footer-links h3{
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  min-height: 60px;
}
.footer-links li a{
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
} 
.footer-links li a:hover{
  color: #000;
}
.footer-popular-button{
  display: none;
}
@media (max-width: 768px)  {
  .footer-without-heading {
      padding-top: 26px;
  }
  .footer-links ul {
    overflow: scroll;
    padding-top: 10px;
  }
  .footer-links .footer-popular-countries{
    max-height: 190px;
  }
  .footer-links .footer-top-destinations,
  .footer-links .footer-top-fully-accessible-accommodations
  {
    max-height: 400px;
  }
  .footer-links h3{
    min-height: initial;
    flex: 1;
  }
  .footer-popular-header{
    border-bottom: 1px solid rgba(102, 102, 102, 0.5);
    display: flex;
    padding-bottom: 15px;
    align-items: center;
    margin-top: 10px;
  }
  .footer-links{
    padding: 0;
  }
  .footer-links li a {
    font-size: 14px;
  }
  .footer-popular-button{
    display: block;
    height: 24px;
    width: 24px;
    background-image: url('../images/icons/footer-arrow-icon.svg');
    background-color: transparent;
    padding: 0;
  }
  .footer-popular-button-open-true{
    transform: rotate(180deg);
  }
}