.coupon-input-field {
  display: flex;
  width: 100%;
}

.coupon-input-field.added.flex-column{
  flex-direction: column;
}

.coupon-input-field > * {
  flex: 1;
}

.coupon-input-field button {
  max-width: 50px;
  padding: 0;
}
