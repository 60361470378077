.add-channel-manager-modal .channel-manager-modal-title{
  margin: 0;
  width: 100%;
  padding: 20px;
  border-radius: 0;
  background-color: #28b6ff;
}
.dashboard-content .dashboard-settings-layout .dashboard-custom-box .border{
  border-right: none!important;
  border-left: none!important;
  border-top: none!important;
}
.add-channel-manager-modal .channel-manager-modal-under{
  border: none;
  padding: 30px 40px;
}
.add-channel-manager-modal .channel-manager-modal-dropdowns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
}
.add-channel-manager-modal .channel-manager-modal-dropdowns select {
    width: 100%;
}
.add-channel-manager-modal .channel-manager-modal-dropdowns .channel-pick {
    flex: 1;
    margin-right: 10px;
}
.add-channel-manager-modal .channel-manager-modal-dropdowns .cancellation-pick {
    flex: 1;
    margin-left: 10px;
}

.add-channel-manager-modal .channel-manager-modal-title h1{
  margin-bottom: 0;
}
.add-channel-manager-modal .add-channel-manager{
  justify-content: flex-start;
}
.add-channel-manager-modal .input-field-wrapper{
    width:100%;
    margin-bottom: 20px;
}
.add-channel-manager-modal button.add-channel-manager {
    width: 100%;
    margin-bottom: 20px;
    justify-content: center;
    background-color: #28b6ff;
}
.add-channel-manager-modal button.add-channel-manager:hover {
    background-color: #3bc4ff;
}
.add-channel-manager-modal .select-dropdown{
    display: flex;
}
.add-channel-manager-modal .handle-channel-managers-modal-dropdown{
  cursor: pointer;
}
