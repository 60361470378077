.select-dropdown {
  position: relative;
  height: 52px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
}

.select-dropdown::before {
  background-image: url('../images/misc/arrow.svg');
  position: absolute;
  pointer-events: none;
  color: #767676;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  bottom: 0;

  right: 10px;
  width: 2em;
  text-align: center;
}
.select-dropdown::after {
    content:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy44OCIgaGVpZ2h0PSI4LjY4NyIgdmlld0JveD0iMCAwIDEzLjg4IDguNjg3Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzMzMztmaWxsLXJ1bGU6ZXZlbm9kZH08L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0xMy44NCAxLjYxNGMwIC4wMDYuMDEuMDEyLjAxLjAxOHYuMDI2YS4zNjkuMzY5IDAgMCAxIC4wMi4xMDUuMzMxLjMzMSAwIDAgMS0uMDkuMjM4TDcuMjEgOC41NzVhLjI4Ny4yODcgMCAwIDEtLjA1LjAzMy40MDkuNDA5IDAgMCAxLS40OC0uMDMzTC4xIDIuMDA1YS4zMzkuMzM5IDAgMCAxLS4wOS0uMjQyLjMyNC4zMjQgMCAwIDEgLjA5LS4yMzdMMS41Ni4wNjVhLjE3OC4xNzggMCAwIDEgLjEzLS4wNTEuMTcyLjE3MiAwIDAgMSAuMTIuMDUxbDUuMSA1LjFhLjE2My4xNjMgMCAwIDAgLjA3LS4wMTNMMTIuMDcuMDYxYS4xNzIuMTcyIDAgMCAxIC4xMi0uMDUxLjE3OC4xNzggMCAwIDEgLjEzLjA1MWwxLjQ2IDEuNDYxYS44MzguODM4IDAgMCAxIC4wNi4wOTJ6Ii8+PC9zdmc+);
    position:absolute;
    pointer-events:none;
    color:#767676;
    display:flex;
    align-items:center;
    justify-content:center;
    top:0;
    height:100%;
    bottom:0;
    right:10px;
    width:2em;
    text-align:center;
}

.select-dropdown > select {
  height: 100%;
  min-height: 35px;
  border: none;
  cursor: pointer;
}

.select-dropdown.err {
  border: solid 1px #F00;
  /*border: solid 1px #aaa;*/
}

.select-dropdown .disabled {
    background-color: #EEEEEE;
}