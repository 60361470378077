.page-not-found-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url('../../images/page-not-found/page-not-found.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.page-not-found-wrapper .info {
  width: 45vw;
  min-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.page-not-found-wrapper .info h1,
.page-not-found-wrapper .info h4,
.page-not-found-wrapper .info p {
  color: #fff !important;
  font-weight: bold;
}

.page-not-found-wrapper .info h1 {
  letter-spacing: 3.76px;
  font-weight: bold;
  font-size: 60px;
  margin-top: 52px;
  margin-bottom: 30px;
}

.page-not-found-wrapper .info h4{
  letter-spacing: 1.88px;
  font-size: 30px;
  margin-bottom: 45px;
}

.page-not-found-wrapper .info p{
  letter-spacing: 1.32px;
  font-size: 21px;
  width: 70%;
  min-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

.page-not-found-wrapper .info p:last-of-type{
  margin-bottom: 45px;
}

.page-not-found-wrapper .info button{
  margin-bottom: 45px;
  font-size: 21px;
}


@media only screen and (max-width: 630px) {
  .page-not-found-wrapper .info {
    width: 100vw !important;
    min-width: 0px !important;
    font-size: 25px;
    height: 100%;
  }

  .page-not-found-wrapper .info h1 {
    font-size: 48px;
  }

  .page-not-found-wrapper .info p{
    width: 90% !important;
    min-width: 0px !important;
    font-size: 18px;
  }

  .page-not-found-wrapper .info button{
    font-size: 16px;
  }
}
