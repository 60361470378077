.slider-wrapper.blog-page {
  width: 65%;
  min-width: 1000px;
  margin: auto;
}

.blog-post-widget-card.blog-page,
.blog-page .blog-post-widget-card-spinner {
  width: calc((100% - 120px) / 3) !important;
  margin: 20px !important;
}

.blog-page .blog-post-widget-card-loader-wrapper {
  min-height: 300px;
}

/*MEDIUM SCREENS*/
@media only screen and (min-width: 601px) and (max-width: 1400px) {

  .blog-post-widget-card.blog-page,
  .blog-page .blog-post-widget-card-spinner {
    width: calc((100% - 80px) / 2) !important;
    margin: 20px !important;
  }
}

@media only screen and (max-width: 950px) {

  .slider-wrapper.blog-page {
    width: 100%;
    min-width: initial;
  }
}

/*SMALL SCREENS*/
@media only screen and (min-width: 0px) and (max-width: 600px) {

  .blog-post-widget-card.blog-page,
  .blog-page .blog-post-widget-card-spinner {
    width: 100% !important;
    margin: 0px !important;
    margin-top: 4% !important;
  }
}
