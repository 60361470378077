.blogPageWrapper {
  min-height: calc(100vh - 349px);
  /* 349 px is footer height */
  padding-bottom: 60px;
}

.blogPageWrapper>div:last-child {
  margin-top: 100px;
}

.blog-latest-articles {
  margin-top: 20px;
}

.blogWrapper {
  max-width: 1000px;
  margin: auto;
  margin-top: 100px;
}

.blogWrapper .details {
  text-align: center;
}

.blogWrapper .details h3 {
  color: #28b6ff !important;
  margin-bottom: 5px !important;
  width: 80%;
  margin: 0 auto;
}

.blogWrapper .details p {
  color: black !important;
  font-size: 16px !important;
  width: 80%;
  margin: 0 auto;
}

.blogWrapper .crp-tile-img {
  margin-bottom: 10px !important;
  padding: 10px !important;
}

.crp-tile.ftg- {
  width: calc(50% - 20px) !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  float: left;
  position: relative;
}

.crp-tile.ftg-:last-of-type {
  margin-bottom: 80px;
}

.blogWrapper img {
  max-width: max-content;
  width: 100%;
  height: auto !important;
  object-fit: contain;
  margin-top: 0px;
  margin-bottom: 30px;
}

.blogWrapper figure {
  width: 100% !important;
}

.blogWrapper figure img {
  margin-bottom: 10px;
  min-width: 100%;
  height: auto;
}

.blogWrapper figure figcaption {
  font-size: 17px;
  display: none;
}

.blogWrapper h1 {
  color: #28b6ff;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-size: 48px;
  line-height: 48px;
  margin-top: 8%;
  margin-bottom: 10px;
  text-align: center;
}

.blogWrapper h2 {
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 5px;
}

.blogWrapper h2 strong {
  color: #454545;
  font-size: 34px;
  font-weight: 800;
}

.blogWrapper h2:first-child {
  margin-top: 0;
}

.blogWrapper h2:first-child strong {
  color: #666666;
  font-weight: 500;
  font-size: 28px;
  line-height: 1.14;
}

.blogWrapper>div>h2:first-child {
  margin-bottom: 4% !important;
}

.blogWrapper h3 {
  color: #454545;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.57;
  margin-bottom: 0;
  margin-top: 5px;
}

.blogWrapper ol {
  margin-bottom: 0 !important;
  margin-top: 5px;
}

.blogWrapper ul {
  list-style: initial;
}

.blog-fader-loginFormContainer {
  position: relative;
  height: auto;
  width: auto;
}

.blogPostfader {
  position: absolute;
  margin-top: -120px;
  left: 0;
  right: 0;
  height: 120px;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #fff);
}

.blogWrapper .signup-login-modal button {
  padding: 0px 10px 0px 50px;
}

.blogPageWrapper .search-bar-wrapper {
  position: fixed;
  width: 100%;
  background-color: #1e1e1e;
  padding-left: 10px;
  padding-right: 10px;
}

.blogPageWrapper .search {
  justify-content: center;
}

.blogPageWrapper .search-wrapper {
  width: 100%;
}

.blogPageWrapper .search-bar-wrapper {
  z-index: 10;
}

.blogPageWrapper .search-bar-wrapper #search-accomodation {
  width: 20%;
}

@media only screen and (max-width: 1080px) {
  .blogWrapper {
    width: 800px;
    margin: auto;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 820px) {
  .blogPageWrapper .search-bar-wrapper {
    position: initial;
    width: 100%;
    padding: 0;
  }
  .blogPageWrapper>div:last-child, .blogPageWrapper .blog-menu-wrapper {
    margin-top: 0;
  }
  .blogWrapper {
    width: 540px;
    margin: auto;
    margin-top: 40px;
  }
  .crp-tile.ftg- {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    float: left;
  }
}

@media only screen and (max-width: 550px) {
  /* For mobile phones: */
  .blogWrapper {
    width: 90vw;
    margin-left: 5vw;
    margin-top: 40px;
  }
  .blogWrapper h1 {
    font-size: 30px;
  }
  .blogWrapper h2 strong {
    font-size: 25px !important;
  }
  .blogWrapper p {
    font-size: 18px !important;
  }
  .blogWrapper .search-field-wrapper {
    width: 70vw !important;
  }
  .blogWrapper .search-submit {
    width: 15vw !important;
    height: 99% !important;
    padding: 0px !important;
    font-size: 30px !important;
    vertical-align: bottom;
  }
  .blogWrapper .search-submit svg {
    margin-top: 10%;
  }
  .blogWrapper .search-submit span {
    visibility: hidden;
  }
}

/* INPUT */

.handiscover-searchbox {
  display: none;
}

.handiscover-searchbox .search-field {
  height: 55px;
}

.blogWrapper .search_jobs {
  height: 55px;
  width: 100%;
  position: relative;
}

.blogWrapper .search-field-wrapper {
  width: 400px;
  height: 100%;
  margin-right: 0;
  display: inline-block;
}

.blogWrapper .search-submit {
  display: inline-block;
  width: 170px;
}

.blogWrapper input::placeholder {
  font-weight: 400;
}

.blogWrapper iframe {
  max-width: 100%;
}

.blogWrapper .blog-post .share-wrapper :first-child {
  margin-top: 0;
}

/* LIST ITEMS */

.blogWrapper li {
  margin-top: 10px;
  color: #454545;
  display: list-item;
  list-style-position: inside;
  font-size: 20px;
  font-weight: 600;
  /* line-height: 2; */
  word-wrap: break-word;
}

/* BOTTOM LINKS */

.blogWrapper li a {
  font-size: 18px;
  line-height: 1;
}

/* HOTEL CONVERSION */

.blogWrapper .details h3 {
  font-size: 21px !important;
  line-height: 1.57;
  display: inline-block;
}

.blogWrapper .gallery-85 {
  margin: auto;
}

.blogWrapper .gallery-85>*:not(script) {
  display: inline-block;
  vertical-align: top;
  width: 45%;
}

.blogWrapper .gallery-85>*:first-child {
  margin-right: 8%;
  float: right;
}

.blogWrapper .metaData {
  margin-bottom: 0px;
  color: gray;
  font-weight: bold;
  font-size: 18px;
}

.blogWrapper p {
  color: #000000;
  font-size: 21px;
  line-height: 1.57;
  word-wrap: break-word;
  /* display: inline-block; */
}

.blogWrapper .blog-post p {
  font-size: 18px;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  line-height: 31px;
  margin-bottom: 15px;
}

.blogWrapper .blog-post .entry-content p {
  margin: 0 0 1.5rem;
}

.blogWrapper .blog-post h2 {
  font-size: 32px;
  margin-top: 5px;
  font-weight: 700;
}

.blogWrapper .blog-post h3, .blogWrapper .blog-post h3>span {
  font-size: 30px;
  margin-top: 5px;
  margin-bottom: 34px;
  /* font weight is important since it comes from wordpress with inline styles that need to be overwriten*/
  font-weight: bold !important;
  line-height: 36px;
  font-family: inherit;
}

.blogWrapper .blog-post h4 {
  font-size: 18px;
  margin-top: 5px;
  font-weight: 600;
}

.blogWrapper .blog-post h5 {
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 5px;
  font-weight: 700;
}

.blogWrapper .blog-post small {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
}

/* EMBEDDED VIDEOS */

.blogWrapper iframe {
  display: block;
  margin: auto;
}

.blogPageWrapper .header-hidden-in-blog {
  display: none;
}

.blogPageWrapper .mobile-search-box .search-hidden-in-blog {
  overflow: hidden;
  height: 90px;
}
.blog-menu-arrow-wrapper {
  display: none;
}
.blogPageWrapper .blog-menu-arrow-wrapper {
  display: flex;
  justify-content: center;
  background-color: #E5E5E5;
}

.blogPageWrapper .blog-menu-arrow-wrapper button {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.blogPageWrapper .blog-menu-arrow-wrapper button>img {
  width: 15px;
}

@media only screen and (max-width: 820px) {
  .blogWrapper iframe {
    max-width: 100%;
    height: 303px;
  }
}

@media only screen and (max-width: 540px) {
  .blogWrapper iframe {
    height: calc(90vw*0.5625);
  }
}

div.share-panel, a.ic-search, a.ic-link {
  display: none !important;
}

div.crp-tile .details {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.50196078431373) !important;
  width: 100%;
}

div.crp-tile .details h3, div.crp-tile .details p {
  color: white !important;
  width: auto !important;
  display: block;
  /* to separate text lines */
}

.ftg-items {
  display: flex;
  flex-wrap: wrap;
  width: 1000px;
  max-height: 2000px;
}

div.crp-tile {
  position: relative;
  /* height: 306px; */
  margin-bottom: 35px!important
}

.ftg-items img.crp-tile-img {
  /* position: absolute; */
  padding: 0px !important;
  max-height: 360px !important;
  max-width: 480px;
}

@media only screen and (max-width:1080px) {
  .ftg-items {
    width: 100%;
  }
  div.crp-tile {
    position: relative;
    /* height: 247px; */
  }
  .ftg-items img.crp-tile-img {
    max-width: 380px;
  }
}

@media only screen and (max-width:820px) {
  .ftg-items {
    width: 100%;
  }
  div.crp-tile {
    position: relative;
    /* height: 335px; */
  }
  .ftg-items img.crp-tile-img {
    max-width: 540px;
  }
}

@media only screen and (max-width:550px) {
  .ftg-items {
    width: 100%;
  }
  div.crp-tile {
    position: relative;
    /* height: 305px; */
  }
  .ftg-items img.crp-tile-img {
    max-width: 460px;
  }
}

@media only screen and (max-width:440px) {
  .ftg-items {
    width: 100%;
  }
  div.crp-tile {
    position: relative;
    /* height: 251px; */
  }
  .ftg-items img.crp-tile-img {
    max-width: 330px;
  }
}

@media only screen and (max-width:340px) {
  .ftg-items {
    width: 100%;
  }
  div.crp-tile {
    position: relative;
    /* height: 208px; */
  }
  .ftg-items img.crp-tile-img {
    max-width: 100%;
  }
}

@media only screen and (max-width:240px) {
  .ftg-items {
    width: 100%;
  }
  div.crp-tile {
    position: relative;
    /* height: 154px; */
  }
}

.gallery {
  display: inline-flex
}

.gallery-item {
  max-width: 50%;
  margin: 10px;
}

.blogPageWrapper .blog-menu-wrapper {
  /*margin-top: 14px;*/
}

img.fts-twitter-description-image {
  width: auto;
  max-width: 100%;
  height: auto !important;
  object-fit: contain;
  margin-top: 0px;
  margin-bottom: 0px;
}
/* Good to know, when updating the wordpress pluggin Feed Them Social also update the class name
   and corresponding css*/

.fts-tweet-others-right {
  display: flex !important;
  flex-direction: raw !important;
  align-items: flex-end !important;
  white-space: nowrap !important;
  margin-top: 5px !important;
  align-content: flex-end !important;
}

.fts-twiter-retweet-feed, .fts-twitter-favorites-feed {
  display: inline-block !important;
  margin-left: 20px !important;
}

.fts-twiter-retweet-feed {
  margin-left: auto !important;
}

.fts-twitter-retweet-feed svg,
 .fts-twitter-favorites-feed svg, .fts-twitter-reply-feed svg {
  height: 19px;
  width: 19px;
  font-size: 16px;
  font-family: Quicksand;
}

.fts-share-wrap svg, .fts-twitter-reply-feed svg {
  display: none !important;
}
