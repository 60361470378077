.channel-managers-page {
  padding: 20px;
}

.channel-managers-page .add-cm-button {
  margin-top: 20px;
}

.channel-managers-table .hidden-tr {
  display: none;
}

.channel-managers-table thead {
  font-weight: bold;
}

.channel-managers-table td {
  border: 1px solid rgba(0,0,0,0.2);
  padding: 5px;
}

.add-channel-manager-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-channel-manager-modal .channel-manager-modal-title {
  background-color: #48a1f8;
  width: auto;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  height: auto;
  border-radius: 3px;
  margin: 40px;
  margin-bottom: 20px;
}

.add-channel-manager-modal .channel-manager-modal-title h1 {
  margin-top: 20;
  font-size: 22px;
  color: white;
}

.add-channel-manager-modal .add-channel-manager {
  display: flex;
  justify-content: center;
}

.add-channel-manager-modal .channel-manager-modal-dropdowns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.add-channel-manager-modal .channel-manager-modal-dropdowns select {
  width: 220px;
}

.add-channel-manager-modal .channel-manager-modal-under {
  background-color: #F9F9F9;
  width: 100%;
  padding: 40px;
  height: 100%;
  border-top: 1px solid #EBEBEB;
}
