.payment-methods-page .add-wrapper {
  margin: 20px;
}

.payment-add-card-modal-header {
  margin-bottom: 15px;
}

.payment-add-card-modal #stripe-card-element {
  padding: 16px 15px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.payment-methods-page {
  width: 100%;
}
.payment-methods-page .dashboard-custom-box .inner-content {
  padding: 20px;
}
.payment-methods-page table {
  border-collapse: collapse;
  width: 100%;
}
.payment-methods-page table thead td {
  border: 1px solid rgb(150, 150, 150);
  border-bottom: none;
}
.payment-methods-page table td {
  padding: 10px;
}
.payment-methods-page table thead {
  background: rgb(150, 150, 150);
  color: #fff;
}
.payment-methods-page table tbody td {
  border: 1px solid rgb(200, 200, 200);
}
.payment-methods-page .retry-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
