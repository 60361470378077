.profile-settings__tabs{
    display: flex;
    border-bottom: 1px solid #F3F3F3;
}
.profile-settings__tab-wrapper button{
    background-color: white;
    font-weight: 500;
    font-size: 18px;
    padding: 20px 20px 20px 43px;
    line-height: 22px;
    outline: none;
    border-radius: 0;
    position: relative;
    border-right: 1px solid #F3F3F3;
    text-transform: capitalize;
}
.profile-settings__tab{    
    color: rgba(121, 118, 118, 0.5);
    border-top: 1px solid #F3F3F3;
}
.profile-settings__tab_active{
    color: #007EB1;
    border-top: 3px solid #007EB1;
}
.profile-settings__tab::before,.profile-settings__tab_active::before{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: 20px;
    background-position: center;
}
.profile-settings__tab_active.profile-settings__tab_general-information::before{
    background-image: url('../../images/user-profile-page/user-icon-active.svg');
}
.profile-settings__tab.profile-settings__tab_general-information::before{
    background-image: url('../../images/user-profile-page/user-icon.svg');
}
.profile-settings__tab_active.profile-settings__tab_disability-information::before{
    background-image: url('../../images/user-profile-page/disability-icon-active.svg');
}  
.profile-settings__tab.profile-settings__tab_disability-information::before{
    background-image: url('../../images/user-profile-page/disability-icon.svg');
} 
.profile-settings__tab_active.profile-settings__tab_travel-preferences::before{
    background-image: url('../../images/user-profile-page/travel-icon-active.svg');
}  
.profile-settings__tab.profile-settings__tab_travel-preferences::before{
    background-image: url('../../images/user-profile-page/travel-icon.svg');
}