.confirmations-page {
    height: calc(100vh - 306px);
}

.confirmations-page p {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  text-align: center;
}
.confirmations-page h1 {
    font-size: 30px;
    color: #484848;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 50px;
}
.confirmation-page-ebook-button {
  background-color: #0E75BA;
  width: 188px;
  height: 55px;
  padding: 17px 0px;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.1);
  border-radius: 3px;
  margin-top: 40px;
}
.confirmation-page-ebook-button p{
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  flex: none;
  align-self: center;
  flex-grow: 0;
  margin: 0px 10px;
  order: 0;
}
.confirmation-page-ebook-container{
  width: 188px;
  margin: auto;
}
