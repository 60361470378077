.property-titles-wrapper .mobility-icon {
  background-color: gray;
  min-width: 64px;
  height: 64px;
  border-radius: 32px;
  margin-right: 20px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.property-titles-wrapper .validated-icon {
  background-color: gray;
  min-width: 64px;
  height: 64px;
  margin-right: 20px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.property-titles-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d4d4d4;
}

.property-titles-wrapper > .between {
  padding: 20px 5px 20px 40px;
  width: 100%;
  max-width: 1200px;
}

.single-property-page-title h1 {
  font-size: 30px;
  font-weight: bold;
  color: #484848;
}

.single-property-page-title h2 {
  font-size: 18px;
  font-weight: 400;
  color: #666;
}

.single-property-page-title h1,
h2 {
  margin-bottom: 0;
}
.property-titles-wrapper div.flex {
  width: 100%;
}
.property-titles-wrapper .title-wrapper {
  align-items: center;
}
.single-property-page-title {
  flex: 1;
}
@media (max-width: 767.98px) {
  .property-titles-wrapper > .between {
    padding: 0;
  }

  .single-property-page-title {
    padding: 20px;
  }
}
@media (max-width: 575px) {
  .property-titles-wrapper .validated-icon {
    margin-right: 0;
  }
}